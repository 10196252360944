import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { baseURL } from "../../BulkUploading/src/BulkUploadingController";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  flag:boolean;
  handleClose:any;
  item:any
  content_type:string;
  value:number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productList:any;
  value:number;
  productVideoList:any;
  playPause:boolean;
  openSnackBar:boolean;
  isAuth:boolean;
  flagOpen:boolean;
  descriptionFlag:boolean;
  items:any;
  contentType:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BlogPostControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    apiProductCallId: string = "";
    apiVideoProductCallId: string = "";
    apiVideoDescCallId:string = "";
    // Customizable Area End
  constructor(props: Props) { 
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.CountryCodeMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      productList:[],
      productVideoList:[],
      value:0,
      playPause:false,
      openSnackBar:false,
      isAuth:false,
      flagOpen:false,
      descriptionFlag:false,
      items:[],
      contentType:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiVideoProductCallId) {
        
        if(responseJson?.errors)
        {
          this.setState({openSnackBar:true})
          setTimeout(()=>{
            this.setState({openSnackBar:false})
          },2000)
          this.props.navigation.navigate("Home")
        }
        else{
        this.setState({productVideoList:responseJson?.data,flagOpen:false})
        
        }
      } 
      if (apiRequestCallId === this.apiProductCallId) {
        if(responseJson?.errors)
        {
          this.setState({openSnackBar:true})  
          setTimeout(()=>{
            this.setState({openSnackBar:false})
          },2000)
          this.props.navigation.navigate("Home")
        }
        else{
        this.setState({productList:responseJson?.data})
        this.setState({flagOpen:false});

        }
      } 
      if(apiRequestCallId ===  this.apiVideoDescCallId)
      { 
        if(responseJson?.errors)
        {
          this.props.navigation?.navigate('BlogPostWeb')
        }
        else{
        this.setState({items:responseJson?.data?.attributes})
        this.setState({descriptionFlag:true});
        }
      }
      if (errorReponse) {
        if (apiRequestCallId === this.apiVideoProductCallId) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiProductCallId) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.state.value === 1 ? this.callGetVideoProductApi()  : this.callGetProductApi();
    window.location.href.split('/').reverse()[0] !== 'blogpost' ? this.handleItem(window.location.href.split('/').reverse()[0]) : NaN;
  }

  handleItem=(i:any)=>{
    this.callGetVideoDescApi(i);
  }
  
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({value:newValue});
  };
  handleVideo=()=>{
    this.callGetVideoProductApi()
  };
  
  handleCopy=(id:any)=>{
  navigator.clipboard.writeText(`${window.location.host}/blogpost/${id}`);
    window.alert('copied');
  }

  handleClose = (_: any, reason: any) => {
    if (reason === 'backdropClick') {
        this.setState({descriptionFlag:false})
    }
}

  // handleShareItem =(i:any)=>this.handleCopy(i?.attributes.id)

  callGetProductApi() {
    this.setState({flagOpen:true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem('authToken')
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProductCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/get_images"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  callGetVideoProductApi() {
    this.setState({flagOpen:true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem('authToken')
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiVideoProductCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/get_videos"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callGetVideoDescApi(id:string) {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem('authToken')
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiVideoDescCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${id}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  

  // Customizable Area End
}