import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
enum API {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  ContentType = "application/json",
  completedOrder = "bx_block_shopping_cart/orders/completed_orders",
  ongoingOrder = "bx_block_shopping_cart/orders/ongoing_orders",
  searchOrder = "bx_block_shopping_cart/orders/search_order?order_no=213862887",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderControl: boolean;
  searchInput1:any;
  startDate: any;
  todayDate:any;
  endDate: any;
  visibleDate: boolean;
  ongoingOrderDetails: any;
  orderDetails: any;
  orderedComplted: any;
  orderStatus: boolean;
  popupOpen: boolean;
  cancelID: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area End
}

export default class UserOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  completedOrderCallId: any;
  ongoingOrderCallId: any;
  getsearchOrderApiCallId: any;
  getSerchDateCallApiId: any;
  cancelItemApiID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderControl: true,
      searchInput1:"",
      startDate: null,
      todayDate:"",
      endDate: null,
      visibleDate: false,
      ongoingOrderDetails: "",
      orderDetails: "",
      orderedComplted: "",
      orderStatus: false,
      popupOpen: false,
      cancelID: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
      if (apiRequestCallId === this.completedOrderCallId) {
        this.setState({ orderedComplted: responseJson.data });
      }
      if (apiRequestCallId === this.ongoingOrderCallId) {
        this.setState({ ongoingOrderDetails: responseJson.data });
        this.setState({orderStatus: false})
      }
      if (apiRequestCallId === this.getSerchDateCallApiId) {
        if (this.state.orderControl === false) {
          this.setState({ orderedComplted: responseJson.data });
        } else {
          this.setState({ ongoingOrderDetails: responseJson.data });
        }
      }
      if (apiRequestCallId === this.getsearchOrderApiCallId) {
         if(!responseJson.data){
          this.setState({orderedComplted:false,ongoingOrderDetails:false})
         }
         if (responseJson.data[0].attributes.status === "completed") {
           this.setState({ orderedComplted: responseJson.data });
         } else {
           this.setState({ ongoingOrderDetails: responseJson.data });
         }
      }
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const current = new Date();
    this.setState({todayDate:current})
    this.getallCompletedOrder();
    this.getallongoingOrder();
  }

  async componentDidUpdate(prevState: any) {
    if (this.state.orderStatus === true && this.state.orderStatus !== prevState.orderStatus) {
      this.getallongoingOrder();
    }
  }

  getSearchDateOrders = (start: any, end:any) => {

    if (start !== null && end !== null) {
      var startsDate = this.formatDate(start);
      var endsDate = this.formatDate(end);
      const header = {
        "Content-Type": API.ContentType,
        token: localStorage.getItem("authToken"),
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getSerchDateCallApiId = getValidationsMsg.messageId;
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_shopping_cart/orders/user_search_order?start_date=${startsDate}&end_date=${endsDate}`
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        API.GET
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  };

  getallCompletedOrder = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: localStorage.getItem("authToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.completedOrderCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.completedOrder
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getallongoingOrder = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: localStorage.getItem("authToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ongoingOrderCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.ongoingOrder
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getallSearchorder = (value: number) => {
    const header = {
      "Content-Type": API.ContentType,  
      token: localStorage.getItem("authToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getsearchOrderApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/search_order?order_no=${value}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  public convertPrice = (price: number) => {
    if (typeof price !== "number") {
      price = parseFloat(price);
      if (!Number.isFinite(price)) {
        price = 0;
      }
    }
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  setOrderControl = (order: boolean) => {
    this.setState({ orderControl: order });
    this.getallongoingOrder()
  };

  cancelOrder = (item: number) => {
    const header = {
      "Content-Type": API.ContentType,
      token: localStorage.getItem("authToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelItemApiID = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/destroy?id=${item}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    this.setState({orderStatus: true})
  };


  handlePopupOpen = (id: any)=>{
    this.setState({popupOpen: true})
    this.setState({cancelID: id})
  }

  handlePopupClose = () => {
    this.setState({popupOpen: false})
  };

  handlePopupCancel =() =>{
    this.setState({popupOpen: false})
    this.cancelOrder(this.state.cancelID)
  }

  formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  };

  handleChange = (dates: any) => {
    const current = new Date();

    if (Array.isArray(dates)) {
    const [start, end] = dates;
    this.setState({ startDate: start, endDate: end }, () => {
      this.getSearchDateOrders(start, end);
    });
  }
  };

  handleVisiblePicker = () => {

     this.setState({ visibleDate: true });
  };

  convertDate = (data: any) => {
    let date = new Date(data);
    let formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  };

  handleOrderSearch = (value: string) => {
    this.setState({searchInput1:value})
    if(value && value.length >= 9) {
      this.getallSearchorder(Number(value));
    } else if(value.length < 9 || value === ""){
       if(this.state?.orderControl === false) { 
         this.getallCompletedOrder()
         } else {
         this.getallongoingOrder()}
      }
    };
  // Customizable Area End
}
