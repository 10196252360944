import * as React from "react";
import {
  Container,
  Box,
  MenuItem,
  Toolbar,
  AppBar,
  Button,
  Menu,
  Typography,
  IconButton,
  Avatar, styled
} from "@material-ui/core";
import "../src/assests/css/style.css";
import { Link } from 'react-router-dom';
import EmailAccountRegistrationSuccess from '../../blocks/email-account-registration/src/EmailAccountRegistrationSuccess'
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import LockOutlined from "@material-ui/icons/LockOutlined"
import { CartImage, img } from "./assest";
import {UserProfileBasicBlockWeb} from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import EmailAccountLoginBlockWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlockWeb.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import { withRouter } from 'react-router-dom';

const urlConfig = require("../../framework/src/config");
const baseURL = urlConfig.baseURL;

const pages = ["Home", "About Us", "Blog Posts", "Store", "Contact Us"];

const CustomButton = styled('button')({
  '&:hover img': {
    filter: 'brightness(3.5)'
  }
})

const TopNavWeb = (props: any) => {
  const [isAuth, setIsAuth] = React.useState(null) as any;
  const [anchorElNav, setAnchorElNav] = React.useState<any>(null);
  const [open, setOpen] = React.useState<any>(false);
  const [couponCode, setCouponCode] = React.useState<string>("");
  const [state, setState] = React.useState("user");
  const [isOpen, setIsOpen] = React.useState<any>(
    {
      signUp: false,
      login: false,
      profile: false, isSucees: false,
      couponCode: ""
    })

  const [profileInfo, setProfileInfo] = React.useState<any>()
  const [isSignup, setIsSignUp] = React.useState(false)
  const [isForgotOpen, setIsForgotOpen] = React.useState(false);
  const [isMerchantOpen, setIsMerchantOpen] = React.useState("");
  const [isCodeOpen,setIsCodeOpen]= React.useState(false)
  React.useEffect(() => {
    let authToken = localStorage.getItem("authToken") || null
    if (authToken) {
      setIsAuth(authToken)
    }

  }, [isSignup, isOpen])


  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: any) => {
    setAnchorElNav(null);
  };

  const handleOpen = (key: any) => {
    setIsOpen({ ...isOpen, [key]: !isOpen[key] });
    getProfile();
  };
  
  const logout = () => {
    setIsAuth(localStorage.clear() as any);
  }

  const getProfile = async (id?: string) => {
    let token = localStorage.getItem("authToken") ?? ""
    const userId = id ?? localStorage.getItem("id")
    if (userId) {
      const url=`${baseURL}/bx_block_profile/profiles/`
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            token: !!token ? token : ""
          },
        })
        const { data } = await response.json()
        if (data?.attributes) {
          localStorage.setItem('id', data.attributes?.id);
          localStorage.setItem("facebook", data.attributes?.facebook_link);
          localStorage.setItem("insta", data.attributes?.instagram_link);
          localStorage.setItem("whatsapp", data.attributes?.whatsapp_link);
          localStorage.setItem("photo", data.attributes?.photo?.url);
          localStorage.setItem("user_data",JSON.stringify(data?.attributes));
          setProfileInfo(data?.attributes)
        } else {
          setProfileInfo(null)
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  const handleForgot = () => {
    setIsForgotOpen(true);
    setIsOpen({ ...isOpen, ['login']: false });
  }

  const handleForgotClose = () => {
    setIsForgotOpen(false);
  }

  const handleCloseProfile=()=>{
    setIsOpen({ ...isOpen, ['profile']: false });
  }

  React.useEffect(() => {
    window.location.href.split("/").reverse()[0] === 'merchanthome' ? setIsMerchantOpen('/merchantstore') : setIsMerchantOpen('/store');
  }, [])
  return (
    <AppBar position="static" className="header-bg">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component={"a"}
            href="/"
            className="logo-size"
            style={{
              marginRight: "2px",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              objectFit: "cover",
            }}
          >
            {/* <img src={img} alt="" /> */}
          </Typography>
          <Link to="/" style={{display: 'flex'}}>
          <Typography
            variant="h5"
            component="a"
            className="logo-size-mobile"
            style={{
              marginRight: "2px",
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              objectFit: "cover",
            }}
          >
            <img src={img} alt="" style={{width: 'unset'}} />
          </Typography></Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="hamburger-icon"
            >
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography
                    style={{
                      textAlign: "center"
                    }}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                key={"Home"}
                onClick={handleCloseNavMenu}
                style={{margin: "2px 0px", color: props.location.pathname === "/" ? "#A9C9F7" : "#231f20", display: "block", fontWeight: props.location.pathname === "/" ? 600 : 400}}
                className="nav-item"
              >
                {"Home"}
              </Button>
            </Link>
            <Link to="/aboutus" style={{ textDecoration: "none", color: "red" }}>
              <Button
                key={"About us"}
                onClick={handleCloseNavMenu}
                style={{margin: "2px 0px", color: props.location.pathname === "/aboutus" ? "#A9C9F7" : "#231f20", display: "block",fontWeight: props.location.pathname === "/aboutus" ? 600 : 400}}
                className="nav-item"
              >
                {"About Us"}
              </Button>
            </Link>
            <Link to="/blogpost" style={{ textDecoration: "none" }}>
              <Button
                key={"Blog Posts"}
                onClick={handleCloseNavMenu}
                style={{margin: "2px 0px", color: props.location.pathname === "/blogpost" ? "#A9C9F7" : "#231f20", display: "block" ,fontWeight: props.location.pathname === "/blogpost" ? 600 : 400}}
                className="nav-item"
              >
                {"Blog Posts"}
              </Button>
            </Link>
            <Link to={isMerchantOpen} className="nav-item" style={{ textDecoration: 'none' }}>
              <Button
                key={"Store"}
                onClick={handleCloseNavMenu}
                style={{margin: "2px 0px", color: props.location.pathname === "/store" ? "#A9C9F7" : "#231f20", display: "block",fontWeight: props.location.pathname === "/store" ? 600 : 400} }
                className="nav-item"
              >
                {"Store"}
              </Button>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
            <Button
              key={"Contact Us"}
              onClick={handleCloseNavMenu}
              style={{ margin: "2px 0px", color: props.location.pathname === "/contact" ? "#A9C9F7" : "#231f20", display: "block",fontWeight: props.location.pathname === "/contact" ? 600 : 400 }}
              className="nav-item"
            >
              {"Contact Us"}
            </Button>
            </Link>
          </Box>  

          <Box sx={{ flexGrow: 0 }}>
            <div className="auth-btn">
              {isAuth ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {localStorage.getItem('user_type')==='merchant' ?
                   <></>
                :
                <Link to="/mycart">
                <CustomButton className="btn-logout" color="inherit" >
                  <img src={CartImage} alt="Cart LOGO" style={{ height: "27px" }} />
                </CustomButton>
                </Link>
                }
                  <Link to='/profile'>
                    <button className="btn-profile" >
                      <Avatar alt="Profile Image" src={localStorage.getItem("userProfileData") || localStorage.getItem("userProfile") as any} />
                    </button>
                  </Link>
                </div>
              ) : (
                <div>
                  <button className="btn-signup" onClick={() => handleOpen("signUp")}>Sign Up</button>
                  <button className="btn-login" onClick={() => handleOpen("login")}>Log In</button>
                </div>
              )}

            </div>
          </Box>
        </Toolbar>
      </Container>
      {isOpen.signUp && (<EmailAccountRegistrationWeb open={isOpen.signUp} setOpen={setIsOpen} setIsSignUp={setIsSignUp} isSignup={isSignup} navigate={props} setCouponCode={setCouponCode} state={state} setState={setState} />)}
      {isOpen.profile && (<UserProfileBasicBlockWeb handleCloseProfile={handleCloseProfile} open={isOpen.profile} isOpen={isOpen} setOpen={setIsOpen} profileInfo={profileInfo} navigate={props.props} getProfile={getProfile} />)}
      {isOpen.login && (<EmailAccountLoginBlockWeb getProfile={getProfile} open={isOpen.login} setOpen={setIsOpen} handleClose={handleOpen} handleForgot={handleForgot} navigation={props} id={""} />)}
      {isSignup && (<EmailAccountRegistrationSuccess open={open} setOpen={setOpen} setIsSignUp={setIsSignUp} isSignup={isSignup} navigate={props} couponCode={couponCode} state={state} setState={setState} isCodeOpen={isCodeOpen} setIsCodeOpen={setIsCodeOpen}/>)}
      {isForgotOpen && (<ForgotPasswordWeb navigation={undefined} id={""} classes={undefined} handleOpen={undefined} isForgotOpen={isForgotOpen} handleForgotClose={handleForgotClose} />)}
    </AppBar>
  );
}
export default withRouter(TopNavWeb);