export const img = require("./assests/img/Maranatha-logo.png");
export const animalCowHead1 = require("./assests/img/Beef_head.png")
export const ribImage = require("./assests/img/Beef_rib.png")
export const cowchuckImage = require("./assests/img/Beef_chuck.png")
export const cowBrisket = require("./assests/img/Beef_brisket.png")
export const cowFlank = require("./assests/img/Beef_flank.png")
export const cowforeShank = require("./assests/img/Beef_foreshank.png")
export const cowround = require("./assests/img/Beef_round.png")
export const cowshank = require("./assests/img/Beef_shank.png")
export const cowshortLion = require("./assests/img/Beef_shortlion.png")
export const cowshortPlate = require("./assests/img/Beef_shortplate.png")
export const cowsirLion = require("./assests/img/Beef_sirlion.png")
export const cowImage = require("./assests/img/Beef.png")

export const pigImage = require("./assests/img/Pork.png")
export const pigHead = require("./assests/img/Pork_head.png")
export const pigBackFat = require("./assests/img/Pork_backfat.png")
export const pigLoin = require("./assests/img/Pork_loin.png")
export const pigLegHam = require("./assests/img/Pork_legham.png")
export const pigLeg = require("./assests/img/Pork_hock1.png")
export const pighock2 = require("./assests/img/Pork_hock2.png")
export const pigBacon = require("./assests/img/Pork_bacon.png")
export const pigShoulder = require("./assests/img/Pork_shoulder.png")
export const pigRibs = require("./assests/img/Pork_ribs.png")
export const pigPicnic = require("./assests/img/Pork_picnic.png")
export const pigJowls = require("./assests/img/Pork_jowl.png")
export const pigNeck = require("./assests/img/Pork_neck.png")

export const chickenImage = require("./assests/img/Chicken.png")
export const chickenLeg = require("./assests/img/Chicken_leg.png")
export const chickenBreast = require("./assests/img/Chicken_breast.png")
export const chickenBack = require("./assests/img/Chicken_back.png")
export const chickenWing = require("./assests/img/Chicken_wing.png")
export const chickenNeck = require("./assests/img/Chicken_neck.png")
export const ChickenThigh = require("./assests/img/Chicken_thigh.png")
export const ChickenTail = require("./assests/img/Chicken_tail.png")
export const CartImage = require("./assests/img/Cart_Image.png")


// lamb images
export const lambBreast = require("./assests/img/lamb_breast.png")
export const lambChuck = require("./assests/img/lamb_chuck.png")
export const lambFlank = require("./assests/img/lamb_flank.png")
export const lambHead = require("./assests/img/lamb_head.png")
export const lambLeg = require("./assests/img/lamb_leg.png")
export const lambLion = require("./assests/img/lamb_lion.png")
export const lambNeck = require("./assests/img/lamb_neck.png")
export const lambRibs = require("./assests/img/lamb_ribs.png")
export const lambShank = require("./assests/img/lamb_shank.png")
export const lambShoulder = require("./assests/img/lamb_shoulder.png")
export const lamb = require("./assests/img/lamb.png")
