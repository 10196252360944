import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
const BaseURL = urlConfig.baseURL;
const config = require("./config");

enum API {
	GET = "GET",
	POST = "POST",
	ContentType = "application/json",
	CategoriesAPIEndPoint = "/bx_block_categories/categories",
	SubCategoriesAPIEndPoint = "/bx_block_categories/categories/get_subcategory",
	AddProductAPIEndpoint = "/bx_block_catalogue/catalogues"
}
// Customizable Area End

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	categories: Array<any>;
	subCategories: { [key: string]: Array<any> };
	products: Array<IProductForm>;
	requestingSubCategories: number;
	processing: boolean;
	responseModal: boolean;
	addedProduct: number;
	selectedCategory: string;
	productVariants: IProductVariant[]
	// Customizable Area End
}

interface SS {
	id: any;
}

// Customizable Area Start
export interface IProductVariant {
	variantDescription: string
	itemCode?: string
	weight: string
	price: string
	stockQty: string
	image: { src: string, file: File | null, placeHolderUrl?: string}
}
export interface IProductForm {
	name: string;
	price: string;
	description: string;
	category: string;
	subCategory: string;
	images: Array<{ src: string, file: File }>;
	selectedCategoryId?: string;
	sellingPrice:string;
	tax: string;
	hsnCode: string;
	subscription: string;
	subscriptionSellingPrice: string;
	freeDelivery: string
	// variant: IProductVariant[]
}
let postProductApiCallId
// Customizable Area End

export default class AddProductController extends BlockComponent<Props, S, SS> {
	getCategoriesApiCallId: any;
	getSubCategoriesApiCallId: any;

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area Start
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
			// Customizable Area End
		];

		this.state = {
			// Customizable Area Start
			categories: [],
			subCategories: {},
			products: [{} as IProductForm],
			productVariants: [{} as IProductVariant],
			requestingSubCategories: -1,
			processing: false,
			responseModal: false,
			addedProduct: 0,
			selectedCategory: ""
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area Start
		// Customizable Area End
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		runEngine.debugLog("Message Recived", message);

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			  );
			switch (message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
				case this.getCategoriesApiCallId:
					if (responseJson?.data) {
						this.setState({
							categories: responseJson.data,
						});
					}
					break;

				case this.getSubCategoriesApiCallId:
					if (responseJson && responseJson.sub_categories) {
						this.setState({
							subCategories: {
								...this.state.subCategories,
								[this.state.requestingSubCategories]: responseJson.sub_categories.data,
							},
							requestingSubCategories: -1,
						});
					} else {
						this.setState({
							subCategories: {
								...this.state.subCategories,
								[this.state.requestingSubCategories]: [],
							},
							requestingSubCategories: -1,
						});
					}
					break;

			}

			if (apiRequestCallId && this.postProductApiCallId && apiRequestCallId === this.postProductApiCallId) {
				console.log("PRODUCTRES", responseJson)
				if (responseJson && responseJson.data && responseJson.data.attributes) {
					this.setState({ responseModal: true, addedProduct: 1, processing: false });
				} else {
					this.setState({ responseModal: true, addedProduct: 0 });
				}
			}
					
		}
		// Customizable Area End
	}

	// Customizable Area Start
	postProductApiCallId: any
	public async componentDidMount() {
		this.getCategoriesRequest();
	}

	public addProductCard() {
		if (this.state.products.length >= 5) return;

		this.setState({
			products: [...this.state.products, {} as IProductForm],
		});
	}

	public updateProductCard(data: IProductForm, index: number) {
		const products = this.state.products;
		products[index] = data;
		const category_id = parseInt(data.category);

		if (!Number.isNaN(category_id) && !this.state.subCategories[data.category]) {
			this.getSubCategoriesRequest(category_id);
		}

		this.setState({
			products,
		});
	}

	public async createProduct() {
		if (this.state.processing) return;
		this.setState({ processing: true, addedProduct: 0 });
		console.log("PRODUCTS",this.state.products, this.state.productVariants)
		await this.createProductRequest(this.state.products);
	}

	public handleResponseModalClose() {
		this.setState({ responseModal: false });
	}

	public goBack() {
		this.props.navigation.goBack();
	}

	private getCategoriesRequest = () => {
		const header = {
			"Content-Type": API.ContentType,
			token: this.getToken(),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.getCategoriesApiCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			API.CategoriesAPIEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			API.GET
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	private getSubCategoriesRequest = (category_id: number) => {
		this.setState({ requestingSubCategories: category_id });
		const header = {
			"Content-Type": API.ContentType,
			token: this.getToken(),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.getSubCategoriesApiCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${API.SubCategoriesAPIEndPoint}?categoryId=${category_id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			API.GET
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	 handleVariantChange = (name: string, value: string, idx: number) => {
		this.setState((prev) => {
			let updatedVariants = [...prev.productVariants];
			updatedVariants[idx] = {
				...updatedVariants[idx],
				[name]: value
			};
			return {
				...prev,
				productVariants: updatedVariants
			}
		})
	}
	 addVariantForm = () => {
		this.setState((prev) => ({
			...prev,
			productVariants: [...prev.productVariants, {
				variantDescription: "",
				itemCode: "",
				weight: "",
				price: "",
				stockQty: "",
				image: {
					src: "",
					file: null
				}
			}]
		}))
	}
	 handleVariantImageUpload = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
		const { files } = e.target;
		if (!files || files.length === 0) return;

		const file = files[0];

		const blob = new Blob([file], { type: file.type });
		const url = URL.createObjectURL(blob);

		this.setState((prev) => {
			let updatedVariants = [...prev.productVariants];
			updatedVariants[idx] = {
				...updatedVariants[idx],
				image: {
					...updatedVariants[idx].image,
					file,
					placeHolderUrl: url
				}
			};
			return {
				...prev,
				productVariants: updatedVariants
			}
		})
	}
	 removeVariantImage = (idx: number) => {
		this.setState((prev) => {
			let updatedVariants = [...prev.productVariants];
			updatedVariants[idx] = {
				...updatedVariants[idx],
				image: {
					...updatedVariants[idx].image,
					placeHolderUrl: ""
				}
			};
			return {
				...prev,
				productVariants: updatedVariants
			}
		})
	}
	private async createProductRequest(products: Array<IProductForm>) {
		const product = products[0];
		const {
			description,
			selectedCategoryId,
			subCategory,
			name,
			price,
			sellingPrice,
			tax,
			hsnCode,
			subscription,
			subscriptionSellingPrice,
			images,
			freeDelivery
		} = product;
		const formData = new FormData();
		formData.append("name", name);
		formData.append("category_id", selectedCategoryId ?? "");
		formData.append("sub_category_id", subCategory ?? "");
		formData.append("description", description ?? "");
		formData.append("price", price ?? "");
		formData.append("sale_price", sellingPrice ?? "");
		formData.append("taxableAmount", tax ?? "");
		formData.append("hsnCode", hsnCode ?? "");
		formData.append("subscription", subscription ?? "");
		formData.append("subscription_selling_price", subscriptionSellingPrice ?? "");
		formData.append("free_delivery", freeDelivery ?? "");
		if (images?.length) formData.append("images", images[0].file)

		this.state.productVariants.forEach((variant, index) => {
			formData.append(`catalogue_variants_attributes[${index}][variant_description]`, variant.variantDescription);
			formData.append(`catalogue_variants_attributes[${index}][itemNo]`, variant.itemCode || "");
			formData.append(`catalogue_variants_attributes[${index}][variantType]`, variant.weight);
			formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price);
			if (variant?.image?.file)
				formData.append(`catalogue_variants_attributes[${index}][images]`, variant.image.file)
		});
	
		const header = {
			'token': this.getToken()
		};
	
		const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
		this.postProductApiCallId = requestMessage.messageId;
		requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), API.AddProductAPIEndpoint);
		requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
		requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
		requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
	}
	

	private getToken = () => {
		return localStorage.getItem('authToken');
	};

	private async toBase64(file: File): Promise<{ data: string; content_type: string; filename: string } | string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					resolve({
						data: reader.result,
						content_type: file.type, // The content type of the file
						filename: file.name, // The name of the file
					});
				}
				else {
					resolve('error');
				}
			}
			reader.onerror = reject;
		});
	}
	// Customizable Area End
}
