export const userProfile = require("../../global_assets/user-profile.png");
export const img37bfe3ba5b0b09897c1a10d05dcfedf145306428 = require("../../global_assets/37bfe3ba5b0b09897c1a10d05dcfedf145306428.png");
export const img803b9c37aafad0151b06a2a4ed7201852f10a212 = require("../../global_assets/803b9c37aafad0151b06a2a4ed7201852f10a212.png");
export const img7141554d7c2565e0de24236044e1841d3d6c9ff6 = require("../../global_assets/7141554d7c2565e0de24236044e1841d3d6c9ff6.png");
export const img47676a188c9c093e814300187bf9a3c0eab5ac2a = require("../../global_assets/47676a188c9c093e814300187bf9a3c0eab5ac2a.png");
export const imgec2518ba77fb9b94cd35a5f402a78b05ebebe1c5 = require("../../global_assets/ec2518ba77fb9b94cd35a5f402a78b05ebebe1c5.png");
export const img1f85f39378b93e6e245b985520bb8ec31f68d616 = require("../../global_assets/1f85f39378b93e6e245b985520bb8ec31f68d616.png");
export const imga0f665489b1d95cc10f55b5401db6abed1e9af1a = require("../../global_assets/a0f665489b1d95cc10f55b5401db6abed1e9af1a.png");
export const img379a20ffc7e6fa5e7a29faaf1143c07fc8e28264 = require("../../global_assets/379a20ffc7e6fa5e7a29faaf1143c07fc8e28264.png");
export const img1d1183e157afea356d55ddda88e1cff288933288 = require("../../global_assets/1d1183e157afea356d55ddda88e1cff288933288.png");
export const img9fa969b0cfa058b02d81fde99817e58a2675f093 = require("../../global_assets/9fa969b0cfa058b02d81fde99817e58a2675f093.png");
export const imgad80547be96d74922ab5aa8639a730638ed0ab71 = require("../../global_assets/ad80547be96d74922ab5aa8639a730638ed0ab71.png");
export const img6b002af444f7f5313c52fd4288f06b16dfa7d20e = require("../../global_assets/6b002af444f7f5313c52fd4288f06b16dfa7d20e.png");
export const img1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8 = require("../../global_assets/1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8.png");
export const img2ffde658c4043470cd93f7dd6dfe73d5e276ace3 = require("../../global_assets/2ffde658c4043470cd93f7dd6dfe73d5e276ace3.png");
export const imga5b164dd49cd5b61e171b96b048e7833bbe9c5db = require("../../global_assets/a5b164dd49cd5b61e171b96b048e7833bbe9c5db.png");
export const img193beead28debffba7480a35f4950e1b3ba7aa10 = require("../../global_assets/193beead28debffba7480a35f4950e1b3ba7aa10.png");
export const img15e817f311d46193d17e4f5945a6d8072c81eaa3 = require("../../global_assets/15e817f311d46193d17e4f5945a6d8072c81eaa3.png");
export const imgbc278414de2b6a7de2663e5734faf5ec96f4cf78 = require("../../global_assets/bc278414de2b6a7de2663e5734faf5ec96f4cf78.png");
export const imgb83817a9721266588f789dbcb4f08ef4ace4bd1f = require("../../global_assets/b83817a9721266588f789dbcb4f08ef4ace4bd1f.png");
export const img780b495b610018e8f4be9748a101e9ba75410e01 = require("../../global_assets/780b495b610018e8f4be9748a101e9ba75410e01.png");
export const imgf8f9191318ceed32c794944aaa2879d26f8fc377 = require("../../global_assets/f8f9191318ceed32c794944aaa2879d26f8fc377.png");
export const img6160a81cbd7123e77488b23bb657150210d849d6 = require("../../global_assets/6160a81cbd7123e77488b23bb657150210d849d6.png");
export const img249c883ed6b7ec5d55c0a2d617d28837a3d49cf6 = require("../../global_assets/249c883ed6b7ec5d55c0a2d617d28837a3d49cf6.png");
export const img66b8ce6c88191fa0497b848592a7eb83c27b76d8 = require("../../global_assets/66b8ce6c88191fa0497b848592a7eb83c27b76d8.png");
export const imge295c69a26856bd84c118fc44526ef4ff4d039e8 = require("../../global_assets/e295c69a26856bd84c118fc44526ef4ff4d039e8.png");
export const img867c06a69aed1fcdf3254913062de11656a4ba0f = require("../../global_assets/867c06a69aed1fcdf3254913062de11656a4ba0f.png");
export const imgdaa200bfa259dd9046a390ea652430fa088080eb = require("../../global_assets/daa200bfa259dd9046a390ea652430fa088080eb.png");
export const img028f30e7875f4a2c149c20cccc29cf3692d74bf4 = require("../../global_assets/028f30e7875f4a2c149c20cccc29cf3692d74bf4.png");
export const imgd99ba118a8bef1a07ee675009316038e28058531 = require("../../global_assets/d99ba118a8bef1a07ee675009316038e28058531.png");
export const imgf01063e1bd01feb0bcb2fda4c775e7a31f116645 = require("../../global_assets/f01063e1bd01feb0bcb2fda4c775e7a31f116645.png");
export const imgaa3872f66240290a0317f1499ebd7ee1d4fa724e = require("../../global_assets/aa3872f66240290a0317f1499ebd7ee1d4fa724e.png");
export const imgd2a4eb6716aa44e15cec3a7e89302a14fc8ad462 = require("../../global_assets/d2a4eb6716aa44e15cec3a7e89302a14fc8ad462.png");
export const imgd692c355c1f26f81ceb7672dbb9ec69a833eb93a = require("../../global_assets/d692c355c1f26f81ceb7672dbb9ec69a833eb93a.png");
export const img565fc4fbaaa8696beab2ac5c45947d555a3fcfd2 = require("../../global_assets/565fc4fbaaa8696beab2ac5c45947d555a3fcfd2.png");
export const img28fb54c2204773592e89b6c9889cc0bff9b0c9f7 = require("../../global_assets/28fb54c2204773592e89b6c9889cc0bff9b0c9f7.png");
export const img9fcaeb8d6c801eab0ed1f6dcdae136473d1baf45 = require("../../global_assets/9fcaeb8d6c801eab0ed1f6dcdae136473d1baf45.png");
export const imgd58cb4034702085a44f976d38d1201eafbce1571 = require("../../global_assets/d58cb4034702085a44f976d38d1201eafbce1571.png");
export const img228ba19860654dac423b1de6e666b6ae0c201a4a = require("../../global_assets/228ba19860654dac423b1de6e666b6ae0c201a4a.png");
export const imgf35f68f66053435ea1beb1719b87c874c3c4076b = require("../../global_assets/f35f68f66053435ea1beb1719b87c874c3c4076b.png");
export const imga58da75d483029a380faec2205e835b82d488bee = require("../assets/a58da75d483029a380faec2205e835b82d488bee.png");
export const img4a21dcd905740fa91f4cf153aa18d6bdb63f32e2 = require("../assets/4a21dcd905740fa91f4cf153aa18d6bdb63f32e2.png");
export const img5cf8f769bc1b1e1d8b24553390eee48168269726 = require("../assets/5cf8f769bc1b1e1d8b24553390eee48168269726.png");
