import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const urlConfig = require("../../../framework/src/config");
const BaseURL = urlConfig.baseURL;

// Customizable Area Start
enum API {
	GET = "GET",
	POST = "POST",
	ContentType = "application/json",
	MyCartAPIEndPoint = "bx_block_shopping_cart/orders/order_alerts",
  PostPaymentDetailsAPIEndPoint="bx_block_stripe_integration/payment_methods",
  PostQueryDetailsAPIEndPoint="bx_block_contact_us/contacts "
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  query:any;
  ContactFlag:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export interface IMyCartForm {
    name: string;
    quantity: number
    price: number;
}
// Customizable Area End
export default class ContactusControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQueryDescriptionId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data:[],
      query:{
       fullName:"",
       email:"",
       queryDescription:"",
      },
      ContactFlag:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        case this.getQueryDescriptionId:
          if (responseJson?.data) {
            this.setState({ContactFlag:true})
          }
          break; 
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  sendQueryDetails = () => {
    const{fullName,email,queryDescription}=this.state.query
    const body ={
      data:{
            name:fullName,
            email:email,
            phone_number:"90245 67909",
            description:queryDescription
      }
  }
    const header = {
      "Content-Type": API.ContentType,
      // token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQueryDescriptionId = getValidationsMsg.messageId;
    let endPoints = `${API.PostQueryDetailsAPIEndPoint}`
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   
  };
 
  changeInputValue=(e:any) =>{
    this.setState({
      query:{
      ...this.state.query,
      [e.target.name]: e.target.value}
    });
  }

    private getToken = () => {
        return localStorage.getItem('authToken');
    };
    handlePopupClose=()=>{
      this.setState({ContactFlag:false})
    };

    handlePopupContactClose=()=>{
      this.setState({ContactFlag:false})
      this.props.navigation.navigate("Home")
    }

  // Customizable Area End
}
