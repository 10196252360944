import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Toolbar,
  AppBar,
  Grid,
  TextField,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CalendarToday from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
//@ts-ignore
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../components/src/Loader.web";
import { img } from "./assets";
// import dateFnsUtils from "@date-io/date-fns";

// import ArrowBack from "@material-ui/icons/ArrowBack";
const MerchantDateBox = styled(Box)({
  '& .MuiSvgIcon-root': {
    color: '#A9C9F7 !important'
  },
  '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selecting-range-start .react-datepicker__day--selecting-range-end':{
    background: '#A9C9F7 !important'
  },
})
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      color: "#231F20",
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import moment from "moment";
import {
  Order,
  OrderResponse
} from "./MerchantOrderManagementController"
// Customizable Area End

import MerchantOrderManagementController, {
  Props,
  configJSON,
} from "./MerchantOrderManagementController";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";

export default class MerchantOrderManagement extends MerchantOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOrderCard = (item: Order, parentIdx: number) => {
    const { orderControl } = this.state
    const {status, catalogue, delivered_at, order_no, price, quantity, other_charges,catalogue_variant } = item.attributes
    const handleDecline = () => {
      this.OrderStatus('cancelled', item.id, parentIdx);
    };

    const handleAccept = () => {
      this.OrderStatus('completed', item.id, parentIdx);
    };

    const renderFooterButtons = () => {
      if (orderControl) {
        return (
          <>
            <Button
              style={{
                ...webStyle.orderCardButton,
                ...webStyle.button,
                ...webStyle.outlineButton,
              }}
              data-testID='cancelled'
              onClick={handleDecline}
            >
              Decline
            </Button>
            <Button
              style={{
                ...webStyle.orderCardButton,
                ...webStyle.button,
              }}
              data-testID='accept'
              onClick={handleAccept}
            >
              Accept
            </Button>
          </>
        );
      } else {
        return (
          <Button
            style={{
              ...webStyle.button,
              width: "100%",
              backgroundColor: status === 'completed' ? "rgb(224, 236, 253)" : "#A9C9F7",
              color: status === 'completed' ? "#A9C9F7" : "#ffff",
            }}
          >
            {status}
          </Button>
        );
      }
    };

    return (
      <Grid style={webStyle.orderCard} key={item.id}>
        <Grid container>
          <Grid style={webStyle.orderCardLeft}>
            <img
              src={catalogue_variant?.data?.attributes.productImage ? 
                catalogue_variant?.data?.attributes.productImage : img}
              style={webStyle.orderCardImage}
            />
          </Grid>
          <Grid style={webStyle.orderCardRight}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "20px", color: "#231F20" }}
            >
              {catalogue.data && 
              <Typography variant="h6" style={webStyle.orderCardTitle}>
                {catalogue.data.attributes.name}
                <Typography variant="subtitle2" style={webStyle.propName}>
                  {catalogue.data.attributes?.catalogue_variant && 
                    catalogue.data.attributes?.catalogue_variant.attributes.variantType ||
                    catalogue.data.attributes.name}
                </Typography>
              </Typography>}
              <Typography variant="h6" style={webStyle.orderCardPrice}>
                {price + ' X ' + quantity}
              </Typography>
            </Grid>
            <Grid container style={webStyle.propRow}>
              <span style={webStyle.propName}>Order Number</span>
              <span style={webStyle.propValue}>
                {order_no}
              </span>
            </Grid>
            <Grid container style={webStyle.propRow}>
              <span style={webStyle.propName}>Due Date</span>
              <span style={webStyle.propValue}>{delivered_at ? this.formatDate(delivered_at) : ''}</span>
            </Grid>
            <Grid container style={webStyle.propRow}>
              <span style={webStyle.propName}>Shipping time</span>
              <span style={webStyle.propValue}>{delivered_at ? this.formatTime(delivered_at) : ''}</span>
            </Grid>
            <Grid container style={webStyle.propRow}>
              <span style={webStyle.propName}>Order Total</span>
              <span style={webStyle.propValue}>
                {`$${price * quantity}`}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={webStyle.orderCardFooter}>
          {renderFooterButtons()}
        </Grid>
      </Grid>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <TopNavLoginWeb />
        <ThemeProvider theme={theme}>
          {this.state.showLoader && (
            <Loader
              loading={this.state.showLoader}
            />
          )}
          <Box style={webStyle.container }>
            <Box style={webStyle.mainStyle }>
              <Grid container>
                <Typography variant="h1" style={webStyle.pageTitle }>
                  My Orders
                </Typography>
              </Grid>

              <Grid style={webStyle.filterStyle } container>
                <Grid style={webStyle.categoryButtonContainer }>
                  {/* Material Link */}
                  <Button
                    style={{
                      ...(webStyle.categoryButton),
                      backgroundColor: this.state.orderControl
                        ? "#A9C9F7"
                        : "white",
                      color: this.state.orderControl ? "#FFF" : "black",
                      fontWeight: this.state.orderControl ? 600 : 300,
                    }}
                    data-testID="Incoming"
                    onClick={() => this.setOrderControl(true, 'incoming')}
                  >
                    Incoming Orders
                  </Button>
                  <Button
                    style={{
                      ...(webStyle.categoryButton),
                      backgroundColor: !this.state.orderControl
                        ? "#A9C9F7"
                        : "white",
                      color: !this.state.orderControl ? "#FFF" : "black",
                      fontWeight: !this.state.orderControl ? 600 : 300,
                    }}
                    data-testID="Previous"
                    onClick={() => this.setOrderControl(false, 'previous')}
                  >
                    Previous Orders
                  </Button>
                </Grid>
                <Grid style={webStyle.filterContainer}>
                  <div style={webStyle.searchInputDiv}>
                    <span style={webStyle.searchIcon}>
                      <Search />
                    </span>
                    <input
                      data-testID='searchOrder'
                      placeholder="Search Order..."
                      id="input-with-icon-adornment"
                      style={webStyle.searchInput}
                      value={this.state.serachTerm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleOrderSearch(e.target.value)}

                    />
                  </div>
                  <MerchantDateBox className="merchantDate">
        <CalendarToday
          data-testID="dataicon"
          onClick={this.handleVisiblePicker}
          style={webStyle.calenderIcon}
        />

        {this.state.visibleDate && (
          <DatePicker
            data-testID="datepicker"
            selected={this.state.startDate}
            onChange={this.handleChange}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selectsRange
            maxDate={this.state.todayDate}
          />
        )}
      </MerchantDateBox>
                  {/* <MuiPickersUtilsProvider utils={dateFnsUtils}>
                    <DatePicker
                      margin="normal"
                      label="label"
                      value={new Date()}
                      onChange={}
                    />
                  </MuiPickersUtilsProvider> */}
                </Grid>
              </Grid>
              {this.state.orderControl ? (
                <>
                  <Grid>
                    <Grid style={webStyle.orderCardContainer}>
                      {this.state.allOrderList?.length === 0 ? (
                        <p>No record found !!!</p>
                      ) : (
                        this.state.allOrderList.map((item: OrderResponse, idx) => (
                          <div style={{ padding: "20px" }} key={`${item.date}+${idx}`}>
                            <Box flexDirection="row" display="flex" padding="15px 0px" color="gray">
                              <Typography>{moment(moment(item.date, ["DD-MM-YYYY", "YYYY-MM-DD"])).format("DD MMM")}</Typography>
                                &nbsp;
                              <Typography>({item?.no_of_order_items} orders)</Typography>
                            </Box>
                            <div style={{ display: "flex", overflow: "scroll" }}>
                              {item.order_items?.data.map(ord => this.renderOrderCard(ord, idx))}
                            </div>
                          </div>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid container style={webStyle.orderCardContainer}>
                      {this.state.allOrderPreviousList?.length === 0 ? (
                        <p>No record found !!!</p>
                      ) : (
                        this.state.allOrderPreviousList.map((item: OrderResponse, idx) => (
                          <div style={{ padding: "20px" }} key={`${item.date}+${idx}`}>
                            <Box flexDirection="row" display="flex" padding="15px 0px" color="gray">
                              <Typography>{moment(moment(item.date, ["DD-MM-YYYY", "YYYY-MM-DD"])).format("DD MMM")}</Typography>
                              <Typography>({item.no_of_order_items} orders)</Typography>
                            </Box>
                            <div style={{ display: "flex", overflow: "scroll" }}>
                              {item.order_items?.data.map(ord => this.renderOrderCard(ord,idx))}
                            </div>
                          </div>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </>
              ) }
            </Box>
          </Box>
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    width: "100%",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#F5F2ED",
  },
  mainStyle: {
    // width: "100%",
    padding: "0px 22px"
  },
  pageTitle: {
    color: "#231F20",
    fontSize: "24px",
    fontWeight: 600,
    padding: "56 0 26 0",
  },
  orderCardDate: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#89817c',
    textTransform: 'uppercase'
  },
  filterStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  categoryButtonContainer: {
    height: "auto",
    borderRadius: "50px",
    marginBottom: "14px",
    background: "#FFF"
  },
  categoryButton: {
    height: "48px",
    borderRadius: "40px",
    textTransform: "capitalize",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  categoryButtonActive: {
    backgroundColor: "#A9C9F7",
    color: "#FFF",
    fontWeight: 600,
  },
  filterContainer: {
    display: "flex",
    width: "100%",
    maxWidth: "30%"
  },
  searchInputDiv: {
    width: "300px",
    height: "50px",
    marginRight: "20px",
    borderRadius: "40px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "30px"
  },
  searchInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    color: "#231F20",
    fontSize: "18px",
  },
  searchIcon: {
    padding: "0 13px",
  },
  calendarButton: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    backgroundColor: "#FFF",
  },

  cardTitle: {
    color: "#867f7b",
    fontWeight: 400,
    fontSize: 15,
  },

  orderCardContainer: {
    flexWrap: "nowrap",
    // overflowX: "scroll",
    marginTop: '2rem',
    display: "block"
  },
  orderCard: {
    backgroundColor: "#FFF",
    padding: "20px",
    width: "370px",
    minWidth: "350px",
    borderRadius: "10px",
    marginRight: "20px",
  },
  orderCardLeft: {
    display: "flex",
    flex: 0,
    marginRight: "10px",
  },
  orderCardImage: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
  },
  orderCardRight: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    color: "#231F20",
    fontSize: "14px",
  },
  orderCardTitle: {
    color: "#231F20",
    fontWeight: 600,
    fontSize: "16px",
  },
  orderCardPrice: {
    fontSize: "14px",
  },

  propRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    color: "#231F20",
  },
  propValue: {
    fontWeight: 600,
  },

  orderCardFooter: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  button: {
    padding: "12px 47px",
    background: "#A9C9F7",
    border: "1px solid #a9caf7b7",
    borderRadius: "30px",
    marginLeft: "20px",
    fontWeight: 600,
    color: "#FFF",
    textTransform: "capitalize",
  },
  outlineButton: {
    background: "rgb(224, 236, 253",
    color: "#A9C9F7",
  },
  calenderIcon: {
    position: "absolute",
    display: "block",
    zIndex: 0,
  }
};
// Customizable Area End
