import React from "react";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Typography,Snackbar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { copyCode, RedcopyCode} from "./assets";
import { celebrate } from "./assets";

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "40px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 3, 3),
      textAlign: "center",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        // fontSize: "48px",
        color: "#231F20",
        marginBottom: "5px",
        paddingBottom: "10px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "bold"
      }
    },
    root: {
      width: "450px",
      margin: "auto"
    },
    success: {
      width: "300px",
      margin: "auto"
    },
    successIcon: {
      fontSize: "50px",
      color: "#a60018",
      marginBottom: "25px"
    },
    successContent: {
      fontSize: "26px",
      fontWeight: 700,
      color: "#231F20"
    },
    copycodeicon: {
      width: "15px",
      color:""
    },

    successbtn: {
      border: "1px solid #a9caf7b7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "#A9C9F7",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "rgb(224, 236, 253)",
      boxShadow: "0px 10px 20px #A9C9F77A  !important",
      width: "60%",
      "&:hover": {
        backgroundColor: "#A9C9F7",
        color:"white"
      }
    },
    iconClose: {
      position: "relative",
      top: "10px",
      left: "46%",
      backgroundColor: "rgb(224, 236, 253)",
      "& svg ": {
        color: "#231F20",
        fontSize: "14px"
      }
    },
    congratesContent: {
      color: "#808080",
      fontSize: "14px",
      width: "70%",
      margin: "auto",
      padding: "5px 0 5px",
      fontWeight: "bold",
      lineHeight: "25px"
    },
    celebrateicon: {
      width: "60px",
      height: "50px"
    },
    clickcode: {
      cursor: "pointer",
      fontSize:"12px"
    },
    celebratecontent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px"
    },
    continue: {
      border: "1px solid #a9caf7b7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "#A9C9F7",
      margin: "20px auto 20px",
      fontWeight: "bold",
      fontSize:"12px",
      backgroundColor: "rgb(224, 236, 253)",
      boxShadow: "0px 10px 20px#A9C9F77A !important",
      width: "60%",
      fontFamily:"roboto",
      "&:hover": {
        backgroundColor: "#A9C9F7",
        color:"white"
      }
    },
    link: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      color: "#808080",
      marginTop: "15px",
      marginBottom: "0",
      fontWeight: "bold",
      "& a ": {
        color: "#231F20",
        fontWeight: "bold",
        padding: "0 5px",
        textDecoration: "underline",
        textTransform: "uppercase"
      },
      "& span": {
        color: "#231F20",
        "& svg": {
          height: "18px"
        }
      }
    }
  })
);
const EmailAccountRegistrationSuccess = ({
  couponCode,
  setIsSignUp,
  isSignup,
  isCodeOpen,
  setIsCodeOpen,
  state
}: any) => {
  const classes = useStyles() as any;
  const handleCode = () => {
    couponCode && navigator.clipboard.writeText(couponCode);
    setIsCodeOpen(true) 
  };

  const handleCloseSnackbar = () => {
    setIsCodeOpen(false) 
  };
  
  const handleClose = () => {
    setIsSignUp(false);
  };

  const renderCongrat = () => {
    return (
      <>
      <div className={classes.paper} style={{maxWidth: 450, width: '100%', position: 'relative'}}>
         {/* <Snackbar open={isCodeOpen} autoHideDuration={6000} onClose={setIsCodeOpen:false}>
          <Alert>You earned a discount code:{couponCode}</Alert>
          </Snackbar> */}

          <Snackbar open={isCodeOpen} autoHideDuration={6000} 
          style={{position: 'absolute',
            top: '20px',
            height: '10px',
            width: '320px',}}
          >
           <Alert  severity="success" style={{ width: '100%', background: '#f8eaec' }}>
           You earned a discount code:{couponCode}
           </Alert>
          </Snackbar>
        <Box>
          <IconButton
            onClick={() => setIsSignUp(false)}
            className={classes.iconClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
        <form className={classes.root} noValidate autoComplete="off">
          <div className={classes.celebratecontent}>
            <h1 id="cong">Congratulations</h1>
            <img className={classes.celebrateicon} src={celebrate} alt=""/>
          </div>
          <Typography className={classes.congratesContent}>
            You earned discount coupon code. You can check this out in your
            profile or Redeem Now!
          </Typography>
          <Typography className={classes.link}>
            to get instant 10% discount on first order 
            <div onClick={handleCode} className={classes.clickcode}>
              <a>Copy Coupon Code</a>
              <span>
                <img className={classes.copycodeicon} src={RedcopyCode} alt="" />
              </span>
            </div>
          </Typography>
          <Button
            component={Link}
            to="/InfoPage"
            variant="contained"
            className={classes.continue}
            onClick={() => setIsSignUp(false)}
          >
            Continue
          </Button>
        </form>
      </div>
      </>
    );
  };
  const renderMerchant = () => {
    return (
      <div className={classes.paper}>
        <Box>
          <IconButton
            onClick={() => setIsSignUp(false)}
            className={classes.iconClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Box>
        <form className={classes.success} noValidate autoComplete="off">
          <CheckCircleOutlineIcon className={classes.successIcon} />
          <Typography className={classes.successContent}>
            Thank you for your application! The Meat Locker will reach out!
          </Typography>
          <Button
            component={Link}
            to="/merchanthome"
            variant="contained"
            className={classes.successbtn}
            onClick={() => setIsSignUp(false)}
          >
            Continue
          </Button>
        </form>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={isSignup}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {state === "user" ? renderCongrat() : renderMerchant()}
      </Modal>
    </div>
  );
};

export default EmailAccountRegistrationSuccess;
