import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { request } from "http";
import React from "react";

// Customizable Area Start
interface CatalogueVariantAttributes {
  id: number;
  catalogue_id: number;
  price: string;
  stock_qty: number | null;
  on_sale: boolean | null;
  itemId: number;
  itemNo: string | null;
  variantType: string;
  productImage: string;
  created_at: string;
  updated_at: string;
  images: string[] | null;
}

export interface CatalogueVariant {
  id: string;
  type: string;
  attributes: CatalogueVariantAttributes;
}

export interface CatalogueAttributes {
  id: number;
  category_id: number;
  brand_id: number | null;
  name: string | null;
  description: string;
  additionalDescription: string;
  category_cutsname: string | null;
  length: number | null;
  breadth: number | null;
  height: number | null;
  stock_qty: number | null;
  recommended: boolean | null;
  favouriteable_enable?: boolean;
  availability: string | null;
  weight: number | null;
  price: number | null;
  on_sale: boolean | null;
  sale_price: number | null;
  discount: number | null;
  farm_description: string | null;
  farm_title: string | null;
  subscription?: boolean
  subUoms: any; // Define the type for this if available
  hsnCode: string;
  netAmount: number | null;
  amount: number | null;
  discountPercentage: number | null;
  discountAmount: number | null;
  taxableAmount: number | null;
  taxAmount: number | null;
  taxPercentage: number | null;
  updatedBy: string | null;
  status_desc: string;
  assetTypeName: string;
  entityId: string;
  itemNo: string;
  categoryCode: string;
  updatedDate: string | null;
  itemId: string | null;
  uom: string;
  assetOrConsumable: string;
  createdDate: string | null;
  productImage: string;
  createdBy: string | null;
  entityName: string | null;
  barcode: string;
  status: string;
  images: string[];
  profile_images: string[];
  profile_photos: string[];
  average_rating: number;
  catalogue_variants: CatalogueVariant[];
}

export interface CatalogueResponse {
  id: string;
  type: string;
  attributes: CatalogueAttributes | null;
}
export interface CatalogueData {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      icon: string | null;
      enable: boolean;
      rank: number | null;
      created_at: string;
      updated_at: string;
      statusDescription: boolean;
      categoryImage: string;
      description: string;
      categoryCode: string;
      categoryId: number;
      seenAll?: boolean;
      cuts: {
          total_cuts: number;
          cuts_name: string[];
      };
      status: number;
      catalogue: {
        data: CatalogueResponse[]
      }
      selected_sub_categories: any[] | null; // Define the type for this if available
  };
}
type CataloguesToShow = {
  showAllProducts: boolean,
  showCategoriesProduct: boolean,
  showSubCategoriesProduct: boolean,
  showFilteredProducts: boolean
}

interface SubCategoryAttributes {
  id: number;
  name: string;
  icon: string | null;
  enable: boolean;
  rank: number | null;
  created_at: string;
  updated_at: string;
  statusDescription: boolean;
  categoryImage: string;
  description: string;
  sub_category_code: string;
  sub_category_id: number;
  category_name: string;
  parentId: number;
  status: number;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  
  // Customizable Area Start
  classes?: any;
  isFavorite?: boolean;
  handleClose?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;
  plusValue1:any;
  subsValue:boolean;
  carouselSelect:string;
  catalogueData: CatalogueData[];
  sort: string;
  selected: any;
  subSelected: string;
  seeAllCategoryIndex: number;
  subCat: string;
  categoriesChip: any;
  subCategoryChip: any;
  merchantFlag: boolean;
  subChipOpen: boolean;
  isSubscriptionOpen: boolean;
  subscriptionflag: boolean;
  isCatalogueLoading: boolean;
  descriptionFlag: boolean;
  isFavouriteModal: {
    isOpen: boolean
    text: string
  };
  myPopUpData: any;
  productDescription:CatalogueResponse;
  cartValue:String;
  descId:string;
  productDescId:number|string;
  favouriteData:any;
  selectedFavouriteID:any;
  selectedFavouriteParentIndex: number
  isFavouriteExist:boolean
  serachTerm:string;
  filteredCatalogue:CatalogueData[];
  categoriesCatalogueData: Record<string,CatalogueResponse[]>
  currentPage:number;
  loginFlag:boolean;
  descLoader:boolean;
  productList :any;
  CatProductList:any;
  matchProduct:any;
  FarmDetails:any;
  farmDetailsImage:any;
  farmDetailsStep1:any;
  farmDetailsStep2:any
  isFilterEnable:boolean
  isBottom:boolean
  variantQty: number
  availablevariantQty: number
  currentSubCategory: string,
  currentSubCategoryId: number,
  subCategories: SubCategoryAttributes[]
  subCategoriesCatalogueData: Record<string,CatalogueResponse[]>
  selectedVariant: {
    price: string
    productImage: string
    stockQty: number| null
    variant: string
    variantId: number
    variantErrorMsg?: string
    variantItemId?: number
  },
  showCatalogues: CataloguesToShow
  isDetailPage: boolean
  setFrequency:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCategoriesCallId: string = "";
  apiFavoritesCallId: string = "";
  apiSubCategoriesCallId: string = "";
  apiAscendingCallId: string = "";
  productSearchCallId:string = "";

  getAllCatalogueAPICallId: string = "";
  myFavorites: string = "";
  addToCart:string="";
  myPopup = "";
  
  productDescriptionAPICallId:string = "";
  selectedFavouriteID: any;
  deplayDebounce:any=null;
  getSubCategoriesProductsAPICallId:string="";
  getSubCategoriesAPICallId:string="";
  apiProductListCallId: any;
  getCategoryProductsAPICallId: unknown;
  checkStocksAvailablityAPICallId: unknown;
  seeAllAPICallId: unknown;
  apiFarmDetaisCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      value: 1,
      subsValue:false,
      plusValue1:0,
      carouselSelect:"",
      catalogueData: [],
      categoriesCatalogueData:{},
      sort: "",
      selected: 0,
      subSelected: "",
      subCat: "",
      categoriesChip: [],
      subCategoryChip: [],
      merchantFlag: false,
      descriptionFlag: false,
      subChipOpen: false,
      isSubscriptionOpen:false,
      subscriptionflag: false,
      isCatalogueLoading: false,
      isFavouriteModal: {
        isOpen: false,
        text: ""
      },
      selectedFavouriteParentIndex: 0,
      myPopUpData: null,
      cartValue:'1',
      descId:'',
      productDescription:{
        id: "",
        type: "",
        attributes: null
      },
      productDescId:0,
      favouriteData:[],
      selectedFavouriteID:'',
      isFavouriteExist:false,
      serachTerm:'',
      filteredCatalogue:[],
      subCategoriesCatalogueData:{},
      currentSubCategory: "",
      currentSubCategoryId:0,
      subCategories:[],
      currentPage:1,
      loginFlag:false,
      descLoader:false,
      productList:[],
      CatProductList : "",
      matchProduct:[],
      FarmDetails:"",
      farmDetailsImage:"",
      farmDetailsStep1:"",
      farmDetailsStep2:"",
      seeAllCategoryIndex:0,
      isFilterEnable:false,
      isBottom:false,
      variantQty: 0,
      availablevariantQty: 0,
      selectedVariant: {
        stockQty: 0,
        variantId: 0,
        price:"",
        productImage: '',
        variant: '',
        variantErrorMsg: ""
      },
      showCatalogues: {
        showAllProducts: false,
        showCategoriesProduct: false,
        showSubCategoriesProduct: false,
        showFilteredProducts: false,
      },
      isDetailPage: window.location.href.split('/').reverse()[1] === 'CategoriesDescription',
      setFrequency:"Weekly"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.NavigationMessageSettingToCategories)
      );
      if (data) {
        this.setState({ currentSubCategory: data.subCategoryName, currentSubCategoryId: data.subCategoryId })
        this.getSubCategoriesProducts(data.subCategoryId)
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if(apiRequestCallId===this.apiFavoritesCallId){
        console.log({responseJSON:responseJson?.data})
        this.setState({favouriteData:responseJson?.data})
    }

      if (apiRequestCallId === this.apiCategoriesCallId) {
        console.log("apiCategoriesCallId",responseJson);
        
        this.setState({ categoriesChip:responseJson?.data })
        // if(responseJson?.errors){
        //   window.alert('Login Expired')
        //   this.props.navigation.navigate('Home')
        // }
          // console.log("match",responseJson.data);
      }
      if (apiRequestCallId === this.myFavorites) {
        if (responseJson) {
          const {isDetailPage, selectedFavouriteParentIndex, selectedFavouriteID, selected, showCatalogues,currentSubCategoryId } = this.state
          if (isDetailPage) {
            this.setState((prevState) => {
              return {
                productDescription: {
                  ...prevState.productDescription,
                  attributes: {
                    ...prevState.productDescription.attributes,
                    favouriteable_enable: responseJson.data ? true : false
                  } as CatalogueAttributes
                }
              }
            })
            return
          }
          if (showCatalogues.showAllProducts || showCatalogues.showFilteredProducts) {
            this.setState(prevState => {
              const updatedCatalogueData = [...(showCatalogues.showAllProducts ?
                prevState.catalogueData :
                prevState.filteredCatalogue)];
              const catalogue = { ...updatedCatalogueData[selectedFavouriteParentIndex] };
              const updatedData: any = catalogue.attributes.catalogue.data.map(item => {
                if (item.attributes?.id === selectedFavouriteID) {
                  return {
                    ...item,
                    attributes: {
                      ...item.attributes,
                      favouriteable_enable: responseJson.data ? true : false
                    }
                  };
                }
                return item;
              });

              catalogue.attributes.catalogue.data = updatedData;
              // if(showCatalogues.showFilteredProducts)
              return {
                ...prevState,
                ...(showCatalogues.showAllProducts ?
                  { catalogueData: updatedCatalogueData } :
                  { filteredCatalogue: updatedCatalogueData }
                ),
                isFavouriteModal: {
                  isOpen: false,
                  text: ""
                }
              };
            });

          } else if (showCatalogues.showCategoriesProduct ||
            showCatalogues.showSubCategoriesProduct) {
            this.setState(prevState => {
              const updatedCatalogueData = [...(showCatalogues.showCategoriesProduct ?
                prevState.categoriesCatalogueData[selected] :
                prevState.subCategoriesCatalogueData[currentSubCategoryId])];
              const updatedData: any = updatedCatalogueData.map(item => {
                if (item.attributes?.id === selectedFavouriteID) {
                  return {
                    ...item,
                    attributes: {
                      ...item.attributes,
                      favouriteable_enable: responseJson.data ? true : false
                    }
                  };
                }
                return item;
              });

              return {
                ...prevState,
                ...(showCatalogues.showCategoriesProduct ?
                  {
                    categoriesCatalogueData: {
                      ...prevState.categoriesCatalogueData,
                      [selected]: updatedData
                    }
                  } :
                  {
                    subCategoriesCatalogueData: {
                      ...prevState.subCategoriesCatalogueData,
                      [currentSubCategoryId]: updatedData
                    }
                  }
                ),
                isFavouriteModal: {
                  isOpen: false,
                  text: ""
                }
              };
            });
          }
        }
      }
      // if (apiRequestCallId === this.myPopup) {
      //   this.setState({ myPopUpData: responseJson.data })
      //   this.setState({ isFavouriteModal: true })
      // }
      if (apiRequestCallId === this.productDescriptionAPICallId) {
        const catalogResponse = responseJson as { data: CatalogueResponse };
        if (catalogResponse && catalogResponse.data) {
          const { price, stock_qty, productImage, variantType, itemId, id } =
            catalogResponse.data.attributes?.catalogue_variants[0].attributes as CatalogueVariantAttributes;
          this.checkStocks(itemId)

          this.setState({ productDescId: catalogResponse?.data.id })
          this.setState({
            productDescription: catalogResponse?.data, selectedVariant: {
              price,
              productImage,
              variantId: id,
              variantItemId: itemId,
              stockQty: stock_qty,
              variant: variantType,
              // variantErrorMsg: !stock_qty ? "Out of stock" : ""
            }
          })
          // this.setState({ descLoader: false })
        } else {

        }
          
      }

      if (
        apiRequestCallId &&
        this.apiProductListCallId &&
        apiRequestCallId === this.apiProductListCallId
      ) {
        const allCatalogue = responseJson as { data: CatalogueData[] }
        if (allCatalogue && allCatalogue.data && allCatalogue.data.length) {
          this.setState({
            showCatalogues: this.showAllCatalogues,
            catalogueData: allCatalogue.data,
            currentPage: this.state.currentPage + 1
          })
          this.setState({ isCatalogueLoading: false })
        } else {
          this.setState({
            showCatalogues: this.showAllCatalogues,
            catalogueData: [],
            isCatalogueLoading: false
          })
        }
      }
      if (apiRequestCallId &&
        this.getCategoryProductsAPICallId &&
        apiRequestCallId === this.getCategoryProductsAPICallId
      ) {
        const categoryCatalogue = responseJson as { data: CatalogueResponse[] }
        if (
          categoryCatalogue &&
          categoryCatalogue.data &&
          categoryCatalogue.data.length
        ) {
          this.setState((prev) => ({
            showCatalogues: this.showCategoriesCatalogues,
            categoriesCatalogueData: {
              ...prev.categoriesCatalogueData,
              [this.state.selected]: categoryCatalogue.data
            },
            isCatalogueLoading: false
          }))
        } else {
          this.setState((prev) => ({
            showCatalogues: this.showCategoriesCatalogues,
            categoriesCatalogueData: {
              ...prev.categoriesCatalogueData,
              [this.state.selected]: []
            },
            isCatalogueLoading: false
          }))
        }
        // console.log("CATEGORPR", this.state.categoriesCatalogueData)
      }
      if (apiRequestCallId &&
        this.seeAllAPICallId &&
        apiRequestCallId === this.seeAllAPICallId
      ) {
        const categoryCatalogue = responseJson as { data: CatalogueResponse[] }
        if (
          categoryCatalogue &&
          categoryCatalogue.data &&
          categoryCatalogue.data.length
        ) {
          this.setState((prev) => {
            const { showAllProducts } = prev.showCatalogues
            let catalogues = [...(showAllProducts ? prev.catalogueData : prev.filteredCatalogue)];
            catalogues[prev.seeAllCategoryIndex].attributes.catalogue.data = categoryCatalogue.data;
            catalogues[prev.seeAllCategoryIndex].attributes.seenAll = true
            return {
              ...prev,
              ...(showAllProducts ?
                { catalogueData: catalogues } :
                { filteredCatalogue: catalogues })
            };
          });
        }
      }
      if(apiRequestCallId === this.apiFarmDetaisCallId){
      
        const reqFarmDetails = responseJson?.data[0].attributes
        this.setState({FarmDetails: reqFarmDetails})
        this.setState({farmDetailsImage:reqFarmDetails.images[0].url})
        this.setState({farmDetailsStep1:reqFarmDetails.step1_images[0].url })
        this.setState({farmDetailsStep2:reqFarmDetails.step2_images[0].url })
        // console.log("FarmDetails", this.state.FarmDetails);
        // console.log("farmDetailsImage",this.state.farmDetailsImage);
        // console.log("farmDetailsStep1",this.state.farmDetailsStep1);
        // console.log("farmDetailsStep1",this.state.farmDetailsStep1); 
      }
      

      if(apiRequestCallId===this.apiAscendingCallId){
        console.log("SORT", responseJson)
        // if(responseJson?.data){
        //   this.setState({isFilterEnable:true,filteredData:responseJson?.data,currentPage: this.state.currentPage})
        // }

      }

      if (apiRequestCallId &&
        this.getSubCategoriesProductsAPICallId &&
        apiRequestCallId === this.getSubCategoriesProductsAPICallId
      ) {
        const subCategoryCatalogue = responseJson as { data: CatalogueResponse[] }
        if (
          subCategoryCatalogue &&
          subCategoryCatalogue.data &&
          subCategoryCatalogue.data.length
        ) {
          this.setState((prev) => ({
            showCatalogues: this.showSubCategoriesCatalogues,
            subCategoriesCatalogueData: {
              ...prev.subCategoriesCatalogueData,
              [this.state.currentSubCategoryId]: subCategoryCatalogue.data
            },
            isCatalogueLoading: false
          }))
        } else {
          this.setState((prev) => ({
            showCatalogues: this.showSubCategoriesCatalogues,
            subCategoriesCatalogueData: {
              ...prev.subCategoriesCatalogueData,
              [this.state.currentSubCategoryId]: []
            },
            isCatalogueLoading: false
          }))
        }
      }
      if (apiRequestCallId &&
        this.getSubCategoriesAPICallId &&
        apiRequestCallId === this.getSubCategoriesAPICallId
      ) {
        const subCategories = responseJson as {
          sub_categories: {
            data: {
              attributes: SubCategoryAttributes
            }[]
          }
        }
        if (
          subCategories &&
          subCategories.sub_categories &&
          subCategories.sub_categories.data.length
        ) {
          console.log("SUBCATEGo", subCategories)
          let customData = {}
          // subCategories.sub_categories.data.forEach(item => {
          //   if(item.attributes.name.toLowerCase().includes("beef") ||
          //   item.attributes.name.toLowerCase().includes("wagyu")
          //   ){
          //     customData.category = "beef"
          //   }
          // })
        }
      }
      if (
        apiRequestCallId &&
        this.productSearchCallId &&
        apiRequestCallId === this.productSearchCallId
      ) {
        const filteredCatalogueProducts = responseJson as { data: CatalogueData[] }
        if (filteredCatalogueProducts &&
          filteredCatalogueProducts.data &&
          filteredCatalogueProducts.data.length
        ) {
          this.setState({
            showCatalogues: this.showFilteredCatalogues,
            filteredCatalogue: filteredCatalogueProducts.data,
            isCatalogueLoading: false,
            currentPage: this.state.currentPage + 1
          })
        } else {
          this.setState({
            isCatalogueLoading: false,
            showCatalogues: this.showFilteredCatalogues,
            filteredCatalogue: []
          })
        }
      }
      if (
        apiRequestCallId &&
        this.checkStocksAvailablityAPICallId &&
        apiRequestCallId === this.checkStocksAvailablityAPICallId
      ) {
        console.log("AVAIL", responseJson)
        if (responseJson && responseJson.result && responseJson.result.stockQty) {
          this.setState({ availablevariantQty: responseJson.result.stockQty, descLoader: false })
        } else {
          this.setState({ availablevariantQty: 0, descLoader: false })
        }
      }
      if (apiRequestCallId &&
        this.addToCart &&
        apiRequestCallId === this.addToCart
      ) {
        if (responseJson && responseJson.data) {
          window.alert("Product added to the cart")
        } else {
          window.alert("Something went wrong")
        }
      }
      if (errorReponse) {
        if (apiRequestCallId === this.apiCategoriesCallId) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }

      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  showAllCatalogues: CataloguesToShow = {
    showAllProducts: true,
    showCategoriesProduct: false,
    showSubCategoriesProduct: false,
    showFilteredProducts: false
  }
  showCategoriesCatalogues: CataloguesToShow = {
    showAllProducts: false,
    showCategoriesProduct: true,
    showSubCategoriesProduct: false,
    showFilteredProducts: false
  }
  showSubCategoriesCatalogues: CataloguesToShow = {
    showAllProducts: false,
    showCategoriesProduct: false,
    showSubCategoriesProduct: true,
    showFilteredProducts: false
  }
  showFilteredCatalogues: CataloguesToShow = {
    showAllProducts: false,
    showCategoriesProduct: false,
    showSubCategoriesProduct: false,
    showFilteredProducts: true
  }
  handleChangeSortBy = (event: any) => {
    const {selected,currentSubCategoryId,serachTerm} = this.state
    this.setState({ sort: event.target.value })
    if (selected && !currentSubCategoryId && !serachTerm) {
      this.getCategoryProducts(selected, false, event.target.value)
    } else if (currentSubCategoryId && !serachTerm) {
      this.getSubCategoriesProducts(currentSubCategoryId, event.target.value)
    } else {
      this.setState({ sort: event.target.value }, () => {
        this.filterItems()
      });
    }

  };

  handleModal(id:any){
    console.log(id,"favorite data",this.state.favouriteData,"------helloooooooooooooooooooo")
    let data=this.state.favouriteData&&this.state.favouriteData.find((i:any)=>i.attributes?.catalogue_id?.data?.id==id)
    if(data)
      return true
  }
  
  handleIncrement = () => {
    this.setState(prevState => ({
      value : prevState.value + 1
    }))
  }
  handleSubscriptionClose = () => {
    console.log("hellppppppp")
    this.setState({ isSubscriptionOpen : false})
  }
  handleSubcriptionOpen = () => {
    console.log("okay");
    this.setState({ subscriptionflag : true})
  }

  async componentDidMount() {
    // this.callGetFavoriteApi()
    window.addEventListener("scroll", this.handleScroll);

    localStorage.getItem('user_type') === 'merchant' ? this.setState({ merchantFlag: true }) : this.setState({ merchantFlag: false })
    if (window.location.href.split('/').reverse()[1] == 'CategoriesDescription') {
      this.handleProductClick(window.location.href.split('/').reverse()[0], 'desc')
      this.FarmDetais();
      this.setState({ descLoader: true })
    } else {
      this.callGetCategoriesApi()
      this.ProductListApi();
    }

  }

  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

    setTimeout(this.deplayDebounce)
  }

  handleScroll=()=>{
    // this.callGetCatalogueApi()
  }

  favouriteModal=()=>{
    // this.setState({ isFavouriteModal: false })
  }
  handleAddToFavrouite = (id: number | undefined, index: number, toAdd: boolean) => {
    this.setState({
      isFavouriteModal: {
        isOpen: true,
        text: toAdd ?
          "Are you sure want to add product in my favorites" :
          "Are you sure want to remove product from my favorites"
      },
      selectedFavouriteID: id,
      selectedFavouriteParentIndex: index
    })
  }
  handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value

    clearTimeout(this.deplayDebounce)
    this.setState({ serachTerm: value }, () => {
      this.deplayDebounce = setTimeout(() => {
        this.filterItems()
        if (value?.length === 0) {
          this.setState({
            isFilterEnable: false,
            currentPage: 1
          }, () => { this.ProductListApi() })
        } else {
          this.setState({
            isFilterEnable: true
          })
        }
      }, 300)
    })
  }
  handleScrollData() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (windowBottom >= docHeight) {
        this.setState({
            isBottom: true
        });
    } else {
        this.setState({
            isBottom:false
        });
    }
}

  filterItems = () => {
    const { serachTerm, sort, isFilterEnable } = this.state
    let searchValue = serachTerm
    if (searchValue.trim() === "" && !sort) {
      this.setState({
        filteredCatalogue: [],
        showCatalogues: this.showAllCatalogues
      })
    } else {
      let filterWithQueryParam = searchValue.length && `query=${searchValue}`;
      let filterWithSortParam = sort && `price=${sort}`
      if (!searchValue.trim()) {
        this.setState({
          isCatalogueLoading: true,
          showCatalogues: this.showAllCatalogues
        })
      } else {
        this.setState({
          carouselSelect: "",
          selected: 0
        })
      }

      const headers = {
        "Content-Type": configJSON.categoryApiContentType,
        "token": localStorage.getItem("authToken")
      }
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.productSearchCallId = getValidationsMsg.messageId
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_catalogue/catalogues/search_product?${[
          filterWithQueryParam, filterWithSortParam
        ].filter(item => item).join("&")}`
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

      // this.setState({filteredData:filtered})
    }
  }

  handleSeeAll = (id: number, index: number) => {
    this.getCategoryProducts(id, true);
    this.setState({ seeAllCategoryIndex: index })
  }
  handleClicks = (id: number, name: string) => {
    this.setState({ selected: id, carouselSelect: name , currentSubCategoryId: 0});
    this.getCategoryProducts(id)
    // this.getSubCategories(id)
  }
  getSubCategories = (category_id: number) => {    
    // this.setState({isCatalogueLoading: true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoriesAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories/get_subcategory?categoryId=${category_id}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }

  getSubCategoriesProducts = (sub_category_id: number, sortBy?: string) => {    
    // this.setState({isCatalogueLoading: true})
    if (this.state.subCategoriesCatalogueData[sub_category_id] && !sortBy) {
      this.setState({ showCatalogues: this.showSubCategoriesCatalogues });
      return
    }
    const { sort } = this.state
    let sortParam = (sortBy || sort) && `&price=${sortBy ? sortBy : sort}`
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoriesProductsAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories/fetch_subcategory_products?sub_category_id=${sub_category_id}${sortParam ? sortParam : ""}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    
  }



  ProductListApi = () => {
    if (this.state.catalogueData.length) {
      this.setState({ showCatalogues: this.showAllCatalogues })
      return
    }
    this.setState({isCatalogueLoading: true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
       "token" : localStorage.getItem("authToken") || ""
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProductListCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/catalogues_by_category_8_items`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    
  }

  checkStocks = (id: number, variantSelect?:string) => {
    this.setState({ descLoader: variantSelect ? false : true })
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": localStorage.getItem("authToken") || ""
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkStocksAvailablityAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories/check_stock_availability?item_id=${id}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);


  }
  FarmDetais = () => {
  
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFarmDetaisCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_farm/farms`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    
  }

  getCategoryProducts = (id:number, seeAll:boolean = false, sortBy?: string) => {
    if (this.state.categoriesCatalogueData[id] && !seeAll && !sortBy) {
      this.setState({ showCatalogues: this.showCategoriesCatalogues });
      return
    }
    const { sort } = this.state
    let sortParam = (sortBy || sort) && `&price=${sortBy ? sortBy : sort}`
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (seeAll) {
      this.seeAllAPICallId = getValidationsMsg.messageId;
    } else {
      this.setState({ isCatalogueLoading: sortBy ? false :  true })
      this.getCategoryProductsAPICallId = getValidationsMsg.messageId;
    }

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories/fetch_products_by_category?category_id=${id}${sortParam ? sortParam : ""}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    
  }
  handleClose = (_: any, reason: any) => {
    if (reason === 'backdropClick') {
      this.setState({ descriptionFlag: false })
    }
  }

  handleButton1 = (quanValue: any) => {
    this.setState({subsValue:true})
    const product = {[this.state.productDescId]: true}
    localStorage.setItem("quantityValue",JSON.stringify(product))
    this.setAddToCart(this.state.productDescId)
  }

  handleIncrement2 = () => {
    this.setState({plusValue1 : this.state.plusValue1 + 1})
  
  }

  handleDecrement2 = () => {
    if(this.state.plusValue1 <= 0){
      return
    }
    this.setState({plusValue1:this.state.plusValue1 - 1})
  }

  handleCopy=(id:any)=>{
      console.log(">>>>>>>>>>>>>>>>>", id)
      const urlToCopy = window.location.href;
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        alert('URL copied to clipboard!');
      })
    }

  handleVariantQtyIncrement = () => {
    // console.log("CLICKE")
    const { availablevariantQty } = this.state
    if (availablevariantQty && availablevariantQty <= this.state.variantQty) {
      this.setState((prevstate) => ({
        selectedVariant: {
          ...prevstate.selectedVariant,
          variantErrorMsg: `Cannot add stock more than ${availablevariantQty}`
        }
      }))
      return
    }
    this.setState((prevstate) => ({ variantQty: prevstate.variantQty + 1 }))
  }

  handleVariantQtyDecrement = () => {
    if (this.state.variantQty <= 0) return
    this.setState((prevstate) => ({ variantQty: prevstate.variantQty - 1 }))
  }

  handleVariantSelect = (item: CatalogueVariantAttributes) => {
    this.checkStocks(item.itemId,"variantSelect");
    this.setState({
      selectedVariant: {
        variantId: item.id,
        variantItemId: item.itemId,
        variant: item.variantType,
        price: item.price,
        productImage: item.productImage,
        stockQty: item.stock_qty,
      },
      variantQty: 0
    })
  }
  setMyFavourite=(id:number|string,fromDetails?:boolean)=>{
    if (fromDetails) {
      this.setState({ selectedFavouriteID: id })
    }
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }
   
    const body={
      favourites:{
        favouriteable_id:2,
        favouritebale_type:"AccountBlock::Account",
        catalogue_id:id
    }
  }
 
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myFavorites = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/favourites"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    console.log(getValidationsMsg,"DATTATAA")
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  
  }

  handleProductClick = (id: any,value: string) => {
    // this.setState({selectedFavouriteID:id})
    // this.callGetFavoriteApi()
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": localStorage.getItem("authToken")
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(value==='desc'){
      this.productDescriptionAPICallId = getValidationsMsg.messageId;
    }else{
      this.myPopup = getValidationsMsg.messageId;
    }
    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/${id}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callGetCategoriesApi() {
    this.setState({isCatalogueLoading:true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoriesCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
 


  setAddToCart=(id:any)=>{
    localStorage.setItem('fastDelivery', 'false');
        if(!localStorage.getItem('authToken'))
    {
      this.setState({loginFlag:true})
      return null
    }

    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      "token" : localStorage.getItem("authToken")
    }
    const body={
          order_items:{
                catalogue_id:id,
                quantity:this.state.isSubscriptionOpen ? this.state.plusValue1 : this.state.variantQty,
        catalogue_variant_id: this.state.selectedVariant.variantId,
        frequency: this.state.isSubscriptionOpen ? this.state.setFrequency : undefined,
          taxable:"true",
          taxable_value:0.1233,
          total_tax:"100",
          created_date:"21 april 2023",
          totalAmount:"100",
           payment_type:"cash on delivery",
          other_charges:0.124,
           delivered_at:"2023-04-21T12:27:59.395Z"
          }
     }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCart = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_shopping_cart/order_items"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
    // this.props.navigation.navigate('MyCart')
  }

  handleloginContinue=()=>{
    this.props.navigation.navigate('Home')
    this.setState({loginFlag:false})
  }

  handleloginClose=()=>{
    this.setState({loginFlag:false})
  }

  callGetFavoriteApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const body={
      favouriteable_type:"AccountBlock::Account"
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFavoritesCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/favourites/index_create"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    handleFrequency = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({setFrequency: event.target.value})
    }
  // Customizable Area End
}