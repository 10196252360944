import React, { Component } from "react";
import settingControllerWeb, {
  LambSubCategory,
  Props,
} from "../../blocks/Settings/src/settingControllerWeb";
import { lamb, lambBreast, lambChuck, lambFlank, lambHead, lambLeg, lambLion, lambNeck, lambRibs, lambShank, lambShoulder } from "./assest"; 


class AnimalLambNG extends settingControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isAnalytics } = this.state
    const webStyle: any = {

      animalImgCont1: {
        overFlow: "hidden",
      },
      animalImage: {
        width: "50%",
        height: "50%",
        objectFit: isAnalytics ? "fills" : "none",
        mixBlendMode: "darken",
        marginLeft: isAnalytics ? 0 : "5rem",
        transform: isAnalytics ? "scale(2.2)" : 0
      },
      imageContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
      },
      chunckArea1: {
        cursor: "pointer",
      }
    }
    return (
      // Customizable Area Start
      <div style={{ minWidth: '630px' }} className="container" data-testid="animalView">
        <div style={webStyle.animalImgCont1}>
          <div style={webStyle.imageContainer}>
            {this.state.lamb && <img style={webStyle.animalImage} src={lamb} useMap="#image_map" />}
            {this.state.lambBreast && <img style={webStyle.animalImage} src={lambBreast} useMap="#image_map" />}
            {this.state.lambChuck && <img style={webStyle.animalImage} src={lambChuck} useMap="#image_map" />}
            {this.state.lambFlank && <img style={webStyle.animalImage} src={lambFlank} useMap="#image_map" />}
            {this.state.lambHead && <img style={webStyle.animalImage} src={lambHead} useMap="#image_map" />}
            {this.state.lambLeg && <img style={webStyle.animalImage} src={lambLeg} useMap="#image_map" />}
            {this.state.lambLion && <img style={webStyle.animalImage} src={lambLion} useMap="#image_map" />}
            {this.state.lambNeck && <img style={webStyle.animalImage} src={lambNeck} useMap="#image_map" />}
            {this.state.lambRibs && <img style={webStyle.animalImage} src={lambRibs} useMap="#image_map" />}
            {this.state.lambShank && <img style={webStyle.animalImage} src={lambShank} useMap="#image_map" />}
            {this.state.lambShoulder && <img style={webStyle.animalImage} src={lambShoulder} useMap="#image_map" />}
          </div>
          <map name="image_map"
            style={webStyle.chunckArea1}>
            <area
              style={webStyle.chunckArea1}
              alt="chunk"
              coords={!isAnalytics ? "218,228,242,229,279,231,277,262,279,291,253,292,232,292" : "157,137,174,140,194,140,194,160,193,176,168,176" }
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambBreast", LambSubCategory.Breast, "Breast")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="chunk"
              coords={!isAnalytics ? "196,85,241,89,229,124,215,186,209,235,175,210,144,212,117,228,102,239,88,211,148,152": "141,47,169,50,159,80,155,115,152,136,132,128,109,127,84,141,76,125,114,86"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambChuck", LambSubCategory.Chuck, "Chuck")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "289,231,331,232,379,233,383,279,352,286,290,293" : "202,142,232,141,256,140,259,158,245,173,204,176"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambFlank", LambSubCategory.Flank, "Flank")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "10,101,22,84,42,58,69,44,95,41,118,34,122,47,137,54,127,74,118,88,109,102,95,115,75,129,20,116,60,115,12,107": "27,54,40,38,72,17,97,20,102,39,91,53,68,71,35,66"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambHead", LambSubCategory.Head, "Head")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "415,83,472,91,500,115,511,143,518,171,509,192,494,192,493,221,493,254,495,273,433,271,403,275,390,254,391,163" : "280,46,313,51,333,72,342,96,340,110,329,121,332,163,308,161,282,163,266,164,264,102"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambLeg", LambSubCategory.Leg, "Leg")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "325,85,406,79,384,146,380,216,337,221,301,221,306,156" : "225,46,274,45,262,85,259,131,232,135,211,133,210,94" }
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambLion", LambSubCategory.Loin, "Lion")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "144,62,185,83,149,139,114,175,87,197,77,145,113,106" : "109,33,137,43,127,63,111,80,91,100,78,112,69,86"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambNeck", LambSubCategory.Neck, "Neck")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "250,89,316,83,300,144,289,220,220,221,227,161" : "177,48,203,46,217,46,209,85,202,130,161,130,161,112" }
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambRibs", LambSubCategory.Ribs, "Ribs")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "123,276,130,300,135,324,134,366,125,399,144,397,149,362,156,328,166,323,174,341,174,367,166,394,181,400,184,367,192,335,200,321,210,303,398,286,434,281,492,279,499,295,496,305,491,318,482,357,472,404,464,388,469,370,467,337,456,322,448,333,442,345,433,379,424,403,411,388,424,362,424,336,416,310,400,292,231,291" : "97,173,103,200,103,232,98,249,107,249,109,231,113,215,116,204,129,208,128,226,122,248,133,240,133,222,140,205,145,183,278,168,277,175,312,168,332,173,331,192,322,207,319,242,316,208,311,195,296,207,290,238,290,202,283,187,272,174"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambShank", LambSubCategory.Shank, "Shank")}
            />
            <area
              style={webStyle.chunckArea1}
              alt="head"
              coords={!isAnalytics ? "109,244,137,224,171,219,191,229,203,240,218,271,221,288,182,286,121,267" : "90,144,113,127,144,137,152,155,158,172,145,173,102,162"}
              shape="poly"
              onClick={() => this.handleLambPartClicks("lambShoulder", LambSubCategory.Shoulder, "Shoulder")}
            />
          </map>
        </div>
      </div>
      // Customizable Area End 
    );
  }
}

export default AnimalLambNG;

