import React from "react";
// Customizable Area Start
import {
    Grid,
    Modal, Box, Typography, IconButton, Card, FormControl, styled
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";
import CustomInlineButton from "../../../components/src/CustomInlineButton.web";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import AnimalCow from "../../../components/src/AnimalCow.web";
import AnimalPig from "../../../components/src/AnimalPig.web";
import AnimalChicken from "../../../components/src/AnimalChicken.web";
import myImage from '../assets/calendarIcon.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// import { PieChart } from '@mui/x-charts/';

// Customizable Area End

import settingControllerWeb, {
  Props,
} from "./settingControllerWeb";
import { CustomSelect } from "../../../components/src/CustomSelect";
import PieChart from "../../../components/src/PieChart.web";
import AnimalLamb from "../../../components/src/AnimalLamb.web";
import { getStorageData } from "../../../framework/src/Utilities";


export default class SettingWeb extends settingControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  modalShow = (options: any[], creditsAnalytics: any) => (    

    <Modal
      open={this.state?.isModalAnalytic}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={webStyle.profileBox}
    >
      <div style={webStyle.modalDiv}>
        <div style={webStyle.modalSubDiv}>
          <Box>
            <Typography
              style={webStyle.modalHeading}
              variant="h4"
              component="h2"
            >
              Analytics
            </Typography>
            <IconButton
              onClick={() => this.setState({ isModalAnalytic: false })}
              data-testid="closeModal"
              style={{
                position: "absolute",
                top: "20px",
                right: "25px",
                backgroundColor: "rgb(224, 236, 253)",
              }}
              aria-label="close"
            >
              <Close style={webStyle.iconCloseSvg} />
            </IconButton>
          </Box>
          <Box style={webStyle.cardStorageSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card style={webStyle.cardStorage}>
                  <Box style={webStyle.cardSpend}>
                    <Typography style={webStyle.cardSpendNumber}>
                      Number of spend
                    </Typography>
                    <Box style={webStyle.cardSpendrow}>
                      <Typography
                        variant="h6"
                        style={webStyle.cardSpendHeading}
                      >
                        {this.state.creditsAnalytics?.no_of_spend} 
                      </Typography>
                      <Typography
                        variant="h6"
                        style={webStyle.cardSpendSubHeading}
                      >
                        ${ this.state.creditsAnalytics?.total_spend_amount}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
                <FormControlWeb variant="outlined" style={webStyle.formControl}>
                  <CustomSelect
                    label="example"
                    hideLabel
                    options={options as any}
                    onChange={this.handleSelectOption}
                    value={this.state?.selectedOption}
                  />
                </FormControlWeb>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  style={{
                    ...webStyle.cardStorage,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                 >
                    {(this.state.selectedOption === "Angus Beef Bacon" ||
                    this.state.selectedOption === "Angus Beef" ||
                    this.state.selectedOption === "Angus and Wagyu" ||
                    this.state.selectedOption === "American Wagyu Beef" ||
                    this.state.selectedOption === "American Wagyu" ||
                    this.state.selectedOption === "Angus Beef Bacon") && <AnimalCow navigation={undefined} id={""} />}

                    {(this.state.selectedOption === "Mangalitsa Pork"||
                    this.state.selectedOption === "Bundles and Bulk" ||
                    this.state.selectedOption === "Berkshire Pork" ||
                    this.state.selectedOption === "Berkshire and Mangalitsa" ||
                    this.state.selectedOption === "Both Beeves"
                    ) && <AnimalPig navigation={undefined} id={""} />}

                    {(this.state.selectedOption === "Chicken" ) && <AnimalChicken navigation={undefined} id={""} />}

                    {(this.state.selectedOption === "Lamb" ||
                    this.state.selectedOption === "Herd Shares (Raw Milkk)") && <AnimalLamb navigation={undefined} id={""} />}

                  <ul style={webStyle.chartList}>
                    <li style={webStyle.chartLinks}>Remaining</li>
                    <li style={webStyle.chartLinks}>Sold</li>
                  </ul>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Current Animal Purchased
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>{this.state.creditsAnalytics?.animal}
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Total Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {this.state.creditsAnalytics?.total_cuts}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Used Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {this.state.creditsAnalytics?.used_cuts}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Remaining Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {this.state.creditsAnalytics?.remaining_cuts > 0 ? 
                            `${this.state.creditsAnalytics?.remaining_cuts} (${this.state.creditsAnalytics.remaining_cuts_per})` : "No Remain cuts"}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box style={webStyle.countinueSettingBtn} onClick={() => this.setState({ isModalAnalytic: false })} >
                  <CustomInlineButton title={"Continue"} styleWidth={"280px"}/>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Modal>
  );

  merchantModalShow = (options: any[], creditsAnalytics: any) => (
    <Modal
      open={this.state?.isMerchantModalAnalytic}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={webStyle.profileBox}
    >
      <div style={webStyle.modalDiv}>
        <div style={webStyle.modalSubDiv}>
          <Box>
            <Typography
              style={webStyle.modalHeading}
              variant="h4"
              component="h2"
            >
              Analytics
            </Typography>
            <IconButton
              onClick={() => this.setState({ isMerchantModalAnalytic: false })}
              data-testid="merchantclose"
              style={{
                position: "absolute",
                top: "20px",
                right: "25px",
                backgroundColor: "#d0e5fd",
              }}
              aria-label="close"
            >
              <Close style={webStyle.iconCloseSvg} />
            </IconButton>
          </Box>
          <Box style={webStyle.cardStorageSection}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card style={webStyle.cardStorage}>
                  <Box style={webStyle.cardTotal}>
                    <Box>
                      <Typography style={webStyle.cardSpendNumber}>
                        Total Income
                      </Typography>
                      <Box style={webStyle.cardSpendrow}>
                        <Typography
                          variant="h6"
                          style={webStyle.cardSpendHeading}
                        >
                           ${creditsAnalytics?.total_spend_amount?.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{ position: 'relative' }}>
                      <Typography
                        variant="h6"
                        style={webStyle.totalDate}
                        onClick={this.toggleOnDatePicker}
                      >
                        {format(this.state.selectedDate, "MMMM / 2024")}
                      </Typography>
                      {this.state.showDatePicker && (
                        <DatePicker
                          data-testid="datepicker"
                          selected={this.state.selectedDate}
                          onChange={this.handleDateChange}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          inline
                        />
                      )}
                    </Box>
                  </Box>
                  <Box>
                        <Bar data-testid='BarElement' data={this.state.data} options={this.state.options} />
                  </Box>
                </Card>
                <Card style={webStyle.cardStorage}>
                  <Box>
                    <Typography style={webStyle.cardSpendNumber}>
                      Number of Sales
                    </Typography>
                    <Box style={webStyle.cardSpendrow}>
                      <Typography
                        variant="h6"
                        style={webStyle.cardSpendHeading}
                      >
                        {creditsAnalytics?.no_of_spend || "0"}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={webStyle.cardSpendSubHeading}
                      >
                        {creditsAnalytics?.tota_amount || "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlWeb
                  variant="outlined"
                  style={webStyle.merchantFormControl}
                >
                  <CustomSelect
                    label="select"
                    hideLabel
                    options={options as any}
                    onChange={this.handleSelectOption}
                    value={this.state?.selectedOption}
                  />
                </FormControlWeb>
                <Card
                  style={{
                    ...webStyle.cardStorage,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {(this.state.selectedOption.toLowerCase().includes("beef") ||
                    this.state.selectedOption.toLowerCase().includes("wagyu") ||
                    this.state.selectedOption.toLowerCase().includes("angus")) &&
                    <AnimalCow navigation={undefined} id={""} />}
                  {(this.state.selectedOption.toLowerCase().includes("pork") ||
                    this.state.selectedOption.toLowerCase().includes("mangalitsa") ||
                    this.state.selectedOption.toLowerCase().includes("berkshire")) &&
                    <AnimalPig navigation={undefined} id={""} />}
                  {this.state.selectedOption.toLowerCase().includes("chicken") &&
                    <AnimalChicken navigation={undefined} id={""} />} 
                  {this.state.selectedOption.toLowerCase().includes("lamb") &&
                    <AnimalLamb navigation={undefined} id={""} />} 
                  <ul style={webStyle.chartList}>
                    <li style={webStyle.chartLinks}>Remaining</li>
                    <li style={webStyle.chartLinks}>Sold</li>
                  </ul>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Current Animal Purchased
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {this.state?.selectedOption || "No animal"}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Total Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {creditsAnalytics?.total_cuts || "No cuts"}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Used Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {creditsAnalytics?.used_cuts || "No used cuts"}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <Card style={webStyle.cardStorage}>
                      <Box style={webStyle.cardSpend}>
                        <Typography style={webStyle.cardSpendNumber}>
                          Remaining Cuts
                        </Typography>
                        <Box style={webStyle.cardSpendrow}>
                          <Typography
                            variant="h6"
                            style={webStyle.cardSpendHeading}
                          >
                            {creditsAnalytics?.remaining_cuts > 0 ? creditsAnalytics?.remaining_cuts : "No remaining cuts"}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box style={webStyle.countinueSettingBtn} onClick={() => this.setState({ isMerchantModalAnalytic: false })}>
                  <CustomInlineButton title={"Continue"} styleWidth={"280px"}/>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Modal>
  );

  render() {
    const { options,creditsAnalytics } = this.state;
    return (
      <div>
        <TopNavLoginWeb />
        {this.state?.isModalAnalytic && this.modalShow(options,creditsAnalytics)}
        {this.state?.isMerchantModalAnalytic && this.merchantModalShow(options,creditsAnalytics)}
        <div style={webStyle.root}>
          <h3 style={webStyle.heading}>Settings</h3>
          <Grid container style={webStyle.GridBox}>
              <Grid item lg={4} xs={12} sm={4}>
                <div style={webStyle.GridContainer}
                data-testid="modalAnalytic"
                  onClick={() => 
                    {(this.state?.userType === "user" || this.state?.userType === "customer") ? (
                    this.setState({
                      isModalAnalytic: !this.state?.isModalAnalytic,
                    })
                    ) : 
                    this.setState({
                      isMerchantModalAnalytic: !this.state?.isMerchantModalAnalytic,
                    })
                    }}>
                  <span>Analytics</span>
                </div>
              </Grid>
            <Grid item lg={4} xs={12} sm={4}>
              <Link to="/terms" style={{textDecoration:"none"}}>
                <div style={webStyle.GridContainer}>
                  <span>Terms & Conditions</span>
                </div>
              </Link>
            </Grid>
            {localStorage.getItem("user_type") ==='user'?(<Grid item lg={4} xs={12} sm={4}>
              <Link to="/user-orders" style={{textDecoration:"none"}}>
                <div style={webStyle.GridContainer}>
                  <span>My Orders</span>
                </div>
              </Link>
            </Grid>):(<Grid item lg={4} xs={12} sm={4}>
              <Link to="/merchant-orders" style={{textDecoration:"none"}}>
                <div style={webStyle.GridContainer}>
                  <span>My Orders</span>
                </div>
              </Link>
            </Grid>)}
            
            {(this.state?.userType === "user" ||
              this.state?.userType === "customer") && (
              <Grid item lg={4} xs={12} sm={4}>
                <div style={webStyle.GridContainer} className={"GridContainer"}>
                  <span>Lifetime Subscription</span>
                  <Switch
                    data-testid="checked"  
                    checked={this.state.isChecked}
                    onChange={this.handleChecked}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </Grid>
            )}
            {(this.state?.userType === "user" ||
              this.state?.userType === "customer") && (
              <Grid item lg={4} xs={12} sm={4}>
                <div style={webStyle.GridContainer} className={"GridContainer"}>
                  <span>Cold Packaging Fee</span>
                  <Switch
                    data-testid="checkedFee"
                    checked={this.state.checkedFee}
                    onChange={this.handleCheckedFee}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </Grid>
            )}
            <Grid item lg={4} xs={12} sm={4}>
              <div
                style={webStyle.GridContainer}
                data-testid="logout"
                onClick={this.handleLogout}
              >
                <span>LogOut</span>
              </div>
            </Grid>
            <Grid item lg={4} xs={12} sm={4}>
              <div
                data-testid="deleteAcc"
                style={webStyle.GridContainer}
                onClick={() => this.handleDelete()}>
                <span style={{ color: "#A9C9F7" }}>Delete My Account</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );}
}

const FormControlWeb = styled(FormControl)({
  "& fieldset": {
    display: "none",
  },
});

const webStyle: any = {
  root: {
    // maxWidth: 0,
    backgroundColor: "#F5F2ED",
    // height: 'auto',
    height: "100vh",
    paddingTop: "2rem",
  },
  dropDownImage:{
    width:"100%",
    height:"auto",
  },
  chunckArea : {
    cursor:"pointer",
  },
  heading: {
    color: "#231F20",
    marginLeft: "48px",
    fontSize: "1.5rem",
  },
  GridContainer: {
    backgroundColor: "white",
    width: "80%",
    padding: "1rem",
    borderRadius: "1rem",
    color:"#231F20",
    cursor: "pointer",
    marginBottom: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "roboto"
  },
  GridBox: {
    padding: "1rem 0rem 0rem 3rem",
  },
  modalDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modalSubDiv: {
    width: "80%",
    minHeight: "400px",
    background: "white",
    borderRadius: "15px",
    position: "relative",
  },
  iconCloseSvg: {
    color: "#231F20",
    fontSize: "14px",
  },
  modalHeading: {
    color: "#231F20",
    fontWeight: "bold",
    marginTop: "15px",
    marginBOttom: "30px",
    paddingBottom: "10px",
    textTransform: "capitalize",
    textAlign: "center",
  },
  cardStorage: {
    backgroundColor: "rgb(224, 236, 253)",
    borderRadius: 20,
    padding: "18px 14px",
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
  },
  cardSpendNumber: {
    fontSize: "14px",
    color: "gray",
    minHeight: "45px",
  },
  cardSpendrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardSpendHeading: {
    color: "#231F20",
    fontWeight: "600",
  },
  cardSpendSubHeading: {
    color: "#231F20",
  },
  cardStorageSection: {
    width: "94%",
    margin: "20px auto",
  },
  chartList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 0,
    fontSize: "13px",
    color: "rgb(87, 35, 40)",
    gap: "30px",
    fontFamily: 'font-family: "Roboto", "Helvetica", "Arial", sans-serif',
  },
  countinueSettingBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  formControl: {
    width: "100%",
    backgroundColor: "rgba(166, 0, 25, 0.082)",
    borderRadius: "20px",
    marginBottom: 15,
  },
  merchantFormControl: {
    width: "100%",
    backgroundColor: "rgba(166, 0, 25, 0.082)",
    borderRadius: "20px",
    marginBottom: 60,
  },
  totalDate: {
    padding: "7px 15px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    fontSize: "12px",
    color: "#3c3e49",
    fontWeight: 500,
  },
  cardSpend: {
    padding: "20px 10px",
  },
  cardTotal: {
    display: "flex",
    justifyContent: "space-between",
  },
  chart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "200px",
    padding: "10px",
  },
  barContainer: { 
    display: "flex",
    flexDirection: "column",
  },
  bar: {
    width: "30px",
    backgroundColor: "#fff",
    marginRight: "5px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  iconButton: {
    padding: 0,
    marginLeft: '10px',
  },
  iconImage: {
    width: '17px', 
    height: '17px',
    paddingLeft: '10px'
  },
};