import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

interface DoughnutChartProps {
  data: [number, number];
}

interface DoughnutChartState {
  chartData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
    }[];
  };
  options: {
    plugins: {
      legend: {
        display: boolean;
      };
      title: {
        display: boolean;
        text?: string;
      };
      tooltip: {
        enabled: boolean;
      };
    };
    animation: {
      animateScale: boolean;
    };
  };
}

class DoughnutChart extends Component<DoughnutChartProps, DoughnutChartState> {
  constructor(props: DoughnutChartProps) {
    super(props);
    this.state = {
      chartData: {
        labels: ['Remaining', 'Sold'],
        datasets: [
          {
            data: props.data,
            backgroundColor: ['#5d2221', '#a0282a'],
            hoverBackgroundColor: ['#5d2221', '#a0282a']
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: ''
          },
          tooltip: {
            enabled: true
          }
        },
        animation: {
          animateScale: true
        }
      }
    };
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        height: '120px',
        maxWidth: 'fit-content',
        margin: '10px auto',
        padding: '10px',
        
      }}>
        <Doughnut data={this.state.chartData} options={this.state.options} />
      </div>
    );
  }
}

export default DoughnutChart;
