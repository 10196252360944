import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Avatar,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Button
} from "@material-ui/core";
import TopNavWeb from "../../../components/src/TopNavWeb";
import IconButton from '@material-ui/core/IconButton';
import BookmarkBorderOutlined from "@material-ui/icons/BookmarkBorderOutlined";
import AboutUsControllerWeb from "./AboutUsControllerWeb";
// Customizable Area End

export default class AboutUs extends AboutUsControllerWeb {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const isLoggIn = !!localStorage.getItem('authToken')
        return (
            <>
                <TopNavWeb />
                <div style={webStyle.MainDiv}>
                    {this.state.data &&
                        <Box >
                            <Typography variant="h4" style={{ padding: '20px 35px', color: '#231F20', fontWeight: 900 }}>
                                {this.state.data[0]?.attributes.title}
                            </Typography>
                            <Box style={{ backgroundColor: 'white', margin: '0px 30px', borderRadius: '15px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} style={{ padding: '20px 0px 20px 35px' }}>
                                        <Typography style={{ paddingBottom: "15px", color: '#231F20' }}>
                                            {this.state.data[0]?.attributes.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box style={{ padding: '0px 10px' }}>
                                            <img src={this.state.data[0].attributes.images[0].url} alt="" style={{ width: "100%", height: '100%', borderRadius: '10px' }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>}

                    <Typography variant="h4" style={{ padding: '20px 35px', color: '#231F20', fontWeight: 900 }}>Feature Farms</Typography>
                    <Grid container wrap="nowrap" spacing={3} style={webStyle.GridBox}>
                        {this.state.farmData?.length !== 0 && (
                            this.state.farmData?.map(item => (
                                <Grid style={{minWidth: "350px"}} item xs={12} sm={4}>
                                    <Card style={webStyle.root}>
                                        <CardHeader
                                            style={{ paddingBottom: '0px' }}
                                            avatar={
                                                <Avatar aria-label="recipe" style={webStyle.avatar}>
                                                    <img 
                                                    style={{ width: "inherit" }} 
                                                    src={item.attributes.profile_images.length ? 
                                                        item.attributes.profile_images[0].url :
                                                        "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg"} 
                                                    />
                                                </Avatar>
                                            }
                                            title={<Typography style={webStyle.cardHeading}>{item.attributes.title}</Typography>}
                                            subheader={item.attributes.address}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <CardContent>
                                                <Typography variant="body2" style={webStyle.contentColor} color="textSecondary" component="p">
                                                    {item.attributes.sub_title}
                                                </Typography>
                                                <Typography style={{ fontSize: '15px', color: 'gray', margin: '20px 0px 10px 0px', letterSpacing: '1px' }}>PHOTOS</Typography>
                                                <Box style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                    {item.attributes.images.concat(item.attributes.step1_images, item.attributes.step2_images).map(image => (
                                                        <img
                                                            key={image.id}
                                                            style={{ width: 90, height: 90, borderRadius: '8px', margin: '5px' }}
                                                            src={image.url}
                                                            alt={image.filename || "featured_image"}
                                                        />
                                                    ))}
                                                </Box>
                                            </CardContent>
                                            <Typography style={{ fontSize: '15px', color: 'gray', margin: '10px', letterSpacing: '1px' }}>PRODUCTS</Typography>

                                            <div style={{ position: 'relative' }}>
                                                <CardMedia
                                                    style={webStyle.media}
                                                    image={item.attributes.product_image}
                                                    title='ncdvdhgc jhccyg'
                                                />
                                                <IconButton aria-label="favourite" style={webStyle.share}>
                                                <BookmarkBorderOutlined color="primary" />
                                                </IconButton>
                                            </div>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 10px 0px 15px" }}>
                                                <Typography style={webStyle.mediaText}>{item.attributes.productName}</Typography>
                                                <Typography style={webStyle.mediaText}>$ {item.attributes.price} /Kg</Typography>
                                            </Box>
                                            <Box style={webStyle.CardContentWithBtn}>
                                                <Typography variant="body2" style={webStyle.bottomcontentColor} color="textSecondary" component="p">
                                                    {item.attributes.description}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    type="submit"
                                                    style={webStyle.addToCart}
                                                    onClick={() => {
                                                        isLoggIn 
                                                          ? this.props.navigation.history.push(`CategoriesDescription/${item.attributes?.catalogue_id}`)
                                                          : window.alert("Need to Login");
                                                      }}
                                                >
                                                    Add to cart
                                                </Button>
                                            </Box>

                                        </div>
                                    </Card>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </div>

            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle: any = {
    root: {
        backgroundColor: "white",
        width: "100%",
        textAlign: "left",
        borderRadius: '10px'
    },
    MainDiv: {
        backgroundColor: "#F5F2ED",
        width: "100%",
        textAlign: "left",
        borderRadius: '10px'
    },
    GridBox: {
        paddingInline: "20px",
        width: "100%",
        height: "auto !important",
        minHeight: "calc(100vh - 187px)",
        overflow: "scroll"
    },
    addToCart: {
        border: "1px solid #a9caf7b7",
        borderRadius: "30px",
        padding: "10px 5px",
        textDecoration: "none",
        textTransform: "capitalize",
        color: "white",
        backgroundColor: '#A9C9F7',
        margin: "20px auto 20px",
        fontWeight: "bold",
        width: "130px",
        display: "block",
        marginLeft: "auto",
        marginRight: "0",
        "&:hover": {
            backgroundColor: "#A9C9F7"
        }
    },
    media: {
        height: 0,
        paddingTop: '30%',
        borderRadius: '10px',
        margin: '10px'
    },
    mediaText: {
        fontSize: '18px',
        fontWeight: 900,
        color: '#231F20'
    },
    mainRoot: {
        backgroundColor: "#fff",
        width: "100%",
    },

    BackToWork: {
        backgroundColor: "#fff",
        padding: "25px 50px",
        display: "flex",
        justifyContent: "space-between",
    },
    backTitle: {
        fontWeight: 700,
        color: "#700010"
    },
    contentColor: {
        color: "#231F20",
        minHeight: '40px',
    },
    bottomcontentColor: {
        color: "gray",
    },
    shareIcon: {
        color: "#A9C9F7",
    },
    share: {
        position: "absolute",
        top: "30px",
        right: "30px",
        border: "1px solid #A9C9F7",
        "@media (max-width: 900px)": {
            padding: '5px!important',
        },
    },
    cardHeading: {
        fontWeight: 700,
        color: "#231F20"
    },

    avatar: {
        width: "60px",
        height: "60px",
        borderRadius: '10px'
    },
    CardContentWithBtn: {
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: "10px 10px 30px 15px", 
        alignItems: 'center' ,
        "@media (max-width: 900px)": {
            flexDirection: 'column',
        }
    }

}

// Customizable Area End
