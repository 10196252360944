import React, { Component } from "react";
import settingControllerWeb, {
    Props,
  } from "../../blocks/Settings/src/settingControllerWeb";
import { pigimg } from "../../blocks/Settings/src/assets";
import { pigBacon, pigHead,pigLegHam,pigLoin, pigPicnic, pigRibs, pigShoulder,pigLeg, pigJowls, pigImage, pigNeck, pighock2, pigBackFat } from "./assest";

export default class AnimalPigNG extends settingControllerWeb {

constructor(props: Props) {
    super(props);
  }

  render() {
    const {isAnalytics} = this.state
    return (
      // Customizable Area Start   
       <div style={{minWidth: '600px'}} className="container" data-testid="animalView">
            <div style={webStyle.animalImgCont1}>
                <div style={webStyle.pigImageContainer}>
                  { this.state.pigImage && <img style={webStyle.animalImage} src={pigImage}  useMap="#image_map1"/> }
                  { this.state.pigHead && <img style={webStyle.animalImage} src={pigHead}  useMap="#image_map1"/> }
                  { this.state.pigLoin && <img style={webStyle.animalImage} src={pigLoin}  useMap="#image_map1"/>}
                  { this.state.legHam && <img style={webStyle.animalImage} src={pigLegHam}  useMap="#image_map1"/>}
                  { this.state.pigRibs && <img style={webStyle.animalImage} src={pigRibs}  useMap="#image_map1"/>}
                  { this.state.pigShoulder && <img style={webStyle.animalImage} src={pigShoulder}  useMap="#image_map1"/>}
                  { this.state.pigBacon && <img style={webStyle.animalImage} src={pigBacon}  useMap="#image_map1"/>}
                  { this.state.pigPicnic && <img style={webStyle.animalImage} src={pigPicnic}  useMap="#image_map1"/>}
                  { this.state.pigHock && <img style={webStyle.animalImage} src={pigLeg}  useMap="#image_map1"/>}
                  { this.state.pigJowls && <img style={webStyle.animalImage} src={pigJowls}  useMap="#image_map1"/>}
                  { this.state.pigNeck && <img style={webStyle.animalImage} src={pigNeck}  useMap="#image_map1"/>}
                  { this.state.pigHock2 && <img style={webStyle.animalImage} src={pighock2}  useMap="#image_map1"/>}
                  { this.state.pigBackFat && <img style={webStyle.animalImage} src={pigBackFat}  useMap="#image_map1"/>}
                </div>
                <map name="image_map1"> 
                   <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? 
                      "35,72,113,61,71,116,17,156,123,60,13,179,44,184,165,169,156,52,61,106" : 
                      "15,32,72,27,71,77,9,83"}
                    shape="poly"
                    onClick={this.handlePigHead}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "476,12,565,32,618,65,623,124,600,199,522,196,477,222,459,126" : "222,7,292,27,277,92,222,95"}
                    shape="poly"
                    onClick={this.handleLegHamClick}
                    />
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "284,68,280,136,340,129,393,126,452,122,450,72,373,64" : "136,33,203,33,203,52,133,61" }
                    shape="poly"
                    onClick={this.handlePigLoinClick}
                    />
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "174,231,239,234,224,328,168,329,179,262" : "80,111,108,112,102,137,83,142"}
                    shape="poly"
                    onClick={this.handlePigHock1Click}
                    />  
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "481,234,504,218,560,207,594,214,586,229,588,255,585,291,578,327,565,333,566,295,555,269,534,251,529,277,522,299,501,325,502,272" :  "222,105,270,96,267,141,231,134"} 
                    shape="poly"
                    onClick={this.handlePigHock2Click}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "262,224,321,224,384,204,450,153,459,194,467,230,389,246,328,246,258,240" : "207,78,217,110,139,114,132,106"}
                    shape="poly"
                    onClick={this.handleBaconClick}
                    /> 
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "202,25,274,4,272,168,209,160": "96,11,123,8,124,73,102,68" }
                    shape="poly"
                    onClick={this.handlePigShoulderClick}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "175,226,195,181,237,166,265,179,246,230,219,211" : "121,86,113,101,84,98,101,81"}
                    shape="poly"
                    onClick={this.handlePigPicnicClick}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "284,150,375,139,450,135,401,180,340,201,272,211" : "131,72,203,64,174,86,129,94"}
                    shape="poly"
                    onClick={this.handlePigRibsClick}
                    /> 
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "26,198,162,180,146,227" : "67,84,66,102,17,91"}
                    shape="poly"
                    onClick={this.handlePigJowlsClick}
                    />
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? 
                      "165,37,194,27,205,130,200,165,179,181,167,226":  "80,17,90,17,94,71,77,100"}
                    shape="poly"
                    onClick={this.handlePigNeckClick}
                    />
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "280,9,381,10,464,9,450,59,372,56,283,56" :"133,5,210,8,207,25,133,25" }
                    shape="poly"
                    onClick={this.handlePigBackFat}
                    />
                    </map>
            </div>
      </div>
      // Customizable Area End 
    );
  }
}

const webStyle: any = {
  animalImgCont1: {
    overFlow: "hidden"
  },
  animalImage: {
    width: "50%",
    height: "100%",
    // objectFit:"cover"
    mixBlendMode: "darken"
  },
  chunckArea1: {
    cursor: "pointer"
  },
  pigImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
}
