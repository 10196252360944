import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { cowimg } from "./assets";
import { pigimg } from "./assets";
import {chickenimg} from "./assets";
import { ribImage } from "./assets";
import { chickenImage } from "../../../components/src/assest";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

export enum ChickenSubCategory {
  back = 34206,
  breast = 34207,
  boneBroth = 34208,
  leg = 34209,
  neck = 34210,
  organs = 34211,
  thigh = 34212,
  wholeChicken = 34213,
  wing = 34214,
  tail = 0
}
export enum PigSubCategory {
  Bacon = 34192,
  Jowl = 34193,
  LegHam = 34194,
  Loin = 34195,
  LoinBackFat = 34196,
  LoinRib = 34197,
  OrgansAndMore = 34198,
  Picnic = 34199,
  Ribs = 34200,
  RibsLoin = 34201,
  SausagesAndGround = 34202,
  Shoulder = 34203,
  head = 0,
  Neck = 0,
  Hock = 0,
}

export enum BeefSubCategory {
  All = 34171,
  Brisket = 34172,
  Chuck = 34173,
  ChuckRibShortPlate = 34174,
  Flank = 34175,
  GroundBeefAndSausage = 34176,
  Head = 34177,
  OrgansAndMore = 34178,
  QuarterHalfAndWholeCow = 34179,
  Rib = 34180,
  Round = 34181,
  Shank = 34182,
  ShortLoin = 34183,
  ShortLoinSirloin = 34184,
  ShortPlate = 34185,
  Shoulder = 34186,
  Sirloin = 34187,
  ForeShank = 0,
  Bacon = 0
}
export enum LambSubCategory {
  Shoulder = 34222,
  Ribs = 34221,
  OrgansAndMore = 34220,
  Neck = 34219,
  Loin = 34218,
  Leg = 34217,
  GroundLamb = 34216,
  Chuck = 34215,
  Flank = 0,
  Breast = 0,
  Shank = 0,
  Head = 0
}
export interface IAnalytics {
  amount: number;
  no_of_spend_count: number;
  remaining_cuts: number;
  total_cuts: number;
  used_cuts: number;
}
export interface CreditsAnalytics {
  amount: number;
  no_of_spend_count: number;
  remaining_cuts: number;
  total_cuts: number;
  used_cuts: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // isForAnalytics?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  animalSelectedValue1:boolean;
  imageCoordinates: any;
  chickenImage:boolean;
  chickenBack:boolean;
  chickenLeg:boolean;
  chickenBreast:boolean;
  chickenWing:boolean;
  chickenThigh:boolean;
  chickenNeck:boolean;
  chickenTail:boolean;
  pigImage:boolean;
  pigBackFat:boolean;
  pigJowls:boolean;
  pigNeck:boolean;
  pigShoulder:boolean;
  pigPicnic:boolean;
  pigBacon:boolean;
  pigHock:boolean;
  pigLoin:boolean;
  pigHock2:boolean;
  pigRibs:boolean;
  pigHead:boolean;
  cowHead:boolean;
  cowHead1:boolean;
  cowBrisket:boolean;
  cowShortPlate:boolean;
  cowRound:boolean;
  cowShank:boolean;
  cowShank2:boolean;
  cowSirLion:boolean;
  cowFlank:boolean;
  cowShortLion:boolean;
  rib:boolean;
  cowChuck:boolean;
  legHam:boolean;
  cowimage:boolean;
  // lamb
  lambBreast: boolean;
  lambChuck: boolean;
  lambFlank: boolean;
  lambHead: boolean;
  lambLeg: boolean;
  lambLion: boolean;
  lambNeck: boolean;
  lambRibs: boolean;
  lambShank: boolean;
  lambShoulder: boolean;
  lamb: boolean;

  isChecked: boolean;
  isClicked: boolean;
  options: any;
  dataId: any;
  imageSelected:any;
  selectedOption: any;
  isModalAnalytic: boolean;
  isMerchantModalAnalytic: boolean;
  checkedFee: boolean;
  userType: string;
  analytics: IAnalytics;
  showDonutGraph: boolean;
  donutData: [];
  ourCredits : CreditsAnalytics;
  creditsAnalytics: any;
  showStat: boolean
  isAnalytics: boolean
  selectedDate: Date;
  showDatePicker: boolean;
  data: any;
  dataOptions: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class settingControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteAccountCallID: any;
  fetchTotalIncomeCallID: any
  fetchCategoriesCallID: any;
  creditsCallID: any;
  fetchUserAnalyticsCallID: any;
  getLifetimeSubscriptionID:any;
  updateLifetimeSubscriptionCallID: any;
  updateColdPackagingFeeCallID: any;
  getColdPackagingFeeCallID: any; 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    const localStorageCheckedState = localStorage.getItem('checkboxState');
    const localStorageCheckFeeState = localStorage.getItem('checkedFeeState');

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      isAnalytics: window.location.href.split('/').reverse()[0] === 'settingWeb',
      imageCoordinates: null,

      // lamb
      lambBreast: false,
      lambChuck: false,
      lambFlank: false,
      lambHead: false,
      lambLeg: false,
      lambLion: false,
      lambNeck: false,
      lambRibs: false,
      lambShank: false,
      lambShoulder: false,
      lamb: true,
      
      rib:false,
      cowChuck:false,
      cowBrisket:false,
      cowShank2:false,
      cowShank:false,
      cowFlank:false,
      cowRound:false,
      cowSirLion:false,
      cowShortPlate:false,
      cowShortLion:false,
      chickenImage:true,
      chickenBack:false,
      chickenLeg:false,
      chickenBreast:false,
      chickenWing:false,
      chickenThigh:false,
      chickenNeck:false,
      chickenTail:false,
      pigImage:true,
      pigBackFat:false,
      pigJowls:false,
      pigShoulder:false,
      pigBacon:false,
      pigPicnic:false,
      pigRibs:false,
      legHam:false,
      pigHead:false,
      pigLoin:false,
      pigHock:false,
      pigHock2:false,
      pigNeck:false,
      cowHead:true,
      cowHead1:false,
      animalSelectedValue1:true,
      cowimage:true,
      options: [],
      selectedOption: "",
      isChecked: localStorageCheckedState ? localStorageCheckedState === 'true' : false,
      checkedFee: localStorageCheckFeeState ? localStorageCheckFeeState === 'true' : false,
      isModalAnalytic: false,
      isMerchantModalAnalytic: false,
      dataId : 0,
      isClicked: false,
      userType: "",
      imageSelected:"Beef Bacon(Naval)",
      showDonutGraph: false,
      donutData: [77, 23], //default values
      // analyticsStatusId:"",
      creditsAnalytics:{},
      showStat: false,
      selectedDate: new Date(),
      showDatePicker: false,
      data: {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            label: 'Sales',
            data: [12, 19, 3, 5, 2, 3, 9],
            backgroundColor: 'rgba(160,40,42,255)',
            borderColor: 'rgba(160,40,42,255)',
            borderWidth: 1,
          },
        ],
      },
      dataOptions: {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Monthly Sales Data',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    },
    } as any;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if(responseJson){
        if (apiRequestCallId === this.fetchTotalIncomeCallID) {
          const totalSpendAmount = responseJson.total_spend_amount;
          this.setState({
            creditsAnalytics: {
              ...this.state.creditsAnalytics,
              total_spend_amount: totalSpendAmount,
            },
          });
        }
        if (apiRequestCallId === this.deleteAccountCallID) {
          this.handleLogout()
        }
        if (apiRequestCallId === this.creditsCallID) {
          this.setState({
            creditsAnalytics:responseJson
          })
                   
        }
        if(apiRequestCallId === this.getLifetimeSubscriptionID){
          if(this.state?.isChecked === true){
            localStorage.setItem("LifetimeSubscription", String(true));
          } else {
            localStorage.setItem("LifetimeSubscription", String(false));
          }
        }
        if (apiRequestCallId === this.fetchCategoriesCallID) {
          const { data = [] } = responseJson;
          const options = data.map((cat: any) => {
            return {
            label: this.capitalize(cat.attributes.name),
            value: {
              val: this.capitalize(cat.attributes.name),
              categoryId: cat.attributes.id
            },
          }});
          const first = options;
          this.setState({ options : options });
        }
        if (apiRequestCallId === this.fetchUserAnalyticsCallID) {
          this.setState({ analytics: responseJson });
        }
      }
      if (errorReponse) {
        if (apiRequestCallId === this.deleteAccountCallID) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ userType: localStorage.getItem("user_type") || "" });
    this.fetchTotalIncome();
    this.fetchCategories();
    this.getCreditsApi(this.state.dataId);
    this.getLifetimeSubscription();
    this.getColdPackagingFee();
  }

  handleDateChange = (date: any) => {
    this.setState({ selectedDate: date, showDatePicker: false });
  };

  toggleOnDatePicker = () => {
    this.setState({ showDatePicker: true });
  };
  toggleOffDatePicker = () => {
    this.setState({ showDatePicker: false });
  };


  handleImageLoad = (event:any) => {
    const imageCoordinates = event.target.getBoundingClientRect();
    this.setState({ imageCoordinates });
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.navigation.navigate('Home');
      localStorage.removeItem("photo")
  }
  handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {  
    const isChecked = event.target.checked;
    this.setState({ isChecked });
    this.updateLifetimeSubscription(isChecked); 
    localStorage.setItem('checkboxState', isChecked.toString());
    this.getLifetimeSubscription();
  }
  handleCheckedFee = (event:React.ChangeEvent<HTMLInputElement>) => {
    let checkedFee = event.target.checked;
    this.setState({ checkedFee});
    this.updateColdPackagingFee(checkedFee);
    localStorage.setItem('checkedFeeState', checkedFee.toString());
  }

  handleChunckClick = () => {
    // alert("chuck is clicked")
  }

  handlePigLoinClick = () => {
    // alert("pig loin is clicked ")
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:true,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Loin, "Lion")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigBackFat = () => {
    // alert("pig loin is clicked ")
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:true});
    this.subCategoryMessage(PigSubCategory.LoinBackFat, "Back fat")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigHock1Click = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:true,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Hock, "Hock")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigHock2Click = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:true,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Hock, "Hock")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleBaconClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:true,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Bacon, "Bacon")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigShoulderClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:true,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Shoulder, "Shoulder")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigPicnicClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:true,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Picnic, "Picnic")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigRibsClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:true,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Ribs, "Ribs")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigJowlsClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:true,pigNeck:false,pigHock2:false,pigBackFat:false});
    this.subCategoryMessage(PigSubCategory.Jowl, "Jowls")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handlePigNeckClick = () => {
    this.setState({pigImage:false,pigHead:false,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBackFat:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:true,pigHock2:false});
    this.subCategoryMessage(PigSubCategory.Neck, "Neck")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleRibClick = () => {
    // alert("Rib is clicked")
    this.setState({rib:true,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Rib, "Rib")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleHeadClick = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:true,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Head, "Head")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowChuckClick = () => {
    // alert("Rib is clicked")
    this.setState({rib:false,cowHead:false,cowChuck:true,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Chuck, "Chuck")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowBrisket = () => {
    // alert("Rib is clicked")
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:true,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Brisket, "Brisket")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowForShank = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:true,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.ForeShank, "Fore Shank")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowshortPlate = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:true,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.ShortPlate, "Short Plate")
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowshortLion = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:true,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.ShortLoin, "Short Lion")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowFlank = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:true,cowSirLion:false,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Flank, "Flank")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowSirlion = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:true,cowRound:false,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Sirloin, "Sirlion")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowRound = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:true,cowShank2:false});
    this.subCategoryMessage(BeefSubCategory.Round, "Round")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleCowShank = () => {
    this.setState({rib:false,cowHead:false,cowChuck:false,cowHead1:false,cowBrisket:false,cowShank:false,cowShortPlate:false,cowShortLion:false,cowFlank:false,cowSirLion:false,cowRound:false,cowShank2:true});
    this.subCategoryMessage(BeefSubCategory.Shank, "Shank")
   this.setState({ showDonutGraph: true, donutData: [] });
    
  }

  handleLoinClick = () => {
    // alert("Loin is clicked")
  }

  handleClick = () => {
    // alert("Loin is clicked")
  }

  handlePigHead = () => {
  //  alert("pig head is clicked")
   this.setState({pigImage:false,pigHead:true,legHam:false,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
   this.subCategoryMessage(PigSubCategory.head, "Head")
  this.setState({ showDonutGraph: true, donutData: [] });
   
  }

  handleLegHamClick = () => {
  //  alert("leg ham is clicked")
   this.setState({pigImage:false,pigHead:false,legHam:true,pigLoin:false,pigRibs:false,pigHock:false,pigBacon:false,pigShoulder:false,pigPicnic:false,pigJowls:false,pigNeck:false,pigHock2:false,pigBackFat:false});
   this.subCategoryMessage(PigSubCategory.LegHam, "Leg Ham")
  this.setState({ showDonutGraph: true, donutData: [] });
   
  }

  handleChickenBack = (event:any) => {
    this.setState({chickenBack:true,chickenImage:false,chickenBreast:false,chickenNeck:false,chickenLeg:false,chickenTail:false,chickenThigh:false,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.back, "Back")
   this.setState({ showDonutGraph: true, donutData: [] });
    
  }

  handleChickenLeg = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:false,chickenNeck:false,chickenLeg:true,chickenTail:false,chickenThigh:false,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.leg, "Leg")
   this.setState({ showDonutGraph: true, donutData: [] });
    
  }

  handleChickenBreast = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:true,chickenNeck:false,chickenLeg:false,chickenTail:false,chickenThigh:false,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.breast, "Breast")
   this.setState({ showDonutGraph: true, donutData: [] });
    
  }

  handleChickenWing = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:false,chickenNeck:false,chickenLeg:false,chickenTail:false,chickenThigh:false,chickenWing:true});
    this.subCategoryMessage(ChickenSubCategory.wing, "Wing")
   this.setState({ showDonutGraph: true, donutData: [] });
    
  }

  handleChickenTail = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:false,chickenNeck:false,chickenLeg:false,chickenTail:true,chickenThigh:false,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.tail, "Tail")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleChickenThigh = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:false,chickenNeck:false,chickenLeg:false,chickenTail:false,chickenThigh:true,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.thigh, "Thigh")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleChickenNeck = () => {
    this.setState({chickenBack:false,chickenImage:false,chickenBreast:false,chickenNeck:true,chickenLeg:false,chickenTail:false,chickenThigh:false,chickenWing:false});
    this.subCategoryMessage(ChickenSubCategory.neck, "Neck")
  this.setState({ showDonutGraph: true, donutData: [] });
  }

  handleLambPartClicks = (part: string, id: number, name: string) => {
    let lambState: { [key: string]: boolean } = {
      lambBreast: false,
      lambChuck: false,
      lambFlank: false,
      lambHead: false,
      lambLeg: false,
      lambLion: false,
      lambNeck: false,
      lambRibs: false,
      lambShank: false,
      lambShoulder: false,
      lamb: false,
    }
    lambState[part] = true
    this.setState({ ...lambState as unknown as S })
    this.subCategoryMessage(id, name)
    this.setState({ showDonutGraph: true, donutData: [] });
  }

  subCategoryMessage = (id: number, name: string) => {
    const data = {
      subCategoryId: id,
      subCategoryName: name
    };
    const msg = new Message(getName(MessageEnum.NavigationSettingMessage));
    msg.addData(getName(MessageEnum.NavigationMessageSettingToCategories), data);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  handleSelectOption = (selectedOption: any) => {
     this.setState({dataId : selectedOption.categoryId})
     this.setState({selectedOption: selectedOption.val})
     this.getCreditsApi(selectedOption.categoryId)
  }

  public convertPrice = (price: number) => {
    if (typeof price !== 'number') {
      price = parseFloat(price);

      if (!Number.isFinite(price)) {
        price = 0;
      }
    }

    return price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
  };

  private capitalize(txt: string | null  = ''): string {
    if(txt === null){
      return '';
    }
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  }

  handleDelete = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/delete_account'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  fetchTotalIncome = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
  
    const getTotalIncomeMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchTotalIncomeCallID = getTotalIncomeMsg.messageId;
  
    getTotalIncomeMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/total_income`
    );
  
    getTotalIncomeMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getTotalIncomeMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getTotalIncomeMsg.id, getTotalIncomeMsg);
  }
  

  fetchCategories = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchCategoriesCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCreditsApi = (catId :any) => {
    const headers = {    
      "Content-Type": configJSON.validationApiContentType, 
      "token": localStorage.getItem("authToken")
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditsCallID = getValidationsMsg.messageId;
    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/my_credits?category_id=${catId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  fetchUserAnalytics = (name: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchUserAnalyticsCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/my_credits?query=${name}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getLifetimeSubscription = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
  
    const getLifetimeSubscriptionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLifetimeSubscriptionID = getLifetimeSubscriptionMsg.messageId;
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_subscriptions/subscriptions'
    );
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getLifetimeSubscriptionMsg.id, getLifetimeSubscriptionMsg);
    console.log('updated lts: ', getLifetimeSubscriptionMsg);
  }

  updateLifetimeSubscription = (subscribed: boolean) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
    const updateLifetimeSubscriptionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateLifetimeSubscriptionCallID = updateLifetimeSubscriptionMsg.messageId;
    updateLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_subscriptions/subscriptions/update?subscribed=${subscribed}`
    );
    updateLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(updateLifetimeSubscriptionMsg.id, updateLifetimeSubscriptionMsg);
  }

  updateColdPackagingFee = (coldPackaging: boolean) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
  
    const updateColdPackagingFeeMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateColdPackagingFeeCallID = updateColdPackagingFeeMsg.messageId;
  
    updateColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/cold_packaging_fees?cold_packaging=${coldPackaging}`
    );
  
    updateColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    updateColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
  
    runEngine.sendMessage(updateColdPackagingFeeMsg.id, updateColdPackagingFeeMsg);
  }
  
  getColdPackagingFee = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
  
    const getColdPackagingFeeMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getColdPackagingFeeCallID = getColdPackagingFeeMsg.messageId;
  
    getColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/cold_packaging_fees'
    );
  
    getColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getColdPackagingFeeMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getColdPackagingFeeMsg.id, getColdPackagingFeeMsg);
  }

  // Customizable Area End
}