import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Customizable Area Start
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Icon
} from '@material-ui/core';
import { clsx } from "clsx";

import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { CustomInput } from './CustomInput';
// Customizable Area End

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& input': {
      cursor: 'pointer',
    }
  },
  icon: {
    position: 'absolute',
    right: '10px',
    top: '43px',
    transform: 'rotate(270deg)',
    transition: 'transform 0.3s ease-in-out',
    cursor: 'pointer',
  },
  iconActive: {
    transform: 'rotate(90deg)',
  },
  clickHolder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 20
  },
  hideLabel: {
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      display: 'none !important'
    },
    '& .MuiTextField-root': {
      marginTop: '0 !important'
    },
    '& .MuiInput-root': {
      marginTop: '0 !important'
    },
    '& .MuiIcon-root': {
      top: '13px !important'
    }
  }
}));

interface CustomSelectProps {
  options: any[];
  value: any;
  label: string;
  onChange: (value: any) => void;
  PopoverProps?: any;
  hideLabel?: boolean;
  containerStyles?: React.CSSProperties;
}

export const CustomSelect = (props: CustomSelectProps) => {
  const {
    options,
    value,
    onChange,
    label,
    hideLabel,
    ...rest
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [selected, setSelected] = React.useState(value);
  const [selectedLabel, setSelectedLabel] = React.useState('');

  React.useEffect(() => {
    if (value === '') {
      setSelectedLabel('');
    }
  }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;

    if (!target) return;

    setAnchorEl(target.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: any) => {
    setSelected(option.value);
    setSelectedLabel(option.label);
    onChange(option.value);
    handleClose();
  };

  React.useEffect(() => {
    const option = options.find((option: any) => option.value === value);
    if (option) {
      setSelectedLabel(option.label);
    }
  }, [value, options]);

  return (
    <div style={rest.containerStyles} className={clsx(classes.root, hideLabel && classes.hideLabel)}>
      <CustomInput
        label={label}
        value={selectedLabel}
        placeholder='Select'
        onChange={() => { }}
        variant="standard"
        disabled
      />

      <Icon
        className={clsx(classes.icon, { [classes.iconActive]: !!anchorEl})}>
        <ChevronLeft />
      </Icon>

      <div className={classes.clickHolder} onClick={handleClick}></div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorEl?.clientWidth,
            maxHeight: '200px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            color: '#231F20',
          },
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          {options.map((option: any) => (
            <ListItem
              button
              key={option.value}
              onClick={() => handleSelect(option)}
            >
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};