// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import NewOrderAlert from '../../blocks/alert/src/NewOrderAlert.web';
import MeatTypeMap from '../../blocks/MeatTypeMap/src/MeatTypeMap';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import VideoLibrary from '../../blocks/VideoLibrary/src/VideoLibrary';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Documentation from '../../blocks/Documentation/src/Documentation';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import Favourites from '../../blocks/favourites/src/Favourites';
import AddFavourites from '../../blocks/favourites/src/AddFavourites';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import Videos from '../../blocks/videos/src/Videos';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import DeliveryEstimator13 from '../../blocks/DeliveryEstimator13/src/DeliveryEstimator13';
import Wishlist2 from '../../blocks/Wishlist2/src/Wishlist2';
import Analytics from '../../blocks/analytics/src/Analytics';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Trending from '../../blocks/Trending/src/Trending';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import {UserProfileBasicBlockWeb} from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ShippingChargeCalculator from '../../blocks/ShippingChargeCalculator/src/ShippingChargeCalculator';
import Share from '../../blocks/share/src/Share';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
// import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import TargetedFeed from '../../blocks/TargetedFeed/src/TargetedFeed';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Search from '../../blocks/search/src/Search';
import Geofence from '../../blocks/geofence/src/Geofence';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Settings from '../../blocks/Settings/src/Settings';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import CategoriesCategoriesWeb from "../../blocks/categoriessubcategories/src/CategoriesCategories.web";
import ViewInventory from "../../blocks/categoriessubcategories/src/ViewInventory.web";
import BlogPostWeb from "../../blocks/landingpage/src/BlogPost.web";
import ProfileWeb from "../../blocks/user-profile-basic/src/Profile.web";
import settingWeb from "../../blocks/Settings/src/setting.web";
import TermsConditionsUsersWeb from "../../blocks/termsconditions/src/TermsConditionsUsersWeb.web";
import AboutUs from "../../blocks/landingpage/src/AboutUs.web";
import MerchantAddProduct from "../../blocks/catalogue/src/AddProduct.web";
import MerchantOrderManagement from "../../blocks/ordermanagement/src/MerchantOrderManagement";
import UserOrderManagement from "../../blocks/ordermanagement/src/UserOrderManagement";
import AnimalDetail from '../../blocks/catalogue/src/AnimalDetail.web';
import MyCredits from "../../blocks/user-profile-basic/src/MyCredits.web";
import MyCart from "../../blocks/catalogue/src/MyCart.web";
import CategoriesDescriptionWeb from '../../blocks/categoriessubcategories/src/CategoriesDescription.web';
import SendInvoice from '../../blocks/catalogue/src/SendInvoice.web';
import ContactusWeb from '../../blocks/contactus/src/ContactusWeb';
import Stripe from '../../blocks/catalogue/src/stripe/stripe';


const routeMap = {
  settingWeb: {
    component: settingWeb,
    path: '/settingWeb',
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },

  Geofence: {
    component: Geofence,
    path: '/Geofence',
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration',
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3',
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions',
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole',
  },

  MeatTypeMap: {
    component: MeatTypeMap,
    path: '/MeatTypeMap',
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics',
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles',
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary',
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement',
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails',
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen',
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation',
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration',
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders',
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem',
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites',
  },
  AddFavourites: {
    component: AddFavourites,
    path: '/AddFavourites',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2',
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
  },
  Videos: {
    component: Videos,
    path: '/Videos',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications',
  },
  
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPasswordWeb,
    path: '/NewPassword',
  },
  DeliveryEstimator13: {
    component: DeliveryEstimator13,
    path: '/DeliveryEstimator13',
  },
  Wishlist2: {
    component: Wishlist2,
    path: '/Wishlist2',
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics',
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions',
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails',
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation',
  },
  Posts: {
    component: Posts,
    path: '/Posts',
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails',
  },
  Trending: {
    component: Trending,
    path: '/Trending',
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3',
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5',
  },
  UserProfileBasicBlockWeb: {
    component: UserProfileBasicBlockWeb,
    path: '/UserProfileBasicBlock',
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  ShippingChargeCalculator: {
    component: ShippingChargeCalculator,
    path: '/ShippingChargeCalculator',
  },
  Share: {
    component: Share,
    path: '/Share',
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions',
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen',
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting',
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus',
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus',
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistration',
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen',
  },
  TargetedFeed: {
    component: TargetedFeed,
    path: '/TargetedFeed',
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },
  MerchantHome: {
    component:LandingPage,
    path: '/merchanthome',
  },
  Search: {
    component: Search,
    path: '/Search',
  },

  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },
  MerchantHome: {
    component:LandingPageWeb,
      path: '/merchanthome',
      exact: true
    },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  CategoriesCategoriesWeb: {
    component: CategoriesCategoriesWeb,
    path: '/store'
  },
  CategoriesCategoriesMerchantWeb: {
    component: CategoriesCategoriesWeb,
    path: '/merchantstore'
  },
  ViewInventory: {
    component: ViewInventory,
    path: '/inventory'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ProfileWeb: {
    component: ProfileWeb,
    path: "/profile",
  },
  
  NewOrderAlert: {
    component: NewOrderAlert,
    path: '/NewOrderAlert'
  },
  
  AnimalDetail: {
    component: AnimalDetail,
    path: '/AnimalDetail'
  },

  BlogPostWeb:{
    component: BlogPostWeb,
    path:'/blogpost',
  },
  TermsConditionsUsersWeb:{
    component: TermsConditionsUsersWeb,
    path:'/terms',
  },
  AboutUs:{
    component: AboutUs,
    path:'/aboutus',
  },
  MerchantAddProduct:{
    component: MerchantAddProduct,
    path:'/merchant-addProduct',
  },
  MerchantOrderManagement:{
    component: MerchantOrderManagement,
    path:'/merchant-orders',
  },
  UserOrderManagement:{
    component: UserOrderManagement,
    path:'/user-orders',
  },
  mycredits:{
    component: MyCredits,
    path:'/mycredits',
  },
  MyCart:{
    component: Stripe,
    path:'/mycart',
  },
  ContactusWeb: {
    component: ContactusWeb,
    path: '/contact',
  },
  CategoriesDescriptionWeb:{
    component:CategoriesDescriptionWeb,
    path:'/CategoriesDescription/:id'},
    SendInvoice:{
      component:SendInvoice,
      path:'/invoice'
    }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div style={{ height: '100%', width: '100%' }}>

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </div>
    );
  }
}

export default App;
