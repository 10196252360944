import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// Customizable Area Start
import { Container, Grid, Divider, Box, InputLabel } from "@material-ui/core";
import {
	Typography,
	Button,
	FormLabel
} from "@material-ui/core";
import { clsx } from "clsx";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Close from "@material-ui/icons/Close";

import TopNavWeb from "../../../components/src/TopNavWeb";
import { CustomSelect } from "../../../components/src/CustomSelect";
import { CustomInput } from "../../../components/src/CustomInput";

import Add from "@material-ui/icons/Add";
// Customizable Area End

import AddProductController, {
	Props,
	IProductForm,
	IProductVariant
} from "./AddProductController";
import CustomModalWeb from "../../../components/src/CustomModal.web";

export default class AddProductWeb extends AddProductController {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const { products, responseModal, addedProduct,productVariants } = this.state;
		return (
			<div style={webStyles.root}>
				<TopNavWeb props={this.props} />
				<Container maxWidth="lg" style={webStyles.container}>
					<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
						<Typography variant="h1" style={{ ...webStyles.pageText, fontSize: '30px' }}>
							Add Products
						</Typography>

						{/* <Typography
							variant="h4"
							onClick={this.addProductCard.bind(this)}
							style={{ ...webStyles.pageText, fontSize: '20px', cursor: 'pointer' }}>
							+ Add More
						</Typography> */}
					</Grid>

					{products.map((data, index) => (
						<div style={{ borderRadius: '15px'}}>
							<ProductCard
								root={this}
								index={index}
								lastIndex={products.length - 1}
								data={data}
								onChangeData={(data: IProductForm) => this.updateProductCard(data, index)}
							/>
							{productVariants.map((item, index) => (
								<ProductVariant
									key={index}
									removeVariantImage={this.removeVariantImage}
									handleVariantImageUpload={this.handleVariantImageUpload}
									handleVariantChange={this.handleVariantChange}
									item={item}
									lastIndex={productVariants.length - 1}
									index={index}
								/>
							))}
						</div>
					))}
					<div style={{ ...(webStyles.buttonContainer as any) }}>
						<Button
							variant="contained"
							color="secondary"
							style={{ ...webStyles.button, ...webStyles.buttonCancel }}
							onClick={() => this.goBack()}>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							style={webStyles.button}
							onClick={this.createProduct.bind(this)}
						>
							Publish
						</Button>
					</div>
					<CustomModalWeb
						handleButton={this.goBack.bind(this)}
						handleClose={this.handleResponseModalClose.bind(this)}
						isModalOpen={responseModal}
						btnTitle={'Continue'}
						statusImage={addedProduct ? CheckCircleOutlineIcon : Close}
						title={
							addedProduct ?
								`Thank you for you products.You'll get notified once they'll accept by admin.` :
								'Product not created please try again later.'
						}
					/>
				</Container>
			</div>
		);
	};
}

// Customizable Area Start
interface IProductVariantProps {
	index: number
	lastIndex: number
	item: IProductVariant
	handleVariantChange: (name:string,value:string,index:number) => void
	handleVariantImageUpload: (event: React.ChangeEvent<HTMLInputElement>, index:number) => void
	removeVariantImage: (index: number) => void
}
const ProductVariant: React.FC<IProductVariantProps> =
	({ index, lastIndex, item, handleVariantChange, handleVariantImageUpload, removeVariantImage }) => {
		const cls = useProductCardStyles();
		const extraClasses = [];
		// if (index === 0) extraClasses.push(cls.first);
		if (index === lastIndex) extraClasses.push(cls.last);

		return (
			<Grid
				container
				// style={{
				// 	borderBottomLeftRadius: '15px',
				// 	borderBottomRightRadius: '15px'
				// }}
				justifyContent="space-between"
				className={clsx(cls.flexRow, cls.variantContainer,...extraClasses)}>
				<CustomInput
					key={index}
					inputProps={{
						style: {
							width: '100%',
							minWidth: '300px'
						}
					}}
					label="variant description"
					name="variantDescription"
					value={item.variantDescription}
					onChange={(text: any) => handleVariantChange('variantDescription', text.toString(), index)}
					placeholder='Eg: 2 dz'
				/>
				<CustomInput
					inputProps={{ style: webStyles.variantInputs }}
					label="Item code (optional)"
					value={item.itemCode || ""}
					onChange={(text: any) => handleVariantChange('itemCode', text.toString(), index)}
					placeholder='Eg: 567123'
				/>
				<CustomInput
					inputProps={{ style: webStyles.variantInputs }}
					label="Weight (in lbs)"
					value={item.weight}
					type="number"
					onChange={(text: any) => handleVariantChange('weight', text.toString(), index)}
					placeholder='Eg: 2.2'
				/>
				<CustomInput
					inputProps={{ style: webStyles.variantInputs }}
					label="price"
					value={item.price}
					type="number"
					onChange={(text: any) => handleVariantChange('price', text.toString(), index)}
					placeholder='Eg: 10'
				/>

				<CustomInput
					inputProps={{ style: webStyles.variantInputs }}
					label="Stock quantity"
					value={item.stockQty}
					type="number"
					onChange={(text: any) => handleVariantChange('stockQty', text.toString(), index)}
					placeholder='Eg: 10'
				/>
				<Box marginTop="12px">
					<InputLabel style={{ fontSize: "12px", marginBottom: '5px' }}>Variant Image</InputLabel>
					{!item?.image?.placeHolderUrl &&
						<Grid className={clsx(cls.uploadCard, cls.flex)}>
							<Add />
							<input
								type="file"
								onChange={(e) => handleVariantImageUpload(e, index)}
								itemType="image/*"
							/>
						</Grid>}
					{item?.image?.placeHolderUrl && (
						<Grid
							key={index}
							className={clsx(cls.uploadCard, cls.flex)}
							style={{ backgroundImage: `url(${item.image.placeHolderUrl})` }}
						>
							<Grid className="delete" onClick={() => removeVariantImage(index)}>
								<DeleteOutlineIcon />
							</Grid>
						</Grid>
					)}
				</Box>
				<Grid className={clsx(cls.flex, cls.wideBlank)} />
			</Grid>
		)
	}
interface IProductCardProps {
	root: AddProductWeb;
	index: number;
	lastIndex: number;
	data: IProductForm;
	// variant: IProductVariant[]
	onChangeData?: (data: IProductForm) => void;
}

const ProductCard = ({
	data: initialData,
	index,
	lastIndex,
	root: { addVariantForm, state: { categories, subCategories } },
	onChangeData = () => { },
}: IProductCardProps) => {
	const cls = useProductCardStyles();
	const [data, setData] = React.useState<IProductForm>(initialData || {});
	React.useEffect(() => {
		onChangeData(data);
	}, [data]);

	const handleFileUpload = (e: any) => {
		const { files } = e.target;
		if (files?.length === 0) return;

		const newImages = Array.from<File>(files).map((file: File) => {
			const blob = new Blob([file], { type: file.type });
			const url = URL.createObjectURL(blob);

			return { src: url, file };
		});

		setData({
			...data,
			images: [...(data?.images || []), ...newImages],
		});
	};

	const removeImage = (index: number) => {
		data.images.splice(index, 1);

		setData({
			...data,
			images: [...data.images]
		})
	}

	const extraClasses = [];
	if (index === 0) extraClasses.push(cls.first);
	// if (index === lastIndex) extraClasses.push(cls.last);
	
	return (
		<Grid className={clsx(cls.container, ...extraClasses)}>
			{/* <Typography className={cls.title} variant="h6">
				{`#${index + 1} Product`}
			</Typography> */}

			<Grid container style={{marginBottom: '20px'}} className={cls.flexRow}>
				<Grid container direction="column" className={cls.flex}>
					<Grid container className={cls.flex}>
						<CustomInput
							label="Enter Title"
							value={data.name}
							onChange={(text: any) => setData({ ...data, name: text.toString() })}
							placeholder='Eg: Title of Product'
						/>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomSelect
								label='Choose Category'
								value={data.category}
								onChange={choice => setData({
									...data,
									selectedCategoryId: choice.id,
									category: choice.val.toString(),
									subCategory: ''
								})}
								options={
									categories.map(category => ({
										value: { val: category.attributes.categoryId, id: category.id },
										label: category.attributes.name,
									}))
								}
							/>
						</Grid>

						<Grid className={clsx(cls.flex, cls.blank)} />
						<Grid className={clsx(cls.flex)}>
							<CustomSelect
								label='Choose SubCategory'
								value={data.subCategory}
								onChange={()=> {}}
								options={(subCategories[data.category] || []).map((category:any) => ({
									value: category.attributes.sub_category_id,
									label: category.attributes.name,
								  }))}
							/>
						</Grid>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomInput
								label="Enter Description"
								inputProps={{
									style:{
										minHeight: '120px'
									}
								}}
								value={data.description}
								onChange={(text: any) => setData({ ...data, description: text.toString() })}
								placeholder='Eg: Description of product'
							/>
						</Grid>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomInput
								label="Enter Pricing"
								value={data.price}
								type="number"
								onChange={(text: any) => setData({ ...data, price: text.toString() })}
								placeholder='Eg: $234'
							/>
						</Grid>
						<Grid className={clsx(cls.flex, cls.blank)} />
						<Grid className={cls.flex}>
							<CustomInput
								label="Selling Pricing"
								value={data.sellingPrice}
								type="number"
								onChange={(text: any) => setData({ ...data, sellingPrice: text.toString() })}
								placeholder='Eg: $234'
							/>
						</Grid>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomInput
								label="Tax (%)"
								value={data.tax}
								type="number"
								onChange={(text: any) => setData({ ...data, tax: text.toString() })}
								placeholder='Eg: 10'
							/>
						</Grid>
						<Grid className={clsx(cls.flex, cls.blank)} />
						<Grid className={cls.flex}>
							<CustomInput
								label="HSN Code"
								value={data.hsnCode}
								onChange={(text: any) => setData({ ...data, hsnCode: text.toString() })}
								placeholder='Eg: HG5Na'
							/>
						</Grid>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomSelect
								label='Subscription'
								value={data.subscription}
								onChange={choice => setData({
									...data,
									subscription: choice.toString()
								})}
								options={
									[
										{ label: "Yes", value: "Yes" },
										{ label: "No", value: "No" }
									]
								}
							/>
						</Grid>
						<Grid className={clsx(cls.flex, cls.blank)} />
						<Grid className={cls.flex}>
							<CustomInput
								label="Subscription Selling Pricing"
								value={data.subscriptionSellingPrice}
								onChange={(text: any) => setData({ ...data, subscriptionSellingPrice: text.toString() })}
								placeholder='Eg: $234'
							/>
						</Grid>
					</Grid>
					<Grid container className={clsx(cls.flex, cls.flexRow)}>
						<Grid className={cls.flex}>
							<CustomSelect
								label='Free Delivery'
								value={data.freeDelivery}
								containerStyles={{
									maxWidth: '275px'
								}}
								onChange={choice => setData({
									...data,
									freeDelivery: choice.toString()
								})}
								options={
									[
										{ label: "Yes", value: "Yes" },
										{ label: "No", value: "No" }
									]
								}
							/>
						</Grid>

					</Grid>
				</Grid>

				<Grid className={clsx(cls.flex, cls.blank)} />

				<Grid container wrap="wrap" className={clsx(cls.flex)}>
					<FormLabel style={{ color: 'rgba(0, 0, 0, 0.54) !important', margin: '9px 0', fontSize: '12px' }}>Add Image</FormLabel>

					<Grid container wrap="wrap" className={clsx(cls.flex, cls.flexRow)}>
						{/* as previous can were adding multiple images to the product*/}
						{(data && !data?.images?.length) && 
						<Grid className={clsx(cls.uploadCard, cls.flex)}>
							<Add />
							<input
								type="file"
								onChange={handleFileUpload}
								multiple
								itemType="image/*"
							/>
						</Grid>}

						{data?.images?.map((image, index) => (
							<Grid
								key={index}
								className={clsx(cls.uploadCard, cls.flex)}
								style={{ backgroundImage: `url(${image.src})` }}
							>
								<Grid className="delete" onClick={() => removeImage(index)}>
									<DeleteOutlineIcon />
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
			<Divider />
			<Grid container style={{marginTop: '20px'}}>
				<Grid container justifyContent="space-between" className={clsx(cls.flexRow)}>
					<Typography className={cls.title} variant="h6">Add Variant</Typography>
					<Typography
						style={{ cursor: "pointer" }}
						className={cls.title} 
						variant="h6"
						onClick={() => addVariantForm()}
					> + Add another variant
					</Typography>
				</Grid>	
			</Grid>
		</Grid>
	);
};


// Customizable Area End

// Customizable Area Start
const webStyles = {
	root: {
		width: '100%',
		minHeight: '100%',
		backgroundColor: '#F5F2ED',
	},
	container: {
		marginTop: "30px",
		paddingBottom: "30px"
	},
	pageText: {
		color: '#231F20',
		fontWeight: 600,
	},
	buttonContainer: {
		position: 'fixed',
		bottom: '20px',
		right: '20px',
	},
	button: {
		padding: '10px 40px',
		background: '#A9C9F7',
		border: '1px solid #a9caf7b7',
		borderRadius: '30px',
		marginLeft: '20px',
	},
	buttonCancel: {
		background: '#A9C9F7',
		color: 'white',
	},
	variantInputs: {
		width: '100%',
		minWidth: '200px'
	}
};

const useProductCardStyles = makeStyles(() => createStyles({
	flex: { display: 'flex', flex: 1, flexDirection: 'column' },
	flex2: { flex: 2 },
	flexRow: { flexDirection: 'row' },
	blank: { maxWidth: '30px' },
	wideBlank: {maxWidth: '750px'},
	container: {
		backgroundColor: "#FFF",
		padding: "20px",
	},
	first: {
		marginTop: 5,
		borderTopLeftRadius: "15px",
		borderTopRightRadius: "15px",
	},
	last: {
		borderBottomLeftRadius: "15px",
		borderBottomRightRadius: "15px",
	},
	title: {
		color: '#231F20',
		fontWeight: 600,
		fontSize: '20px',
	},
	uploadCard: {
		position: 'relative',
		width: '100px',
		height: '100px',
		maxWidth: '100px',
		border: '1px dashed #a9caf7b7',
		borderRadius: '10px',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundSize: 'cover',
		marginRight: '10px',

		'& > input': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			opacity: 0,
			cursor: 'pointer',
		},

		'& > svg': {
			color: '#a9caf7b7',
			fontSize: '40px',
		},

		'& > .delete': {
			position: 'absolute',
			width: '30px',
			height: '30px',
			top: 0, right: 0,
			background: 'rgba(0,0,0,0.4)',
			borderBottomLeftRadius: '10px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer'
		}
	},
	variantContainer: {
		backgroundColor: "#FFF",
		padding: "20px"
	}
}));
// Customizable Area End
