import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import MailOutlineSharp from "@material-ui/icons/MailOutlineSharp";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Modal from "@material-ui/core/Modal";

// Customizable Area End

import ForgotPasswordControllerWeb, {
  Props,
} from "./ForgotPasswordControllerWeb";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  imageStyle: {
    width: 100,
  },
  qualificationBlock: {
    marginTop: 40,
  },
  learnMore: {
    color: "#0000FF",
    cursor: "pointer",
  },
  root: {
    margin: 'auto',
    position: 'absolute',
    width: '432px',
    backgroundColor: "#fff",
    borderRadius: '40px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
    textAlign: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    "& h2": {
      color: "#A9C9F7",
      fontWeight: "bold",
      marginTop: "15px",
      marginBOttom: "30px",
      paddingBottom: "10px",
      textTransform: "capitalize",
      // fontSize: "48px"
    }
  },
  rootOuter: {
    width: "32ch",
    margin: "auto",
  },
  textfield: {
    border: "1px solid #ccc",
    boxShadow: "none",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    margin: "8px auto",
    textAlign: "left",
    width: "29ch",
    padding: "8px 12px",
    "& input": {
      fontSize: "12px",
      color: "#231F20",
      fontWeight: "bold",
      paddingLeft: "12px",
    },
    "& ::after": {
      display: "none",
    },
    "& ::before": {
      display: "none",
    },
  },
  iconInput: {
    // backgroundColor: "#a6001915",
    borderRadius: "5px",
    padding: "8px",
    marginRight: "5px",
  },
  iconInputSize: {
    fontSize: "14px",
    color: "#231F20",

  },
  iconInputSize1: {
    // color:"#737579",
    fontSize:"18px"
  },

  linkLogin: {
    width: "100%",
    textAlign: "left",
    fontSize: "12px",
    color: "#700010",
    "& span ": {
      color: "#808080",
      paddingLeft: "5px",
      textDecoration: "none",
    },
  },
  loginBtn: {
    "& button": {
      border: "1px solid #a60018",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "white",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#a60018",
      boxShadow: "0px 10px 20px #a6001942 !important",
      width: "85%",
      "&:hover": {
        backgroundColor: "#a60018",
      },
    },
  },
  haveAnAccount: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    color: "#808080",
  },
  haveAnAccountLink: {
    color: "#700010",
    paddingLeft: "5px",
    textDecoration: "none",
  },
  iconClose: {
    position: "absolute",
    top: "20px",
    right: "25px",
    backgroundColor: "#a6001915",
  },
  iconCloseSvg: {
    color: "#231F20",
    fontSize: "14px",
  },
  radioLabel: {
    marginRight: "0px",
    marginLeft: "10px",
    textAlign: "left",
    "& span ": {
      fontSize: "12px",
      color: " #a60018",
      "& svg ": {
        height: "12px",
        width: "12px",
      },
    },
  },
};
export default class NewPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      

      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Modal
            open={this.state.newPasswordOpen}
            onClose={() => { }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={webStyle.root}>
              <Typography variant="h4" component="h2">
                {"Reset Password"}
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  backgroundColor: "#rgb(224, 236, 253)",
                }} aria-label="close"
              onClick={() => this.setState({newPasswordOpen: false})}
              >
                <Close style={webStyle.iconCloseSvg} />
              </IconButton>

              <Box sx={webStyle.rootOuter}>

                <Typography style={{ width: "100%", textAlign: "center", fontSize: "13px", color: "#231F20",fontFamily:"roboto" }}>
                  Enter new password to access my account
                </Typography>


                <Box sx={webStyle.textfield}>
                  <IconButton style={webStyle.iconInput}>
                    <LockOutlinedIcon style={webStyle.iconInputSize1} />
                  </IconButton>
                  <Input
                    data-testid="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Enter new password"}
                    fullWidth={true}
                    value={this.state.newPassword}
                    onChange={this.handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end" data-testID="passwordToggle">
                        <IconButton
                         
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordField ? (
                            <Visibility style={webStyle.iconInputSize} />
                          ) : (
                            <VisibilityOff style={webStyle.iconInputSize} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>

                <Box sx={webStyle.textfield}>
                  <IconButton style={webStyle.iconInput}>
                    <LockOutlinedIcon style={webStyle.iconInputSize1} />
                  </IconButton>
                  <Input
                    data-testid="txtInputConfrimPassword"
                    type={this.state.enableConfrimPasswordField ? "password" : "text"}
                    placeholder={"ReEnter new password"}
                    fullWidth={true}
                    value={this.state.confrimPassword}
                    onChange={this.handlePasswordConfrimChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfrimPassword}
                          edge="end"
                        >
                          {this.state.enableConfrimPasswordField ? (
                            <Visibility style={webStyle.iconInputSize} />
                          ) : (
                            <VisibilityOff style={webStyle.iconInputSize} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <span style={{color:'#a13529'}}>{this.state.passwordError}</span>

                <Box>
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={this.validatePassword}

                    style={{
                      border: "1px solid #A9C9F7",
                      borderRadius: "30px",
                      padding: " 10px 55px",
                      textDecoration: "none",
                      textTransform: "capitalize",
                      color: "white !important",
                      margin: "20px auto 20px",
                      fontWeight: "bold",
                      backgroundColor: "#A9C9F7",
                      boxShadow: "0px 10px 20px #A9C9F77A !important",
                      fontSize:"14px",
                      // width: "85%",
                      fontFamily:"roboto",
                    }}
                  >
                    Reset Password {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>

              </Box>
            </Box>
          </Modal>
        </Container>
      </ThemeProvider>
    );
  }
}