export const bellIcon = require("../assets/bell.png");
export const circleIcon = require("../../global_assets/circle.png");
export const crossIcon = require("../../global_assets/cross.png");
export const img37bfe3ba5b0b09897c1a10d05dcfedf145306428 = require("../../global_assets/37bfe3ba5b0b09897c1a10d05dcfedf145306428.png");
export const img803b9c37aafad0151b06a2a4ed7201852f10a212 = require("../../global_assets/803b9c37aafad0151b06a2a4ed7201852f10a212.png");
export const img7141554d7c2565e0de24236044e1841d3d6c9ff6 = require("../../global_assets/7141554d7c2565e0de24236044e1841d3d6c9ff6.png");
export const img1d1183e157afea356d55ddda88e1cff288933288 = require("../../global_assets/1d1183e157afea356d55ddda88e1cff288933288.png");
export const img585f949ce506324109f05cdc5cbf0f4749808e42 = require("../../global_assets/585f949ce506324109f05cdc5cbf0f4749808e42.png");
export const imgf01063e1bd01feb0bcb2fda4c775e7a31f116645 = require("../../global_assets/f01063e1bd01feb0bcb2fda4c775e7a31f116645.png");
export const imgb4a5cadb6fea6c06c5365713a0adf6cb741701b3 = require("../../global_assets/b4a5cadb6fea6c06c5365713a0adf6cb741701b3.png");
export const imgddafeb79febe72e70e99cd6d6adbebc7f5f866ea = require("../../global_assets/ddafeb79febe72e70e99cd6d6adbebc7f5f866ea.png");
export const imge069386e98114f1df73a456a15dffa9e267ccda4 = require("../assets/e069386e98114f1df73a456a15dffa9e267ccda4.png");
export const img2a6dbe8d49f7b85aa5e687376af3ac9f520bf695 = require("../assets/2a6dbe8d49f7b85aa5e687376af3ac9f520bf695.png");
export const imgd6f2376de69c7b5ddb21826baffc3e0c544c9440 = require("../assets/d6f2376de69c7b5ddb21826baffc3e0c544c9440.png");
export const img4aa5a7897d2ffb921c427b6658f912c82d8b726d = require("../assets/4aa5a7897d2ffb921c427b6658f912c82d8b726d.png");
export const img8fc729189a3a86cc26afcd9d8691cdb77cbde1b0 = require("../assets/8fc729189a3a86cc26afcd9d8691cdb77cbde1b0.png");
