import React from 'react';
import {
    Button,
  } from "@material-ui/core";
  import { makeStyles, createStyles } from "@material-ui/core/styles";

  const useStyles = makeStyles(theme =>
    createStyles({
        signup: {
            border: "1px solid #aec2fa !important",
            borderRadius: "30px",
            padding: " 10px 55px",
            textDecoration: "none",
            textTransform: "capitalize",
            color: "#aabffa",
            margin: "20px auto 20px",
            fontWeight: "bold",
            backgroundColor: "#eef2fe",
            boxShadow: (props:any) =>
            props.disabled ?  "none" :  "0px 10px 20px #A9C9F77A !important",
    
            // boxShadow: "0px 10px 20px #a6001942 !important",
            // width: "35ch",
            "&:hover": {
              backgroundColor: "#A9C9F7",
              color:"white"
            }
          }
    })
    );

const CustomInlineButton = (props:any) => {
    const classes = useStyles(props) as any;
    console.log(props.disabled,"...props disabled")
    return (
       
        <div>
            <Button
                variant="contained"
                type="submit"
                className={classes.signup}
                style={{width:props.styleWidth,padding:props.stylePadding, ...props.style,opacity:props.styleOpacity}}
                onClick={props.handleChange}
                disabled={props.disabled}
            >
                {props.title}
            </Button></div>
    )
}

export default CustomInlineButton