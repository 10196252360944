import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Box,
  Button,
  IconButton,
  InputBase,
  Link,
  Typography,
  InputLabel
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CheckBox from "@material-ui/icons/CheckBox";
import Close from "@material-ui/icons/Close";
import MailOutlineSharp from "@material-ui/icons/MailOutlineSharp";
import LockOpenSharp from "@material-ui/icons/LockOpenSharp";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from "@material-ui/lab/Alert";


const urlConfig = require("../../../framework/src/config");
const baseURL = urlConfig.baseURL;


const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "40px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 5, 3),
      textAlign: "center",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        color: "#A9C9F7",
        marginTop: "15px",
        paddingBottom: "0px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
      }
    },
    root: {
      width: "400px",
      margin: "auto"
    },
    radioSelectBtn: {
      marginBottom: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
      color: '#231F20'
    },
    radioBtn: {
      accentColor: '#231F20',
      margin: '0px',
    },
    radioBtnDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: "18px",
      fontWeight: 400
    },
    merchantroot: {
      width: "600px",
      margin: "auto"
    },
    textfieldRow: {
      display: "flex",
      boxShadow: "none"
    },
    merchanttextfield: {
      boxShadow: "none",
      margin: "8px auto",
      textAlign: "left",
      width: "100%",
      position: "relative",
      "& label": {
        fontSize: "12px"
      },
      "& input ": {
        border: "1px solid #ccc",
        width: "225px",
        marginTop: "8px",
        padding: "13px 15px",
        borderRadius: "10px",
        fontSize: "12px",
        color: "#000000",
        fontWeight: "bold",
        "& ::placeholder": {
          opacity: "1"
        }
      }
    },

    textfield: {
      border: "1px solid #ccc",
      boxShadow: "none",
      borderRadius: "15px",
      margin: "8px auto",
      textAlign: "left",
      width: "27ch",
      padding: "8px 12px",
      position: "relative",
      "& input ": {
        fontSize: "12px",
        // color: "#700010",
        color: "#000000",
        fontWeight: "bold",
        paddingLeft: "12px",
        fontFamily:"roboto",
        "& ::placeholder": {
          opacity: "1"
        }
      },
      "& button ": {
        padding: "8px",
        "& svg ": {
          fontSize: "16px",
          color: "#231F20"
        }
      }
    },
    iconClose: {
      position: "absolute",
      top: "20px",
      right: "25px",
      backgroundColor: "rgb(224, 236, 253)",
      "& svg ": {
        color: "#231F20",
        fontSize: "14px"
      }
    },
    iconButton: {
      // backgroundColor: "#a6001915",
      borderRadius: "5px",
      padding: "8px !important",
      marginRight: "5px",
      "& svg ": {
        fontSize: "14px",
        color: "#A9C9F7"
        
       
      }
    },
    checkedLabelIcon: {
      textAlign: "left",
      width: "29ch",
      alignItems: "flex-start",
      margin: "10px 0",
      "& span ": {
        fontSize: "12px",
        padding: "2px 0",
        color: "#A9C9F7",
        "& svg ": {
          height: "12px",
          width: "12px"
        }
      },
      "& .MuiFormControlLabel-label": {
        color: "#231F20",
        padding: "3px 0 2px 12px",
        fontSize: "14px",
        // fontWeight: 400
      },
      "& .MuiIconButton-label": {
        border: "1px solid #efe1e1",
        borderRadius: "7px",
        padding: "5px",
        width: "12px"
      }
    },
    checkedIcon: {
      width: "9px!important",
      height: "9px!important",
      borderRadius: "2px",
      background: "#A9C9F7",
      marginTop: "1px"
    },
    signup: {
      border: "1px solid #A9C9F7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "white",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#A9C9F7",
      boxShadow: "0px 10px 20px #A9C9F77A !important",
      width: "43ch",
      fontSize:"12px",
      "&:hover": {
        backgroundColor: "#A9C9F7"
      }
    },
    submitDetails: {
      border: "1px solid #A9C9F7",
      borderRadius: "30px",
      padding: " 10px 35px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "white",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#A9C9F7",
      boxShadow: "0px 10px 20px #a6001942 !important",
      width: "35ch",
      "&:hover": {
        backgroundColor: "#A9C9F7"
      }
    },
    link: {
      width: "100%",
      textAlign: "center",
      fontSize: "16px",
      color: "#231F20",
      marginTop: "auto",
      marginBottom: "20px",
      fontWeight: 400,
      fontFamily:"roboto",
      "& a ": {
        color: "#A9C9F7",
        fontWeight: "600",
        paddingLeft: "5px",
        textDecoration: "none",
        fontFamily:"roboto",
      }
    },
    visibleIcon: {
      position: "absolute",
      right: "0px"
    },
    errorContent: {
      fontSize: "10px",
      color: "red",
      textAlign: "center",
      padding: "0 15px"
    },
    infoAlert: {
      position: "absolute",
      right: "15px",
      top: "15px"
    }
  })
);

const initialValues = {
  email: "",
  password: "",
  acceptTos: false
};

const merchantValues = {
  email: "",
  password: "",
  farm_Name: "",
  farm_description: "",
  farm_location: "",
  farm_website: "",
  contact_information: "",
  farm_socialmedia: "",
  farm_product: "",
  activated: false,
  acceptTos: false
};

const RegisterSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("Please enter your email"),
  password: Yup.string()
    .required("Please enter your password"),
    // .matches(
    //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    // ),
  acceptTos: Yup.boolean().oneOf(
    [true],
    "Please accept the terms and conditions"
  )
});

const ApplicationSchema = Yup.object({
  farm_name: Yup.string().required("Please enter name of the farm"),
  farm_product: Yup.string().required("Please enter name of the product"),
  farm_location: Yup.string().required("Please enter location of farm"),
  contact_information: Yup.string().required(
    "Please enter contact information"
  ),
  farm_socialmedia: Yup.string().required("Please enter contact information"),
  farm_description: Yup.string().required("Please enter farm description"),
  farm_website: Yup.string().required("Please enter farm website"),
});

const EmailAccountRegistrationWeb = ({
  setCouponCode,
  open,
  setOpen,
  setIsSignUp,
  state,
  setState
}: any) => {
  const classes = useStyles() as any;
  const [showpassword, setShowPassword] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const[showModal, setShowModal] = React.useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  }, [showAlert]);

  const { errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: state === "user" ? initialValues : merchantValues,
    validationSchema: state === "user" ? RegisterSchema : ApplicationSchema,
    onSubmit: async (values: any) => {
      let datas: any;

      if (state !== "user") {
        datas = {
          data: {
            type: "email_account",
            attributes: {
              email: values.email,
              password: values.password,
              user_type: "merchant",
              farm_name: values.farm_name,
              farm_description: values.farm_description,
              farm_location: values.farm_location,
              farm_website: values.farm_website,
              contact_information: values.contact_information,
              farm_socialmedia: values.farm_socialmedia,
              farm_products: values.farm_product,
              activated: "false"
            }
          }
        };
        setShowModal(true)
      } else {
        datas = {
          data: {
            type: "email_account",
            attributes: {
              email: values.email,
              password: values.password,
              user_type: "customer",
              activated: values.acceptTos
            }
          }
        };
      }
      let url=`${baseURL}/account_block/accounts`
      fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(datas)
        }
      )
      .then(response => {
        return response.json();
    })
    .then(data => {
        if(data.message){
            setAlertMsg(data.message)
            setOpen(false)
        }
        if(data.errors){
          setAlertMsg(data.errors[0].account)
        }
        if (data.meta.token) {
            window.localStorage.setItem("authToken", data.meta.token);
            window.localStorage.setItem("user_type", state);
              localStorage.setItem("user_email", values.email);
            setCouponCode(data.data.attributes.code);
            setIsSignUp(true);
            setOpen({ ...open, signUp: false });
        }
    })
        .catch(() => {
          setShowAlert(true);
          setIsSignUp(false);
        });
    }
  });

  const onChangeValue = (e: any) => {
    setState(e.target.value);
    console.log(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleClose = () => {
    setOpen({ ...open, signUp: false });
  };

  const body = (
    <div className="">
      {showAlert && (
        <Alert
          severity="info"
          className={classes.infoAlert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          {alertMsg}
        </Alert>
      )}
      <div className={classes.paper}>
        <Box>
          <h1 id="signup-title">
            {state === "user" ? "Sign Up" : "Merchant Application"}
          </h1>
          <IconButton
            className={classes.iconClose}
            aria-label="close"
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </Box>
        <div className={classes.radioSelectBtn} onChange={onChangeValue}>
          <div className={classes.radioBtnDiv}>
            <input
              className={classes.radioBtn}
              type="radio"
              value="user"
              name="state"
              checked={state === "user"}
            />{" "}
            User
          </div>
          <div className={classes.radioBtnDiv}>
            <input
             className={classes.radioBtn}
              type="radio"
              value="merchant"
              name="state"
              checked={state === "merchant"}
            />
            Merchant
          </div>
        </div>
        {state === "user" ? (
          <form
            className={classes.root}
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Paper component={"div" as any} className={classes.textfield}>
              <IconButton className={classes.iconButton} aria-label="mail">
                <MailOutlineRoundedIcon style={{color:"#737579",fontSize:"18px"}} />
              </IconButton>
              <InputBase
                className={classes.input}
                type="email"
                placeholder="Email ID"
                name="email"
                onChange={handleChange}
                inputProps={{ "aria-label": "email id" }}
                style={{color: "#B5D2F7"}}
              />
            </Paper>
            {errors.email && touched.email ? (
              <p className={classes.errorContent}>{errors.email} *</p>
            ) : null}
            <Paper component={"div" as any} className={classes.textfield}>
              <IconButton className={classes.iconButton} aria-label="lock">
                <LockOutlinedIcon  style={{color:"#737579",fontSize:"18px"}}/>
              </IconButton>
              <InputBase
                className={classes.input}
                type={showpassword ? "text" : "password"}
                placeholder="Choose password"
                name="password"
                style={{color: "#B5D2F7",}}
                onChange={handleChange}
                inputProps={{ "aria-label": "choose password" }}
              />  
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                className={classes.visibleIcon}
              >
                {showpassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </IconButton>
            </Paper>
            {errors.password && touched.password ? (
              <p className={classes.errorContent}>{errors.password} *</p>
            ) : null}
            <FormControlLabel
              className={classes.checkedLabelIcon}
              control={
                <Checkbox
                  onChange={handleChange}
                  name="acceptTos"
                  checkedIcon={
                    <CheckBox
                      className={classes.checkedIcon}
                      fontSize="small"
                    />
                  }
                />
              }
              label="By clicking you're agreeing to follow Newsletter and information"
            />
            {errors.acceptTos && touched.acceptTos ? (
              <p className={classes.errorContent}>{errors.acceptTos} *</p>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.signup}
            >
              Sign Up
            </Button>
            <Typography className={classes.link}>
              Already have an account?
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => setOpen({ ...open, signUp: false, login: true })}
              >
                Log In
              </Link>
            </Typography>
          </form>
        ) : (
          <>
            <form
              className={classes.merchantroot}
              noValidate
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Paper component={"div" as any} className={classes.textfieldRow}>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Email</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Password</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
              </Paper>
              <Paper component={"div" as any} className={classes.textfieldRow}>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Name of farm</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter name of farm"
                    name="farm_name"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Products of Farm</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter products of farm"
                    name="farm_product"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
              </Paper>
              <Paper component={"div" as any} className={classes.textfieldRow}>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Location of farm</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter location of farm"
                    name="farm_location"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Contact Information</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter contact information"
                    name="contact_information"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
              </Paper>
              <Paper component={"div" as any} className={classes.textfieldRow}>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Description of the farm</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter description of farm"
                    name="farm_description"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Farm website</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter farm website"
                    name="farm_website"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
              </Paper>
              <Paper component={"div" as any} className={classes.textfieldRow}>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel>Farm social media</InputLabel>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter farm social media"
                    name="farm_socialmedia"
                    onChange={handleChange}
                    inputProps={{ "aria-label": "email id" }}
                  />
                </Box>
                <Box
                  component={"div" as any}
                  className={classes.merchanttextfield}
                >
                  <InputLabel />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={classes.submitDetails}
                  >
                    Submit Application
                  </Button>
                </Box>
              </Paper>
              <Typography className={classes.link}>
                Already have an account?
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setOpen({ ...open, signUp: false, login: true })
                  }
                >
                  Log In
                </Link>
              </Typography>
            </form>
          </>
        )}
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        <CustomModalWeb data-test-id="emptyCart" handleButton={() => setShowModal(false)} isModalOpen={showModal} btnTitle={'continue'} description={"Thank you for your application! The Meat Locker will react out!"} />
      </div>
    </>
  );
};

export default EmailAccountRegistrationWeb;
