import React, { Component } from "react";
import settingControllerWeb, {
    Props,
  } from "../../blocks/Settings/src/settingControllerWeb";
import {  animalCowHead1,ribImage,cowImage, cowchuckImage, cowBrisket, cowshank, cowforeShank, cowshortPlate, cowshortLion, cowFlank, cowsirLion, cowround } from "./assest"; // Import your image <asset />
import DonutGraph from "./PieChartAnalytics.web";


class AnimalCow extends settingControllerWeb {
constructor(props: Props) {
    super(props);
  }

  render() {
    const {isAnalytics, showDonutGraph} = this.state
    return (
      // Customizable Area Start
       <div style={{minWidth: '630px'}} className="container" data-testid="animalView">
            <div style={{textAlign:'center', display: 'block', margin: 'auto', }}>
              {showDonutGraph && <DonutGraph data={[50,50]} />}
              </div>
            <div style={webStyle.animalImgCont1}>
                <div style={webStyle.imageContainer}>
              {this.state.cowHead && <img  onLoad={this.handleImageLoad} style={webStyle.animalImage} src={cowImage}  useMap="#image_map"/> }
              {this.state.rib && <img style={webStyle.animalImage} src={ribImage}   useMap="#image_map"/> }  
              {this.state.cowChuck && <img style={webStyle.animalImage} src={cowchuckImage}   useMap="#image_map"/> }
              {this.state.cowHead1 && <img style={webStyle.animalImage} src={animalCowHead1}   useMap="#image_map"/> }    
              {this.state.cowBrisket && <img style={webStyle.animalImage} src={cowBrisket}   useMap="#image_map"/> }    
              {this.state.cowShank && <img style={webStyle.animalImage} src={cowforeShank}   useMap="#image_map"/> }   
              {this.state.cowShortPlate && <img style={webStyle.animalImage} src={cowshortPlate}   useMap="#image_map"/> }   
              {this.state.cowShortLion && <img style={webStyle.animalImage} src={cowshortLion}   useMap="#image_map"/> }   
              {this.state.cowFlank && <img style={webStyle.animalImage} src={cowFlank}   useMap="#image_map"/> }  
              {this.state.cowSirLion && <img style={webStyle.animalImage} src={cowsirLion}   useMap="#image_map"/> }   
              {this.state.cowRound && <img style={webStyle.animalImage} src={cowround}   useMap="#image_map"/> }  
              {this.state.cowShank2 && <img style={webStyle.animalImage} src={cowshank}   useMap="#image_map"/> }   
                </div>
                <map name="image_map" 
                    style={webStyle.chunckArea1}> 
                   <area
                     style={webStyle.chunckArea1} 
                    alt="chunk"
                    coords={!isAnalytics ? "381,12,502,128" : "185,57,237,6"}
                    shape="rect"
                    onClick={this.handleCowChuckClick}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "316,113,378,9" : "154,53,180,7"}
                    shape="rect"
                    onClick={this.handleRibClick}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "537,179,635,26" : "252,78,306,21" }
                    shape="rect"
                    onClick={this.handleHeadClick}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "446,140,530,233" : "217,64,250,110"}
                    shape="rect"
                    onClick={this.handleCowBrisket}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "366,132,440,251" : "178,67,212,154"}
                    shape="rect"
                    onClick={this.handleCowForShank}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "269,246,357,126" : "127,66,172,118" }
                    shape="rect"
                    onClick={this.handleCowshortPlate}
                    /> 
                   <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "248,122,311,25" : "121,13,148,58"}
                    shape="rect"
                    onClick={this.handleCowshortLion}
                    /> 
                      <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "167,235,256,135" : "85,67,124,112" }
                    shape="rect"
                    onClick={this.handleCowFlank}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "187,21,240,127" : "90,10,114,59"}
                    shape="rect"
                    onClick={this.handleCowSirlion}
                    /> 
                     <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "179,17,32,137" : "18,68,83,10" }
                    shape="rect"
                    onClick={this.handleCowRound}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="head"
                    coords={!isAnalytics ? "28,148,152,255" :  "9,74,68,134"}
                    shape="rect"
                    onClick={this.handleCowShank}
                    /> 
                    </map>
            </div>
      </div>
      // Customizable Area End 
    );
  }
}

export default AnimalCow;

const webStyle: any = {
    
    animalImgCont1:{
        overFlow:"hidden",
    },
    animalImage:{
        width:"50%",
        height:"50%",
        objectFit:"contain",
        mixBlendMode: "darken"
    },
    imageContainer:{
        width:"100%",
        display: "flex",
        justifyContent: "center"
    },
    chunckArea1:{
        cursor:"pointer",
    }
}
