import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// Customizable Area Start
import {
	Typography,
	TextField,
	TextFieldProps
} from "@material-ui/core";

import { clsx } from "clsx";
// Customizable Area End

interface ICustomInputProps {
	label: string;
	value?: string;
	onChange: (value: string) => void;
}

type CustomInputProps = TextFieldProps & ICustomInputProps;

export const CustomInput = (props: CustomInputProps) => {
	const { label, value, onChange, className, ...rest } = props;
	const classes = useStyles();

	return (
		<TextField
			label={label}
			variant="standard"
			value={value}
			onChange={(e) => onChange(e.target.value)}
			className={clsx(classes.textField, className)}
			InputLabelProps={{
				shrink: true,
			}}
			{...rest}
			onKeyDown={(e) => {
				// A workaround to contain only numerical values 
				if (props.type === "number" && !/^[\d.]+$/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"].includes(e.key)) {
					e.preventDefault();
				}
			}}
		/>
	);
};

const useStyles = makeStyles(() => createStyles({
	textField: {
		marginTop: "10px",

		'& .MuiFormLabel-root': {
			color: 'rgba(0, 0, 0, 0.54) !important',
		},

		'& .MuiInputBase-root': {
			marginTop: "20px",
		},

		'& .MuiInputBase-input': {
			borderRadius: "10px",
			// border: "1px solid rgb(118, 33, 46)",
			// backgroundColor: "#fff5f6",
			backgroundColor:"rgb(224, 236, 253)",
			height: '23px',
		},

		// '& .MuiTypography-displayBlock': {
		// },

		'& .MuiInput-underline:after, & .MuiInput-underline:before': {
			display: 'none !important',
		},

		'& input': {
			padding: '15px 20px',
			color: '#231F20',
			fontWeight: 600,
			fontSize: '14px',
		}
	}
}));