import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import {
 
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography
} from "@material-ui/core";
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CustomModalWeb from "../../../components/src/CustomModal.web";
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import {Delete} from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  //   categoriesArray: any;
  open:boolean;
  ViewInventoryData:any;
  page: any;
  rowsPerPage: any;
  totalCount :any;
  orderDetails: any;
  InventoryStatus:any;
  ViewInventoryFillterByDate:any;
  ViewInventoryStatusData:any;
  InventoryDate:any;
  InventoryCategory:any;
  category:any;
  DateChange:boolean,

  expandedRow:any;
  serachTerm:any;
  popupOpen:any;
  deleteItem:any;
  selectedOption: string;
  noOrders: boolean;
 
 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
  //   getCategoriesApiCallId: any;
      apiInventoryDataCallId:any
      apiFillterByDateCallId:any
      apiFillterByStatusCallId:any
      apiFillterByCategoryCallId:any
      apiCategoryCallId:any
      apiDeleteOrderCallId:any
      apiSeachByOderNumberCallId:any
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      //   categoriesArray: [],
      open:false,
      ViewInventoryData:[],
      page: 0,
      rowsPerPage: 10,
      totalCount :0,
      InventoryStatus:"",
      orderDetails:[],
      ViewInventoryFillterByDate:[],
      ViewInventoryStatusData:[],
      InventoryDate:new Date() || "",
      InventoryCategory:"",
      category:[],
     DateChange :false,

      expandedRow:null,
      serachTerm:"",
      popupOpen:false,
      deleteItem:"",
      selectedOption: "",
      noOrders: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
      this.InventoryData();
      // this.FillterByDate();
      // this.fillterByStatus();
      // this.fillterByCategory();
      this.Category();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId && responseJson) {
        
        if (apiRequestCallId === this.apiInventoryDataCallId) {
          if (!responseJson.errors && !responseJson.error) {
            
            if (responseJson) {
              const count = responseJson.meta.total_pages
              this.setState({totalCount: count})
              
              const req = responseJson?.orders?.map((item: any) => item?.data) || []
              const requestOrderDetails = responseJson?.data?.attributes
             
             this.setState({ViewInventoryData : [...req]});
            //  this.setState({ViewInventoryData : requestOrderDetails})
             
            } 
          }else{
            console.log("error response",errorReponse);

          }
        }
      }


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiFillterByDateCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("responsejson.error");
            
            if (responseJson) {
              console.log("responseJson == true", responseJson);
              
              if (responseJson.message === "no order present") {
                // Set noOrders to true if the message indicates no orders
                this.setState({ noOrders: true, ViewInventoryData: [] });
              } else {
                const count = responseJson.meta.total_pages;
                this.setState({ totalCount: count, noOrders: false });
                
                const reqDate = responseJson?.orders?.map((item: any) => item?.data) || [];
                this.setState({ ViewInventoryData: [...reqDate] });
              }
            } else {
              alert("No Data...");
            }
          }
        }
      }
      

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiFillterByStatusCallId) {
          console.log("ressss",responseJson?.error,responseJson?.errors)
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
              const count = responseJson?.meta?.total_pages
              this.setState({totalCount: count})
             
              
             const reqStatus = responseJson?.orders?.map((item: any) => item?.data) || []
             console.log("reqqqq",reqStatus)
             this.setState({ViewInventoryData : [...reqStatus]})
            } 
          }
        }
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiFillterByCategoryCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson && responseJson.meta) {
              const count = responseJson.meta.total_pages
              this.setState({totalCount: count})
             
             const reqCategory = responseJson?.orders?.map((item: any) => item?.data) || []
             
             this.setState({ViewInventoryData : [...reqCategory]})
              
              
            } 
          }
        }
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDeleteOrderCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
             const orderID = localStorage.getItem("order-Id")
             const filteredData = this.state.ViewInventoryData.filter((e: any) => e?.id !== orderID);
             this.setState({ ViewInventoryData: filteredData })
             
            }
          }
        }
      }


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCategoryCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
              
              const categoryReq = responseJson.data.map((item: any) => item?.attributes) || []
              this.setState({category: [...categoryReq] }) 
              console.log("Category",categoryReq);
              
            } 
          }
        }
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiSeachByOderNumberCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
              const reqSearch = responseJson?.orders?.data.map((item: any) => item) || []
             this.setState({ ViewInventoryData: [...reqSearch] })
              

            } 
          }
        }
      }

      // Customizable Area End
    }
  }
  // Customizable Area Start


  InvetoryDate =(Props:any) =>{
  
    const toggleExpansion = (rowId:any) => {
      console.log("rowiddd",rowId)
      if (this.state.expandedRow === rowId) {
        this.setState({expandedRow: null});
      } else {
        this.setState({expandedRow: rowId});
      }
    };
  return(
    <>
    <CustomModalWeb handleButton={this.handlePopupDelete} handleClose={this.handlePopupClose} isModalOpen={this.state.popupOpen} btnTitle={'Delete'} statusImage={BlockOutlinedIcon} title={'Delete Item'} description={"Are you sure you want to delete this item?"}/>
      <TableBody>
          {Props.ViewInventoryData?.map((row:any)=>{
            return (
              <>
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" style={{ color: "#231F20", }}>
                  <span className='circle'>
                    <Brightness1OutlinedIcon />
                  </span>
                  {row.attributes.order_number}
                </TableCell>
                <TableCell  style={{ color: "#231F20" }}>{row.attributes.Date}</TableCell>
                <TableCell style={{ color: "#231F20" }}>{row.attributes.name}</TableCell>
                <TableCell  style={{ color: "#231F20" }}>{row.attributes.vendor}</TableCell>
                <TableCell  style={{ color: "#231F20" }}>{row.attributes.items}</TableCell>
                <TableCell >
                  <span style={{display: "inline-block",
                  
                  backgroundColor: row.attributes.status === "completed" ? "#e4efe4": "#A9C9F7",
                  color: row.attributes.status === "completed" ? "#7bd791": "white",

                  padding: "12px",borderRadius: "20px"}}>{row.attributes.status}</span>
                </TableCell>
                <TableCell align="right" >
                  <IconButton style={{ color: "#5e2524" }} key={row.id} onClick={() => Props.DeleteOrder(row.id)}  >
                    {/* <DeleteOutlineIcon /> */}
                    <img src={Delete} alt="Delete"  style={{height:"27px"}}/>
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton key={row.id} aria-label="expand row" size="medium" onClick={() => toggleExpansion(row.id)} style={{ color: "black" }}>
                    {this.state.expandedRow === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
                <TableBody style={{display: 'block'}}>
                  {this.state.expandedRow === row.id && (
                    <>
                      {row.attributes.order_items.data.map((description: any) => (
                        <div style={{ backgroundColor: "white", borderRadius: "20px", marginTop: "10px", display: "flex" }}>
                          <Box display="flex" padding="22px">
                            <img src={description.attributes.image} alt="product" style={{ width: "50px", height: "50px" }} />
                          </Box>
                          <Table>
                            <TableHead>
                              <TableRow key={description.id} className="tableDisc">
                                <TableCell style={{fontSize: "22px"}} className="tableDisc1">
                                  {description.attributes.product_name}
                                </TableCell>
                                <TableCell className="tableDisc">
                                  Pick
                                </TableCell>
                                <TableCell align="right" className="tableDisc">
                                  Vendor
                                </TableCell>
                                <TableCell align="right" className="tableDisc">
                                  On Hand
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className="tableDisc1">
                                <TableCell className="tableDisc1">
                                  {row.attributes.order_no}
                                </TableCell>
                                <TableCell className="tableDisc1">
                                  {row.attributes.items}
                                </TableCell>
                                <TableCell align="right" className="tableDisc1">
                                  {description.attributes.vendor}
                                </TableCell>
                                <TableCell align="right" className="tableDisc1">
                                  {description.attributes.on_hand}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      ))
                      }
                    </>
                  )}
                </TableBody>
              </>
            
            )
          }) }
        </TableBody>
    </>
  )
}

handleOrderSearch = (e:any)=>{
  this.setState({ serachTerm: e.target.value },()=>{this.searchByOrderNumber()});
};

handlePopupOpen = (id:any) => {
  this.setState({popupOpen: true, deleteItem: id})
  
};

handlePopupClose = () => {
  this.setState({popupOpen: false})
};

handlePopupDelete =() =>{
  this.DeleteOrder(this.state.deleteItem);
  this.setState({popupOpen: false})
  
}

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage },() => this.filter());
    
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(" chang page",event.target.value)
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: this.state.page });
  };

  OnClickData =() =>{
    this.setState({open:!this.state.open})
  }
 
   handleDateChange = (date:any) => {
    this.setState({InventoryDate:date, DateChange:true, page: 0}, ()=>{this.FillterByDate()})

  };

   handleInventoryStatusChange = async(e: any) => {
   await this.setState({ InventoryStatus: e.target.value, page: 0 },()=>{this.filter()});
  }
  handleInventoryCategoryChange =(e:any) =>{
    this.setState({InventoryCategory: e.target.value, page: 0},()=>{this.fillterByCategory()})
  }

  InventoryData = () =>{ 
   const Token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "Token" :Token
    };
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiInventoryDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.InventoryApiEndPoint
      `account_block/accounts/view_inventory?page=${this.state.page + 1}&per=10`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  filter = () => {
      const Token = localStorage.getItem("authToken")
       const header = {
         "Content-Type": configJSON.contentTypeApiAddDetail,
         "Token" :Token
       };
      
       const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
   
       this.apiFillterByStatusCallId = requestMessage.messageId;
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         // configJSON.InventoryApiEndPoint
         `account_block/accounts/view_inventory?query=${this.state.InventoryCategory}&page=${this.state.page + 1}&per=10&status=${this.state.InventoryStatus}`
       );
   
       requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
   
       // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
   
       requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
   
       runEngine.sendMessage(requestMessage.id, requestMessage);
       this.setState({
        selectedOption: "status"
       })
       return true;
     
  }
  

  FillterByDate = () =>{ 
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiFillterByDateCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
      //  `account_block/accounts/view_inventory?page=1&per=10&date=2023-05-25`
       `account_block/accounts/view_inventory?page=${this.state.page + 1}&per=10&date=${this.state.InventoryDate.toISOString().split('T')[0]}`
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     this.setState({
      selectedOption: "date"
     })
     return true;
   }

   fillterByStatus = () =>{ 
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiFillterByStatusCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
       `account_block/accounts/view_inventory?page=${this.state.page + 1}&per=10&status=${this.state.InventoryStatus}`
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     this.setState({
      selectedOption: "status"
     })
     return true;
   }

   fillterByCategory = () =>{ 
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiFillterByCategoryCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
        // `account_block/accounts/search_on_inventory?query=${this.state.InventoryCategory}`
        `account_block/accounts/search_on_inventory?query=${this.state.InventoryCategory}&page=${this.state.page + 1}&per=10`
        
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     this.setState({
      selectedOption: "category"
     })
     return true;
   }
   
   Category = () =>{ 
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiCategoryCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
        `/bx_block_categories/categories`
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
   }

   DeleteOrder = (id:any) =>{ 
    localStorage.setItem("order-Id",id);
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiDeleteOrderCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
        `bx_block_shopping_cart/orders/${id}`
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
   }


   searchByOrderNumber = () =>{ 
    const Token = localStorage.getItem("authToken")
     const header = {
       "Content-Type": configJSON.contentTypeApiAddDetail,
       "Token" :Token
     };
    
     const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
 
     this.apiSeachByOderNumberCallId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       // configJSON.InventoryApiEndPoint
        // `bx_block_shopping_cart/orders/search_order?order_no=${this.state.serachTerm}`
        `account_block/accounts/search_order?order_no=${this.state.serachTerm}`
     );
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
 
     // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
 
     requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
   }

  // Customizable Area End
}