import {
    Avatar,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputBase,
    NativeSelect,
    Paper,
    Typography,
    Box,
    Card,
    Modal,
    styled,
} from "@material-ui/core";

import React from "react";
import BookmarkBorderOutlined from "@material-ui/icons/BookmarkBorderOutlined";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { searchIcon } from './assets';
import { filterIcon } from './assets';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './CateggoriesCategories.css'
import AddIcon from '@material-ui/icons/Add';
import { beefIcon, lambIcon } from "./assets";
import TopNavWeb from "../../../components/src/TopNavWeb";
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomOutlinedButton from "../../../components/src/CustomOutlinedButton.web";
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import CategoriesControllerWeb, {
    Props,
} from "./CategoriesControllerWeb";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import AnimalChickenNG from "../../../components/src/AnimalChickenNG.web";
import AnimalPigNG from "../../../components/src/AnimalPigNg.web";
import AnimalCowNG from "../../../components/src/AnimalCowNG.web";
import ProductCard from "../../../components/src/ProductCard.web";
import Loader from "../../../components/src/Loader.web";
import AnimalLambNG from "../../../components/src/AnimalLambNG.web";

const PaperBox = styled(Paper)({
    boxShadow: 'unset!important',
    background: 'white',
    '& .MuiInputBase-input::placeholder': {
        color: '#A9C9F7',
        opacity: 1,
    },
    '& .MuiInputBase-input': {
        color: '#231F20 !important',
    }
});
const ExploreIconBox = styled(Paper)({
    width: 250,
    background: 'white',
    boxShadow: 'unset!important',
});
const ExploreBox = styled(FormControl)({
    display: 'flex',
    flexDirection: 'inherit',
    alignItems: 'center',
    width: 185,
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none'
    },
    '& .MuiSvgIcon-root': {
        display: 'none'
    },
    "& .MuiNativeSelect-select:focus": {
        backgroundColor: 'white',
    },
});



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 7
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class CategoriesCategoriesWeb extends CategoriesControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const isLoggIn = !!localStorage.getItem('authToken')
    const { showAllProducts, showCategoriesProduct, showFilteredProducts, showSubCategoriesProduct } = this.state.showCatalogues
    return (
      <>
        <TopNavWeb />
        <div className={"bgBody"}>
          <CustomModalWeb
            handleButton={this.handleloginContinue}
            handleClose={this.handleloginClose}
            isModalOpen={this.state.loginFlag}
            btnTitle={'Continue'}
            title={'Login/ Sign up'}
            description={"user need to login/signup before continue"}
          />
          {this.state.isCatalogueLoading ?
            <Loader
            loading={this.state.isCatalogueLoading}
          />
            :
            <>
              <div className={"root"} style={{ position: 'relative' }}>
                <div className={"exploreAuth"}>
                  <div>
                    {this.state.categoriesChip && (
                      <Carousel responsive={responsive}>
                        {this.state.categoriesChip ? this.state.categoriesChip.map((i: any) => (
                          <Chip
                            data-test-id="category-chip"
                            key={i}
                            size="small"
                            avatar={<Avatar alt="Natacha" src={i.attributes.icon} />}
                            label={i.attributes.name}
                            onClick={() => this.handleClicks(i.attributes.categoryId, i.attributes.name)}
                            className={this.state.selected === i.attributes.categoryId
                              ? "exploreSelected"
                              : "exploreOutlined"} />))
                          : <></>}
                      </Carousel>
                    )}
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                  {(this.state.carouselSelect.toLocaleLowerCase().includes('pork') ||
                    this.state.carouselSelect.toLocaleLowerCase().includes('mangalitsa')) ?
                    <div style={webStyle.pigAnimal} >
                      <AnimalPigNG navigation={undefined} id={""} />
                    </div> : <></>}

                  {(this.state.carouselSelect.toLocaleLowerCase().includes('beef') ||
                    this.state.carouselSelect.toLocaleLowerCase().includes('wagyu')) ?
                    <div style={webStyle.pigAnimal} >
                      <AnimalCowNG navigation={undefined} id={""} />
                    </div> : <></>}

                  {this.state.carouselSelect == "Chicken" ?
                    <div style={webStyle.animalChicken} >
                      <AnimalChickenNG navigation={undefined} id={""} />
                    </div> : <></>}
                  {this.state.carouselSelect.toLocaleLowerCase().includes('lamb') ?
                    <div style={webStyle.animalChicken} >
                      <AnimalLambNG navigation={undefined} id={""} />
                    </div> : <></>}
                </div>
              </div>
              <Grid container className={"exploreSearch"}>
                <Grid item xs={12} sm={3}>
                  <PaperBox component={"div" as any} className={"exploreIcons"}>
                    <Avatar alt="Search" src={searchIcon} />
                    <InputBase
                      // value={this.state.serachTerm}
                      data-testID='search'
                      style={{ color: '#A9C9F7', fontSize: 14 }}
                      placeholder="Search Any Product / Video..."
                      inputProps={{ "aria-label": "search any product / video..." }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }} />
                  </PaperBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ExploreIconBox component={"form" as any} className={"exploreIcons"}>
                    <Avatar src={filterIcon} />
                    <ExploreBox>
                      <FormHelperText
                        style={{
                          width: 110,
                          fontSize: 14,
                          marginTop: -2,
                          color: '#A9C9F7',
                          fontWeight: 600
                        }}>Sort By:</FormHelperText>
                      <NativeSelect
                        value={this.state.sort}
                        onChange={this.handleChangeSortBy}
                        name="sort-by"
                        inputProps={{ "aria-label": "sort" }}
                        style={{ fontSize: 14, color: '#A9C9F7' }}
                      >
                        <option value={'asc'} > Low to High</option>
                        <option value={'desc'}> High to low</option>
                      </NativeSelect>
                    </ExploreBox>
                  </ExploreIconBox>
                </Grid>
              </Grid><div>
                <Box p={3}>
                  {this.state.isFavouriteModal.text &&
                    <div>
                      <Modal
                        open={this.state.isFavouriteModal.isOpen}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={webStyle.profileBox}
                        onClose={(_, reason) => this.setState({ isFavouriteModal: {isOpen:false, text: ""} })}
                      >
                        <div style={webStyle.paper}>
                          <Card className={"exploreCard"}>
                            <div>
                              <Typography
                                style={{
                                  marginBottom: 30,
                                  marginTop: 10,
                                  paddingLeft: 20,
                                }}
                              >
                                {this.state.isFavouriteModal.text}
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: 30,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingBottom: 10,
                                }}
                              >
                                <Button
                                  data-test-id="add-to-cart2"
                                  className="add-to-cart"
                                  onClick={() => this.setMyFavourite(
                                    this.state.selectedFavouriteID
                                  )}
                                >
                                  Ok
                                </Button>
                                <Button
                                  data-test-id="add-to-cart3"
                                  className="add-to-cart"
                                  onClick={() => this.setState({ isFavouriteModal: {isOpen: false, text: ""} })}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Modal>
                    </div>
                  }
                  {(showAllProducts || showFilteredProducts) ?
                    (showAllProducts ?
                      this.state.catalogueData.length :
                      this.state.filteredCatalogue.length) > 0 ?
                      <InfiniteScroll
                        dataLength={showAllProducts ?
                          this.state.catalogueData.length :
                          this.state.filteredCatalogue.length}
                        next={() => { }}
                        hasMore={false}
                        loader={null}
                        data-testID=''
                      >
                        {(showAllProducts ?
                          this.state.catalogueData :
                          this.state.filteredCatalogue).map((item, index) => (
                            <>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                px={3}
                                sx={{ flexGrow: 1 }}
                                className={"exploreHeader"}
                                marginBottom={"-23px"}
                                marginTop={"16px"}
                              >
                                <Box display="flex"
                                  width="100%"
                                  justifyContent="space-between"
                                >
                                  <Typography>{item.attributes.name}</Typography>
                                  {(item.attributes.catalogue.data.length > 7 && !item.attributes.seenAll) ?
                                    <Box
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.handleSeeAll(item.attributes.categoryId, index)}
                                      display="flex">
                                      <Typography>SEE ALL</Typography>
                                      <ChevronRight />
                                    </Box> : <></>}
                                </Box>
                              </Box>
                              <Box mt={3}>
                                <Grid container spacing={3}>
                                  {item.attributes.catalogue.data.length > 0 &&
                                    item.attributes.catalogue.data.map((k, idx: number) => {
                                      return k.attributes && (
                                        <Grid key={idx} item className={"exploreItem"} xs={12} sm={3}>
                                          <ProductCard
                                            key={k.id}
                                            itemID={k.id}
                                            unit={k.attributes.uom}
                                            isFavourite={k.attributes.favouriteable_enable}
                                            itemName={k.attributes.description || k.attributes.name || ""}
                                            itemDescription={k.attributes?.additionalDescription ? k.attributes?.additionalDescription : k.attributes.description}
                                            itemImageURL={k.attributes.catalogue_variants[0]?.attributes.productImage &&
                                              k.attributes.catalogue_variants[0]?.attributes.productImage}
                                            price={`${k.attributes.price}` || ""}
                                            addToFavourite={() => k.attributes?.favouriteable_enable ?
                                              this.handleAddToFavrouite(k.attributes?.id, index, false) :
                                              this.handleAddToFavrouite(k.attributes?.id, index, true)}
                                            CardCTA={<>
                                              {!(localStorage.getItem('user_type') === 'merchant') && <CustomOutlinedButton
                                                data-test-ID={'addtoCartBtn'}
                                                handleChange={() => (isLoggIn ?
                                                  this.props.navigation.history.push(`CategoriesDescription/${k.attributes?.id}`) :
                                                  window.alert("Need to Login")
                                                )}
                                                title={"Add to cart"}
                                                styleMargin="0px auto 20px"
                                              />}
                                            </>
                                            }
                                          />
                                        </Grid>
                                      )
                                    }
                                    )
                                  }
                                </Grid>
                              </Box>
                            </>
                          ))}
                      </InfiniteScroll> :
                      <Typography>No Products Available</Typography> :
                    <></>}

                  {(showCategoriesProduct || showSubCategoriesProduct) ?
                    (showCategoriesProduct ?
                      this.state.categoriesCatalogueData[this.state.selected]?.length :
                      this.state.subCategoriesCatalogueData[this.state.currentSubCategoryId]?.length) > 0 ?
                      <InfiniteScroll
                        dataLength={showCategoriesProduct ?
                          this.state.categoriesCatalogueData[this.state.selected].length :
                          this.state.subCategoriesCatalogueData[this.state.currentSubCategoryId].length}
                        next={() => { }}
                        hasMore={false}
                        loader={null}
                        data-testID=''
                      >
                        <>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={3}
                            sx={{ flexGrow: 1 }}
                            className={"exploreHeader"}
                            marginBottom={"-23px"}
                            marginTop={"16px"}
                          >
                            <Typography>{showCategoriesProduct ?
                              this.state.carouselSelect :
                              this.state.currentSubCategory}</Typography>
                          </Box>
                          <Box mt={3}>
                            <Grid container spacing={3}>
                              {(showCategoriesProduct ?
                                this.state.categoriesCatalogueData[this.state.selected] :
                                this.state.subCategoriesCatalogueData[this.state.currentSubCategoryId]).map((k, index: number) => {
                                  return k.attributes && (
                                    <Grid key={index} item className={"exploreItem"} xs={12} sm={3}>
                                      <ProductCard
                                        key={k.id}
                                        itemID={k.id}
                                        unit={k.attributes.uom}
                                        itemName={k.attributes.description || k.attributes.name || ""}
                                        itemDescription={k.attributes?.additionalDescription ? k.attributes?.additionalDescription : k.attributes.description}
                                        itemImageURL={k.attributes.catalogue_variants[0]?.attributes.productImage &&
                                          k.attributes.catalogue_variants[0]?.attributes.productImage}
                                        price={`${k.attributes.price}` || ""}
                                        isFavourite={k.attributes.favouriteable_enable}
                                        addToFavourite={() => k.attributes?.favouriteable_enable ?
                                          this.handleAddToFavrouite(k.attributes?.id, index, false) :
                                          this.handleAddToFavrouite(k.attributes?.id, index, true)}
                                        CardCTA={<>
                                          {!(localStorage.getItem('user_type') === 'merchant') && <CustomOutlinedButton data-test-ID={'addtoCartBtn'} handleChange={() => (
                                            isLoggIn ?
                                              this.props.navigation.history.push(`CategoriesDescription/${k.attributes?.id}`) :
                                              window.alert("Need to Login"))} title={"Add to cart"} />
                                          }
                                        </>}
                                      />
                                    </Grid>
                                  )
                                }
                                )
                              }
                            </Grid>
                          </Box>
                        </>
                      </InfiniteScroll> :
                      <Typography>No available Products for this category</Typography> :
                    <></>}
                </Box>
              </div></>
          } </div>

        {this.state.merchantFlag === true ?
          <div style={{
            position: "fixed",
            display: 'flex',
            justifyContent: 'flex-end',
            bottom: "16px",
            width: "100%",
            // backgroundColor: "#F5F2ED",
            marginLeft: "-5rem",
          }}>
            <Link to="/inventory" >
              <button className="btn-signup1"><span>Inventory</span></button>
            </Link>
            <Link to="/merchant-addProduct" style={{ display: 'flex', textDecoration: 'none' }}>
              <button className="btn-login1" style={{ display: 'flex' }}><div><AddIcon /></div><span style={{ marginTop: "inherit" }}>Add Products</span></button>
            </Link>
          </div>
          :
          <></>
        }
      </>
    );
  }
}

const webStyle: any = {
    profileBox: {
        overflow: "auto",
    },
    pigAnimal: {
        // width: "315px",
        // height: "166px",
        // marginLeft: "34rem",
        // marginTop: "4rem",
    },
    pigg: {
        width: "490px !important",
        height: "290px !important"
    },
    animalChicken: {
        // width: "200px",
        // height: "199px",
        // marginLeft: "35rem",
        // marginTop: "4rem",
    },
    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: "#01223770",
        borderRadius: "40px",
        textAlign: "center",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        "& h1": {
            color: "#700010",
            marginTop: "15px",
            paddingBottom: "10px",
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
    },
}