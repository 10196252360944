export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img37bfe3ba5b0b09897c1a10d05dcfedf145306428 = require("../../global_assets/37bfe3ba5b0b09897c1a10d05dcfedf145306428.png");
export const img7141554d7c2565e0de24236044e1841d3d6c9ff6 = require("../../global_assets/7141554d7c2565e0de24236044e1841d3d6c9ff6.png");
export const img47676a188c9c093e814300187bf9a3c0eab5ac2a = require("../../global_assets/47676a188c9c093e814300187bf9a3c0eab5ac2a.png");
export const img1f85f39378b93e6e245b985520bb8ec31f68d616 = require("../../global_assets/1f85f39378b93e6e245b985520bb8ec31f68d616.png");
export const imga0f665489b1d95cc10f55b5401db6abed1e9af1a = require("../../global_assets/a0f665489b1d95cc10f55b5401db6abed1e9af1a.png");
export const imgdaa200bfa259dd9046a390ea652430fa088080eb = require("../../global_assets/daa200bfa259dd9046a390ea652430fa088080eb.png");
export const img591b62c0b1f3a1f14625b2cfd32f36843d52dde1 = require("../../global_assets/591b62c0b1f3a1f14625b2cfd32f36843d52dde1.png");
export const cowimg = require("../assets/Cowimg.png")
export const pigimg = require("../assets/pig.png")
export const chickenimg = require("../assets/chicken.png")
export const ribImage = require("../assets/cow_rib.png")
export const cowHead = require("../assets/cow_head.png")