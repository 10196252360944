export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const img7141554d7c2565e0de24236044e1841d3d6c9ff6 = require("../../global_assets/7141554d7c2565e0de24236044e1841d3d6c9ff6.png");
export const img1d1183e157afea356d55ddda88e1cff288933288 = require("../../global_assets/1d1183e157afea356d55ddda88e1cff288933288.png");
export const imgd2c0642dd4283aee1514c396daf22b92822d14c9 = require("../../global_assets/d2c0642dd4283aee1514c396daf22b92822d14c9.png");
export const img9e13765f3a3c6ab3979fba18f856abf34b8baa1a = require("../../global_assets/9e13765f3a3c6ab3979fba18f856abf34b8baa1a.png");
export const imgd09e31e01d878359448f48bf83110091fc6bc561 = require("../../global_assets/d09e31e01d878359448f48bf83110091fc6bc561.png");
export const imgb5fd2f6d1d95ea7fcc27d4334a30ebdd204a211b = require("../../global_assets/b5fd2f6d1d95ea7fcc27d4334a30ebdd204a211b.png");
export const imgac6a218ba79850e27e64ac648cf2321e0492d124 = require("../../global_assets/ac6a218ba79850e27e64ac648cf2321e0492d124.png");
export const img0aba1dbd0217054fe53837e846e2d7dd2951cc64 = require("../../global_assets/0aba1dbd0217054fe53837e846e2d7dd2951cc64.png");
