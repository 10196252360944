import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Select,
    Avatar,
    Typography,
    Grid, styled,FormControl
} from "@material-ui/core";
import { CustomSelect } from "../../../components/src/CustomSelect";

import LockOutlined from "@material-ui/icons/LockOutlined"
import { chickenImage, pigImage, cowImage, lamb } from "../../../components/src/assest";
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import TopNavWeb from "../../../components/src/TopNavWeb";
import { editImage } from "./assets";
import AnimalCow from "../../../components/src/AnimalCow.web";
import AnimalPig from "../../../components/src/AnimalPig.web";
import AnimalChicken from "../../../components/src/AnimalChicken.web";
import AnimalLamb from "../../../components/src/AnimalLamb.web";

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabs = styled(Tabs)({
    '& .Mui-Selected': {
        color: '#A9C9F7 !important'
    }
})
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const productList = [1, 2];
// Customizable Area End

import ProfileControllerWeb, {
    Props,
} from "./ProfileControllerWeb";
import CustomOutlinedButton from "../../../components/src/CustomOutlinedButton.web";
import {UserProfileBasicBlockWeb} from "./UserProfileBasicBlock.web";
import ProductCard from "../../../components/src/ProductCard.web";


export default class ProfileWeb extends ProfileControllerWeb {
    constructor(props: Props) {
        super(props);
    }
  
    render() {
        const {userData} = this.state;
        const retrievedObject = JSON.parse(localStorage.getItem("user_data") || "{}");
        const isMerchant = localStorage.getItem('user_type') === 'merchant';
        const retrievedProfile:any = localStorage.getItem("userProfile");
        return (
            // Required for all blocks
            <div>
            
                    <UserProfileBasicBlockWeb  setOpen={()=>this.setState({isOpen:!this.state.isOpen})} data-test-id='profiletab'  open={this.state.isOpen} handleCloseProfile={this.handleCloseProfile}/>
                <TopNavWeb showPhoto={this.state.userProfilePic}/>
                <div  style={webStyle.root}>
                <div className="profileContainer orderContainer" style={webStyle.orderCart}>
                    <div style={{ display: 'flex' }}>
                        <div className="btn-logout" style={{ margin: '22px' }}>
                            <LockOutlined style={{ margin: '10px' }} />
                        </div>
                        <div>
                            <h3 style={{ color: '#231F20' }}>New Order Alert</h3>
                            <span style={{ color: '#b0aca9', fontSize: 14 }}>Estimated Delivery: Tuesday, 2nd March</span>
                        </div>
                    </div>
                    <div style={{ marginTop: '8px' }}>
                    {isMerchant ?
                    <Typography
                    variant="h6"
                    component={"a"}
                    href="/merchant-orders"
                    style={{textDecoration: "none"}}
                >
                    <Button style={webStyle.btnStyle}>View Details</Button>
                </Typography> :
                <Typography
                variant="h6"
                component={"a"}
                href="/user-orders"
                style={{textDecoration: "none"}}
            >
                <Button style={webStyle.btnStyle}>View Details</Button>
            </Typography>}
                    </div>
                </div>
                    <div className="profileContainer" style={webStyle.profileContainer}>
                        <div style={webStyle.EditContainer} onClick={this.handleOpenProfile}>
                            <img style={webStyle.EditImg} src={editImage} />
                        </div>
                        <div style={webStyle.profilePage}>
                            <div className="profilePage" style={webStyle.profileItem}>
                                <div>
                                    <CustomAvatar
                                        className="profileImg"
                                        src={retrievedProfile ? retrievedProfile : this.state.userProfilePic?.attributes?.images?.[0].url}
                                        style={webStyle.profileImg}
                                    />
                                </div>

                                <div style={{ marginLeft: '2rem' }}>
                                    <h3 style={webStyle.profileIcon}>{(retrievedObject.full_name)??(userData.full_name)}</h3>
                                    <div style={webStyle.SocialIcon}>
                                        <Link to={{ pathname: localStorage.getItem("insta") || "" }} style={{ color: '#A9C9F7' }} target="_blank"><InstagramIcon style={{ cursor: 'pointer',marginRight:'1rem' }} /></Link>
                                        <Link to={{ pathname: localStorage.getItem("whatsapp") || "" }} style={{ color: '#A9C9F7' }} target="_blank"><WhatsAppIcon style={{ cursor: 'pointer',marginRight:'1rem' }} /></Link>
                                        <Link to={{ pathname: localStorage.getItem("facebook") || "" }} style={{ color: '#A9C9F7' }} target="_blank"><FacebookIcon style={{ cursor: 'pointer',marginRight:'1rem' }} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div style={webStyle.AboutContainer}>
                                <h3 style={{ color: '#231F20' }}>About Me</h3>
                                <p style={{ color: '#231F20',wordBreak:'break-all' }}>{retrievedObject.about_me??userData.about_me}</p>
                            </div>
                            <div style={webStyle.AboutContainer}>
                                <h3 style={{ color: '#231F20' }}>My Contacts</h3>
                                <div style={{ display: 'flex' }}><MailIcon /><span style={{ paddingLeft: '0.5rem', color: '#231F20' }}>{localStorage.getItem("user_email")??retrievedObject.email_address??userData.email_address}  </span></div>
                                <div className="btmcontent" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', marginTop: '2rem' }}><CallIcon /><span style={{ paddingLeft: '0.5rem', color: '#231F20' }}>{retrievedObject.phone_number??userData.phone_number}</span></div>
                                    <div className="btnContainer profileContent" style={{ display: 'flex', paddingLeft: '4rem', marginTop: '0px' }}>
                                        <div className="profileBtn"><CustomOutlinedButton data-testid="setting" handleChange={this.handleSetting} title={"Settings"} styleWidth={'160px'} /></div>
                                        {
                                            isMerchant ?
                                            <Link to='/merchant-orders'><div className="profileBtn" style={{ marginLeft: '12px' }}><CustomOutlinedButton title={"My Orders"} styleWidth={'160px'} /></div></Link> :
                                            <Link to='/user-orders'><div className="profileBtn" style={{ marginLeft: '12px' }}><CustomOutlinedButton title={"My Orders"} styleWidth={'160px'} /></div></Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={webStyle.TabIcon} className="abc profileContainer">
                        <CustomTabs style={webStyle.BreadcrumbsList} value={this.state.value} onChange={this.handleChange} aria-label="simple Customtabs example">
                            <Tab data-test-id="favourite-product-tab" label="My Favorites" onClick={()=>this.callGetFavoriteApi()} style={webStyle.BreadcrumbsItems1} {...a11yProps(0)} />
                            <Tab data-test-id="recommended-product-tab" label="Recommendations" onClick={()=>this.callGetRecommendationApi()} style={webStyle.BreadcrumbsItems1} {...a11yProps(1)} />
                            <Tab label="Remaining inventory" style={webStyle.BreadcrumbsItems1} {...a11yProps(2)} />
                        </CustomTabs>
                        <TabPanel value={this.state.value} index={0}>
                            <Grid container spacing={2} style={webStyle.GridBox}>
                                {this.state?.favouriteData?.length>0 && this.state?.favouriteData && this.state?.favouriteData.map((i: any) => {
                                    if (i.attributes.catalogue_id.data !== null) {
                                        const {uom,description,additionalDescription,name,catalogue_variants,favouriteable_enable} = i.attributes.catalogue_id.data.attributes
                                        return (
                                            <Grid key={i.id} item xs={12} sm={6}>
                                                <ProductCard
                                                    data-testid="fav_Product_card"
                                                    key={i.id}
                                                    itemID={i.attributes.catalogue_id.data.id}
                                                    unit={uom}
                                                    itemName={description || name || ""}
                                                    itemDescription={additionalDescription ? additionalDescription : description}
                                                    itemImageURL={catalogue_variants[0]?.attributes.productImage &&
                                                        catalogue_variants[0]?.attributes.productImage}
                                                    price={catalogue_variants[0]?.attributes.price}
                                                    isFavourite={favouriteable_enable}
                                                    addToFavourite={() => this.handleRemove(i.attributes.catalogue_id.data.id)}
                                                    CardCTA={!(localStorage.getItem('user_type') === 'merchant') &&
                                                        <>
                                                            <CustomOutlinedButton
                                                                data-test-ID={'addtoCartBtn'}
                                                                handleChange={() => (
                                                                    this.props.navigation.history.push(`CategoriesDescription/${i.attributes.catalogue_id.data.id}`)
                                                                )}
                                                                title={"Add to cart"}
                                                                styleMargin="0px auto 20px"
                                                            />
                                                        </>}
                                                />
                                            </Grid>
                                        )
                                    }
                                })}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1} >
                            <Grid container spacing={3} style={webStyle.GridBox}>
                                {this.state.recommendationData?.length>0 && this.state.recommendationData && this.state.recommendationData.map((i: any) => 
                                   {
                                    // let recomend_data= i?.attributes?.order_items?.data[0]?.attributes?.catalogue?.data;
                                    const {uom,description,additionalDescription,name,catalogue_variants,favouriteable_enable} = i.attributes
                                    return(
                                        <>
                                            <Grid key={i} item xs={12} sm={6}>
                                                <ProductCard
                                                    key={i.id}
                                                    data-testid="recommend_Product_card"
                                                    itemID={i.id}
                                                    unit={uom}
                                                    itemName={description || name || ""}
                                                    itemDescription={additionalDescription ? additionalDescription : description}
                                                    itemImageURL={catalogue_variants[0]?.attributes.productImage &&
                                                        catalogue_variants[0]?.attributes.productImage}
                                                    price={catalogue_variants[0]?.attributes.price}
                                                    isFavourite={favouriteable_enable}
                                                    addToFavourite={() => this.handleRemove(i.id)}
                                                    CardCTA={!(localStorage.getItem('user_type') === 'merchant') &&
                                                        <>
                                                            <CustomOutlinedButton
                                                                data-testid={'addtoCartBtn'}
                                                                handleChange={() => (
                                                                    this.props.navigation.history.push(`CategoriesDescription/${i.id}`)
                                                                )}
                                                                title={"Add to cart"}
                                                                styleMargin="0px auto 20px"
                                                            />
                                                        </>}
                                                />
                                            </Grid>
                                    </>
                                    )} 
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2}>
                            <Grid container spacing={3} style={webStyle.GridBeefBox}>
                                <Grid item sm={12}>
                                <FormControlWeb variant="outlined" style={webStyle.formControl}>
                                {console.log("lolllllll", this.state.product)}
                                <CustomSelect
                                    label="example"
                                    hideLabel
                                    options={this.state.product as any}
                                    onChange={this.handleSelectedProduct}
                                    value={this.state?.productCredits?.animal}
                                    // value={this.state?.creditsAnalytics.purchased_animal}
                                />
                                </FormControlWeb>
                                </Grid>

                                <Grid item sm={6} style={webStyle.BeefImage}>
                                    <div style={{ padding: '0 15px 0 0', marginBottom: '15px', }}>
                                        
                                        {this.state.productCredits?.animal.toLowerCase().includes('american wagyu') &&
                                            <img data-testid="cowImage" src={cowImage} style={webStyle.BeefImg} />
                                        }
                                        {this.state.productCredits?.animal.toLowerCase().includes('angus beef') &&
                                            <img data-testid="cowImage" src={cowImage} style={webStyle.BeefImg} />
                                        }
                                        {this.state.productCredits?.animal.toLowerCase().includes('angus and wagyu') &&
                                            <img data-testid="cowImage" src={cowImage} style={webStyle.BeefImg} />
                                        }
                                        {this.state.productCredits?.animal.toLowerCase().includes("pork"||"mangalitsa") &&
                                            <img data-testid="pigImage" src={pigImage} style={webStyle.BeefImg} />}
                                        {this.state.productCredits?.animal.toLowerCase().includes("chicken") &&
                                            <img data-testid="chickenImage" src={chickenImage} style={webStyle.BeefImg} />}
                                        {this.state.productCredits?.animal.toLowerCase().includes("lamb") &&
                                            <img data-testid="lambImage" src={lamb} style={webStyle.BeefImg} />}
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                   <Box className="BoxCuts" style={webStyle.BoxCuts}>

                                        <Typography variant="subtitle1" style={webStyle.AvailableCounts}>Total Available Cuts</Typography>
                                        <Typography variant="h6" style={webStyle.CutCounts}>
                                            {this.state.productCredits?.remaining_cuts}
                                            </Typography>
                                    </Box>
                                </Grid>
                                </Grid>
                        </TabPanel>
                    </div>
                </div>
            </div>
        );
    }
}

const FormControlWeb = styled(FormControl)({
    "& fieldset": {
      display: "none",
    },
  });
const CustomAvatar = styled(Avatar)({
    '& .MuiSvgIcon-root': {
        height: '100% !important',
    }
})

const webStyle: any = {
    root: {
        backgroundColor: '#F5F2ED',
        paddingTop: '2rem',

    },
    // CustomSelectBox: {
    //     width: '300px',
    //     marginLeft: 'auto',
    // },
    orderCart: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5rem',
        backgroundColor: 'white',
        borderRadius: '27px',
        margin: 'auto',
        width: '50%',
        alignItems: 'center'
    },
    btnStyle: {
        backgroundColor: "#A9C9F7",
        color: "#FFF",
        fontWeight: 600,
        borderRadius: 30,
        width: 164,
        padding: '11px 8px',
        textTransform: "capitalize",
    },
    profileContainer: {
        backgroundColor: 'white',
        borderRadius: '27px',
        margin: 'auto',
        marginTop: '2rem',
        width: '50%',
    },
    profileItem: {
        display: 'flex',
    },
    signup: {
        border: "1px solid #a60018 !important",
        borderRadius: "30px",
        padding: " 10px 55px",
        textDecoration: "none",
        textTransform: "capitalize",
        color: "white",
        margin: "20px auto 20px",
        fontWeight: "bold",
        backgroundColor: "#a60018",
        boxShadow: "0px 10px 20px #a6001942 !important",
        width: "35ch",
        "signup &:hover": {
            backgroundColor: "#a60018"
        }
    },
    profileImg: {
        borderRadius: "1rem",
        width: "13rem",
        background: "rgb(224, 236, 253)",
        height: "10rem",
    },
    profileIcon: {
        color: "#231F20"
    },
    SocialIcon: {
        color: "#A9C9F7",
        display: 'flex',
        justifyContent: 'space-around',
    },
    AboutContainer: {
        // padding: '3rem',
    },
    BreadcrumbsList: {
        display: "flex",
        backgroundColor: "#F5F2ED",
        "& .BreadcrumbsList": {
            color: "grey !important"
        }
    },
    BreadcrumbsItems1: {
        fontWeight: 600,
        margin: "20px 20px 20px 5px",
        fontSize: "16px",
        "& .MuiTabs-indicator": {
            backgroundColor: 'none !important'
        }
    },
    // formControl: {
    //     width: '100%',
    // },
    topHeaderBookmark: {
        "topHeaderBookmark & .MuiTypography-caption": {
            display: "block",
            color: "white",
            fontWeight: "bold",
            padding: "5px",
            marginLeft: "10px"
        },
        "topHeaderBookmark & .MuiButtonBase-root": {
            color: "white",
            border: "1px solid #a60018",
            padding: "5px",
            marginRight: "10px"
        }
    },
    exploreCard: {
        textAlign: "left",
        padding: "6px",
        boxShadow: "0 5px 6px #f1f1f1",
        "exploreCard & img": {
            borderRadius: "8px"
        },
        "exploreCard & .add-to-cart": {
            color: "#a60018",
            textTransform: "inherit",
            fontSize: "14px",
            border: "1px solid #a60018",
            backgroundColor: "#fff3f6",
            padding: "5px 15px",
            borderRadius: "30px"
        },
        "exploreCard & .MuiTypography-h5 ": {
            color: "#a60018",
            fontWeight: "bold"
        },
        "exploreCard & .MuiTypography-body1": {
            fontWeight: "bold"
        }
    },
    TabIcon: {
        width: "50%",
        margin: 'auto',
        "@media (max-width: 1024px)": {
            width: '100%',
        },
    },
    GridBox: {
        width: "100%",
        height: "auto !important",
        backgroundColor: "#f8f4f3",
        margin: "0px",
        minHeight: "calc(100vh - 187px)",
    },
    EditContainer: {
        width: "50px",
        height: "50px",
        display: "flex",
        position: "relative",
        float: "right",
        backgroundColor: "#F5F2ED",
        borderBottomLeftRadius: "50%",
        cursor:"pointer"
    },
    EditImg: {
        width: "50%",
        height: "50%",
        margin: "auto",
    },
    profilePage: {
        padding: "3rem",
        paddingBottom: '1rem'
    },
    GridBeefBox: {
        padding: "2rem",
        width: "100%",
        height: "auto !important",
        alignItems: 'center',
        backgroundColor: "#fff",
        borderRadius: '27px',
        margin: "0px",
    },
    BeefImg: {
        width: '100%',
        height: '100%',
        maxHeight: '300px'
    },
    BoxCuts: {
        backgroundColor: 'rgb(224, 236, 253)',
        borderRadius: '27px',
        padding: '2rem 1.5rem',
        marginBottom: '15px',
    },
    AvailableCounts: {
        color: '#231F20',
        textTransform: 'Uppercase',
        marginBottom: '15px',
    },
    CutCounts: {
        color: '#231F20',
        fontWeight: 'bold',
    },
    cardaction:{
        "& :hover": {
            backgroundColor: 'white'
        },

    },
};