import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import LandingPage from "../../landingpage/src/LandingPage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  handleOpen: any;
  isForgotOpen: boolean;
  handleForgotClose: any;
  // Customizable Area Start
  // Customizable Area End
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface S {
  // Customizable Area Start
  email: string;
  emailError: string;
  newPassword: string;
  confrimPassword: string;
  passwordError: string;
  enablePasswordField: boolean;
  enableConfrimPasswordField: boolean;
  newPasswordOpen: boolean;
  forgetPasswordOpen: boolean,
  openSnackBar: boolean,
  openSnackBarPassword: boolean,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailCallId: string = "";
  apiPasswordCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: '',
      emailError: '',
      newPassword: '',
      confrimPassword: '',
      passwordError: '',
      enablePasswordField: true,
      enableConfrimPasswordField: true,
      newPasswordOpen: true,
      forgetPasswordOpen: true,
      openSnackBar: false,
      openSnackBarPassword: false,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiEmailCallId) {

      }
      if (apiRequestCallId === this.apiPasswordCallId) {

      }
      if (errorReponse) {

        if (apiRequestCallId === this.apiEmailCallId) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);

        }

        if (apiRequestCallId === this.apiPasswordCallId) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  validateEmail = () => {

    let regex = /^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9-]+\.)+(?:io|com|org|gov\.in|in)$/

    if (regex.test(this.state.email) === true) {
      this.callGetEmailValidationApi()
      this.setState({ openSnackBar: true });
      setTimeout(() => {
        this.setState({ openSnackBar: false });
      }, 1000)
      this.setState({ emailError: '', email: '' })
    }
    else {
      this.setState({ emailError: "Please enter correct email address" })
    }
  }

  callGetEmailValidationApi() {

    const headers = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      data: {
        attributes: {
          email: this.state.email
        }
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  validatePassword = () => {
      if ((this.state.newPassword === "") || (this.state.confrimPassword === "")) {
        this.setState({ passwordError: 'input field are empty.' })
      }
      else if (this.state.newPassword !== this.state.confrimPassword) {
        this.setState({ passwordError: 'password are not match.' })
      }
      else {
        this.callGetPasswordValidationApi()
        this.setState({ openSnackBarPassword: true });
        setTimeout(() => {
          this.setState({ openSnackBar: false });
        }, 1000)
        this.setState({ passwordError: '', newPassword: '', confrimPassword: '' })
        this.props.navigation.navigate('LandingPage');
      }
    }
    handlePasswordChange=(e:any)=>{
      this.setState({newPassword:e?.target?.value,passwordError:''})
    }
    handlePasswordConfrimChange=(e:any)=>{
      this.setState({confrimPassword:e?.target?.value})
    }

  handleClickShowPassword = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField })
  }

  handleClickShowConfrimPassword = () => {
    this.setState({ enableConfrimPasswordField: !this.state.enableConfrimPasswordField })
  }
  handleEmailChange=(e:any)=>{
    this.setState({email : e.target?.value, emailError:''})
  }


  callGetPasswordValidationApi() {
    let token="";
    if(typeof window !== 'undefined'){
      token = window.location.href.split("token=")[1] || ""
    }
    const headers = {
      "Content-Type": "application/json",
      "token": token
    }

    const httpBody = {
      data: {
        new_password: this.state.newPassword,
        confirm_password: this.state.confrimPassword,
      }
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPasswordCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/passwords"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }



  // Customizable Area End
}