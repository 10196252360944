import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SyntheticEvent } from "react";
import moment from 'moment'
import { enNZ } from "date-fns/locale";
import { CatalogueVariant } from "../../categoriessubcategories/src/CategoriesControllerWeb";
enum API {
	GET = "GET",
	POST = "POST",
  PUT = 'PUT',
	ContentType = "application/json",
  getAllOrder = "bx_block_shopping_cart/orders/get_all_orders",
  getAllPreviousOrder = "bx_block_shopping_cart/orders/previous_orders",
  AcceptDecline = "bx_block_shopping_cart/orders/order_status"

}

export interface OrderResponse {
  no_of_order_items: number;
  date: string;
  order_items: OrdersData;
}

export interface OrdersData {
  data: Order[];
}

export interface Order {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderAttributes {
  status: string;
  total_fees: number;
  total_items: number;
  total_tax: number;
  order_no: number;
  netAmount: number;
  discountPercentage: number | null;
  discountAmount: number | null;
  taxableAmount: number;
  taxPercentage: number | null;
  taxAmount: number | null;
  amount: number;
  payment_type: string;
  created_at: string;
  updated_at: string;
  order_enable: boolean;
  delivery_date: string;
  total: number;
  discount_amount: number | null;
  shipping_charge: number | null;
  delivery_hrs: number | null;
  delivery_fees: number;
  subscription_amount: number | null;
  meat_storage_amount: number;
  customer: CustomerData;
  address_id: AddressData;
  subtotal: number;
  bill_to: AddressData;
  slot: any | null;
  shipping_address: AddressData;
  order_items: OrderItemsData;
}

interface CustomerData {
  data: Customer;
}

interface Customer {
  id: string;
  type: string;
  attributes: CustomerAttributes;
}

interface CustomerAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string | null;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string;
  unique_auth_id: string;
  plans: Plan[];
}

interface Plan {
  current_plan: PlanDetails;
  existing_paln: PlanDetails[];
}

interface PlanDetails {
  id: number;
  plan_name: string;
  price: number;
  validity: string;
  created_at: string;
  updated_at: string;
  description: string;
}

interface AddressData {
  data: Address;
}

interface Address {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface AddressAttributes {
  id: number;
  name: string;
  flat_no: string;
  address: string;
  address_type: string;
  address_line_2: string | null;
  zip_code: string;
  phone_number: string;
  latitude: number | null;
  shipping_charge: number;
  longitude: number | null;
  address_for: string;
  is_default: boolean;
  city: string | null;
  state: string;
  country: string;
  landmark: string | null;
  created_at: string;
  updated_at: string;
  email: string;
}

interface OrderItemsData {
  data: OrderItem[];
}

interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderItemAttributes {
  order_no: number;
  status: string;
  price: number;
  quantity: number;
  frequency: any | null;
  taxable: boolean;
  taxable_value: number;
  other_charges: number;
  delivered_at: string;
  payment_type: string | null;
  catalogue: CatalogueData;
  catalogue_variant: {
    data: CatalogueVariant
  }
}

interface CatalogueData {
  data: Catalogue;
}

interface Catalogue {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface CatalogueAttributes {
  category_id: number;
  brand_id: number | null;
  name: string;
  description: string;
  category_cutsname: string;
  length: number | null;
  breadth: number | null;
  height: number | null;
  stock_qty: number | null;
  recommended: boolean;
  availability: number | null;
  weight: number | null;
  price: number;
  on_sale: boolean;
  sale_price: number | null;
  discount: number | null;
  farm_description: string | null;
  farm_title: string | null;
  subUoms: string;
  hsnCode: string;
  netAmount: number | null;
  amount: number | null;
  discountPercentage: number | null;
  discountAmount: number | null;
  taxableAmount: number | null;
  taxAmount: number | null;
  taxPercentage: number | null;
  updatedBy: string;
  status_desc: string;
  assetTypeName: string;
  catalogue_variant: CatalogueVariant
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderControl: boolean
  todayDate:any,
  startDate:any,
  endDate:any
  visibleDate:boolean
  allOrderList : OrderResponse[]
  orderAnalyticsId : any
  showLoader:boolean
  allOrderPreviousList: OrderResponse[]
  serachTerm:string
  pageStatus:string;
  currentAcceptDecline: {
    itemId: string | number
    parentIdx: number
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderApiCallId : any;
  getOrderStatusApiCallId :any;
  getAllPreviousOrderCallId:any;
  getOrderSearchApiCallId:any;
  getOrderbyDateApiCallId:any;
  deplayDebounce:any=null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderControl: true,
      todayDate:"",
      startDate:null,
      endDate:null,
      visibleDate:false,
      allOrderList:[],
      orderAnalyticsId:'',
      showLoader:false,
      allOrderPreviousList:[],
      serachTerm:'',
      pageStatus:'',
      currentAcceptDecline: {
        itemId: 0,
        parentIdx: 0
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {

    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.getOrderApiCallId) {
        if (responseJson && responseJson.data) {
          this.setState({ allOrderList: responseJson.data.attributes.all_order_items })
          this.setState({ showLoader: false })
          this.setState({ serachTerm: '' })
        }
        else {
          window.alert('no record found!!!')
          // this.props.navigation.navigate('Home')
        }
      }
      if (apiRequestCallId === this.getAllPreviousOrderCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            allOrderPreviousList: responseJson.data.attributes.all_order_items
          })

        }
        this.setState({ showLoader: false })
        this.setState({ serachTerm: '' })
      }
    if(apiRequestCallId===this.getOrderStatusApiCallId){
      // this.getAllOrder()
      if (responseJson && responseJson.data) {
        this.setState((prevState) => {
          const { allOrderList, currentAcceptDecline } = prevState;
          const { parentIdx, itemId } = currentAcceptDecline;

          const orderToUpdate = { ...allOrderList[parentIdx] };

          orderToUpdate.order_items = {
            ...orderToUpdate.order_items,
            data: orderToUpdate.order_items.data.filter(item => item.id !== itemId)
          };
          orderToUpdate.no_of_order_items -= 1;
          const updatedAllOrderList = [...allOrderList];
          updatedAllOrderList[parentIdx] = orderToUpdate;

          return {
            allOrderList: updatedAllOrderList,
            showLoader: false
          };
        });
      }
      this.setState({ orderAnalyticsId : responseJson && responseJson.data.attributes.catalogue.data.attributes.category_id})
      sessionStorage.setItem("analyticsId", this.state.orderAnalyticsId)
      this.setState({serachTerm:''})
    }
      if (apiRequestCallId === this.getOrderSearchApiCallId) {
        if (responseJson && responseJson.data) {
          if (this.state.pageStatus === 'previous') {
            this.setState({
              allOrderPreviousList:
                responseJson.data.attributes.all_order_items[0].order_items.data.length > 0 ?
                  responseJson.data.attributes.all_order_items : []
            })
          } else {
            this.setState({
              allOrderList:
                responseJson.data.attributes.all_order_items[0].order_items.data.length > 0 ?
                  responseJson.data.attributes.all_order_items : []
            })
          }
          this.setState({ showLoader: false })
        } else {
          this.setState({ allOrderList: [] })
          this.setState({ showLoader: false })
        }
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const current = new Date();
    this.setState({todayDate:current})
    this.getAllOrder()
  }


  getAllOrder = () => {
    // const {allOrderList} = this.state
    // if (allOrderList && allOrderList.length > 0) return

    this.setState({ showLoader: true })
    const header = {
      "Content-Type": API.ContentType,
      token: localStorage.getItem('authToken'),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_shopping_cart/orders/get_all_orders?page=1&per_page=10"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getAllPreviousOrder = () => {
    // const {allOrderPreviousList} = this.state
    // if (allOrderPreviousList && allOrderPreviousList.length > 0) return
    this.setState({ showLoader: true })
    const header = {
       "Content-Type": API.ContentType,
      token: localStorage.getItem('authToken'),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllPreviousOrderCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.getAllPreviousOrder
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  OrderStatus = (value: string, id: string | number, parentIdx: number = 0) => {
    this.setState({ showLoader: true, currentAcceptDecline: { itemId: id, parentIdx } })
    const header = {
       "Content-Type": API.ContentType,
      token: localStorage.getItem('authToken'),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderStatusApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${API.AcceptDecline}/${id}?status=${value}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.PUT
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  };

  formatTime = (dateString:any) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  setOrderControl = (order: boolean,value:string) => {
    this.setState({ orderControl: order,pageStatus:value });

    if(value==='previous')
    {
      this.getAllPreviousOrder()
    }
    else{
      this.getAllOrder()
    }
  };

  formatDateToYYYYMMDD=(dateString:any)=> {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  handleChange=(dates:[Date | null, Date | null], event: React.SyntheticEvent<any, Event> | undefined)=>{
    this.setState({serachTerm:''})
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      this.setState({ startDate: start, endDate: end }, () => {
        if (start && end)
          this.handleOrderSearch("", { start, end });
      });
    }
  }

  handleVisiblePicker=()=>{
    // this.setState({visibleDate:true});
    this.setState((prevState) => ({
      visibleDate: !prevState.visibleDate,
    }));
  }

  handleOrderSearch = (value: string, searchDates?: { start: Date | null, end: Date | null }) => {
    this.setState({ serachTerm: value });
    // Building URL parameters
    const urlDateParams = searchDates?.start && searchDates.end ? `start_date=${moment(searchDates.start).format("YYYY-MM-DD")}&end_date=${moment(searchDates.end).format("YYYY-MM-DD")}` : '';
    const urlValueParam = value ? `order_no=${value}` : '';
    const isIncommingOrders = this.state.pageStatus === 'previous' ? null : `status=${1}`
    if (value.length === 0 && !urlDateParams.length) {
      this.state.pageStatus === 'previous' ? this.getAllPreviousOrder() : this.getAllOrder();
      return;
    }

    if (value.length >= 9 || urlDateParams.length) {
      console.log("calledAPI");
      this.setState({ showLoader: true });

      const headers = {
        "Content-Type": API.ContentType,
        token: localStorage.getItem('authToken'),
      };

      const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getOrderSearchApiCallId = apiMessage.messageId;

      const endpoint = `bx_block_shippingchargecalculator/pickups/pickups/order/search?${[urlValueParam, urlDateParams,isIncommingOrders].filter(param => param).join('&')}`;
      apiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      apiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      apiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), API.GET);

      runEngine.sendMessage(apiMessage.id, apiMessage);
    }
  }

  // Customizable Area End
}
