export const img803b9c37aafad0151b06a2a4ed7201852f10a212 = require("../../global_assets/803b9c37aafad0151b06a2a4ed7201852f10a212.png");
export const img47676a188c9c093e814300187bf9a3c0eab5ac2a = require("../../global_assets/47676a188c9c093e814300187bf9a3c0eab5ac2a.png");
export const imga0f665489b1d95cc10f55b5401db6abed1e9af1a = require("../../global_assets/a0f665489b1d95cc10f55b5401db6abed1e9af1a.png");
export const img9fa969b0cfa058b02d81fde99817e58a2675f093 = require("../../global_assets/9fa969b0cfa058b02d81fde99817e58a2675f093.png");
export const img867c06a69aed1fcdf3254913062de11656a4ba0f = require("../../global_assets/867c06a69aed1fcdf3254913062de11656a4ba0f.png");
export const imgdaa200bfa259dd9046a390ea652430fa088080eb = require("../../global_assets/daa200bfa259dd9046a390ea652430fa088080eb.png");
export const imge812566880deb62241e215e43f67704d0e4f7c87 = require("../assets/e812566880deb62241e215e43f67704d0e4f7c87.png");
export const imge022b6447286f2cf5b692af4547d3ddba4d8e132 = require("../assets/e022b6447286f2cf5b692af4547d3ddba4d8e132.png");
export const img7b47ae91ac9fd77d6090978a28373ef76b38643b = require("../assets/7b47ae91ac9fd77d6090978a28373ef76b38643b.png");
export const img34a2eef4cf6ffc5d2b5a9828a299e29ab598ac11 = require("../assets/34a2eef4cf6ffc5d2b5a9828a299e29ab598ac11.png");
export const img630d78a079f79921c58262cc68ea461b7c66111d = require("../assets/630d78a079f79921c58262cc68ea461b7c66111d.png");
export const img329075a1776a5f83c6a0db87a16f2350c27cb6b7 = require("../assets/329075a1776a5f83c6a0db87a16f2350c27cb6b7.png");
export const img87088ec0d9814edb1b989d6ca37bd76704fe222f = require("../assets/87088ec0d9814edb1b989d6ca37bd76704fe222f.png");
export const imgc78c9c67783417460a5376d3c4f00b9fa6fabe21 = require("../assets/c78c9c67783417460a5376d3c4f00b9fa6fabe21.png");
export const img91a68039ed74d31ca4aff83351547449d65857b2 = require("../assets/91a68039ed74d31ca4aff83351547449d65857b2.png");
export const img30cfd9e0d37de35713572e7d6d6b47cc9f238c44 = require("../assets/30cfd9e0d37de35713572e7d6d6b47cc9f238c44.png");
export const img =require("../assets/img/Maranatha-logo.png");
