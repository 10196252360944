import React from "react";
import {
  Grid,
  Button,
  CardActionArea,
  CardActions,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  FormHelperText, styled, NativeSelect, FormControl
} from "@material-ui/core";
import "./CateggoriesCategories.css";
import TopNavWeb from "../../../components/src/TopNavWeb";
import CategoriesControllerWeb, { Props } from "./CategoriesControllerWeb";
import ShareIcon from "@material-ui/icons/Share";
import CustomInlineButton from "../../../components/src/CustomInlineButton.web";
import CustomOutlinedButton from "../../../components/src/CustomOutlinedButton.web";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Link } from "react-router-dom";
import BookmarkBorderOutlined from "@material-ui/icons/BookmarkBorderOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../../../components/src/Loader.web";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import RecurringCustomModal from "../../../components/src/RecurringCustomModal.web";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
const VariantWrapper = styled(FormControl)({
  width: 'fit-content',
  flexDirection: 'inherit',
  alignItems: 'baseline',
  display: "grid",
  justifyContent: "center",
  '& .MuiInput-underline::before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'none'
  },
})
const QtyWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
})
export default class CategoriesDescriptionWeb extends CategoriesControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // console.log('product',this.state.productDescription)
    // const {price} = this.state.productDescription?.attributes
    const isMerchant = localStorage.getItem('user_type') === 'merchant';
    return (
      <>
        <TopNavWeb />
        {this.state.descLoader ? (
          <Loader
            loading={this.state.descLoader}
          />
        ):
        <div className={"bgBodyDesc"}>
          <div className="descContainer">
            <Grid container className="descContent" spacing={3}>
              <Box display="flex">
                <img className={"descImg"} src={this.state.selectedVariant.productImage && this.state.selectedVariant.productImage} />
              </Box>
            </Grid>
          </div>
          <div className="descbtmContainer">
            <Grid container className="" spacing={3}>
              <Grid item xs={12} sm={7}>
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    paddingBottom: "15px",
                    color: "#231F20"
                  }}
                >
                  All you Know about {this.state?.productDescription?.attributes?.categoryCode}
                </Typography>
                <Typography style={{ paddingBottom: "15px",color:"gray" }}>
                  {this.state.productDescription?.attributes?.additionalDescription}
                </Typography>
                <Typography style={{ color: "#231F20" }}>
                  Our Favorite Recipe:
                </Typography>
                <Typography style={{ paddingBottom: "15px", color: "#231F20" }}>
                   {this.state.productDescription?.attributes?.categoryCode}
                </Typography>
                <Typography style={{color: "#231F20"}}>
                  $
                  <span style={{ fontSize: "22px", color: "#231F20" }}>
                    {this.state.selectedVariant.price}
                  </span>
                  {this.state.productDescription.attributes?.uom ? 
                  `/${this.state.productDescription.attributes?.uom}` : ""}
                </Typography>
                  {!isMerchant ? 
                <Box style={styles.variantContainer}>
                    <VariantWrapper>
                      <NativeSelect
                        // value={JSON.stringify(this.state.selectedVariant.variant)}
                        onChange={(event) => this.handleVariantSelect(JSON.parse(event.target.value))}
                        name="variant"
                        inputProps={{ "aria-label": "variant" }}
                        style={styles.variantSelect}
                      >
                        {this.state.productDescription?.attributes &&
                          this.state.productDescription.attributes.catalogue_variants.map(item => (
                            <option key={item.id} value={JSON.stringify(item.attributes)}>
                              {item.attributes.variantType || this.state.productDescription?.attributes?.description}
                            </option>
                          ))}
                      </NativeSelect>
                    </VariantWrapper>
                  <QtyWrapper>
                    <button
                      onClick={this.handleVariantQtyDecrement}
                      style={styles.qtyBtns}
                      disabled={this.state.availablevariantQty < 1}>
                      <RemoveIcon htmlColor="#aec2fa" />
                    </button>
                    <span>{String(this.state.variantQty).padStart(2,"0")}</span>
                      <button
                        onClick={this.handleVariantQtyIncrement}
                        style={{
                          ...styles.qtyBtns,
                          ...(this.state.availablevariantQty <= this.state.variantQty ?
                            { backgroundColor: 'rgba(0, 0, 0, 0.12)' } : {})
                        }}
                      disabled={this.state.availablevariantQty < 1}>
                      <AddIcon htmlColor="#aec2fa" />
                    </button>
                  </QtyWrapper>
                </Box> : <div></div>}
                  {(!isMerchant && this.state.availablevariantQty < 1) &&
                    <Box>
                      <Typography
                        style={{ margin: '10px 0 0 20px' }}
                        color="error"
                        variant="subtitle2">
                        {/* {this.state.selectedVariant.variantErrorMsg} */}
                        Out of stock
                      </Typography>
                    </Box>}
                <div className={"btnContent"} style={{ marginTop: "8px" }}>
               {!isMerchant ?
                  <div style={{ display: "flex" }}>
                    <CustomInlineButton
                      title={"Add to Cart"}
                      stylePadding={"10px"}
                      // styleWidth={"max-content"}
                      style={{width:"130px"}}
                      data-test-id={'myFavBtn'}
                      disabled={Boolean(
                        this.state.availablevariantQty < 1 ||
                        this.state.variantQty < 1)}
                      handleChange={()=>this.setAddToCart(this.state.productDescId)}
                    />
                    <CustomInlineButton
                      title={"Subscription"}
                      stylePadding={"10px"}
                      data-test-id={'myFavBtn'}
                      disabled={!this.state.productDescription.attributes?.subscription || this.state.availablevariantQty < 1}
                      style={{marginLeft:"25px",width:"206px"}}
                      handleChange={() => this.setState({isSubscriptionOpen: true})}
                    />
                    <RecurringCustomModal disabled={this.state.plusValue1<1} handleFrequency={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFrequency(event)} isModalOpen={this.state.isSubscriptionOpen} handleClose={this.handleSubscriptionClose} handleButton1={this.handleButton1} handleIncrement2={this.handleIncrement2} handleDecrement2={this.handleDecrement2} plusValue1={this.state.plusValue1} btnTitle={"Add Subscription"} title={"Recurring Order"} description={"You may set up a recurring order by selecting the"}>
                  </RecurringCustomModal>
                  </div> : <div></div>}
                  {/* <div style={{ display: "flex" }}> */}
                    
                  {/* </div> */}
                  <div className={"btnShare"}>
                    <div className="btnshareIcon">
                      <Tooltip title="share">
                        <ShareIcon
                          data-testID="copyproduct"
                          onClick={() => this.handleCopy(this.state.productDescription?.id)}
                          style={{
                            width: "30px",
                            height: "30px",
                            margin: "auto 0px",
                          }} 
                        />
                      </Tooltip>
                    </div>
                      <CustomOutlinedButton
                        title={this.state.productDescription.attributes?.favouriteable_enable ?
                          "Remove from Wishlist" : "Add to Wishlist"}
                        stylePadding={"10px 15px"}
                        styleWidth={"100%"}
                        data-test-id={'myFavBtn0'}
                        handleChange={()=>this.setMyFavourite(this.state.productDescId,true)}
                        // handleChange={()=>this.setMyFavourite(this.state.productDescription.id)}
                      />
                  </div>
                </div>
                <div>
                  <div className="stepContent">
                    <FiberManualRecordIcon className="stepsIcon" />
                    <Typography
                      variant="h6"
                      style={{ paddingBottom: "15px", color: "#231F20" }}
                    >
                      Step 01:
                    </Typography>
                  </div>
                  <Typography variant="body1" style={{ paddingBottom: "15px" ,color:"gray"}}>
                    {this.state.FarmDetails?.step_1}
                  </Typography>
                  <div className="imgContent">
                    <img className={"ContentImg"} src={this.state.farmDetailsStep1 && this.state.farmDetailsStep1} />        
                  </div>
                  <div className="stepContent">
                    <FiberManualRecordIcon className="stepsIcon" />
                    <Typography
                      variant="h6"
                      style={{ paddingBottom: "15px", color: "#231F20" }}
                    >
                      Step 02:
                    </Typography>
                  </div>
                  <Typography variant="body1" style={{ paddingBottom: "15px",color:"gray" }}>
                    {this.state.FarmDetails?.step_2}
                  </Typography>
                  <div className="imgContent">
                    <img className={"ContentImg"} src={this.state.farmDetailsStep2} />
                    
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    paddingBottom: "15px",
                    color: "#231F20"
                  }}
                >
                  About This Farm
                </Typography>
                <div className="aboutContent">
                  <div className="aboutProfile">
                     <img className={"ContentImg"} src={this.state.farmDetailsImage} />
                    <div className="contentheader">
                      <Typography variant="h6" style={{ color: "#231F20",margin:"16px" }}>
                        {this.state.FarmDetails?.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ margin: "16px", color: "grey" }}
                      >
                        {this.state.FarmDetails?.address}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      style={{ margin: "16px", color: "grey" }}
                    >
                      {this.state.FarmDetails?.sub_title}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ marginLeft: "2rem", color: "grey" }}
                    >
                      PHOTOS
                    </Typography>
                    <div className="imgContent">
                      <img className={"ContentImg"} src={this.state.farmDetailsImage} />
                      
                      <Typography
                        variant="h6"
                        style={{ margin: "16px", color: "grey" }}
                      >
                        PRODUCTS
                      </Typography>
                      <div>
                        <Card className={"exploreCard"}>
                          <div>
                            <CardActionArea>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                width={"100%"}
                                margin={"15px 0"}
                                position="absolute"
                                className={"topHeaderBookmark"}
                              >
                                <Typography variant="caption" component="div">
                                  -10% off
                                </Typography>
                                  <IconButton
                                    onClick={() => this.setMyFavourite(this.state.productDescription.id, true)}
                                    style={{ backgroundColor: this.state.productDescription.attributes?.favouriteable_enable ? '#A9C9F7' : '' }}
                                    aria-label="bookmark">
                                    <BookmarkBorderOutlined />
                                  </IconButton>
                              </Box>
                              <CardMedia
                                component="img"
                                height="160"
                                image={this.state.productDescription?.attributes?.productImage}
                                alt="green iguana"
                              />
                              <CardContent style={{display:"flex"}}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {this.state.productDescription?.attributes?.categoryCode ? this.state.productDescription?.attributes?.categoryCode :"Vegetables"}
                                </Typography>
                               
                                <Typography style={{marginLeft: "200px",color: "#231F20"}}>
                                  $
                                  <span style={{ fontSize: "22px"}}>{this.state.selectedVariant.price}</span>
                                    {this.state.productDescription.attributes?.uom ?
                                      `/${this.state.productDescription.attributes?.uom}` : ""}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            <CardActions>
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                width={"100%"}
                                px={1}
                                color="#231F20"
                                fontWeight={"bold"}
                              >
                                <Typography variant="body2" style={{color:"gray"}}>
                                  {this.state.productDescription?.attributes?.description}
                                </Typography>
                                  <CustomOutlinedButton
                                    data-test-id="addtocart"
                                    title={"Add to Cart"}
                                    disabled={Boolean(
                                      this.state.availablevariantQty < 1 ||
                                      this.state.variantQty < 1 ||
                                      Number.parseInt(this.state.selectedVariant.price) < 1)}
                                    handleChange={() => this.setAddToCart(this.state.productDescId)}
                                  />
                              </Box>
                            </CardActions>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>}
      </>
    );
  }
}

const styles = {
  qtyBtns: {
    backgroundColor: "white",
    borderRadius: '6px',
    border: '1px solid #aec2fa',
    padding: '2px',
    cursor: "pointer"
  },
  variantContainer: {
    display: "flex",
    alignItems: "center",
    gap: "35px",
    marginTop: "20px"
  },
  variantSelect: {
    fontSize: 14,
    color: '#A9C9F7',
    border: '1px solid',
    padding: "6px 15px",
    borderRadius: '50px',
    width: '100%',
    minWidth: '190px',
  }
}