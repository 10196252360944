import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Avatar
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TermsConditionsUsersController from "./TermsConditionsUsersController";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";

// Customizable Area End

export default class TermsConditionsUsersWeb extends TermsConditionsUsersController {
  constructor(props: any) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>

        <div style={webStyle.root}>
          {/* <div style={webStyle.mainRoot}>
            <Box style={webStyle.BackToWork}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <KeyboardBackspaceIcon onClick={()=>this.props.navigation.navigate('Home')} />
                <Typography style={webStyle.backTitle}
                >
                  Back to Home
                </Typography>
              </div>
              <div>
                <Avatar alt="Profile Image" src="" />
              </div>
            </Box>
          </div> */}
          <TopNavLoginWeb/>
          {this.state.data.length > 0 && this.state.data.map((i: any, index: any) => {
            return <Box key={index}>

              <Typography variant="h4" style={{ margin: '20px 30px', color: '#231F20', fontWeight: 900 }}>{i.attributes.title}</Typography>
              <Box style={{ backgroundColor: '#F5F2ED', margin: '30px', borderRadius: '15px', padding: "20px" }}>
                <Typography style={{ paddingBottom: "15px", color: '#231F20' }}>
                  {i.attributes.description}
                </Typography>
              </Box>
            </Box>
          }
          )}

        </div>
      </>

    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle: any = {
  root: {
    backgroundColor: "#F5F2ED",
    height: "100vh",
    width: "100%",
    textAlign: "left",
  },
  mainRoot: {
    backgroundColor: "#fff",
    width: "100%",
  },

  BackToWork: {
    backgroundColor: "#fff",
    padding: "25px 50px",
    display: "flex",
    justifyContent: "space-between",
  },
  backTitle: {
    fontWeight: 700,
    color: "#700010"
  },

}

// Customizable Area End
