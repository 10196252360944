import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  Avatar,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockOutlined from "@material-ui/icons/LockOutlined"
import BookmarkBorderOutlined from "@material-ui/icons/BookmarkBorderOutlined";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";

const theme = createTheme({
  palette: {
    background: {
      default: "#67272f"
    },
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
      color: "#67272f",
      fontSize: "20px !important"
    },
    h5: {
      fontWeight: 100,
      color: "#888",
      fontSize: "16px"
    },
    h4: {
      fontWeight: 600,
      color: "#9d5b63",
      fontSize: "14px",
      textTransform: "none"
    }
  }
});

const onlyProduct = [
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    }
];

const product = [
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    },
    {
        title: "Meat",
        subtitle: "Are you searching for fresh vegetable vector",
        price: "22.99",
        button: "Add to cart"
    }
];

// Customizable Area End

import AnimalDetailController, {
  Props
} from "./AnimalDetailController";

export default class AnimalDetail extends AnimalDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <TopNavLoginWeb/>
      <ThemeProvider theme={theme}>
        <div>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={3}
                sx={{ flexGrow: 1 }}
                color={"#4e2628"}
            >
                <Typography>BUY LOIN BUNDLE</Typography>
            </Box>
            <Box p={3}>
                <Grid container spacing={3}>
                    {onlyProduct.map((l: any, index: any) => (
                        <Grid 
                            key={index} 
                            container                 
                            color={"#4e2628"}
                            style={{ padding: '12px' }}
                        >
                            <Card className={"exploreCard"}>
                                <div>
                                    <CardActionArea>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                            margin={"15px 0"}
                                            position="absolute"
                                            className={"topHeaderBookmark"}
                                        >
                                            <Typography variant="caption" component="div">
                                                -10% off
                                            </Typography>
                                            <IconButton aria-label="bookmark">
                                                <BookmarkBorderOutlined />
                                            </IconButton>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography 
                                                gutterBottom 
                                                variant="h5" 
                                                component="div"
                                            >
                                                <span style={{color:"#4e2628"}}> {l.title}</span>
                                            </Typography>
                                            <Typography variant="body2">{l.subtitle}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                            px={1}
                                            color={"#4e2628"}
                                            fontWeight={"bold"}
                                        >
                                            <Typography>
                                                $<span style={{ fontSize: "22px" }}>{l.price}</span>
                                                /kg
                                            </Typography>
                                            <Button className="add-to-cart" size="small">
                                                {l.button}
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
        <div>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={3}
                sx={{ flexGrow: 1 }}
                color={"#4e2628"}
            >
                <Typography>LOIN ITEMS</Typography>
            </Box>
            <Box p={3}>
                <Grid container spacing={3}>
                    {product.map((l: any, index: any) => (
                        <Grid key={index} container className={"exploreItem"} style={{ padding: '12px' }}>
                            <Card className={"exploreCard"}>
                                <div>
                                    <CardActionArea>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                            margin={"15px 0"}
                                            position="absolute"
                                            className={"topHeaderBookmark"}
                                        >
                                            <Typography variant="caption" component="div">
                                                -10% off
                                            </Typography>
                                            <IconButton aria-label="bookmark">
                                                <BookmarkBorderOutlined />
                                            </IconButton>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                            <span style={{color:"#4e2628"}}> {l.title}</span>
                                            </Typography>
                                            <Typography variant="body2">{l.subtitle}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                            px={1}
                                            color={"#4e2628"}
                                            fontWeight={"bold"}
                                        >
                                            <Typography>
                                                $<span style={{ fontSize: "22px" }}>{l.price}</span>
                                                /kg
                                            </Typography>
                                            <Button className="add-to-cart" size="small">
                                                {l.button}
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
      </ThemeProvider>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

};
// Customizable Area End
