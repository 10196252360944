import React from "react";
import {
  Link,
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  styled,
  Typography,
  InputLabel,Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TopNavWeb from "../../../components/src/TopNavWeb";
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import CustomInlineButton from "../../../components/src/CustomInlineButton.web";
import ContactusControllerWeb, { Props } from "./ContactusControllerWeb";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { contactImg } from "./assets";
// import { Link } from 'react-router-dom';
export default class ContactusWeb extends ContactusControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
           {/* Customizable Area Start  */}
          <TopNavWeb/>
          <CustomModalWeb handleClose={this.handlePopupClose} handleButton={this.handlePopupContactClose} isModalOpen={this.state.ContactFlag} btnTitle={'Continue'} statusImage={CheckCircleOutlineIcon} title={" Your Query Submitted"}/>

          <Box style={webStyle.mainDiv}>
            <Grid container spacing={3} style={webStyle.GridBox}>
              <Grid item xs={12} sm={6} style={{ padding: '0px' }}>
                <Box>
                    <img src={contactImg} alt="" style={{ width: "100%", height: '100%', borderRadius: '25px' }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} style={webStyle.GridSubBox}>
                <h1 style={webStyle.headingText}>Contact Us</h1>
                <InputTagBox>
                  <InputBox style={{ marginTop: '25px', width: '100%' }} component={"div" as any}>
                    <InputLabel style={{color: '#231F20'}}>Enter Full Name*</InputLabel>
                    <InputTag data-testID='subject' name='fullName' placeholder="Eg:adfasdlkfjweo" className="inputFeild" value={this.state.query.fullName} onChange={this.changeInputValue} required/>
                  </InputBox>
                  <InputBox style={{ marginTop: '25px', width: '100%' }} component={"div" as any}>
                    <InputLabel style={{color: '#231F20'}}>Enter Email Address*</InputLabel>
                    <InputTag data-testID='subject' name='email' placeholder="Eg:adfasdlkfjweo@gmail.com"  className="inputFeild" value={this.state.query.email} onChange={this.changeInputValue} required/>
                  </InputBox>
                </InputTagBox>
                <InputBox style={{ marginTop: '20px' }} component={"div" as any}>
                  <InputLabel style={{color: '#231F20'}}>Enter Query*</InputLabel>
                  <textarea style={webStyle.textareaTag} data-testID='subject' name='queryDescription' placeholder="Eg:message will be here or problem will be here..." className="inputFeild" value={this.state.query.queryDescription} onChange={this.changeInputValue} required></textarea>
                </InputBox>
                <CustomInlineButton type="submit" handleChange={this.sendQueryDetails} title={"Submit Query"} styleWidth={'100%'} stylePadding={'20px'} />
                <Box>
                  <Typography style={{textAlign: 'center', marginTop: 35, color: '#231F20', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>or reach out us on</Typography>
                </Box>
                <Box style={webStyle.socialSection}>
                <Link  href="mailto:meatlocker@maranathafarms.com" style={webStyle.socialIcon}><MailIcon style={{color: '#A9C9F7'}}/></Link>                  <Link href= {`https://www.instagram.com/${"maranathafarmsva"}`} target="_blank" rel="noopener noreferrer" data-test-id="link" style={webStyle.socialIcon}><InstagramIcon style={{color: '#A9C9F7'}}/></Link>
                  <Link href="https://www.maranathafarms.com" target="_blank" rel="noopener noreferrer" data-test-id="link" style={webStyle.socialIcon}><LanguageIcon style={{color: '#A9C9F7'}}/></Link>
                  {/* <div style={webStyle.socialIcon}><LanguageIcon style={{color: '#df9398'}}/></div> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          
           {/* Customizable End Start  */}
           </>
    )
  }
}

// Customizable Area Start
const InputTag = styled('input')({
  width: '100%',
  color: '#231F20',
  fontWeight: 400,
  
})
const InputBox = styled(Box)({
  '& input::placeholder': {
    color: 'grey',
    fontWeight: 400,
    opacity: 1,
  },
  '& textarea::placeholder': {
    color: 'grey',
    opacity: 1,
    fontFamily:'sans-serif',
    fontWeight: 400,
    fontSize:14,
  },
  '& input:focus-visible': {
    outline: 'none',
    border: '1px solid rgb(224, 236, 253)',
  },
  '& input:focus': {
    outline: 'none',
    border: '1px solid rgb(224, 236, 253)',
  },
  '& textarea:focus': {
    outline: 'none',
    border: '1px solid rgb(224, 236, 253)',
  }
})
const InputTagBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    '@media(max-width:950px)': {
      display: 'block'
    }
})
const webStyle: any = {
  mainDiv: {
      maxWidth:"1440px",
      margin:"auto",
      padding: '50px 25px',
      background: '#F5F2ED'
  },
  socialSection:{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    gap: '20px', 
    marginTop: 30,
  },
  socialIcon: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    cursor: 'pointer',
    background: 'rgb(224, 236, 253)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textareaTag: {
    width: '100%',
    height: '200px',
    resize: 'none',
    color: '#231F20',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  headingText: {
    margin: 0,
    fontSize: 25,
    color: '#231F20',
    marginBottom: 15,
    marginTop: 20
  },
  GridBox: {
    background: 'white',
    borderRadius: '25px',
    padding: '5px'
  },
  GridSubBox: {
    padding: '25px 50px',
  },
  contactTeaxtarea: {
    height: '120px',
    border: '1px solid red',
    borderRadius: '10px',
    width: '97%',
  },
  contactButton: {
    background: 'red',
    borderRadius: 10,
    width: '100%',
    height: '40px',
  }
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Customizable Area End
