import React, { Component } from "react";
import settingControllerWeb, {
    Props,
  } from "../../blocks/Settings/src/settingControllerWeb";
// import { chickenimg, pigimg } from "../../blocks/Settings/src/assets";
import { pigBacon, pigHead,pigLegHam,pigLoin, pigPicnic, pigRibs, pigShoulder,pigLeg, pigJowls, chickenImage, chickenBack, chickenLeg, chickenBreast, chickenNeck, chickenWing, ChickenThigh, ChickenTail } from "./assest";

export default class AnimalChickenNG extends settingControllerWeb {

constructor(props: Props) {
    super(props);
   
  }
  render() {
    const {isAnalytics} = this.state
    return (
      // Customizable Area Start   
       <div style={{minWidth: '600px'}} className="container" data-testid="animalView">
            <div style={webStyle.animalImgCont1}>
                <div style={webStyle.pigImageContainer}>
                  { this.state.chickenImage && <img style={webStyle.animalImage} src={chickenImage}  useMap="#image_map1"/> }
                  { this.state.chickenBack && <img style={webStyle.animalImage} src={chickenBack}  useMap="#image_map1"/> }
                  { this.state.chickenLeg && <img style={webStyle.animalImage} src={chickenLeg}  useMap="#image_map1"/>}
                  { this.state.chickenBreast && <img style={webStyle.animalImage} src={chickenBreast}  useMap="#image_map1"/>}
                  { this.state.chickenWing && <img style={webStyle.animalImage} src={chickenWing}  useMap="#image_map1"/>}
                  { this.state.chickenThigh && <img style={webStyle.animalImage} src={ChickenThigh}  useMap="#image_map1"/>}
                   { this.state.chickenTail && <img style={webStyle.animalImage} src={ChickenTail}  useMap="#image_map1"/>} 
                  { this.state.chickenNeck && <img style={webStyle.animalImage} src={chickenNeck}  useMap="#image_map1"/>}
                  {/* { this.state.pigHock && <img style={webStyle.animalImage} src={pigLeg}  useMap="#image_map1"/>}
                  { this.state.pigJowls && <img style={webStyle.animalImage} src={pigJowls}  useMap="#image_map1"/>} */}
                </div>
                <map name="image_map1"> 
                   <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "161,152,204,163,249,161,282,172,331,172,333,195,342,212,341,224,285,207,245,198,209,195,185,204,171,217,152,198,138,182,146,164" : "75,71,104,74,143,82,163,105,98,91,82,99,63,84" }
                    shape="poly"
                    onClick={this.handleChickenBack}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "48,196,93,189,122,182,153,221,161,244,171,286,166,330,155,355,109,335,76,301,55,255" : "22,92,58,85,75,106,74,125,83,137,79,156,66,164,36,141"} shape="poly"
                    onClick={this.handleChickenBreast}
                    />
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? 
                      "209,361,247,339,278,341,292,357,301,376,300,400,298,417,291,449,287,468,251,413,201,497" : "98,167,130,161,140,183,136,198,104,200"}shape="poly"
                    onClick={this.handleChickenLeg}
                    />
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "18,171,143,28" : "66,77,11,7"}
                    shape="rect"
                    onClick={this.handleChickenNeck}
                    />  
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "346,178,396,176,450,152,489,146,490,167,497,176,468,196,490,206,488,220,478,234,463,248,442,265,429,292,373,240":"162,83,201,129,232,96,223,67" }
                    shape="poly"
                    onClick={this.handleChickenTail}
                    /> 
                    <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "390,255,416,296,379,331,371,362,353,386,322,406,309,362,277,330,230,329,210,343,187,353,179,337" : "171,118,189,138,167,171,155,183,143,163,125,152,97,156,89,176,76,173,88,140,139,142"}
                    shape="poly"
                    onClick={this.handleChickenThigh}
                    /> 
                     <area
                    style={webStyle.chunckArea1}
                    alt="chunk"
                    coords={!isAnalytics ? "185,217,224,206,258,211,288,221,327,232,361,239,334,269,300,287,259,296,209,292,175,268" : "79,108,101,94,123,99,153,106,153,122,120,132,89,129" }shape="poly"
                    onClick={this.handleChickenWing}
                    /> 
                    </map>
            </div>
      </div>
      // Customizable Area End 
    );
  }
}

const webStyle: any = {
  animalImgCont1: {
    overFlow: "hidden"
  },
  animalImage: {
    width: "40%",
    height: "100%",
    objectFit: "cover",
    mixBlendMode: "darken"
  },
  chunckArea1: {
    cursor: "pointer"
  },
  pigImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }

}
