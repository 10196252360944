import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  Avatar
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockOutlined from "@material-ui/icons/LockOutlined"
import BackToProfileTopNav from "../../../components/src/BackToProfileTopNavWeb";
const theme = createTheme({
  palette: {
    background: {
      default: "#67272f"
    },
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
      color: "#67272f",
      fontSize: "20px !important"
    },
    h5: {
      fontWeight: 100,
      color: "#888",
      fontSize: "16px"
    },
    h4: {
      fontWeight: 600,
      color: "#9d5b63",
      fontSize: "14px",
      textTransform: "none"
    }
  }
});
// Customizable Area End

import NewOrderAlertController, {
  Props
} from "./NewOrderAlertController";

export default class NewOrderAlert extends NewOrderAlertController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <BackToProfileTopNav/>
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <Box sx={webStyle.mainTitleStyle}>
            <Box sx={webStyle.titleStyle}> 
              <Typography variant="h6">Alerts</Typography>
            </Box>  
            <Box sx={webStyle.titleStyle}></Box>  
          </Box>  
          <Box sx={webStyle.mainStyle}>
            <Box sx={webStyle.pictureStyle}>
              <img src="https://cdn.discordapp.com/attachments/831257893147377755/1100482196168519800/image_Bitmap.png"></img>
            </Box>
            <Box sx={webStyle.detailStyle}>
              <Box sx={webStyle.boxesStyle}>
                <Box sx={webStyle.boxStyle}>
                  <Typography variant="h5">CURRENT ANIMAL PURCHASED</Typography>
                  <Typography variant="h6">Pork</Typography>
                </Box>
                <Box sx={webStyle.boxStyle}>
                  <Typography variant="h5">TOTAL CUTS</Typography>
                  <Typography variant="h6">10</Typography>
                </Box>
              </Box>
              <Box sx={webStyle.boxesStyle}>
                <Box sx={webStyle.boxStyle}>
                  <Typography variant="h5">USED CUTS</Typography>
                  <Typography variant="h6">7</Typography>
                </Box>
                <Box sx={webStyle.boxStyle}>
                  <Typography variant="h5">REMAINING CUTS</Typography>
                  <Box sx={webStyle.childBoxStyle}>
                    <Typography variant="h6">3</Typography>
                    <Button variant="outlined" style={webStyle.buttonStyle}>
                      <Typography variant="h4">Pickup / Deliver</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Button color={"primary"} style={{backgroundColor: "#a0282a", borderRadius: 30, padding: "13 0 13 0", boxShadow: "0 30 60 -9 rgba(160,40,42,0.44)", marginTop: 14 }}>Purchase New Animal</Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    width: "100%",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#f8f4f3",
  },
  mainTitleStyle: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "50px 0 0 0"
  },
  mainStyle: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "10px 0 0 0"
  },
  titleStyle: {
    width: "649px",
    lineHeight: "50px"
  },
  pictureStyle: {
    width: "649px",
    border: "none",
    marginTop: 10
  },
  detailStyle: {
    display: "flex",
    flexDirection: "column",
    width: "649px",
    border: "none",
  },
  boxesStyle: {
    display: "flex",
    flexDirection: "row"
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 300,
    height: 80, 
    padding: 40,
    borderRadius: 20, 
    margin: 10,
    bgcolor: "#fff",
    border: "1px solid #eee"
  },
  childBoxStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 35
  },
  buttonStyle: {
    borderRadius: 20,
    width: 180,
    borderColor: "#9d5b63",
    color: "#9d5b63",
    padding: 8
  },
};
// Customizable Area End
