import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
const urlConfig = require("../../../framework/src/config");
const BaseURL = urlConfig.baseURL;
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import  {DownloadInvoice}  from '../../../components/src/toolkit';
import moment from "moment"; 
import { loadStripe } from "@stripe/stripe-js";
// Customizable Area Start
import { Stripe, StripeElements } from '@stripe/stripe-js';
export const configJSON = require("./config");
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { setStorageData , getStorageData, removeStorageData} from "../../../framework/src/Utilities";

enum API {
  GET = "GET",
  POST = "POST",

  DELETE = "DELETE",
  ContentType = "application/json",
  MyCartAPIEndPoint = "bx_block_shopping_cart/orders/order_alerts",
  InvoiceAPIEndPoint = "bx_block_invoicebilling/invoices/send_invoice_mail",
  MyDetailAPIEndPoint = "bx_block_order_management/addresses",
  DiscountAPIEndPoint = "account_block/accounts/fetch_discount",
  IncreaseAPIEndPoint = "bx_block_shopping_cart/orders/increase_product_quantity",
  DecreaseAPIEndPoint = "bx_block_shopping_cart/orders/decrease_product_quantity",
  DeleteAPIEndPoint = "bx_block_shopping_cart/order_items/destroy",
  PaymentAPIEndPoint = "bx_block_stripe_integration/payments",
  PostPaymentDetailsAPIEndPoint="bx_block_stripe_integration/payment_methods",
  DownloadInvoice = "bx_block_invoicebilling/invoices/download_invoice_pdf",
}
export interface MerchantAddress {
  id: number;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}

export interface ShowCards {
  card_number: string;
  name_on_card: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  stripe: any;
  elements: any
}

interface S {
  myCartsInvoice:any;
  quanValue:{[key: string]: any};
  suggestions:any;
  myCarts: any;
  myPlans:any;
  data: IAdresses[];
  paymentType:any;
  saveCardDetails:boolean;
  values:any;
  subTotal: number;
  discount: number;
  shippingCharges: number;
  activeStep: number;
  skipped: any;
  btnGold: string;
  btnPlatinum: string;
  activeClassGold: string;
  activeClassPaltinum: string;
  slots: Array<string>;
  discountCode: any | {
        discount: number;
        promo_code: number;
        total: number;
      };
  discountCodeText: string;
  invoiceForm: any;
  emailFlag: boolean;
  paymentStatus: string;
  paymentFlag: boolean;
  paymentIcon: any;
  formError: any;
  paymentMethod: number;
  createAddressModal: boolean;
  address:any;
  checkAddress: number;
  activeSlot: string;
  selectedAdress: IAddressForm;
  deleteItemFlag: boolean;
  deleteFlag: boolean;
  orderID: any;
  deliveryFlag: boolean;
  sucessflag: boolean;
  meatBtnTitle: string;
  description: string;
  lifetimeSubscription: any;
  fastDelivery: any;
  discountErrorMessage: string;
  formatDate:any;
  formatDateDeleiver:any;
  lifetimeSubs :any
  meatStorageAmount:any;
  LifeTimeSubscriptionFlag:any;
  emptyCart:boolean;
  key:any;
  errors:any;
  disabled:any;
  product: any;
  selectedProduct: any;
  formKey:number;
  merchantAddress: MerchantAddress | undefined;
  modalCartData:any;
  checked:boolean
  // isFilled:any;
  // completed:any;
  deliverySlot: {
    date: string,
    time: string
  },
  deliveryDates: string[],
  deliveryTimes: Record<string, string[]>,
  disableBtn: boolean,
  addCheck: boolean,
  slotCheck: boolean,
  customerId: string,
  showCards: ShowCards[],
  cvvCard:string,
  isLoading:boolean,
  showAmount:string,
  planIndex: number,
  currentPlan:boolean,
  cardNameDetail: string,
  issaveChecked:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  stripe: any;
  elements: any;
}
// Customizable Area Start
export interface IMyCartForm {
  name: string;
  quantity: number;
  price: number;
  
}
interface IAdresses {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    flat_no: number;
    address: string;
    address_type: string;
    address_line_2: string;
    zip_code: string;
    phone_number: string;
    latitude: string;
    longitude: string;
    address_for: string;
    is_default: string;
    city: string;
    state: string;
    country: string;
    landmark: null;
    created_at: string;
    updated_at: string;
    email: string;
  };
}

interface IAddressForm {
  id?: number;
  name: string;
  address_type: string;
  flat_no: string;
  address: string;
  zip_code: string;
  phone_number: string;
  state: string;
  country: string;
}
// Customizable Area End
export default class MyCartController extends BlockComponent<Props, S, SS> {
  getMyCartApiCallId: any;
  getInvoiceCartApiCallId:any;
  getIncreaseQuantityId: any;
  getDecreaseQuantityId: any;
  getSendInvoice: any;
  getMyDetailsApiCallId: any;
  getStripePaymentId: any;
  getPaymentId: any;
  // Customizable Area Start
  getDiscountApiCallId: any;
  getMyAddressApiCallId: any;
  deleteProdcutAPICall: any;
  downloadInvoiceAPICall:any;
  getMyDiscountApiCallId:any;
  showDelievryAmountCallId:any;
  getMyInvoiceProductCallId:any;
  getDiscountApiId:any;
  getDelieveryApiId:any;
  getDelieveryFeesApiId:any;
  getSubscriptionsApiId:any;
  getMyStorageApiCallId:any;
  getPrefilledEmailCallId:any;
  getModalCartDetailsAPICallId: string = "";
  apiStateCallId:any;
  getMerchantAddressAPICallId: unknown
  getSlotsForDeliveryAPICallId: unknown;
  showCardDetails: string = "";
  saveCardDetailsCallId: string = ""
  getLifetimeSubscriptionID:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // isFilled:{
      //   "cardNumber":false,
      //   "expiry":false,
      //   "cvv":false
      // },
      myCartsInvoice:[],
      quanValue:{},
      myCarts: [],
      myPlans:[],
      data:[],
      values:{
      cardname:"",
      cardnumber:"",
      expirydate:"",
      cardcvv:"",
      cardtype:"",
      }, 
      suggestions:{
      cardname:"",
      cardnumber:"",
      expirydate:"",
      cardcvv:"",
      cardtype:"",
      }, 
      subTotal: 0,
      paymentType:"card",
      saveCardDetails:false,
      discount: 0,
      shippingCharges: 10,
      activeStep: 0,
      skipped: new Set<number>(),
      btnGold: "Add Storage",
      btnPlatinum: "Add Storage",
      activeClassGold: "",
      activeClassPaltinum: "",
      slots: [
        "6:00 AM",
        "7:00 AM",
        "8:00 PM",
        "9:00 PM",
        "10:00 PM",
        "11:00 AM",
        "12:00 PM",
        "1:00 PM",
        "2:00 PM",
        "3:00 PM",
        "4:00 PM",
        "5:00 PM",
        "6:00 PM",
        "7:00 PM",
      ],
      discountCode: {
        discount: 0,
        promo_code: "",
        total: 0,
      },
      discountCodeText: "",
      invoiceForm: {
        from: "",
        to: "",
        subject: "",
        message: "",
      },
      formError: "",
      emailFlag: false,
      paymentStatus: "",
      paymentFlag: false,
      paymentIcon: CachedIcon,
      paymentMethod: 1,
      createAddressModal: false,
      address: {
        name: "",
        address_type: "",
        flat_no: "",
        address: "",
        zip_code: "",
        phone_number: "",
        state: "",
        country: "",
      },
      checkAddress: 0,
      selectedAdress: {} as IAddressForm,
      activeSlot: "",
      deleteItemFlag: false,
      deleteFlag: false,
      orderID: null,
      deliveryFlag: false,
      sucessflag: false,
      meatBtnTitle: "",
      description: "",
      lifetimeSubscription: localStorage.getItem("LifetimeSubscription"),
      fastDelivery: false,
      discountErrorMessage: "",
      formatDate:"",
      formatDateDeleiver:"",
      lifetimeSubs : false,
      meatStorageAmount:"",
      LifeTimeSubscriptionFlag:false,
      emptyCart:true,
      key:"",
      errors:{},
      disabled:false,
      product: [],
      selectedProduct: { label: "Potato", value: "Potato", category: 26, image: null, cuts: 0 },
      formKey: 0,
      merchantAddress: undefined,

      deliverySlot: {
        date: "",
        time: ""
      },
      deliveryDates: [],
      deliveryTimes: {},
      modalCartData:{},
      checked:false,
      disableBtn: true,
      addCheck: false,
      slotCheck: false,
      customerId: "",
      showCards : [],
      cvvCard:'',
      isLoading:true,
      showAmount:"",
      planIndex: 0,
      currentPlan:false,
      cardNameDetail: "",
      issaveChecked:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    let selectedAdress = null;
    try {
      selectedAdress = JSON.parse(
        localStorage.getItem("selected_address") as any
      );
      if (selectedAdress && selectedAdress.id) {
        this.state = {
          ...this.state,
          selectedAdress: selectedAdress,
        };
      }
    } catch (error) {}
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        case this.showDelievryAmountCallId:
          if(responseJson){
            this.setState({showAmount:responseJson.delivery_hrs_amount})
          }

        case this.getMyCartApiCallId:
          if (responseJson?.data) {
            let count = 0;
            let arr = [] as any
            let cust=responseJson?.data[0]?.attributes?.customer?.data.attributes.plans

            responseJson?.data.length>0&&  Array.isArray(responseJson?.data)&& responseJson?.data[0].attributes.order_items.data.forEach((data: any) => {
              const { catalogue, quantity, catalogue_variant} = data.attributes;
              if(catalogue?.data?.attributes){
                const { price,categoryCode,name,productImage } = catalogue?.data?.attributes;
                count += (catalogue_variant?.data?.attributes.price * quantity);
                let catalogue_id = data.attributes?.catalogue?.data?.id
                let order_number = responseJson?.data[0]?.attributes.order_no
                let order_id = responseJson?.data[0]?.id
                arr.push({ productImage, name: categoryCode, quantity: quantity, price: catalogue_variant?.data?.attributes.price, catalogue_id, order_id, o_id :data?.id,order_number,productName:name, variant:catalogue_variant?.data?.attributes.variantType,variant_id:catalogue_variant?.data?.id, frequency: data?.attributes?.frequency })
  
              }
              // this.setState({
              //     myCarts: [ {name: catalogue.data.attributes.name, quantity: quantity, price: price,catalogue_id,order_id}]
              // });
            })
            const deliveryDate = moment(responseJson?.data[0]?.attributes.delivery_date).format("YYYY-MM-DD")
            const deliveryTime = moment(responseJson?.data[0]?.attributes.delivery_date).format("HH:mm:ss")
            this.setState({
              myCarts: arr,
              subTotal: count,
              isLoading:false,
              deliverySlot: {
                date: deliveryDate,
                time: deliveryTime
              },
              discountCode: {
                discount: responseJson?.data[0]?.attributes.discount_amount
              },
              myPlans:cust,
            })
          }
          break;

        case this.saveCardDetailsCallId:
          if(responseJson) {
            this.setState({
              customerId: responseJson.customer
            }, () => this.showSavedCardDetails())
          }
          break;
          case this.showCardDetails :
            this.setState({
              showCards: responseJson
              });
            break;
        
        case this.getMyDetailsApiCallId:
          if (responseJson && responseJson.data) {
            const attr = responseJson.data[0].attributes;
            const { selectedAdress } = this.state;
            if (!selectedAdress.name) {
              localStorage.setItem("selected_address", JSON.stringify(attr));
              localStorage.setItem("selectedShipping",JSON.stringify(attr.shipping_charge))
              this.setState({
                 data: responseJson.data,
                 selectedAdress: attr,
                 checkAddress: Number(attr.id),
              });
            } else {
              this.setState({
                data: responseJson.data,
                // checkAddress: Number(selectedAdress.id),
              });
            }
          }
          break;
        case this.getIncreaseQuantityId:
          this.getMyCartRequest();
          break;
        case this.getDecreaseQuantityId:
          this.getMyCartRequest();
          break;
        case this.getPaymentId:
          this.paymentPostMethod(responseJson?.data?.id);
          break;
          case this.getModalCartDetailsAPICallId:
              this.setState({modalCartData:responseJson.data[0]})
        case this.getStripePaymentId:
          if (responseJson?.message) {
            this.setState({
              paymentStatus:
              "Payment Success",
              paymentIcon: CheckCircleIcon,
            });
            const invoice: Message = new Message(getName(MessageEnum.NavigationMessage));
            invoice.addData(getName(MessageEnum.NavigationTargetMessage), "SendInvoice");
            invoice.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(invoice);
          } else {
            this.setState({
              paymentStatus: "Payment Failed",
              paymentIcon: ErrorOutlineIcon,
            });
          }
          break;
        case this.getDiscountApiCallId:
          if (responseJson) {
            localStorage.setItem(
              "coupon_promocode",
              JSON.stringify(responseJson.promo_code)
            );
            localStorage.setItem("coupon_discount", responseJson.discount);
            
            this.setState({
              discountCode: responseJson,
              discountCodeText: responseJson.promo_code,
            });
            this.isDiscountCodeFetching = false;
          }
          break;
        case this.getPrefilledEmailCallId:
          if(responseJson?.invoice_detail){
           this.setState({invoiceForm:responseJson?.invoice_detail})
          }
           break;
        case this.getSendInvoice:
          if (message) {
            this.setState({
              invoiceForm: { from: "", to: "", subject: "", message: "" },
              formError: "",
            });
            this.setState({ emailFlag: true });
          }
          break;
        case this.deleteProdcutAPICall:
          if (responseJson) {
            this.setState({ deleteFlag: false, deleteItemFlag: false });
            this.getMyCartRequest();
          }
          break;
        case this.getMyDiscountApiCallId:
          // if (responseJson.discount !== "Discount not present") {
          //   // this.setState({ discountCodeText: "QWERT" });
          //   localStorage.setItem("coupon_promocode", "QWERT");
          //   localStorage.setItem(
          //     "coupon_discount",
          //     JSON.parse(responseJson.discount)
          //   );
          //   // Number('22')
          //   const result = {
          //     discount: JSON.parse(responseJson.discount),
          //   };

          //   this.setState({
          //     discountCode: {
          //       ...this.state.discountCode,
          //       discount: JSON.parse(responseJson.discount),
          //     },
          //     // shippingCharges: responseJson.shipping_charge,
          //     discountCodeText: responseJson.promo_code,
          //   });
          if(responseJson){
            this.getMyCartRequest()
          } else {
           this.setState({discountErrorMessage: "Discount not present"})  
          }
            break;
          case this.getMyInvoiceProductCallId:
            if(responseJson?.data)
            {
              this.setState({myCartsInvoice: responseJson?.data.attributes})
              this.setState({ formatDate:this.formatDate(this.state.myCartsInvoice?.created_at) || ''})
              this.setState({formatDateDeleiver:this.formatDate(this.state.myCartsInvoice?.delivery_date) || ''})
                          }
            break;
          case this.getDiscountApiId:
          break;

          case this.getSubscriptionsApiId:
          break;

          case this.getDelieveryFeesApiId:
          break;

          case this.getDelieveryApiId:
          break;
           
          case this.apiStateCallId:
            if (responseJson) {
              let arr = [] as any
              responseJson?.map((i: any) => {
                let item = {} as any;
                item.label = i?.name;
                item.value = i?.name;
                arr.push(item);
              })
              this.setState({ product: arr })
            }
          break;

          case this.getMyStorageApiCallId:
            if(responseJson?.data){
              this.setState({meatStorageAmount:responseJson?.data.attributes.price})
             this.handleNext()
            }
            break;

        case this.getMerchantAddressAPICallId:
          if (responseJson && responseJson.data) {
            this.setState({
              merchantAddress: responseJson.data[0].attributes
            })
          }
          break;
        case this.getSlotsForDeliveryAPICallId:
          if (responseJson && responseJson.data) {
                        let deliveryDates = responseJson.data.map((item: typeof responseJson.data[0]) => item.attributes.date)
            let deliveryTimes = responseJson.data.reduce(
              (
                acc: typeof responseJson.data,
                item: typeof responseJson.data[0]
              ) => {
                return {
                  ...acc,
                  [item.attributes.date]: item.attributes.slots
                }
              }, {})
            this.setState({ deliveryDates, deliveryTimes })
          }
        
      }
    }

  }

    public async componentDidMount() {
      const fastDelivery = localStorage.getItem('fastDelivery') === 'true';
        this.setState({ fastDelivery });
        this.getMyCartRequest();
        this.getMyDetails();
        this.handleInvoiceProduct();
        this.handlePrefilledData();
        this.getDelieveryHrsApi();
        // this.getDiscountApi();
        this.getDelieveryFeesApi();
        this.getSubscriptionsApi();
        this.handleGetDataLocalStorage();
        this.callStateApi();
        this.getMerchantAddress();
        this.getSlotsForDelivery();
        this.showDelievryAmount();
        this.getLifetimeSubscription();
        await this.showModalCartDetails()

        window.addEventListener('storage', this.handleLocalStorageChange);   
        if (this.props.navigation?.history?.location?.state?.personalDetail) {
          this.setState({ activeStep: this.props.navigation?.history?.location?.state?.personalDetail })
        }
    }
    componentDidUpdate(prevProps: any, prevState: any) {
      if (prevState.myCarts.length === 0 && this.state.myCarts.length > 0) {
          this.setState({ isLoading: false });
      }
  }
  
    private isDiscountCodeFetching: boolean = false;
    showModalCartDetails(){
      const getMerchantAPIMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getModalCartDetailsAPICallId = getMerchantAPIMessage.messageId;
  
      getMerchantAPIMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_subscriptions/subscription_conditions"
      );
  
      getMerchantAPIMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(getMerchantAPIMessage.id, getMerchantAPIMessage);
      return this.getModalCartDetailsAPICallId;
    }
   onClickFetchDiscount=()=> {
    // this.getDiscount()
    // if (this.state.discountCodeText) {
      const header = {
        "Content-Type": API.ContentType,
        token: this.getToken(),
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMyDiscountApiCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/accounts/discount?promo_code=${this.state.discountCodeText}`
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        API.POST
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    // } else if (!this.isDiscountCodeFetching) {
    //   this.isDiscountCodeFetching = true;
    //   this.getDiscount();
    // }
    return this.getMyDiscountApiCallId
  }
  showDelievryAmount = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showDelievryAmountCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/delivery_hrs_amount"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  return this.showDelievryAmountCallId;
  }
  handleDiscount = (e: any) => {
    this.setState({ discountCodeText: e?.target?.value });
  };

  public humanizeAddress(address: any = {}) {
    const {
      flat_no,
      address: street,
      address_line_2,
      zip_code,
      city,
      state,
      country,
    } = address;

    if (street === undefined || street === null) return "";

    let addressString = `${flat_no}, ${street}`;

    addressString += ` ${state} ${zip_code} ${country}`;

    return addressString;
  }

  createAdress() {
    const values = this.state.address;
    const name = values.name
    this.setState({
      errors:{
        address:values.address === ""?"*Address field is required.":"",
        name:values.name === ""?"*Name field is required.":"",
        address_type:values.address_type === ""?"*Address Type field is required":"",
        country:values.country === ""?"*Country field is required":"",
        state:values.state.value === ""?"*State field is required":"",
        flat_no:values.flat_no === ""?"*Flat number is required":"",
        zip_code:values.zip_code === ""?"*Zip code is required":"",
        phone_number:!this.validatePhoneNumber(values.phone_number)?"*Phone number is Required.":""
      },
      addCheck: true,
    })
    if (
      values.address === "" ||
      values.address_type === "" ||
      values.country === "" ||
      values.flat_no === "" ||
      values.name === "" ||
      values.state.value === "" ||
      values.zip_code === ""
    ) {
      return null
    } else if (!this.validatePhoneNumber(values.phone_number)) {
      return null
    } else {
      const requestOptions = {
        method: "POST",
        headers: JSON.parse(
          this.getHeaderMessage({ contentType: API.ContentType.toString() })
        ),
        body: JSON.stringify(values),
      };

      return fetch(
        `${BaseURL}/${API.MyDetailAPIEndPoint}`,
        requestOptions
      ).then((response) => {
        if (response.ok) {
          this.setState({
            address: {
              name: "",
              address_type: "",
              flat_no: "",
              address: "",
              zip_code: "",
              phone_number: "",
              state: "",
              country: "",
            },
          });
          this.handleClose();
        } else {
          window.alert("Something went wrong. Please try again.");
        }
      });
    }
  }


  public onClickFetchDiscountCancel() {
    this.setState({ discountCodeText: "" });
    this.setState({ discountCode: null });
    localStorage.removeItem("coupon_promocode");
    localStorage.removeItem("coupon_discount");
  }

  getOrderDeliveryType = () => {
    let deliveryType = ""
    switch (this.state.paymentMethod) {
      case 1:
        deliveryType = "delivery";
        break;
      case 2:
        deliveryType = "shipping";
        break;
      case 3:
        deliveryType = "pickup";
        break;
      default:
        break;
    }

    return deliveryType
  }

  getMyCartRequest = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyCartApiCallId = getValidationsMsg.messageId;
    const {date,time} = this.state.deliverySlot
    let deliveryDateParam =  date && `date=${date.trim()}`
    let deliveryTimeParam = time && `slot=${time.trim()}`
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.MyCartAPIEndPoint + `?type=${this.getOrderDeliveryType()}&${[deliveryDateParam,deliveryTimeParam].filter(item => item).join("&")}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getMyDetails = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyDetailsApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.MyDetailAPIEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  getDiscount = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDiscountApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.DiscountAPIEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
};
 paymentPostMethod = (id?: any) => {
  this.setState({paymentFlag:true})
  this.setState({paymentStatus:'Payment in Progress', paymentIcon:CachedIcon })
    let body = {
      payment: {
        order_id: this.state.myCarts[0]?.order_id ?? 3,
      } as any,
    };
    if (this.state.paymentType === "card") {
      body.payment["payment_method_id"] = id ;
    }
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStripePaymentId = getValidationsMsg.messageId;
    let endPoints = `${API.PaymentAPIEndPoint}?query=${this.state.paymentType}`;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  // sendPaymentDetails = () => {
  //   const { cardname, cardnumber, expirydate, cardcvv } = this.state.values;
  //   const body = {
  //     payment_method: {
  //       // number:cardnumber,
  //       // exp_month:5,
  //       // exp_year:expirydate,
  //       //  cvc:cardcvv
  //       number: cardnumber,
  //       exp_month: expirydate.split("/")[0],
  //       name_on_card: cardname,
  //       exp_year: expirydate.split("/")[1],
  //       cvc: cardcvv,
  //     },
  //   };
  //   const header = {
  //     "Content-Type": API.ContentType,
  //     token: this.getToken(),
  //   };
  //   const getValidationsMsg = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.getPaymentId = getValidationsMsg.messageId;
  //   let endPoints = `${API.PostPaymentDetailsAPIEndPoint}`;
  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     endPoints
  //   );
  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );
  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(body)
  //   );
  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     API.POST
  //   );
  //   runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  // };
  
  getIncreaseApi = (catalogue_id: any, order_id: any, variant_id:number) => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIncreaseQuantityId = getValidationsMsg.messageId;

    let endPoints = `${API.IncreaseAPIEndPoint}?catalogue_id=${catalogue_id}&order_id=${order_id}&catalogue_variant_id=${variant_id}`;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getDecreaseApi = (catalogue_id: any, order_id: any,variant_id:number) => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDecreaseQuantityId = getValidationsMsg.messageId;
    let endPoints = `${API.DecreaseAPIEndPoint}?catalogue_id=${catalogue_id}&order_id=${order_id}&catalogue_variant_id=${variant_id}`;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleLocalStorageChange = (event: any) => {
    if (event.key === "LifetimeSubscription") {
      this.setState({ lifetimeSubscription: event.newValue });
    }
  };
handleCardNumberChange = (event: any) => {
  return "4242 4242 4242 4242"
};
handleCvv= () => {
return 123
}
handleExpiry = () => {
  return "11/25"
}
  changeInputValue=(e:any) =>{
    let formattedText 
    let text
    if(e.elementType==='cardnumber'){
      const cleanedText = e.target.value.replace(/\D/g, '');
      formattedText = cleanedText.replace(/(\d{4})(?=\d)/g, '$1 ');
      this.setState({
        values:{
          ...this.state.values,
          cardnumber:formattedText
        }
      })  

         
    }
      if(e.target?.name==='expirydate'){
         text = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
      if (text.length > 2) {
        text = `${text.slice(0, 2)}/${text.slice(2)}`;
     } 
     this.setState({values:{...this.state.values,expirydate:text}})
    }

    if(e.target?.name === "cardcvv"){

      this.setState({
        values:{
        ...this.state.values,
        cardcvv:e.target.value
      }});

    }

    if(e.target?.name === "cardtype"){

      this.setState({
        values:{
        ...this.state.values,
        cardtype:e.target.value
      }});
    }

    if(e.target?.name==='cardname'){

    this.setState({
      values:{
      ...this.state.values,
      cardname:e.target.value
    }});
  }  
 
  }

  handleCardDetails = () => {
    if(this.state.suggestions){
      this.setState({values:this.state.suggestions})
    }
  }
   
  handleSpanClick = () => {
    
    let cardData1 = this.state.saveCardDetails;
   
    this.setState({
      values:{
        ...this.state.values,
      }
    })
    
    const span1 = document.getElementById("sapn1");
    if (span1) {
      span1.style.visibility = "hidden";
    }
    setTimeout(() => {
    }, 3000);
  }

  handleSpan2 = () => {

    let cardData1 = this.state.suggestions;
    this.setState({
      values:{
        ...this.state.values,
         cardnumber:this.state.suggestions.cardnumber
      }
    })

    const span2 = document.getElementById("sapn2");
   if (span2) {
     span2.style.visibility = "hidden";
     }
  }

  handleSpan3 = () => {

    let cardData1 = this.state.saveCardDetails;

    this.setState({...this.state.values,
      values:{
        ...this.state.values,
      }
    })

    const span3 = document.getElementById("sapn3");
    if (span3) {
     span3.style.visibility = "hidden";
    }

  }

  private getToken = () => {
    return localStorage.getItem("authToken");
  };

  public convertPrice = (price: number) => {
    if (typeof price !== "number") {
      price = parseFloat(price);

      if (!Number.isFinite(price)) {
        price = 0;
      }
    }

    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  handleStepClick = (index: number) => {
    const { activeStep, disableBtn } = this.state;
    
    if ((index === activeStep + 1 && (activeStep !== 1 || !disableBtn)) || index <= activeStep) {
      this.handleStep(index);
    }
  };
  
  handleStep = (step: any) => {
    this.setState({ activeStep: step });
    if (step == 3 || step === 1) {
      this.getMyDetails();
    }
    if (step === 2) {
      this.setState({ meatStorageAmount: 0 })
    }
  };

  isStepSkipped = (step: number) => {
    return this.state.skipped.has(step);
  };

  handlefastDelivery = () => {
    this.setState({ fastDelivery: true });
    localStorage.setItem('fastDelivery', 'true');
  };

  handleRemoveFastDelivery = () => {
    this.setState({ fastDelivery: false });
    localStorage.setItem('fastDelivery', 'false');
  };

  handleLifeTimeSubs = () => {
   
    this.setState({LifeTimeSubscriptionFlag:true, checked:false})
    
  }

  handleRemoveLifeTimeSubs = () => {
    this.setState({lifetimeSubs: false})
  }

  handleLifetimeModal = () => {
    this.setState({LifeTimeSubscriptionFlag: true})
  }

  handleLifeTimeSubFlag = () =>{
     this.setState({lifetimeSubs: true,LifeTimeSubscriptionFlag:false})
  }
  
  handleLifeTimeSubFlagClose = () =>{
    this.setState({LifeTimeSubscriptionFlag:false})
  }

  handleNext = () => {
    const { skipped } = this.state;
    let newSkipped = skipped;
    if (this.state.activeStep === 1 && !(this.state.paymentMethod===3 || this.state.paymentMethod === 2)) {
      this.setState({ deliveryFlag: true });
      return;
    }
    if (this.isStepSkipped(this.state.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(this.state.activeStep);
    }
    this.setState((preState) => ({ activeStep: preState.activeStep + 1 }));
    this.setState({ skipped: newSkipped });
  };

  handleStorage = (value: any, index: number) => {
    const lifetime = localStorage.getItem("LifetimeSubscription")
    if(lifetime === "true") {
      this.setState({LifeTimeSubscriptionFlag: true, planIndex: index})
    this.setState({currentPlan: true})
    }
    if(value == "Remove"){ 
      this.setState({lifetimeSubs: false, LifeTimeSubscriptionFlag: false})
    }
    this.setState({meatBtnTitle: value})
  };

  handleFinalStage = () => {
    this.props.navigation.navigate("SendInvoice");
  };

  handleForm = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      invoiceForm: { ...this.state.invoiceForm, [name]: value },
    });
  };
  handleSubmit = () => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    const { to, from, message, subject } = this.state.invoiceForm;
    if (!(to.trim() && from.trim() && message.trim() && subject.trim())) {
      this.setState({
        formError: {
          ...this.state.formError,
          submitMsg: " all the fields Mandatory.",
        },
      });
    } else if (!(regex.test(to) && regex.test(from))) {
      this.setState({ formError: "Email format is incorrect." });
    } else {
      this.sendInvoice();
    }
  };

  handlePayment = () => {
    this.setState({ paymentType: "card" });
  };

  handlePopupClose=()=>{
    this.setState({emailFlag:false})
    this.props.navigation.navigate("CategoriesCategoriesWeb");
  }
  
  handlePopupPaymentClose=()=>{
    this.setState({paymentFlag:false});
    if (this.state.paymentStatus === 'Payment Done' || this.state.paymentStatus === 'We are glad to inform you that we have confirmed your order as COD')
    { 
      this.setState({sucessflag:true})
    }     
  }

  sendInvoice = () => {
    const { to, from, message, subject } = this.state.invoiceForm;
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };

    const body = {
      send_invoice: {
        from: from,
        to: to,
        subject: subject,
        message: message,
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSendInvoice = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      API.InvoiceAPIEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handlePaymentMethod = (state: number) => {
    this.setState({ paymentMethod: state }, () => {
      if (state === 1) {
        if (this.state?.addCheck === true) {
          this.setState({ disableBtn: false });
        } else {
          this.setState({ disableBtn: true });
        }
      } else if (state === 2) {
        if (this.state?.addCheck === true) {
          this.setState({ disableBtn: false });
        }
      } else {
        this.setState({ disableBtn: true });
      }
    });
  };

  // validateInput(name:any, value:any) {
  //   if (value.trim() === '') {
  //     this.setState((prevState) => ({
  //       errors: {
  //         ...prevState.errors,
  //         [name]: 'This field is required.',
  //       },
  //     }));
  //   } else {
  //     this.setState((prevState) => ({
  //       errors: {
  //         ...prevState.errors,
  //         [name]: undefined,
  //       },
  //     }));
  //   }
  // }

  handleOpen = () => {
    this.setState({ createAddressModal: true });
  };

  handleClose = () => {
    this.setState({ createAddressModal: false });
    this.getMyDetails();
  };

  private getHeaderMessage(params?: { token?: string; contentType?: string }) {
    const header = {
      "Content-Type": params ? params.contentType : API.ContentType,
      token: params?.token || this.getToken(),
    };

    return JSON.stringify(header);
  }

  validatePhoneNumber(phone: string) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(phone);
  }

  handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    this.setState({
      checkAddress: Number((event.target as HTMLInputElement).value),
    });
    this.setState({ addCheck: true})
    if(this.state?.paymentMethod == 2) {
      this.setState({disableBtn: false})
    }
    this.setState({ selectedAdress: data });
    localStorage.setItem("selectedShipping", JSON.stringify(data?.shipping_charge));
    localStorage.setItem("selected_address", JSON.stringify(data));
  };

  activeSlot = (slot: string) => {
    this.setState({ activeSlot: slot, slotCheck: true, disableBtn: false });
  };

  handleGoToStore = () => {
    const goToStore: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToStore.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriesCategoriesWeb");
    goToStore.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToStore);
  }

  handlePopupDeleteClose = () => {
    // this.setState({deleteItemFlag:true,deleteFlag:true}
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProdcutAPICall = getValidationsMsg.messageId;
    let endPoints = `${API.DeleteAPIEndPoint}?id=${this.state.orderID}`;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.DELETE
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  
  handlePopupDeleteNotClose = () => {
    this.setState({ deleteItemFlag: false, deleteFlag: false });
  };

  deleteProduct = (order_id: any) => {
    this.setState({ orderID: order_id, deleteFlag: true });
  };

onButtonClick = async () => {
  const originalStyles = new Map<HTMLElement, { border: string, outline: string, boxShadow: string }>();
  const removeStyles = () => {
      const elements = document.querySelectorAll('*');
      elements.forEach(el => {
          if (el instanceof HTMLElement) {
              const style = window.getComputedStyle(el);
              originalStyles.set(el, {
                  border: style.border,
                  outline: style.outline,
                  boxShadow: style.boxShadow
              });
              el.style.border = 'none';
              el.style.outline = 'none'; 
              el.style.boxShadow = 'none'; 
          }
      });
  };
  const restoreStyles = () => {
      originalStyles.forEach((styles, el) => {
          el.style.border = styles.border; 
          el.style.outline = styles.outline;
          el.style.boxShadow = styles.boxShadow; 
      });
  };
  removeStyles();

  const element = document.body;
  const { scrollWidth, scrollHeight, clientHeight } = document.documentElement;
  const canvas = await html2canvas(element, {
      width: scrollWidth * 0.7,
      height: scrollHeight
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;
  const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  const newWidth = imgWidth * ratio;
  const newHeight = imgHeight * ratio;
  const xOffset = (pdfWidth - newWidth) / 2;
  const yOffset = 0;

  pdf.addImage(imgData, 'PNG', xOffset, yOffset, newWidth, newHeight);
  pdf.save('invoice.pdf');
  restoreStyles();
};

  handlePopupDelClose = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
      deliveryFlag: false,
    });
    this.getMyCartRequest();
  };
  handlePopupDelDontClose = () => {
    this.setState({ deliveryFlag: false });
  };

  handleSuccessClose = () => {

    this.setState({ sucessflag: false });
    this.props.navigation.navigate("SendInvoice");
  };

  handleSuccessContinue = () => {
    this.setState({ sucessflag: false });
    this.props?.navigation?.navigate("SendInvoice");
  };

  handleInvoiceProduct=()=>{
      const header = {
          "Content-Type": API.ContentType,
          token: this.getToken(),
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMyInvoiceProductCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_invoicebilling/invoices`
      );
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          API.GET
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

   handleSaveCardChange = async () => {
    const stripePromise = loadStripe("pk_test_51PLJDUIQ0Ku0TrCz7biqLavkd0Kr3vOkcFoqbAEbuHNkmN2LMJWjZcx20O3JPIBy2GSLRZ1JPpE3GNUxQrQnBWhd00qHvMJfFb");
    const stripe: any = await stripePromise;
  
    if (!stripe) {
      console.error('Stripe failed to load.');
      return;
    }
    const elementsss = stripe.elements();
    const card = elementsss.create('card');
    card.on('change', function(event:any) {
      const displayError :any= document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    const form:any = document.getElementById('payment-form');
    form.addEventListener('submit', async function(event: { preventDefault: () => void; }) {
      event.preventDefault();
      
      const { token, error } = await stripe.createToken(card);
      if (error) {
        const errorElement:any = document.getElementById('card-errors');
        errorElement.textContent = error.message;
      } else {
        fetch('/save-card', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ stripeToken: token?.id })
        })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error(error);
        });
      }
    });
  };

  handleCardName = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        cardNameDetail: event.target.value
      }, () => setStorageData("cardName", this.state.cardNameDetail));
  }
handleSaveCheckbox= (event:React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.checked){
    this.hanleAPiStripe();
  }
  else{
    return null
  }
}
  hanleAPiStripe = async () => {
    const nameCardDetailsSave = await getStorageData("cardName")
    const header = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer pk_test_51PLJDUIQ0Ku0TrCz7biqLavkd0Kr3vOkcFoqbAEbuHNkmN2LMJWjZcx20O3JPIBy2GSLRZ1JPpE3GNUxQrQnBWhd00qHvMJfFb"
    };

    const urlencoded = new URLSearchParams();
    urlencoded.append("card[number]", "4242424242424242");
    urlencoded.append("card[exp_month]", "11");
    urlencoded.append("card[exp_year]", "2025");
    urlencoded.append("card[cvc]", "123");
    urlencoded.append("card[name]", nameCardDetailsSave);

    const requestOptions:any = {
      method: "POST",
      headers: header,
      body: urlencoded,
      redirect: "follow" 
    };

    fetch("https://api.stripe.com/v1/tokens", requestOptions)
    .then((response) => response.json())
    .then((result: any) => {
      this.saveCardDetsails(result.id);
    })
    .catch((error) => console.error(error));
  }
 
  saveCardDetsails = (cardId: string) => {
    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      "stripeToken": cardId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.saveCardDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_stripe_integration/payments/save'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.saveCardDetailsCallId;
  }

    showSavedCardDetails = () => {
      const header = {
        
        token: this.getToken(),
      };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showCardDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payments/retrive?customer=${this.state.customerId}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.showCardDetails;
    }
    

  handleSaveCardData = () => {
    if(!this.state.suggestions){
      alert("No Card selected")
      return
    } else {  
    this.setState({values : this.state.suggestions})
    }
  }
  handleGetDataLocalStorage = () => {
    let savedData :any;
    let quValue: any;
    savedData = localStorage.getItem("CardDetails") || '{}'; 
    quValue = JSON.parse(localStorage.getItem("quantityValue")|| '{}');

    const abc = savedData && JSON.parse(savedData)

    if(savedData){
      this.setState({suggestions:abc[0]})
      this.setState({quanValue:quValue})
    }

  }
  
  formatDate = (dateString:any) => {
    return moment(dateString).format('DD MMM YYYY');
  }

  getDiscountApi = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDiscountApiId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/discount'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getDelieveryHrsApi = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDelieveryApiId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/delivery_hrs'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getDelieveryFeesApi = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDelieveryFeesApiId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/delivery_fees'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getSubscriptionsApi = () => {
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubscriptionsApiId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_subscription/subscriptions'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.POST
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleMeatStorage=(plan_name:any)=>{
      const header = {
        "Content-Type": API.ContentType,
        token: this.getToken(),
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMyStorageApiCallId = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_storage/storages/add_storage?plan_name=${plan_name}`
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        API.GET
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getLifetimeSubscription = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
  
    const getLifetimeSubscriptionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLifetimeSubscriptionID = getLifetimeSubscriptionMsg.messageId;
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_subscriptions/subscriptions'
    );
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLifetimeSubscriptionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getLifetimeSubscriptionMsg.id, getLifetimeSubscriptionMsg);
  }
  //prefilled email
  handlePrefilledData=()=>{
    const header = {
      "Content-Type": API.ContentType,
      token: this.getToken(),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrefilledEmailCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_invoicebilling/invoices/get_mail_params'
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      API.GET
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

 handleBlur = () => {
    console.log("[blur]");
  };
handleFocus = () => {
    console.log("[focus]");
  };
   handleReady = () => {
    console.log("[ready]");
  };
 
handleIdForPayment=(id:any)=>{
  this.paymentPostMethod(id.paymentMethod.id)
}

handleSelectedProduct = (selectedProduct: any) => {
  let id = this.state.product.filter((item: any) => {
    if (selectedProduct === item.label) {
      return item;
    }
    return false;
  })
  const abc = id[0] || "";
  this.setState({ selectedProduct: abc.value })
  this.handleAddressForm('state', abc.value); 
}

handleAddressForm = (name:any, value:any) => {
    this.setState((prevState) => ({
      address: { ...prevState.address, [name]: value },  
  }));
 }

  formattedMerchantAddress: (attrs?: MerchantAddress) => string = (attrs) => {
    if (!attrs) return ""
    const { address_1, address_2, city, state, country, zipcode } = attrs;
    return `${address_1 ?? ""}, ${city.trim() ?? ""}, ${state ?? ""}, ${zipcode ?? ""}, ${country ?? ""}`;
  }


// this.validateInput(name, value);

callStateApi() {
  const headers = {
    "Content-Type": configJSON.apiContentType,
    "token": localStorage.getItem("authToken")
  };

  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiStateCallId = getValidationsMsg.messageId;

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_shopping_cart/orders/get_shipping_states"
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  return this.apiStateCallId
}
  getMerchantAddress() {
    const headers = {
      "Content-Type": API.ContentType,
      "token": localStorage.getItem("authToken")
    };

    const getMerchantAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMerchantAddressAPICallId = getMerchantAPIMessage.messageId;

    getMerchantAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_order_management/merchant_addresses"
    );

    getMerchantAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMerchantAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getMerchantAPIMessage.id, getMerchantAPIMessage);
  }

  getSlotsForDelivery = () => {

    const headers = {
      "Content-Type": API.ContentType,
      "token": localStorage.getItem("authToken")
    };

    const getSlotsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSlotsForDeliveryAPICallId = getSlotsMessage.messageId;

    getSlotsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shippingchargecalculator/delivery_days/show`
    );

    getSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getSlotsMessage.id, getSlotsMessage);

    return this.getSlotsForDeliveryAPICallId
  }
  setDeliverySlotDay = (date: string) =>{
    this.setState(prev => ({ deliverySlot: { ...prev.deliverySlot, date } }));
}
  setDeliverySlotTime = (time: string) =>{
    this.setState(prev => ({ deliverySlot: { ...prev.deliverySlot, time } }))
    if(this.state?.addCheck == true) {
      this.setState({disableBtn: false})
    }
  }
  
  handleAcceptTnc= (event:any)=>{
    this.setState({checked: event.target.checked})
  }
  handlePaymentBtn = () => {
    this.setState({paymentFlag : true})
  }
  handleBasic = () => {
    this.setState({currentPlan: false})
  }
  // Customizable Area End
}