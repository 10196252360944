export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img37bfe3ba5b0b09897c1a10d05dcfedf145306428 = require("../../global_assets/37bfe3ba5b0b09897c1a10d05dcfedf145306428.png");
export const img47676a188c9c093e814300187bf9a3c0eab5ac2a = require("../../global_assets/47676a188c9c093e814300187bf9a3c0eab5ac2a.png");
export const img1f85f39378b93e6e245b985520bb8ec31f68d616 = require("../../global_assets/1f85f39378b93e6e245b985520bb8ec31f68d616.png");
export const imga0f665489b1d95cc10f55b5401db6abed1e9af1a = require("../../global_assets/a0f665489b1d95cc10f55b5401db6abed1e9af1a.png");
export const img9fa969b0cfa058b02d81fde99817e58a2675f093 = require("../../global_assets/9fa969b0cfa058b02d81fde99817e58a2675f093.png");
export const imgad80547be96d74922ab5aa8639a730638ed0ab71 = require("../../global_assets/ad80547be96d74922ab5aa8639a730638ed0ab71.png");
export const img6b002af444f7f5313c52fd4288f06b16dfa7d20e = require("../../global_assets/6b002af444f7f5313c52fd4288f06b16dfa7d20e.png");
export const img1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8 = require("../../global_assets/1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8.png");
export const img2ffde658c4043470cd93f7dd6dfe73d5e276ace3 = require("../../global_assets/2ffde658c4043470cd93f7dd6dfe73d5e276ace3.png");
export const imga5b164dd49cd5b61e171b96b048e7833bbe9c5db = require("../../global_assets/a5b164dd49cd5b61e171b96b048e7833bbe9c5db.png");
export const img193beead28debffba7480a35f4950e1b3ba7aa10 = require("../../global_assets/193beead28debffba7480a35f4950e1b3ba7aa10.png");
export const imgbc278414de2b6a7de2663e5734faf5ec96f4cf78 = require("../../global_assets/bc278414de2b6a7de2663e5734faf5ec96f4cf78.png");
export const imgf8f9191318ceed32c794944aaa2879d26f8fc377 = require("../../global_assets/f8f9191318ceed32c794944aaa2879d26f8fc377.png");
