import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import InputLabel from '@material-ui/core/InputLabel';
import { withRouter } from 'react-router-dom';

import {
  Paper,
  Box,
  Button,
  IconButton,
  InputBase,
  Link,
  TextField,
  Typography,
  Avatar
} from "@material-ui/core";
import { setStorageData } from "../../../framework/src/Utilities";

const urlConfig = require("../../../framework/src/config");
const baseURL = urlConfig.baseURL;

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 700,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "40px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 5, 3),
      textAlign: "center",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        color: "#700010",
        marginTop: "15px",
        paddingBottom: "10px",
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
    },
    root: {
      width: "100%",
      margin: "auto",
      marginTop: "20px",
    },
    textfield: {
      border: "1px solid #B5D2F7",
      boxShadow: "none",
      borderRadius: "15px",
      margin: "8px auto",
      textAlign: "left",
      width: "26ch",
      padding: "8px 12px",
      "& input ": {
        fontSize: "12px",
        color: "#231F20",
        fontWeight: "bold",
        paddingLeft: "12px",
        "& ::placeholder": {
          opacity: "1",
        },
      },
      "& button ": {
        padding: "8px",
        "& svg ": {
          fontSize: "14px",
          color: "#a60018",
        },
      },
    },
    iconClose: {
      position: "absolute",
      top: "20px",
      right: "25px",
      backgroundColor: "#a6001915",
      "& svg ": {
        color: "#a60018",
        fontSize: "14px",
      },
    },
    iconButton: {
      backgroundColor: "#a6001915",
      borderRadius: "5px",
      padding: "8px !important",
      marginRight: "5px",
      "& svg ": {
        fontSize: "14px",
        color: "#a60018",
      },
    },
    checkedLabelIcon: {
      textAlign: "left",
      width: "90%",
      alignItems: "flex-start",
      margin: "10px 0",
      "& span ": {
        fontSize: "12px",
        padding: "2px 0",
        color: " #a60018",
        "& svg ": {
          height: "12px",
          width: "12px",
        },
      },
      "& .MuiFormControlLabel-label": {
        color: "#808080",
        padding: "5px 0 2px 12px",
      },
      "& .MuiIconButton-label": {
        border: "1px solid #efe1e1",
        borderRadius: "7px",
        padding: "5px",
      },
    },
    signup: {
      border: "1px solid #a9caf7b7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "#A9C9F7",
      fontWeight: "bold",
      backgroundColor: "rgb(224, 236, 253)",
      boxShadow: "0px 10px 20px #A9C9F77A !important",
      width: "100%",
      "&:hover": {
        backgroundColor: "#A9C9F7",
        color:"#fff"
      },
    },
    link: {
      width: "100%",
      textAlign: "center",
      fontSize: "12px",
      color: "#808080",
      marginTop: "15px",
      marginBottom: "20px",
      "& a ": {
        color: "#700010",
        fontWeight: "600",
        paddingLeft: "5px",
        textDecoration: "none",
      },
    },
    visibleIcon: {
      position: "absolute",
    },
    errorContent: {
      fontSize: "10px",
      color: "red",
      textAlign: "left",
      padding: "0 15px",
    },
    infoAlert: {
      position: "absolute",
      right: "15px",
      top: "15px",
    },
    inputBox: {
      display: "flex",
      justifyContent: "space-around",
    },
    textareaArea: {
      height: "206px",
      '& .MuiInput-input':{
        fontSize: "12px",
        color: "#231F20",
        fontWeight: "bold",
        paddingLeft: "12px",
      },
        "& ::placeholder": {
          opacity: "0.42",
        },

      '& .MuiInput-underline:before': {
        borderBottom: '0px solid'
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '0px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInput-underline:after' : {
      borderBottom: '0px solid',
    }
    },
    profileImage: {
      width: "150px",
      height: "150px",
      borderRadius: "10px",
      border: "1px solid #ccc",
      background: '#e5e6e8',
      color: '#afb4b8',
      margin: '0 auto',
      '& span': {
        '& img': {
          display: 'none',
        },
      }
    },
    inputLabel: {
      textAlign: "start",
      marginTop: "20px",
    },
    inputLabelButton: {
      marginTop: "30px",
      visibility: "hidden",
    },
    profileBox: {
      overflowY: "auto",
    },
    validationMessage:{
     color: 'red',
     fontSize: '12px',
     textAlign: 'left',
     margin: 0,
    }
  })
);

export const UserProfileBasicBlock = (props: any) => {
  const { open, isOpen, setOpen, getProfile, navigate,handleCloseProfile } = props
  const userId = localStorage.getItem("id");
  const userProfileDataImage= localStorage.getItem("userProfileData")
  const classes = useStyles() as any;
  const [profileDetail, setProfileDetail] = React.useState({
    full_name: "",
    email_address: localStorage.getItem('email')??"",
    phone_number: "",
    instagram_link: "",
    facebook_link: "",
    about_me: "",
    whatsapp_link: "",
    photo: ""
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target
    if (name === "email_address") {
      setEmailValid(isValidEmail(value));
    } else if (name === "phone_number"|| "") {
      setPhoneValid(isValidPhoneNumber(value));
    }else if (name === "instagram_link") {
      setInstaValid(validateInstagramLink(value));
    }else if (name === "facebook_link") {
      setFacebookValid(validateFacebookLink(value));
    }else if (name === "about_me") {
      setAboutValid(validateAbout(value));
    }else if (name === "whatsapp_link") {
      setWhatsAppValid(validateWhatsApp(value));
    }

    setProfileDetail({ ...profileDetail, [name]: value })
  }
  const [selectedFile, setSelectedFile] = React.useState<any>(null)
  const [showProfileImage, setShowProfileImage] = React.useState<any>(null)
  const [emailValid, setEmailValid] = React.useState(true);
  const [phoneValid, setPhoneValid] = React.useState(true);
  const [instaValid, setInstaValid] = React.useState(true);
  const [facebookValid, setFacebookValid] = React.useState(true);
  const [aboutValid, setAboutValid] = React.useState(true);
  const [whatsappValid, setWhatsAppValid] = React.useState(true);
  const retrievedObject = JSON.parse(localStorage.getItem("user_data") || "{}");
  
  const handleUploadClick = (event: any) => {
    var file = event.target.files[0];
    const reader = new FileReader() as any;
    var url = reader.readAsDataURL(file) as any;

    reader.onloadend = function (e: any) {
      setShowProfileImage(
        [reader?.result]
      );
    }
    setSelectedFile(
      event.target.files[0]
    );
  };

  const isValidEmail = (email:any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const isValidPhoneNumber = (phoneNumber:any) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const validateInstagramLink = (instagram_link:any) => {
    return  instagram_link.trim() !== '';
  };
  const validateFacebookLink = (facebook_link:any) => {
    return  facebook_link.trim() !== '';
  };
  const validateAbout = (about_me:any) => {
    return about_me.trim() !== '';
  };
  const validateWhatsApp = (whatsapp_link:any) => {
    return whatsapp_link.trim() !== '';
  };

  // const handleClose = (_:any, reason:any) => {
  //   debugger
  //   if (reason !== 'backdropClick') {
  //     props.isOpen({profile:false})
  //   }
  // }

  const handleSubmit = async (e: any) => {
  //  handleCloseProfile();
    e.preventDefault()
    props.setOpen({ profile: false });
    await setStorageData("userProfile", showProfileImage)
    if(!phoneValid || !emailValid || !instaValid || !facebookValid || !whatsappValid || !aboutValid){
      return
    }
    let token = localStorage.getItem("authToken") ?? ""
    let url=`${baseURL}/bx_block_profile/profiles`
    if(!!userId){
      url+=`/${userId}`
    }
    let formData = new FormData();
    if(!!selectedFile){
      formData.append("images", selectedFile);
    }
    formData.append("full_name", profileDetail.full_name);
    formData.append("about_me", profileDetail.about_me);
    formData.append("whatsapp_link", profileDetail.whatsapp_link);
    formData.append("instagram_link", profileDetail.instagram_link);
    formData.append("email_address", profileDetail.email_address);
    formData.append("facebook_link", profileDetail.facebook_link);
    formData.append("phone_number", profileDetail.phone_number);
    localStorage.setItem("facebook",profileDetail.facebook_link);
    localStorage.setItem("insta",profileDetail.instagram_link);
    localStorage.setItem("whatsapp",profileDetail.whatsapp_link);
    localStorage.setItem("user_data",JSON.stringify(profileDetail));
    let methodAPI= !!userId? "PATCH" : "POST"
    try {
      const response = await fetch(url, {
        method: methodAPI,
        headers: {
          token
        },
        body: formData
      }) as any
      response.json().then((i: any) => {
        if (i.data) {
          localStorage.setItem('id', i.data.id)
          localStorage.setItem("photo",(i.data.attributes.images[0]?.url))
          props.history.push({pathname:'/profile',state:{url:i.data.attributes.images[0].url??""}})

         props?.getProfile&& props.getProfile(i.data.id)
        }
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  

  React.useEffect(() => {
    let profileInfo=localStorage.getItem('user_data') as any
    if(profileInfo){
      profileInfo=JSON.parse(localStorage.getItem('user_data') as any) 
    }
    if (profileInfo) {
      setProfileDetail({
        ...profileDetail,
        full_name: profileInfo.full_name ?? "",
        email_address: profileInfo.email_address ?? "",
        phone_number: profileInfo.phone_number ?? "",
        instagram_link: profileInfo.instagram_link ?? "",
        facebook_link: profileInfo.facebook_link ?? "",
        about_me: profileInfo.about_me ?? "",
        whatsapp_link: profileInfo.whatsapp_link ?? "",
      })
      setShowProfileImage(localStorage.getItem('photo'))
    }
  }, [open])

  const body = (
    <div className={classes.profileBox}>
      <div className={classes.paper}>
        <Box>
          <input
            accept="image/*"
            data-test-id="loadEnd"
            id="contained-button-file"
            multiple
            style={{ display: "none" }}
            type="file"
            onChange={handleUploadClick}
          />
          <label htmlFor="contained-button-file">
            <Avatar src={showProfileImage} 
             className={classes.profileImage} />
          </label>
        </Box>
        <form className={classes.root} autoComplete="off" data-testid="handleClick" id="clickedId" onSubmit={(e:any)=>handleSubmit(e)}>
          <Box className={classes.inputBox}>
            <Box>
              <InputLabel className={classes.inputLabel}>Enter Full Name</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>
                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Full Name*"
                  name="full_name"
                  id="emailid"
                  required
                  value={profileDetail.full_name}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              <InputLabel className={classes.inputLabel}>Email Address</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>

                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Email Address"
                  value={localStorage.getItem("user_email")??retrievedObject.email_address}
                  name="email_address"
                  id="emailid2"
                  disabled
                  onChange={(e: any) => handleChange(e)}
                  readOnly
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              {!emailValid && (
                 <p className={classes.validationMessage}>Please enter a valid email address.</p>
                )}
              <InputLabel className={classes.inputLabel}>Phone Number</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>
                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Number"
                  name="phone_number"
                  id="emailid3"
                  required
                  value={profileDetail.phone_number}
                  onChange={(e: any) => handleChange(e)}
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              {!phoneValid && (
                  <p className={classes.validationMessage}>Please enter a valid phone number.</p>
                )}
              <InputLabel className={classes.inputLabel}>Instagram Link</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>

                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter link"
                  name="instagram_link"
                  id="emailid4"
                  value={profileDetail.instagram_link}
                  onChange={(e: any) => handleChange(e)}
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              {!instaValid && (
                  <p className={classes.validationMessage}>Please enter a valid instagram id.</p>
                )}
              <InputLabel className={classes.inputLabel}>Facebook Link</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>
                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Link"
                  name="facebook_link"
                  id="emailid5"
                  value={profileDetail.facebook_link}
                  onChange={(e: any) => handleChange(e)}
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              {!facebookValid && (
                  <p className={classes.validationMessage}>Please enter facebook id.</p>
                )}
            </Box>
            <Box>
              <InputLabel className={classes.inputLabel}>About Me</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>

                <TextField
                  multiline
                  placeholder="Enter About "
                  name="about_me"
                  maxRows={4}
                  id="emailid6"
                  className={classes.textareaArea}
                  value={profileDetail.about_me}
                  onChange={(e: any) => handleChange(e)}
                />
              </Paper>
              {!aboutValid && (
                  <p className={classes.validationMessage}>Please enter about me.</p>
                )}
              <InputLabel className={classes.inputLabel}>WhatsApp Links</InputLabel>
              <Paper component={"div" as any} className={classes.textfield}>
                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Link"
                  name="whatsapp_link"
                  id="emailid7"
                  value={profileDetail.whatsapp_link}
                  onChange={(e: any) => handleChange(e)}
                  inputProps={{ "aria-label": "email id" }}
                />
              </Paper>
              {!whatsappValid && (
                  <p className={classes.validationMessage}>Please enter whatsapp link.</p>
                )}
              <InputLabel className={classes.inputLabelButton}>button</InputLabel>

              <Button
                variant="contained"
                // color="secondary"
                type="submit"
                className={classes.signup}
              >
                {(userId) ? "Edit Details" : "Save Details"}
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Modal
          id="someButton"
          open={open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.profileBox}
          onClose={(_, reason) => { 
            props.setOpen({profile:false})
  }}
        >
          {body}
        </Modal>
      </div>
    </>
  );
};
//@ts-ignore
export const UserProfileBasicBlockWeb:any = withRouter(UserProfileBasicBlock);
