import React from "react";
import {
    Box,
    Button,
    createStyles,
    IconButton,
    makeStyles,
    Modal,
    Typography,
    FormControlLabel, Checkbox, styled
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
const useStyles = makeStyles(theme =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: "40px",
            padding: theme.spacing(3, 3, 3),
            textAlign: "center",
            width: '450px',
            "& h1": {
                fontSize: "30px",
                color: "#231F20",
                marginBottom: "5px",
                paddingBottom: "10px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif"
            }
        },
        dialogContent: {
            "& .MuiDialog-paper": {
                borderRadius: "40px",
            },
            "& .MuiDialog-paperScrollPaper": {
                maxHeight: 'auto',
            }
        },
        root: {
            width: "450px",
            margin: "auto"
        },
        success: {
            // width: "300px",
            margin: "auto",
            overflowY: "hidden"
        },
        successIcon: {
            fontSize: "50px",
            color: "#A9C9F7",
            marginBottom: "25px"
        },
        successContent: {
            fontSize: "26px",
            fontWeight: 700,
            color: "#231F20"
        },
        copycodeicon: {
            width: "15px"
        },

        successbtn: {
            border: "1px solid #a9caf7b7",
            borderRadius: "30px",
            padding: " 10px 55px",
            textDecoration: "none",
            textTransform: "capitalize",
            color: "#A9C9F7",
            margin: "20px auto 20px",
            fontWeight: "bold",
            backgroundColor: "rgb(224, 236, 253)",
            boxShadow: "0px 10px 20px #A9C9F77A !important",
            "&:hover": {
                backgroundColor: "#A9C9F7",
                color: "#fff"
            }
        },
        iconClose: {
            position: "relative",
            top: "10px",
            left: "46%",
            backgroundColor: "rgb(224, 236, 253)",
            "& svg ": {
                color: "#231F20",
                fontSize: "14px"
            }
        },
        congratesContent: {
            color: "#808080",
            fontSize: "14px",
            width: "70%",
            margin: "auto",
            padding: "5px 0 5px"
        },
        celebrateicon: {
            width: "60px",
            height: "50px"
        },
        clickcode: {
            cursor: "pointer"
        },
        celebratecontent: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px"
        },
        continue: {
            border: "1px solid #a60018",
            borderRadius: "30px",
            padding: " 10px 55px",
            textDecoration: "none",
            textTransform: "capitalize",
            color: "#fff",
            margin: "20px auto 20px",
            fontWeight: "bold",
            backgroundColor: "#a60018",
            boxShadow: "0px 10px 20px #a6001942 !important",
            width: "60%",
            "&:hover": {
                backgroundColor: "#a60018"
            }
        },
        link: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            color: "#808080",
            marginTop: "15px",
            marginBottom: "0",
            "& a ": {
                color: "#700010",
                fontWeight: "600",
                padding: "0 5px",
                textDecoration: "underline",
                textTransform: "uppercase"
            },
            "& span": {
                color: "#700010",
                "& svg": {
                    height: "18px"
                }
            }
        },
        descriptionBox: {
            maxHeight: "150px",
            overflowY: "scroll"
        }
    })
);

const CustomModalWeb = (props: any) => {

    const classes = useStyles() as any;
    return (
        <Dialog className={classes.dialogContent} onClose={props.handlePaymentBtn ? "" : props.handleClose} open={props.isModalOpen}>
            <div className={classes.paper}>
                <Box>
                    <IconButton
                        onClick={props.handleClose}
                        className={classes.iconClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <div>
                        <div>
                            {props.statusImage && <props.statusImage className={`${classes.successIcon}`} />}
                        </div>
                        <div>
                            <Typography variant="h1" className={classes.successContent}>
                                {props.title}
                            </Typography>
                        </div>
                    </div>
                </Box>
                <form className={classes.success} noValidate autoComplete="off">

                    <div className={classes.descriptionBox}>
                        <Typography>
                            {props.description}
                        </Typography>
                        {props.showCheckbox && <FormControlLabel
                            control={<CustomCheckbox checked={props.checked} onChange={props.handleAcceptTnc}
                            />}
                            label="I Accept the terms and conditions"
                        />}
                    </div>

                    <Button
                        variant="contained"
                        className={classes.successbtn}
                        onClick={props.handleButton}
                        disabled={props.showCheckbox && !props.checked}
                    >
                        {props.btnTitle}
                    </Button>
                </form>
            </div>
        </Dialog>
    );
};
const CustomCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
        color: "#A9C9F7"
    },
})

export default CustomModalWeb