//@ts-nocheck
import React from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import './style.css'
import MyCartController from '../MyCartController'

interface Props {
  stripe: any;
  elements:any;
  navigation: any;
  id: string;
}
class PaymentForm extends MyCartController {
  constructor(props: Props) {
    super(props)

  }

  handleBlur = () => {
    console.log("[blur]");
  };

  handleFocus = () => {
    console.log("[focus]");
  };
  handleReady = () => {
    console.log("[ready]");
  };
  handleFieldChange = () => {
    // console.log(e,"");
   }

  createOptions = (fontSize?: any, padding?: any) => {
    return {
      style: {
        base: {
          fontSize: "14px",
          color: "#611a20",
          letterSpacing: "0.025em",

          "::placeholder": {
            opacity:'revert',
            color: "lightgray",
            fontSize: "14px",
            fontWeight:400
            
          },
          padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };


  render() {
    return (
      <>
        <div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
          </div>
          <div
            className="common-form"
            style={{ maxWidth: "597px", margin: "0 auto" }}
          >
            <form className="stripe-input-tag" id="payment-form" onSubmit={() => { }}>
              <div style={{ display: 'flex', gap: 20 }}>
                <div style={{ width: '100%' }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "normal",
                      paddingBottom: 10,
                      display: 'inline-block',
                      color: "#231F20",
                      marginTop: "17px",
                    }}
                  >
                    Name on Card
                  </label>
                  <input
                    className="input-box-popup"
                    type="text"
                    name="cardNameText"
                    value={this.state.cardNameDetail}
                    placeholder="tongbang"
                    onChange={
                      this.handleCardName
                    }
                  />
                </div>

                <div style={{ width: '100%' }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "normal",
                      paddingBottom: 10,
                      display: 'inline-block',
                      color: "#231F20",
                      marginTop: "17px"
                    }}
                  >
                    Card Number
                  </label>
                  <CardNumberElement
                    className="input-box-popup"
                    value="4242 4242 4242 4242"
                    onChange={(e)=>this.handleCardNumberChange(e)}
                    options={{
                      placeholder: "4242 4242 4242 4242",
                    }}
                    id="card-number-element"
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", gap: "20px", width: '100%' }}>
                  <div style={{ flex: 1, width: '100%' }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "normal",
                        paddingBottom: 10,
                        display: 'inline-block',
                        color: "#231F20",
                      }}
                    >
                      CVV
                    </label>
                    <CardCvcElement
                        className="input-box-popup"
                        value={123}
                        onChange={(event) =>
                          this.handleFieldChange("cvv", event.error ? "" : "filled")
                        }
                        options={{
                          placeholder: "123",
                        }}
                        id="card-cvc-element"
                      />
                  </div>

                  <div style={{ flex: 1, width: '100%' }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "normal",
                        paddingBottom: 10,
                        display: 'inline-block',
                        color: "#231F20",
                      }}
                    >
                      Expiry Date
                    </label>
                    <CardExpiryElement
                      className="input-box-popup"
                      onBlur={this.handleBlur}
                      onChange={this.handleExpiry}
                      onFocus={this.handleFocus}
                      onReady={this.handleReady}
                      value={"11/25"}
                      id="card-expiry-element"
                      // options={this.createOptions()}
                    />
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "normal",
                      paddingBottom: 10,
                      display: 'inline-block',
                      color: "#231F20",
                    }}
                  >
                    Card Type
                  </label>
                  <input
                    className="input-box-popup"
                    type="text"
                    src=""
                    alt=""
                    name="cardtype"
                    value={this.state.values.cardtype}
                    placeholder="visa"
                    onChange={(event) =>
                      this.changeInputValue(event)
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Success Modal */}
      </>
    );
  }
}
export default PaymentForm;

const webStyle: any = {
  root: {
    backgroundColor: '#f8f4f3',
    minheight: '100%',
  },
  rootContainer: {
    padding: '25px 50px',
    // maxWidth: '1320px',
    margin: '0 auto',
  },
  btnStyle: {
    backgroundColor: "#a0282a",
    color: "#FFF",
    fontWeight: 600,
    borderRadius: 30,
    width: 164,
    padding: '11px 8px',
    textTransform: "capitalize",
  },
  cardStorageFont: {
    fontSize: '20px',
  },
  cardHeading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  LeftbtnStyle: {
    backgroundColor: "#F8F4F3",
    color: "rgb(160, 40, 42)",
    borderRadius: "32px",
    textTransform: "capitalize",
    border: '1px solid rgb(160, 40, 42)',
    padding: '0px 24px',
    margin: '8px',

  },
  creditCard: {
    backgroundColor: "white",
    width: "100%",
    // height: "50%",
    textAlign: "left",
    borderRadius: '30px'
  },
  creditCard1: {
    backgroundColor: "white",
    width: "100%",
    // height: "50%",
    textAlign: "left",
    borderRadius: '30px',
    marginTop: '1rem',
  },
  profileContainer: {
    // padding: '3rem',
    backgroundColor: 'white',
    borderRadius: '27px',
    margin: 'auto',
    marginTop: '2rem',
    width: '50%',
  },
  orderCart: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: '27px',
    // width: '40%',
    // alignItems: 'center'
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: '30px'
  },
  cardStorageHeading: {
    fontWeight: 700,
    color: "#611a20",
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '20px',
    width: '20%',
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  increment: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#611a2014',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  amountRate: {
    fontWeight: 900,
    color: '#611a20'
  },
  deleteIcon: {
    fill: "gray",
    width: '18px'
  },
  card: {
    marginTop: '2px',
    marginBottom: '2px',
    borderRadius: '30px',
    paddingInline: '15px'
  },
  cardStorage: {
    marginTop: '2px',
    marginBottom: '15px',
    borderRadius: '30px',
    paddingInline: '15px',
    // minHeight:'223px',
    padding: '2rem',
    "&:hover": {
      backgroundColor: '#a13529',
      color: 'white'
    }
  },
  Leftcard: {
    margin: '1rem auto',
    borderRadius: '30px',
    paddingInline: '15px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardInput: {
    color: '#611a20',
    background: 'inherit',
    border: 'none',
    marginLeft: '4px'
  },
  cardInputDiv: {
    display: 'flex',
    background: '#FBEFEF',
    color: '#611a20',
    borderRadius: '15px',
    marginBottom: '1rem',
    padding: '1rem',
    overflow: 'hidden',
    marginTop: '5px'
  },
  cardMainDiv: {
    display: 'flex',
    background: '#FBEFEF',
    color: '#611a20',
    borderRadius: '15px',
    border: '1px solid #611a20',
    marginBottom: '1rem',
    padding: '1rem',
    overflow: 'hidden',
  },
  CardMainDiv_span: {
    margin: 'auto',
    color: "rgb(97, 26, 32)",
    fontSize: "12px",
    marginBottom: '20px',
    paddingLeft: '15px',
    background: '#ee80891a',
  },
}