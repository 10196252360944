import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface HomePageResponse {
  id: string;
  type: string;
  attributes: HomePageAttributes;
}

interface HomePageAttributes {
  title: string;
  description: string;
  images: Image[];
}

interface Image {
  id: number;
  filename: string;
  url: string;
  type: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  exploreAlert: boolean;
  homePageData: HomePageResponse | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      exploreAlert: false,
      homePageData: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (
        apiRequestCallId &&
        this.getHomeDataAPICallId &&
        apiRequestCallId === this.getHomeDataAPICallId
      ) {
        if (responseJson && responseJson.data) {
          this.setState({ homePageData: responseJson.data[0] });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getHomeDataAPICallId: unknown = "";
  async componentDidMount() {
    super.componentDidMount();
    this.getHomePageDetails();
  }

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ exploreAlert: false });
  };
  handleClick = () => {
    window.location.href.split("/").reverse()[0] === "merchanthome"
      ? this.props.navigation.navigate("CategoriesCategoriesMerchantWeb")
      : this.props.navigation.navigate("CategoriesCategoriesWeb");
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getHomePageDetails = () => {
    const { homePageData } = this.state;
    if (homePageData && homePageData.id) {
      return;
    }
    const headers = {
      "Content-Type": "application/json"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeDataAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/home_pages"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  };
  // Customizable Area End
}
