import React from "react";
import {
  Avatar,
  Typography,
  Button,
  CardHeader,
  Card,
  Grid,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Modal,
  createStyles,
  IconButton,
  makeStyles,
  Box,
  Input,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  withStyles,styled
} from "@material-ui/core";
import PaymentForm from './stripe/paymentForm.web'
import MyCartController, { IMyCartForm, Props, ShowCards } from "./MyCartController";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CustomInlineButton from "../../../components/src/CustomInlineButton.web";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";
import LockOutlined from "@material-ui/icons/LockOutlined";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CustomOutlinedButton from "../../../components/src/CustomOutlinedButton.web";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Close from "@material-ui/icons/Close";
import { RadioProps } from '@material-ui/core/Radio';
import { pink } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import { CustomSelect } from "../../../components/src/CustomSelect";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Delivery, Shipping } from "./assets";
const StyleRadio = withStyles({
  root: {
    color: '#a9caf7b7',
    '&$checked': {
      color: "#a9caf7b7",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio {...props} />);
import {

  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
export default class MyCart extends MyCartController {
  // stripe?: any;
  // elements?: any;
  constructor(props: Props) {
    super(props);
  }

  handleSubmits = async (event: any) => {
    // event.preventDefault();
    const { stripe, elements } = this.props as any;
    if (this.state.paymentType === "cod") {
      return this.paymentPostMethod()
    }
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      "billing_details": {
        "address": {
          "city": "LA",
          "country": "US",
          "line1": "usa",
          "line2": "usa",
          "postal_code": 10022,
          "state": "new york"
        },
        "name": this.state.cardNameDetail
      }
    });
    if (result) {
      this.setState({ disabled: false })
      this.paymentPostMethod(result?.paymentMethod?.id)
    }
  };

  render() {
    const steps = ["My Cart", "Personal Details", "Summary", "Payments"];
    const shippingString = globalThis.localStorage.getItem('selectedShipping');
    const shipping = shippingString ? JSON.parse(shippingString) : 0;
    const userData = JSON.parse(globalThis.localStorage.getItem('selected_address') || '{}')
    const now = moment();
    now.add(3, 'days');
    const formatted = now.format('Do MMM, dddd');
    const adrs = this.state?.address;
    const inputs = [
      ["name", "Name", adrs.name],
      ["address_type", "Address Type", adrs.address_type],
      ["flat_no", "Flat No", adrs.flat_no],
      ["address", "Address", adrs.address],
      ["zip_code", "Zip Code", adrs.zip_code],
      ["phone_number", "Phone Number (123) 456-7890", adrs.phone_number],
      ["state", "State", adrs.state],
      ["country", "Country", adrs.country],
    ];
    const { selectedAdress } = this.state;
    const { cardnumber, expirydate, cardcvv } = this.state.values;
    const isDisabled = this.state.paymentType === 'card' ? this.state.disabled : false;
    let emptyModalOpen = false;
    if (this.state.myCarts.length === 0 && !this.state.isLoading) {
      emptyModalOpen = true;
    }
    

    return (
      // Required for all blocks
      <>
        <CustomModalWeb data-test-id="emptyCart" handleButton={this.handleGoToStore} handleClose={this.state.myCarts.length > 0} isModalOpen={emptyModalOpen} btnTitle={'Go To Store'} statusImage={ShoppingCartIcon} title={'Your Cart is empty'} description={"Your cart has no items please click the below button and proceed to Store to add items in your cart"} />
        <CustomModalWeb handleButton={this.handlePopupDelClose} handleClose={this.handlePopupDelDontClose} isModalOpen={this.state.deliveryFlag} btnTitle={'Continue'} title={this.state.paymentMethod == 1 ? 'Delivery Fees' : 'Shipping Charge'} description={`You'll be charge $${shipping} for product delivery`} />
        <CustomModalWeb handleButton={this.handlePopupDeleteClose} handleClose={this.handlePopupDeleteNotClose} isModalOpen={this.state.deleteFlag} btnTitle={'Continue'} statusImage={DeleteIcon} title={'Delete Product'} description={""} />
        <CustomModalWeb handlePaymentBtn={true} handleClose={this.handlePopupPaymentClose} handleButton={this.handlePopupPaymentClose} isModalOpen={this.state.paymentFlag} btnTitle={'Continue'} statusImage={this.state.paymentIcon} title={this.state.paymentStatus} description={this.state.description} />
        <CustomModalWeb handleButton={this.handleSuccessContinue} handleClose={this.handleSuccessClose} isModalOpen={this.state.sucessflag} btnTitle={'Continue'} statusImage={CheckCircleOutlineIcon} title={'Thank you for your order'} description={`your order number is ${this.state.myCarts[0]?.order_number}`} />
        <TopNavLoginWeb />
        <div style={webStyle.root}>
          <div style={webStyle.rootContainer}>
            <StepperBlock
              style={{ backgroundColor: "#F5F2ED" }}
              alternativeLabel
              activeStep={this.state?.activeStep}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepBlocked
                    data-testid={`steps${index}`}
                    onClick={() => this.handleStepClick(index)}
                    style={webStyle.stepBlock}
                  >
                    {label}
                  </StepBlocked>
                </Step>
              ))}
            </StepperBlock>
            {this.state?.activeStep === 0 && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    padding: "20px 35px",
                    color: "#231F20",
                    fontWeight: 600
                  }}
                >
                  My Cart
                </Typography>
                <div style={{ display: "flex", gap: "15px", width: "100%" }}>
                  <div style={{ width: "75%" }}>
                    <Card style={webStyle.creditCard}>
                      <Typography
                        style={{
                          color: "gray",
                          margin: "10px 0px 10px 0px",
                          letterSpacing: "1px",
                          paddingLeft: "20px",
                          fontWeight: 700,
                          paddingTop: '2rem',
                        }}
                      >
                        ADDED ITEMS(
                        {this.state?.myCarts.length < 10
                          ? "0" + this.state?.myCarts.length
                          : this.state?.myCarts.length}
                        )
                      </Typography>
                      {this.state.isLoading ? <><style>
                        {`
                          .MuiCircularProgress-svg {
                            color: #A9C9F7 !important;
                          }
                        `}
                      </style><CircularProgress color="inherit" style={{ display: "flex", justifyContent: "center", width: "100%" }} /></> : this.state?.myCarts.map((mycart: { [key: string]: any }, index: number) =>
                      (
                        <CardContent key={index} style={webStyle.cardContent}>
                          <CardHeader
                            style={{ paddingBottom: "0px", minWidth: '20%' }}
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                style={webStyle.avatar}
                              >
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={mycart.productImage ? mycart.productImage : "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg"}
                                />
                              </Avatar>
                            }
                            title={
                              <Typography style={webStyle.cardHeading}>
                                {mycart.productName}
                              </Typography>
                            }
                            subheader={
                              <div style={{ width: "250px" }}>
                                <Typography>{mycart.variant || mycart.productName}</Typography>
                                <Typography>{"count x" + " " + mycart.quantity}</Typography>
                              </div>

                            }
                          />
                          <div style={webStyle.showFre}>
                            <div style={webStyle.count}>
                              {mycart.quantity > 1 ? <button style={webStyle.increment} data-testid="decreaseApi" disabled={mycart.quantity > 1 ? false : true} onClick={() => this.getDecreaseApi(mycart.catalogue_id, mycart.order_id, mycart.variant_id)}>-</button>
                                :
                                <button style={webStyle.increment1}></button>
                              }
                              <span>{mycart.quantity}</span>
                              <span style={webStyle.increment} data-testid="IncreaseApi" onClick={() => this.getIncreaseApi(mycart.catalogue_id, mycart.order_id, mycart.variant_id)}>+</span>
                            </div>
                            {mycart.frequency && <Typography>Frequency: {mycart.frequency} </Typography>}
                          </div>
                          <div style={{ minWidth: "150px", textAlign: 'right' }}>
                            {this.convertPrice(mycart.price)} *{" "}
                            {mycart.quantity}{mycart.frequency && "(S)"}
                          </div>
                          <div style={webStyle.amount}>
                            <span style={webStyle.amountRate}>
                              {this.convertPrice(
                                mycart.price * mycart.quantity
                              )}
                            </span>
                            <span>
                              <DeleteOutlineIcon
                                style={webStyle.deleteIcon}
                                onClick={() => this.deleteProduct(mycart?.o_id)}
                                data-testid={`deleteProductButton${index}`}
                              />
                            </span>
                          </div>
                        </CardContent>
                      ))}
                    </Card>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div style={webStyle.cardMainDiv}>
                      <CustomInput
                        data-testid="discountInput"
                        style={webStyle.cardInput}
                        type="text"
                        name=""
                        placeholder="Enter Discount Code"
                        onChange={(e) => this.handleDiscount(e)}
                        value={this.state.discountCodeText}
                      />
                      <button
                        style={{
                          margin: "0px 5px",
                          color: "#231F20",
                          fontSize: "12px",
                          cursor: "pointer",
                          border: 'none'
                        }}
                        disabled={!this.state?.discountCodeText}
                        onClick={this.onClickFetchDiscount.bind(this)}
                      >
                        Fetch Directly
                      </button>

                    </div>
                    <Typography style={{ color: "red", fontSize: "11px", paddingLeft: "20px" }}>
                      {this.state?.discountErrorMessage ? this.state?.discountErrorMessage : null}
                    </Typography>
                    <Card style={webStyle.card}>
                      <Typography
                        style={{
                          color: "gray",
                          margin: "10px 0px 10px 2px",
                          letterSpacing: "1px",
                          padding: "10px",
                          fontWeight: 700,
                          borderBottom: "1px solid #d3d3d34a"
                        }}
                      >
                        PAYMENT DETAILS
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px"
                        }}
                      >
                        <div>Subtotal</div>
                        <div style={{ fontWeight: 900 }}>
                          {this.convertPrice(this.state?.subTotal)}
                        </div>
                      </div>
                      {this.state?.discountCode.discount ?
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px"
                          }}
                        >
                          <div>Discount</div>
                          <div style={{ fontWeight: 900 }}>
                            - ${Math.abs(this.state?.discountCode ? this.state?.discountCode?.discount : 0).toFixed(2)}
                            ({this.state?.discountCode?.discount ? Math.abs(this.state?.subTotal / this.state?.discountCode?.discount).toFixed(2) : 0}%)
                          </div>
                        </div> : ""
                      }
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px",
                          paddingBottom: "20px"
                        }}
                      >
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "15px",
                          borderTop: "1px solid #d3d3d34a"
                        }}
                      >
                        <div>Total</div>
                        <div style={{ fontWeight: 900 }}>
                          {this.convertPrice(
                            this.state?.subTotal
                            - (this.state?.discountCode && typeof (this.state?.discountCode.discount) === "number"
                              ? Math.abs(this.state?.discountCode.discount) : 0)
                          )}
                        </div>
                      </div>
                    </Card>
                    <CustomInlineButton
                      handleChange={this.handleNext}
                      title={"Continue to Personal Details"}
                      styleWidth={"100%"}
                      stylePadding={"10px"}
                    />
                  </div>
                </div>
              </>
            )}
            {this.state?.activeStep === 1 && (
              <>
                <div>
                  <Modal
                    open={this.state?.createAddressModal}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <CustomModalBox style={webStyle.paper}>
                      <Box>
                        <Typography variant="h4" style={webStyle.hStyle}>
                          Create Address
                        </Typography>
                        <IconButton
                          onClick={this.handleClose}
                          style={webStyle.iconClose}
                          aria-label="close"
                          data-testid="closeCreateAddress"
                        >
                          <Close style={webStyle.closeSvgStyle} />
                        </IconButton>
                      </Box>
                      {inputs.map((input, i) => (
                        <div key={input[0]}>
                          <Box sx={webStyle.textfield}>
                            {i == 6 ? (
                              <FormControlWeb variant="outlined" style={webStyle.formControl}>
                                <CustomSelect
                                  label="example"
                                  hideLabel
                                  options={this.state.product as any}
                                  onChange={this.handleSelectedProduct}
                                  value={this.state?.selectedProduct}
                                />
                              </FormControlWeb>
                            ) : (
                              <Input
                                name={input[0]}
                                placeholder={input[1]}
                                inputProps={{
                                  inputMode: i === 4 ? 'numeric' : 'text',
                                  pattern: i === 4 ? "[0-9]*" : undefined,
                                }}
                                type={i === 4 ? "number" : "text"}
                                data-testid='address'
                                fullWidth={true}
                                value={this.state.address[input[3]]}
                                onChange={e => this.handleAddressForm(input[0], e.target.value)}
                                onKeyDown={(e) => {
                                  // A workaround for zip_code to contain only numerical values and a max length of 6
                                  const isDigit = /^\d+$/.test(e.key);
                                  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"];
                                  const input = (e.target as HTMLInputElement).value;
                                  if (i === 4) {
                                    if (!isDigit && !allowedKeys.includes(e.key)) {
                                      e.preventDefault();
                                    }
                                    if (input.length >= 6 && isDigit) {
                                      e.preventDefault();
                                    }
                                  }
                                }}
                              />
                            )}
                          </Box>
                          {!!this.state.errors[input[0]] && (
                            <div style={{
                              color: 'red', width: "28ch", fontSize: "12px",
                              margin: "auto", textAlign: "left"
                            }}>{this.state.errors[input[0]]}</div>
                          )}
                        </div>
                      ))}
                      <Box>
                        <Button
                          data-test-id={"btnEmailLogIn"}
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => this.createAdress()}
                          style={{
                            border: "1px solid #a9caf7b7",
                            borderRadius: "30px",
                            padding: " 10px 55px",
                            textDecoration: "none",
                            textTransform: "capitalize",
                            color: "white",
                            margin: "20px auto 20px",
                            fontWeight: "bold",
                            backgroundColor: "#A9C9F7",
                            boxShadow: "0px 10px 20px #A9C9F77A !important",
                            width: "79%",
                          }}
                        >
                          Create
                        </Button>
                      </Box>
                    </CustomModalBox>
                  </Modal>
                </div>
                <Typography
                  variant="h4"
                  style={{
                    padding: "20px 35px",
                    color: "#231F20",
                    fontWeight: 600
                  }}
                >
                  Personal Details
                </Typography>
                <div style={{ display: "flex", gap: "15px", width: "100%" }}>
                  <div style={{ width: "38%" }}>
                    <Card style={webStyle.card}>
                      <Typography style={{ color: 'gray', margin: '10px 0px 10px 2px', letterSpacing: '1px', padding: '10px 0px 10px 0px', fontWeight: 700, borderBottom: '1px solid #d3d3d34a' }}>MY DETAILS</Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                        <div>Name</div><div style={{ fontWeight: 900 }}>{userData.name}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                        <div>Email</div><div style={{ fontWeight: 900 }}>{userData.email}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                        <div>Phone</div><div style={{ fontWeight: 900 }}>{userData.phone_number}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px",
                          paddingBottom: "20px"
                        }}
                      >
                        <div>Shipping Add.</div>
                        <div style={{ fontWeight: 900, textAlign: "right", maxWidth: '50%' }}>
                          {this.humanizeAddress(selectedAdress)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "15px",
                          borderTop: "1px solid #d3d3d34a"
                        }}
                      >
                        <div>Zipcode</div>
                        <div style={{ fontWeight: 900 }}>{selectedAdress && selectedAdress.zip_code}</div>
                      </div>
                    </Card>
                    <div style={{ color: "#aaa5a1", fontStyle: 'italic' }}>
                      <p>*Estimated Delivery:</p>
                      <p>within 3 days, {formatted} - 9:00AM to 6:00PM</p>
                    </div>
                  </div>
                  <div style={{ width: "62%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <CustomCard
                        data-testid="paymentHandle1"
                        style={{
                          ...webStyle.card,
                          width: "28%",
                          height: "160px",
                          cursor: "pointer",
                          ...(this.state.paymentMethod == 1 ? { background: "#A9C9F7", color: 'white', } : {})
                        }}
                        onClick={() => this.handlePaymentMethod(1)}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            textAlign: "center",
                            color: this.state.paymentMethod == 1 ? "white" : "#A9C9F7",
                            paddingBlock: "58px"
                          }}
                        >
                          <div>
                            {/* <DeleteOutlineIcon /> */}
                            <img src={Delivery} alt="Delivery" style={{
                              height: "28px",
                              filter: this.state.paymentMethod == 1 ? "brightness(3.5)" : "brightness(1)",
                            }} />
                          </div>
                          <div className="delivery-text">Delivery</div>
                        </div>
                      </CustomCard>
                      <CustomCard
                        data-testid="paymentHandle2"
                        style={{
                          ...webStyle.card,
                          width: "28%",
                          height: "160px",
                          cursor: "pointer",

                          ...(this.state.paymentMethod == 2 ? { background: "#A9C9F7", color: 'white' } : {})
                        }}
                        onClick={() => this.handlePaymentMethod(2)}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            textAlign: "center",
                            color: this.state.paymentMethod == 2 ? "white" : "#A9C9F7",
                            paddingBlock: "58px"
                          }}
                        >
                          <div>
                            {/* <DeleteOutlineIcon /> */}
                            <img src={Shipping} alt="Shipping" style={{ height: "28px", filter: this.state.paymentMethod == 2 ? "brightness(3.5)" : "brightness(1)" }}
                            />
                          </div>
                          <div>Shipping / Mailing</div>
                        </div>
                      </CustomCard>
                      <CustomCard
                        data-testid="paymentHandle3"
                        style={{
                          ...webStyle.card,
                          width: "28%",
                          height: "160px",
                          cursor: "pointer",
                          ...(this.state.paymentMethod == 3 ? { background: "#A9C9F7", color: 'white' } : {})
                        }}
                        onClick={() => this.handlePaymentMethod(3)}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            textAlign: "center",
                            color: this.state.paymentMethod == 3 ? "white" : "#A9C9F7",
                            paddingBlock: "58px"
                          }}
                        >
                          <div>
                            <DeleteOutlineIcon />
                          </div>
                          <div>Pick Up</div>
                        </div>
                      </CustomCard>
                    </div>
                    {this.state?.paymentMethod === 1 ?
                      <Card style={webStyle.card}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#611a20",
                            paddingBlock: "8px",
                            marginBottom: 15,
                            marginTop: 20
                          }}
                        >
                          <div
                            style={{
                              color: "gray",
                              letterSpacing: "1px",
                              fontWeight: 700,
                              borderBottom: "1px solid #d3d3d34a"
                            }}
                          >
                            CHOOSE FROM SAVED ADDRESS
                          </div>
                          <div style={{ color: "#231F20" }}>
                            <Button
                              type="button"
                              onClick={this.handleOpen}
                              variant="outlined"
                              size="small"
                              style={{ textTransform: "capitalize", marginRight: 40 }}
                              data-testid="createAddress"
                            >
                              Create Address
                            </Button>
                          </div>
                        </div>

                        {this.state?.data.map(address => {
                          const attr = address.attributes;

                          return <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              color: "#A9C9F7",
                              marginTop: 10
                            }}
                          >
                            <div
                              style={{
                                color: "#aaa5a1",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <div style={webStyle.list} />{" "}
                              <FormControl component="fieldset">
                                <RadioGroup aria-label="address" name="address" value={this.state?.checkAddress ? this.state?.checkAddress : false} onChange={(e) => this.handleAddressChange(e, attr)}>
                                  <FormControlLabel
                                    value={attr.id}
                                    control={
                                      <StyleRadio />
                                    }
                                    label={`${attr.address_type}`}
                                  />
                                </RadioGroup>
                              </FormControl>{" "}
                            </div>
                            <div
                              style={{
                                fontWeight: 900,
                                marginTop: 6,
                                marginLeft: 30,
                                marginBottom: 25,
                                color: "#231F20"
                              }}
                            >
                              {this.humanizeAddress(attr)}
                            </div>
                          </div>
                        })}
                        <Typography
                          style={{
                            color: "gray",
                            letterSpacing: "1px",
                            fontWeight: 700,
                            borderBottom: "1px solid #d3d3d34a",
                            marginBottom: "10px"
                          }}
                        >
                          CHOOSE DELIVERY SLOT
                        </Typography>
                        <Box display="flex">
                          {this.state.deliveryDates?.length > 0 &&
                            this.state.deliveryDates.map(item => (
                              <Card
                                style={{
                                  padding: "12px 2px",
                                  color: item == this.state.deliverySlot.date ? "#FFF" : "#aaa5a1",
                                  backgroundColor: item == this.state.deliverySlot.date ? "#A9C9F7" : "",
                                  width: "12%",
                                  marginBottom: "14px",
                                  marginRight: "8px",
                                  fontSize: "13px",
                                  textAlign: "center",
                                  cursor: "pointer"
                                }}
                                className="slot"
                                onClick={() => this.setDeliverySlotDay(item)}
                              >
                                {item}
                              </Card>
                            ))}
                        </Box>
                        <Box display="flex">
                          {this.state.deliveryTimes[this.state.deliverySlot.date] ?
                            this.state.deliveryTimes[this.state.deliverySlot.date].map(item => (
                              <Card
                                style={{
                                  padding: "12px 2px",
                                  color: item == this.state.deliverySlot.time ? "#FFF" : "#aaa5a1",
                                  backgroundColor: item == this.state.deliverySlot.time ? "#A9C9F7" : "",
                                  width: "12%",
                                  marginBottom: "14px",
                                  marginRight: "8px",
                                  fontSize: "13px",
                                  textAlign: "center",
                                  cursor: "pointer"
                                }}
                                className="slot"
                                onClick={() => this.setDeliverySlotTime(item)}
                              >
                                {item}
                              </Card>
                            ))
                            : <></>}
                        </Box>
                      </Card>
                      : this.state?.paymentMethod === 2 ?
                        <Card style={webStyle.card}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#611a20",
                              paddingBlock: "8px",
                              marginBottom: 15,
                              marginTop: 20
                            }}
                          >
                            <div
                              style={{
                                color: "gray",
                                letterSpacing: "1px",
                                fontWeight: 700,
                                borderBottom: "1px solid #d3d3d34a"
                              }}
                            >
                              CHOOSE FROM SAVED ADDRESS
                            </div>
                            <div style={{ color: "#611a20" }}>
                              <Button
                                type="button"
                                onClick={this.handleOpen}
                                variant="outlined"
                                size="small"
                                style={{ textTransform: "capitalize", marginRight: 40 }}
                                data-testid="createAddress"
                              >
                                Create Address
                              </Button>
                            </div>
                          </div>

                          {this.state?.data.map(address => {
                            const attr = address.attributes;

                            return <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                color: "#A9C9F7",
                                marginTop: 10
                              }}
                            >
                              <div
                                style={{
                                  color: "#aaa5a1",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <div style={webStyle.list} />{" "}
                                <FormControl component="fieldset">
                                  <RadioGroup aria-label="address" name="address" value={this.state?.checkAddress ? this.state?.checkAddress : false} onChange={(e) => this.handleAddressChange(e, attr)}>
                                    <FormControlLabel
                                      value={attr.id}
                                      control={
                                        <StyleRadio />
                                      }
                                      label={`${attr.address_type}`}
                                    />
                                  </RadioGroup>
                                </FormControl>{" "}
                              </div>
                              <div
                                style={{
                                  fontWeight: 900,
                                  marginTop: 6,
                                  marginLeft: 30,
                                  marginBottom: 25,
                                }}
                              >
                                {this.humanizeAddress(attr)}
                              </div>
                            </div>
                          })}
                        </Card>
                        :
                        <Card style={webStyle.card}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px",
                              marginBottom: 30,
                              marginTop: 20
                            }}
                          >
                            <div>Pick Up ({this.formattedMerchantAddress(this.state.merchantAddress)})</div>
                            <div style={{ color: "#aaa5a1" }}>
                              Check in shipping calculator*
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px",
                              fontWeight: 400
                            }}
                          >
                            <div>Available Slots</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              marginBottom: "20px"
                            }}
                          >
                            {this.state?.slots.map(slot => {
                              return this.state?.activeSlot === slot ?
                                <Card
                                  style={{
                                    padding: "12px 2px",
                                    color: "#fff",
                                    backgroundColor: "#A9C9F7",
                                    width: "12%",
                                    marginBottom: "14px",
                                    marginRight: "8px",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    cursor: "pointer"
                                  }}
                                  className="slot"
                                  onClick={this.activeSlot.bind(this, slot)}
                                >
                                  {slot}
                                </Card>
                                :
                                <Card
                                  style={{
                                    padding: "12px 2px",
                                    color: "#aaa5a1",
                                    width: "12%",
                                    marginBottom: "14px",
                                    marginRight: "8px",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    cursor: "pointer"
                                  }}
                                  className="slot"
                                  onClick={() => this.activeSlot(slot)}
                                >
                                  {slot}
                                </Card>
                            })}
                          </div>
                        </Card>
                    }
                    {(
                      this.state?.data?.some((address: any) => Object.keys(address.attributes).length > 0) &&
                      shipping && this.state.deliverySlot.time
                    ) ?
                      <div style={{ width: `100%`, textAlign: "right" }}>
                        <CustomInlineButton
                          title={"Continue to Summary"}
                          styleWidth={"50%"}
                          stylePadding={"10px"}
                          handleChange={this.handleNext.bind(this)}
                          disabled={this.state.disableBtn}
                        />
                      </div>
                      :
                      <div style={{ width: `100%`, textAlign: "right" }}>
                        <CustomInlineButton
                          title={"Continue to Summary"}
                          styleWidth={"50%"}
                          stylePadding={"10px"}
                          handleChange={this.handleNext.bind(this)}
                          style={{ color: "rgb(97, 26, 32)", backgroundColor: "rgb(251, 239, 239)" }}
                          disabled={this.state.disableBtn}
                        />
                      </div>
                    }
                  </div>
                </div>
              </>
            )}
            {this.state?.activeStep === 2 && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    padding: "20px 35px",
                    color: "#231F20",
                    fontWeight: 600
                  }}
                >
                  Summary
                </Typography>

                <div style={{ display: "flex", gap: "15px", width: "100%" }}>
                  <div style={{ width: "75%" }}>
                    <CustomModalWeb data-test-id="handleAcceptTnc"
                      handleButton={this.handleLifeTimeSubFlag} handleClose={this.handleLifeTimeSubFlagClose}
                      isModalOpen={this.state.LifeTimeSubscriptionFlag}
                      showCheckbox={this.state.LifeTimeSubscriptionFlag}
                      btnTitle={'Add to Cart'} statusImage={EventNoteOutlinedIcon} title={'Lifetime Subscription'}
                      description={this.state.modalCartData.attributes?.description}
                      handleAcceptTnc={this.handleAcceptTnc} checked={this.state.checked} />
                    <Card style={webStyle.creditCard1}>
                      <Typography
                        style={{
                          color: "gray",
                          margin: "10px 0px 10px 0px",
                          letterSpacing: "1px",
                          paddingLeft: "20px",
                          fontWeight: 700
                        }}
                      >
                        ADDED ITEMS(
                        {this.state?.myCarts.length < 10
                          ? "0" + this.state?.myCarts.length
                          : this.state?.myCarts.length}
                        )
                      </Typography>
                      {this.state?.myCarts.map((mycart: any, index:number) => (
                        <CardContent style={webStyle.cardContent}>
                          <CardHeader
                            style={webStyle.showHeadings}
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                style={webStyle.avatar}
                              >
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={mycart.productImage ? mycart.productImage : "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg"}
                                />
                              </Avatar>
                            }
                            title={
                              <Typography style={webStyle.cardHeading}>
                                {mycart.name || mycart.productName}
                              </Typography>
                            }
                            subheader={
                              <div>
                                <Typography>{mycart.variant || mycart.productName}</Typography>
                                <Typography>{"count x" + " " + mycart.quantity}</Typography>
                              </div>
                            }
                          />
                          <div style={webStyle.setFrequency}>
                            <div style={webStyle.count}>
                              {mycart.quantity > 1 ? <button style={webStyle.increment} disabled={mycart.quantity > 1 ? false : true} data-testid="decreaseApi0" onClick={() => this.getDecreaseApi(mycart.catalogue_id, mycart.order_id, mycart.variant_id)}>-</button>
                                :
                                <button style={webStyle.increment1}></button>
                              }
                              <span>{mycart.quantity}</span>
                              <button onClick={() => this.getIncreaseApi(mycart.catalogue_id, mycart.order_id, mycart.variant_id)} data-testid="increaseApi0" style={webStyle.increment}>+</button>
                            </div>
                            {mycart.frequency && <Typography>Frequency: {mycart.frequency} </Typography>}
                          </div>
                          <div style={{ minWidth: "150px", textAlign: 'right' }}>
                            {this.convertPrice(mycart.price)} *{" "}
                            {mycart.quantity}
                          </div>

                          <div style={webStyle.amount}>
                            <span style={webStyle.amountRate}>
                              {this.convertPrice(
                                mycart.price * mycart.quantity
                              )}
                            </span>
                            <span>
                              <DeleteOutlineIcon
                                style={webStyle.deleteIcon}
                                onClick={() => this.deleteProduct(mycart?.o_id)}
                                data-testid={`deleteProductButton${index}`}
                              />
                            </span>
                          </div>
                          

                        </CardContent>
                      ))}
                    </Card>
                  </div>
                  <div style={{ width: "25%" }}>
                    <Card style={webStyle.card}>
                      <Typography style={{ color: 'gray', margin: '10px 0px 10px 2px', letterSpacing: '1px', padding: '10px', fontWeight: 700, borderBottom: '1px solid #d3d3d34a' }}>MY DETAILS</Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                        <div>Name</div><div style={{ fontWeight: 900 }}>{userData.name}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                        <div>Email</div><div style={{ fontWeight: 900 }}>{userData.email}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '15px', borderTop: '1px solid #d3d3d34a' }}>
                        <div>Phone</div><div style={{ fontWeight: 900 }}>{userData.phone_number}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px",
                          paddingBottom: "20px"
                        }}
                      >
                        <div style={{ marginRight: '3rem' }}>Shipping Add.</div>
                        <div style={{ fontWeight: 900, textAlign: 'end' }}>
                          {this.humanizeAddress(selectedAdress)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "15px",
                          borderTop: "1px solid #d3d3d34a"
                        }}
                      >
                        <div>Zipcode</div>
                        <div style={{ fontWeight: 900 }}>{selectedAdress && selectedAdress.zip_code}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '15px', borderTop: '1px solid #d3d3d34a' }}>
                        <div>Delivery</div><div style={{ fontWeight: 900 }}>{`${this.state.deliverySlot.date}(${this.state.deliverySlot.time})`}</div>
                      </div>
                    </Card>
                    <Card style={webStyle.Leftcard}>
                      <p style={{ color: "#231F20" }}>Delivery in 24hrs</p>
                      {this.state?.fastDelivery === false ?
                        <Button onClick={this.handlefastDelivery} style={webStyle.LeftbtnStyle}>{`$${this.state.showAmount}`}</Button>
                        :
                        <Button onClick={this.handleRemoveFastDelivery} style={webStyle.removefastdelivery}>{"Remove"}</Button>
                      }
                    </Card>
                    <Card style={webStyle.card}>
                      <Typography
                        style={{
                          color: "gray",
                          margin: "10px 0px 10px 2px",
                          letterSpacing: "1px",
                          padding: "10px",
                          fontWeight: 700,
                          borderBottom: "1px solid #d3d3d34a"
                        }}
                      >
                        PAYMENT DETAILS
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px"
                        }}
                      >
                        <div>Subtotal</div>
                        <div style={{ fontWeight: 900 }}>
                          {this.convertPrice(this.state?.subTotal)}
                        </div>
                      </div>
                      {this.state?.discountCode.discount ?
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px"
                          }}
                        >
                          <div>Discount</div>
                          <div style={{ fontWeight: 900 }}>
                            - ${Math.abs(this.state?.discountCode ? this.state?.discountCode?.discount : 0).toFixed(2)}
                            ({this.state?.discountCode?.discount ? Math.abs(this.state?.subTotal / this.state?.discountCode?.discount).toFixed(2) : 0}%)
                          </div>
                        </div> : ""
                      }
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "8px",
                        }}
                      >
                        <div>{this.state.paymentMethod == 1 ? "Delivery Charges" : "Shipping Charges"}</div>
                        <div style={{ fontWeight: 900 }}>
                          {this.convertPrice(shipping)}
                        </div>
                      </div>
                      {this.state?.fastDelivery === false ?
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px",
                          }}
                        >
                          {/* <div>Delivery Charges</div>
                          <div style={{ fontWeight: 900 }}>
                            {"$0.0"}
                          </div> */}
                        </div> : ""}
                      {this.state?.fastDelivery ?
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px",
                            paddingBottom: "20px"
                          }}
                        >
                          <div>Delivery in 24hrs fees</div>
                          <div style={{ fontWeight: 900 }}>
                            ${this.state.showAmount}
                          </div>
                        </div> : ""
                      }
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#231F20"
                          }}
                        >
                          <LocationOnOutlinedIcon
                            style={{ fontSize: "12px", marginTop: "10px" , color:"#B5D2F7"}}
                          />
                          <p style={{ fontSize: "10px" }}>3.2km away</p>
                        </div>
                        <span
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "-2px",
                            color: "#848484"
                          }}
                        >
                          change my address to reduce the shipping charge
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#231F20",
                          paddingBlock: "15px",
                          borderTop: "1px solid #d3d3d34a",
                          padding: '1rem',
                        }}
                      >
                        <div>Total</div>
                        <div style={{ fontWeight: 900 }}>
                          {this.convertPrice(
                            this.state?.subTotal
                            - (this.state?.discountCode && typeof (this.state?.discountCode.discount) === "number"
                              ? Math.abs(this.state?.discountCode.discount) : 0)
                            + (this.state?.fastDelivery ? Number(this.state.showAmount) : 0)
                            + (shipping && typeof (shipping) === "number"
                              ? Math.abs(shipping) : 0)
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div>
                  <Typography
                    variant="h4"
                    style={{
                      padding: "20px 35px",
                      color: "#231F20",
                      fontWeight: 600
                    }}
                  >
                    Meat Storage
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={webStyle.GridBoxstatestate}
                    className="GridBox"
                  >
                    {this.state?.myPlans
                      ?.sort((a: any, b: any) => a.current_plan.price - b.current_plan.price)
                      ?.map((plan: any, index: number) => (
                        <>
                          {plan.current_plan.plan_name === "Basic" && (
                            <Grid item xs={12} sm={4}>
                              <Card style={webStyle.cardStorage}>
                                <div
                                  className={"cardStorageHeading"}
                                  style={webStyle.cardStorageHeading}
                                >
                                  <span style={{ color: "#5757bd" }}>
                                    {plan.current_plan.plan_name}
                                  </span>
                                  <span style={{ color: "#5757bd" }}>
                                    {this.state.planIndex === null && this.state.currentPlan
                                      ? "Current"
                                      : `${plan.current_plan.price}/Month`}
                                  </span>
                                </div>
                                <div
                                  className={"cardStorageHeading"}
                                  style={{
                                    maxWidth: "270px",
                                    marginTop: "2rem",
                                    color: "#848484",
                                  }}
                                >
                                  <span
                                    className={"cardStorageHeading"}
                                    style={webStyle.cardStorageFont}
                                    dangerouslySetInnerHTML={{
                                      __html: plan.current_plan.description,
                                    }}
                                  ></span>
                                </div>
                                {this.state.currentPlan && (
                                  <button
                                    className="btn-signup2"
                                    onClick={this.handleBasic}
                                  >
                                    <span>
                                      {this.state.lifetimeSubs ? "Basic" : "Remove"}
                                    </span>
                                  </button>
                                )}
                              </Card>
                            </Grid>
                          )}

                          {/* Render the other plans */}
                          {plan.existing_paln.map((i: any, idx: number) => (
                            <Grid item xs={12} sm={4} className={"goldStorage"} key={idx}>
                              <Card
                                data-testid="storageGold"
                                className={
                                  this.state?.planIndex === idx ? this.state?.activeClassGold : ""
                                }
                                style={
                                  this.state.lifetimeSubs && this.state.planIndex === idx
                                    ? webStyle.cardStorageBackground
                                    : webStyle.cardStorage
                                }
                              >
                                <div>
                                  <div style={{ minHeight: "260px" }}>
                                    <div
                                      className={"cardStorageHeading"}
                                      style={webStyle.cardStorageHeading}
                                    >
                                      <span style={{ color: "#5757bd" }}>
                                        {i.plan_name}
                                      </span>

                                      <span style={{ color: "#5757bd" }}>
                                        {this.state.planIndex === idx && this.state.currentPlan
                                          ? "Current"
                                          : `${i.price}/Month`}
                                      </span>
                                    </div>
                                    <div
                                      className={"cardStorageHeadingBasic"}
                                      style={{
                                        maxWidth: "270px",
                                        marginTop: "2rem",
                                        color: "#848484",
                                      }}
                                    >
                                      <span
                                        className={"cardStorageHeading"}
                                        style={webStyle.cardStorageFont}
                                        dangerouslySetInnerHTML={{
                                          __html: i.description,
                                        }}
                                      ></span>
                                    </div>
                                  </div>

                                  <button
                                    className="btn-signup2"
                                    onClick={() =>
                                      this.handleStorage(
                                        this.state.meatBtnTitle === i.plan_name ? "" : i.plan_name,
                                        idx
                                      )
                                    }
                                  >
                                    <span>
                                      {this.state.lifetimeSubs &&
                                        this.state.planIndex === idx
                                        ? "Remove"
                                        : i.plan_name}
                                    </span>
                                  </button>
                                </div>
                              </Card>
                            </Grid>
                          ))}
                        </>
                      ))}
                  </Grid>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ marginRight: '1rem' }} data-testid="data"><CustomOutlinedButton handleChange={this.handleNext} title={"I don't want any storage"} styleWidth={'250px'} stylePadding={'10px'} /></div>
                  <div>
                    {this.state.currentPlan ? <CustomInlineButton handleChange={() => this.handleMeatStorage(this.state.meatBtnTitle ? this.state.meatBtnTitle : this.state?.myPlans?.length > 0 ? this.state?.myPlans[0]?.current_plan.plan_name : "Basic")} title={`Continue with ${this.state.meatBtnTitle ? this.state.meatBtnTitle : this.state.myPlans?.length > 0 ? this.state.myPlans[0]?.current_plan.plan_name : "Basic"}`} styleWidth={'250px'} stylePadding={'10px'} />
                    :
                    <CustomInlineButton handleChange={() => this.handleMeatStorage(this.state.meatBtnTitle ? this.state.meatBtnTitle : this.state?.myPlans?.length > 0 ? this.state?.myPlans[0]?.current_plan.plan_name : "Basic")} title={`Continue with Basic`} styleWidth={'250px'} stylePadding={'10px'} />
                                      }</div>  </div> 
              </>
            )}
            {this.state?.activeStep === 3 && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    padding: "20px 35px",
                    color: "#231F20",
                    fontWeight: 600
                  }}
                >
                  Payment
                </Typography>
                <Grid
                  container
                  spacing={3}
                  style={webStyle.GridBox}
                  className="GridBox"
                >
                  <Grid item xs={12} sm={3}>
                    <Card style={webStyle.cardStorage}>
                      <Typography
                        style={{
                          color: "gray",
                          padding: "5px 0px 5px 2px",
                          letterSpacing: "2px",
                          fontWeight: 700,
                          borderBottom: "1px solid #d3d3d34a"
                        }}
                      >

                        CHOOSE PAYMENT METHOD
                      </Typography>
                      <div
                        className={"cardStorageHeading"}
                        style={{ marginTop: "2rem", color: "#848484" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                            color: "#231F20"
                          }}
                        >
                          <input
                            type="radio"
                            name="mycard"
                            onChange={this.handlePayment}
                            checked={this.state?.paymentType === "card"}
                            id=""
                          />
                          Credit/Debit Card
                        </p>
                      </div>
                    </Card>

                    <Typography
                      style={webStyle.typographyCard}
                    >
                      Card Details
                    </Typography>
                    {(() => {
  type ShowCards = {
    name_on_card: string | null;
    card_number: string;
  };

  const uniqueCards: ShowCards[] = [];
  const cardNumbers: Set<string> = new Set();

  this.state.showCards.forEach((card: ShowCards) => {
    if (card.name_on_card !== null && !cardNumbers.has(card.card_number)) {
      cardNumbers.add(card.card_number);
      uniqueCards.push(card);
    }
  });

  return uniqueCards.map((card: ShowCards, index: number) => (
    <Card style={webStyle.cardStorageSave} key={index}>
      <div
        className={"cardStorageHeading"}
        style={{ marginTop: "2rem", color: "#848484" }}
      >
        <p
          style={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            color: "#231F20"
          }}
        >
          Card Name: {card.name_on_card}
        </p>
        <p
          style={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            marginBottom: "10px",
            color: "#231F20"
          }}
        >
          Card Number: {card.card_number}
        </p>
      </div>
    </Card>
  ));
})()}


                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {this.state?.paymentType === 'card' ?
                      <Card style={webStyle.cardStorage}>
                        <Typography
                          style={{
                            position: "relative",
                            padding: "5px 0px 12px 2px",
                            borderBottom: "1px solid #d3d3d34a"
                          }}
                        >
                          <span
                            style={{
                              color: "gray",
                              letterSpacing: "2px",
                              fontWeight: 700
                            }}
                          >
                            CARD DETAILS
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "0px",
                              color: "#A9C9F7",
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              this.setState((prev) => ({
                                formKey: prev.formKey + 1
                              }))
                            }}
                          >
                            Reset Details
                          </span>
                        </Typography>
                        <div
                          className={"cardStorageHeading"}
                          style={{ marginTop: "2rem", color: "#848484" }}
                        >
                          <PaymentForm key={this.state.formKey} {...this.props} />
                          <div style={{ cursor: "pointer", padding: "5px 15px", border: "none", borderRadius: "40px", background: "#E7F3FF", display: "flex", alignItems: "center" }}>
                          <FormControlLabel
                              control={
                                <Checkbox 
                                  onChange={this.handleSaveCheckbox} 
                                  name="saveCheckbox"
                                  style={{ marginRight: "10px", color:"lightblue" }}
                                />
                              }
                              label="Save Card Details"
                            />
                        </div>
                        </div>
                      </Card> : ""}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card style={webStyle.cardStorage}>
                      <Typography
                        style={{
                          color: "gray",
                          padding: "5px 0px 5px 2px",
                          letterSpacing: "2px",
                          fontWeight: 700,
                          borderBottom: "1px solid #d3d3d34a"
                        }}
                      >
                        PAYMENT DETAILS
                      </Typography>
                      <div
                        className={"cardStorageHeading"}
                        style={{ color: "#848484" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px"
                          }}
                        >
                          <div>Subtotal</div>
                          <div style={{ fontWeight: 900 }}>
                            {this.convertPrice(this.state?.subTotal)}
                          </div>
                        </div>
                        {this.state?.discountCode.discount ?
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px"
                            }}
                          >
                            <div>Discount</div>
                            <div style={{ fontWeight: 900 }}>
                            - ${Math.abs(this.state?.discountCode ? this.state?.discountCode?.discount : 0).toFixed(2)}
                            ({this.state?.discountCode?.discount ? Math.abs(this.state?.subTotal / this.state?.discountCode?.discount).toFixed(2) : 0}%)
                          </div>
                          </div>
                          : ""}              
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px",
                            }}
                          >
                            <div>Delivery Charges</div>
                            <div style={{ fontWeight: 900 }}>
                              {this.state.paymentMethod == 1 ? this.convertPrice(shipping) : "$0.0"}
                            </div>
                          </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingBlock: "8px",
                          }}
                        >
                          <div>Meat Storage</div>
                          <div style={{ fontWeight: 900 }}>
                            ${this.state.meatStorageAmount}
                          </div>
                        </div>
                        {this.state?.fastDelivery ?
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px",
                              paddingBottom: "20px"
                            }}
                          >
                            <div>Delivery in 24hrs fees</div>
                            <div style={{ fontWeight: 900 }}>
                            ${this.state.showAmount}
                            </div>
                          </div> : ""
                        }
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#231F20",
                            paddingTop: "15px",
                            borderTop: "1px solid #d3d3d34a"
                          }}
                        >
                          <div>Total</div>
                          <div style={{ fontWeight: 900 }}>
                            {this.convertPrice(
                              this.state?.subTotal
                              - (this.state?.discountCode && typeof (this.state?.discountCode.discount) === "number"
                                ? Math.abs(this.state?.discountCode.discount) : 0)
                              + (this.state?.fastDelivery ? Number(this.state.showAmount) : 0)
                              + (shipping && typeof (shipping) === "number"
                                ? Math.abs(shipping) : 0)
                              + (this.state?.meatStorageAmount)
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card style={webStyle.cardStorage}>
                      <Typography
                        style={{
                          color: "gray",
                          padding: "5px 0px 5px 2px",
                          letterSpacing: "2px",
                          fontWeight: 700,
                          borderBottom: "1px solid #d3d3d34a"
                        }}
                      >
                        MY DETAILS
                      </Typography>
                      <div
                        className={"cardStorageHeading"}
                        style={{ marginTop: "2rem", color: "#848484" }}
                      >

                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                            <div>Name</div><div style={{ fontWeight: 900 }}>{userData.name}</div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                            <div>Email</div><div style={{ fontWeight: 900 }}>{userData.email}</div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '8px' }}>
                            <div>Phone</div><div style={{ fontWeight: 900 }}>{userData.phone_number}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingBlock: "8px",
                              paddingBottom: "20px"
                            }}
                          >
                            <div>Shipping Add.</div>
                            <div style={{ fontWeight: 900 }}>
                              {this.humanizeAddress(selectedAdress)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#231F20",
                              paddingTop: "15px",
                              borderTop: "1px solid #d3d3d34a"
                            }}
                          >
                            <div>Zipcode</div>
                            <div style={{ fontWeight: 900 }}>
                              {selectedAdress && selectedAdress.zip_code}
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#231F20', paddingBlock: '15px', borderTop: '1px solid #d3d3d34a' }}>
                            <div>Delivery</div><div style={{ fontWeight: 900 }}>{`${this.state.deliverySlot.date}(${this.state.deliverySlot.time})`}</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <CustomInlineButton
                      // disabled={this.state.disableBtn} 
                      handleChange={(e: any) => this.handleSubmits(e)} title={this.state?.paymentType === 'card' ? "Pay" : "Continue"} styleWidth={'100%'} stylePadding={'10px'} styleOpacity={isDisabled ? 0.4 : 1} />
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const FormControlWeb = styled(FormControl)({
  "& fieldset": {
    display: "none",
  },
});

const webStyle: any = {
  root: {
    backgroundColor: "#F5F2ED",
    minHeight: "100vh"
  },
  typographyCard: {
    color: "gray",
    letterSpacing: "2px",
    fontWeight: 700,
    borderBottom: "1px solid #d3d3d34a",
    borderRadius: "30px",
    backgroundColor: "white",
    padding: "20px"
  },
  showHeadings: { paddingBottom: "0px", minWidth: '20%', width: "20%", wordWrap: "anywhere" },
  rootContainer: {
    padding: "25px 50px",
    margin: "0 auto"
  },
  btnStyle: {
    backgroundColor: "#a0282a",
    color: "#FFF",
    fontWeight: 600,
    borderRadius: 30,
    width: 164,
    padding: "11px 8px",
    textTransform: "capitalize"
  },
  cardStorageFont: {
    fontSize: "20px"
  },
  cardHeading: {
    display: "flex",
    justifyContent: "space-between",
    color: '#231F20)',
    fontWeight: 'bold'
  },
  LeftbtnStyle: {
    backgroundColor: "#A9C9F7",
    color: "#fff",
    borderRadius: "32px",
    textTransform: "capitalize",
    border: "1px solid  #a9caf7b7",
    padding: "0px 24px",
    margin: "8px"
  },
  removefastdelivery: {
    backgroundColor: "#A9C9F7",
    color: "#fff",
    borderRadius: "32px",
    textTransform: "capitalize",
    border: "1px solid  #a9caf7b7",
    padding: "0px 24px",
    margin: "8px"
  },
  creditCard: {
    backgroundColor: "white",
    width: "100%",
    textAlign: "left",
    borderRadius: "30px"
  },
  creditCard1: {
    backgroundColor: "white",
    width: "100%",
    textAlign: "left",
    borderRadius: "30px",
    marginTop: "1rem"
  },
  profileContainer: {
    backgroundColor: "white",
    borderRadius: "27px",
    margin: "auto",
    marginTop: "2rem",
    width: "50%"
  },
  orderCart: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "27px"
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "30px"
  },
  cardStorageHeading: {
    fontWeight: 700,
    color: "#A9C9F7",
    display: "flex",
    justifyContent: "space-between",
    '& .MuiRadio-root': {
      color: '#A9C9F7',
    }
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  amount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "20px",
    width: "20%"
  },
  count: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    minWidth: "80px"
  },
  increment: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#611a2014",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  increment1: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  amountRate: {
    fontWeight: 900,
    color: "#231F20"
  },
  deleteIcon: {
    fill: "gray",
    width: "18px",
    cursor:"pointer"
  },
  card: {
    marginTop: "2px",
    marginBottom: "10px",
    borderRadius: "30px",
    paddingInline: "15px"
  },
  cardStorage: {
    marginTop: "2px",
    marginBottom: "15px",
    borderRadius: "30px",
    paddingInline: "15px",
    padding: "2rem",
    minHeight: "350px"
  },
  cardStorageSave: {
    marginTop: "2px",
    marginBottom: "2px",
    borderRadius: "30px",
    paddingInline: "15px",
    padding: "20px"
  },
  cardStorageBackground: {
    backgroundColor: "#A9C9F7",
    color: "white",
    marginTop: "2px",
    marginBottom: "15px",
    borderRadius: "30px",
    paddingInline: "15px",
    padding: "2rem"
  },
  Leftcard: {
    margin: "1rem auto",
    borderRadius: "30px",
    paddingInline: "15px",
    display: "flex",
    justifyContent: "space-between"
  },
  cardInput: {
    color: "#aabffa" ,
    background: "inherit",
    border: "none",
    width: '100%',
    maxWidth: '170px',
    outline: 'none',
    fontWeight: "600",
  },
  cardInputDiv: {
    display: "flex",
    background: "#FBEFEF",
    color: "#611a20",
    borderRadius: "15px",
    marginBottom: "1rem",
    padding: "1rem",
    overflow: "hidden",
    marginTop: "5px",
  },
  cardMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgb(224, 236, 253)",
    color: "#231F20",
    borderRadius: "15px",
    border: "1px solid #a9caf7b7",
    marginBottom: "1rem",
    padding: "1rem",
    overflow: "hidden",
    alignItems: "center",
  },
  paper: {
    margin: 'auto',
    position: 'absolute',
    width: '400px',
    backgroundColor: "#fff",
    borderRadius: '40px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
    textAlign: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '500px',
    overflowY: 'auto',
  },
  iconClose: {
    position: "absolute",
    top: "20px",
    right: "25px",
    backgroundColor: "rgb(224, 236, 253)",
  },
  closeSvgStyle: {
    color: "#231F20",
    fontSize: "14px"
  },
  hStyle: {
    color: "#231F20",
    fontWeight: "bold",
    marginBOttom: "30px",
    paddingBottom: "10px",
    textTransform: "capitalize",
  },
  showFre: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justiyContent: "center",
    gap: "10px"
  },
  textfield: {
    border: "1px solid #a9caf7b7",
    boxShadow: "none",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    margin: "8px auto",
    textAlign: "left",
    width: "26ch",
    padding: "8px 12px",
    "& input": {
      fontSize: "12px",
      color: "#231F20",
      fontWeight: "bold",
      paddingLeft: "12px",
    },
    "& ::after": {
      display: "none",
    },
    "& ::before": {
      display: "none",
    },
  },
  radio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  setFrequency: {
    display: "flex",
    flexDirection: "column"
  },
  radioIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};

const CustomCard = styled(Card)({
  '&:hover': {
    backgroundColor: '#A9C9F7',
  },
  '&:hover div': {
    color: 'white',
  },
  '&:hover img': {
    filter: 'brightness(3.5) !important',
  },
})

const CustomModalBox = styled('div')({
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const StepperBlock: any = styled(Stepper)({
  "& .MuiStep-completed": {
    "& .MuiStepLabel-iconContainer": {
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        height: "15px",
        width: "15px",
        backgroundColor: "#A9C9F7",
        zIndex: 9,
        borderRadius: "50%",
        top: "5px",
        left: "10px"
      }
    },
    "& +": {
      "& .MuiStep-horizontal": {
        "& .MuiStepConnector-lineHorizontal": {
          borderColor: "#A9C9F7"
        }
      }
    }
  },
  "& .MuiStep-alternativeLabel": {
    "& .MuiStepLabel-label": {
      marginTop: "0",
      textTransform: "uppercase",
      color: "#ccc",
      fontWeight: "600",
      fontSize: "12px",
      letterSpacing: "2.5px"
    },
    "& .MuiStepLabel-active": {
      color: "#A9C9F7"
    },
    "& .MuiStepLabel-completed": {
      color: "#A9C9F7"
    }
  }
});

const ParentCheckBox = styled('p')({
  '& .MuiCheckbox-colorSecondary.Mui-checked': {
    color: 'rgb(97, 26, 32)',
    padding: 5,
  },
  '& .MuiIconButton-colorSecondary': {
    padding: 3,
  }
})

const StepBlocked: any = styled(StepLabel)({
  "& .MuiStepIcon-root": {
    backgroundColor: "#fff",
    color: "#fff",
    borderRadius: "50%",
    padding: "10px",
    height: "15px",
    width: "15px",
    position: "relative",
    top: "-5px",
    "& .MuiStepIcon-text": {
      fontSize: 0
    }
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    backgroundColor: "#fff",
    color: "#A9C9F7"
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    backgroundColor: "#fff",
    color: "#A9C9F7"
  }
});
const StepBox = styled(StepLabel)({
  "& .MuiStepIcon-root": {
    backgroundColor: "#fff",
    color: "#fff",
    borderRadius: "50%",
    padding: "10px",
    height: "15px",
    width: "15px",
    position: "relative",
    top: "-5px",
    "& .MuiStepIcon-text": {
      fontSize: 0
    }
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    backgroundColor: "#fff",
    color: "#A9C9F7"
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    backgroundColor: "#fff",
    color: "#A9C9F7"
  }
});
const CustomInput = styled('input')({
  "&::placeholder": {
    color: "#aabffa" 
  }
})