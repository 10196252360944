import "../assets/css/style.css";
import { img } from './assets';
import * as React from "react";
import { Container, Box, Grid, Paper, styled } from "@material-ui/core";
import TopNavWeb from "../../../components/src/TopNavWeb";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


import LandingPageController, {
  Props,
} from "./LandingPageController";
export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        {this.state.exploreAlert &&
          <Snackbar open={this.state.exploreAlert} autoHideDuration={2000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity="success">
              User need to Login In
            </Alert>
          </Snackbar>
        }
        <TopNavWeb props={this.props} />
        <Box className="landingpage" style={{
          backgroundImage: `url(${this.state.homePageData?.attributes.images[0].url})`
        }}>
          <Container maxWidth="xl">
            <Grid container spacing={2} alignItems="center" className="landing-box">
              <Grid item xs={12} md={12}>
                <Box className="landing-left">
                  <h1 style={{ maxWidth: "380px", color: "#FFF" }}>{this.state.homePageData?.attributes.title ?? ""}</h1>
                  <p style={{ maxWidth: "700px", color: "#FFF" }}>{this.state.homePageData?.attributes.description ?? ""}</p>
                  <div className="landing-btn">
                    <button test-dataid={"goToHomeButton"} onClick={this.handleClick} className="btn-common">Explore</button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  };
}

