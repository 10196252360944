import * as React from "react";
import {
  Container,
  Box,
  MenuItem,
  Toolbar,
  AppBar,
  Button,
  Menu,
  Typography,
  IconButton,
  Avatar,styled
} from "@material-ui/core";
import "../src/assests/css/style.css";
import { Link } from 'react-router-dom';
import EmailAccountRegistrationSuccess from '../../blocks/email-account-registration/src/EmailAccountRegistrationSuccess'
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import LockOutlined from "@material-ui/icons/LockOutlined"
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import { CartImage } from "./assest";
import {UserProfileBasicBlockWeb} from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import EmailAccountLoginBlockWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlockWeb.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import {withRouter} from 'react-router-dom';
const pages = ["Home", "About Us", "Blog Posts", "Store", "Contact Us"];

const urlConfig = require("../../framework/src/config");
const baseURL = urlConfig.baseURL;


const BackToProfileTopNav=(props:any)=> {
  const [isAuth, setIsAuth] = React.useState(null) as any;
  const [anchorElNav, setAnchorElNav] = React.useState<any>(null);
  const [open, setOpen] = React.useState<any>(false);
  const [couponCode, setCouponCode] = React.useState<string>("");
  const [state, setState] = React.useState("user");
  const [isOpen, setIsOpen] = React.useState<any>(
    {
      signUp: false,
      login: false,
      profile: false,isSucees:false,
      couponCode:""
    })

  const [profileInfo,setProfileInfo]=React.useState<any>()
  const [isSignup, setIsSignUp] = React.useState(false)
  const [isForgotOpen, setIsForgotOpen] = React.useState(false);
  const [isMerchantOpen, setIsMerchantOpen] = React.useState("");

  React.useEffect(()=>{
     let authToken=localStorage.getItem("authToken")||null
    if(authToken){
      setIsAuth(authToken)
    }
    
  },[isSignup,isOpen])
  

  const handleOpenNavMenu = (event:any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event:any) => {
    setAnchorElNav(null);
  };

  const handleOpen = (key:any) => {
    setIsOpen({ ...isOpen, [key]: !isOpen[key] });
    getProfile();
  };
  const logout = () => {
    setIsAuth(localStorage.clear() as any);
  }

  const CustomButton = styled('button')({
    '&:hover img': {
      filter: 'brightness(3.5)'
    }
  })

  const getProfile = async (id?: string) => {
    let token = localStorage.getItem("authToken") ?? ""
    const userId = id ?? localStorage.getItem("id")
    if(userId){
      const url=`${baseURL}/bx_block_profile/profiles/`

      try {
        const response = await fetch(url, {
        method: "GET",
        headers: {
          token: !!token ? token: ""
        },
      })
      const { data } = await response.json()
      if (data?.attributes) {
        localStorage.setItem('id', data.attributes?.id)
        localStorage.setItem("user_data",JSON.stringify(data?.attributes));
        localStorage.setItem("photo", data.attributes?.photo?.url);
        setProfileInfo(data?.attributes)
      }else{
        setProfileInfo(null)
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  }

  const handleForgot=()=>{
    setIsForgotOpen(true);
    setIsOpen({ ...isOpen, ['login']: false });
  }

  const handleForgotClose =()=>{
    setIsForgotOpen(false);
  }

  React.useEffect(()=>{  
    window.location.href.split("/").reverse()[0] === 'merchanthome' ? setIsMerchantOpen('/merchantstore') : setIsMerchantOpen('/store'); 
  },[])

  return (
    <AppBar position="static" className="header-bg">
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{justifyContent: "space-between"}}>

          <Typography
            variant="h6"
            component={"a"}
            href="/profile"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              fontSize: "17px !important"
            }}
          >
            <Typography style={{color: "black",  marginRight: 14}}>
              <ArrowBack width="22px"/>
            </Typography>
            
            Back To Profile
        </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <div className="auth-btn">
              {isAuth ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link to="/">
                  <CustomButton className="btn-logout" color="inherit">
                  <img src={CartImage} alt="Cart LOGO" style={{ height: "27px" }} />
                </CustomButton>
                  </Link>
                  <Link to="/profile">
                  <button className="btn-profile" >
                    <Avatar alt="Profile Image" src={localStorage.getItem("userProfileData") || localStorage.getItem("userProfile") as any} />
                  </button>
                  </Link>
                </div>
              ) : (
                <div>
                  <button className="btn-signup" onClick={() => handleOpen("signUp")}>Sign Up</button>
                  <button className="btn-login" onClick={() => handleOpen("login")}>Login</button>
                </div>

              )}

            </div>
          </Box>
        </Toolbar>
      </Container>
      {isOpen.signUp && (<EmailAccountRegistrationWeb open={isOpen.signUp} setOpen={setIsOpen} setIsSignUp={setIsSignUp} isSignup={isSignup} navigate={props} setCouponCode={setCouponCode} state={state} setState={setState}/>)}
      {isOpen.profile && (<UserProfileBasicBlockWeb open={isOpen.profile} isOpen={isOpen} setOpen={setIsOpen} profileInfo={profileInfo} navigate={props.props} getProfile={getProfile}/>)}
      {isOpen.login && (<EmailAccountLoginBlockWeb getProfile={getProfile} open={isOpen.login} setOpen={setIsOpen} handleClose={handleOpen} handleForgot={handleForgot} navigation={props.props.navigation} id={""}/>)}
      {isSignup && (<EmailAccountRegistrationSuccess open={open} setOpen={setOpen} setIsSignUp={setIsSignUp} isSignup={isSignup} navigate={props} couponCode={couponCode} state={state} setState={setState}/>)}
      {isForgotOpen && (<ForgotPasswordWeb navigation={undefined} id={""} classes={undefined}  handleOpen={undefined} isForgotOpen={isForgotOpen} handleForgotClose={handleForgotClose} />)}
    </AppBar>
  );
}
// export default TopNavWeb;

export default withRouter(BackToProfileTopNav);