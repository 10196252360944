import React from 'react';
import {
    Button,
  } from "@material-ui/core";
  import { makeStyles, createStyles } from "@material-ui/core/styles";

  const useStyles = makeStyles(theme =>
    createStyles({
        signup1: {
            border: "1px solid #aec2fa !important",
            borderRadius: "30px",
            padding: "10px 15px",
            textDecoration: "none",
            textTransform: "capitalize",
            color: "#aabffa",
            margin: "20px auto 20px",
            fontWeight: "bold",
            backgroundColor: "#eef2fe",
            boxShadow: "0px 10px 20px #A9C9F77A !important",
            "&:hover": {
              backgroundColor: "#A9C9F7 !important",
              boxShadow: "0px 10px 20px #A9C9F77A",
              color: "white !important"
            //   background-color: #a60018;
            //   color: white;
            //   box-shadow: 0px 10px 20px #a6001942;
            }
          }
    })
    );

const CustomOutlinedButton = (props:any) => {
    const classes = useStyles() as any;
    return (
        <div>
            <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.signup1}
                style={{width:props.styleWidth,padding:props.stylePadding,margin:props.styleMargin,fontSize:props.stylefontSize}}
                onClick={props.handleChange}
                {...props}
            >
                {props.title}
            </Button></div>
    )
}

export default CustomOutlinedButton