import React, {useState} from "react";
// Customizable Area Start
import {
  Box,
  Button,
  createStyles,
  Grid,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Avatar, styled
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Link } from 'react-router-dom';
import { CartImage } from "./assest";

const CustomButton = styled('button')({
  '&:hover img': {
    filter: 'brightness(3.5)'
  }
})
// Customizable Area End



const TopNavLoginWeb = (props: any) => {
  const classes = useStyles();
  const handleLogout=()=>{
    localStorage.clear()
  }

  const path=window.location.pathname
  const favPaths=['/mycart','/user-orders',"/merchant-orders","/invoice","/terms"]
  const titlePath=['/mycredits','/settingWeb']
  return (
    // Required for all blocks
    <Box className={classes.backToWork}>
      {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link to='/store'><KeyboardBackspaceIcon style={{fill: 'black', fontSize: 25, position: 'relative', bottom: 1}}/></Link>
        <Typography className={classes.backTitle}
        >
         {titlePath.includes(path)  ? "Back to profile" : "Back to store"} 
        </Typography>
      </div> */}
      {titlePath.includes(path) ? <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link to='/profile'><KeyboardBackspaceIcon style={{fill: 'black', fontSize: 25, position: 'relative', bottom: 1,fontWeight: 'bold'}}/></Link>
        <Typography className={classes.backTitle}
        >
         {/* {titlePath.includes(path)  ? "Back to profile" : "Back to store"}  */}Back to Profile
        </Typography>
      </div>: <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link to='/store'><KeyboardBackspaceIcon style={{fill: 'black', fontSize: 25, position: 'relative', bottom: 1,fontWeight: 'bold'}}/></Link>
        <Typography className={classes.backTitle}
        >
         {/* {titlePath.includes(path)  ? "Back to profile" : "Back to store"}  */}Back to Store
        </Typography>
      </div>}
      { path !== "/user-orders" && path !== "/merchant-orders" ? (
      <div style={{ display: 'flex' }}>
        {!favPaths.includes(path) && (
          <Link to='/mycart'>
            <CustomButton className="btn-logout" color="inherit">
              {/* <LockOutlined /> */}
              <img src={CartImage} alt="Cart LOGO" style={{ height: "27px" }} />
            </CustomButton>
          </Link>
        )}
    <Link to="/profile">
      <Avatar className="btn-profile" style={{ width: '46px', height: '46px', marginLeft: '6px',backgroundColor:'rgb(224, 236, 253)'}} alt="Profile Image" src={localStorage.getItem("userProfileData") || localStorage.getItem("userProfile") as any} />
    </Link>
  </div>
) : null}
    </Box>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "15px 0",
      backgroundColor: "#f9f4f4",
      height: "100vh",
      width: "100%",
      textAlign: "left",
    },
    backToWork: {
      backgroundColor: "#fff",
      padding: "25px 50px",
      display: "flex",
      justifyContent: "space-between",
    },
    backTitle: {
      fontWeight: 700,
      color: "#231F20"
    },
    mainRoot: {
      backgroundColor: "#fff",
      borderRadius: 20,

      margin: "15px auto",
      width: "95%",
    },
    rootSpacing: {
      padding: "24px",
      "& h4": {
        color: "#700010",
        fontWeight: "600",
        marginBottom: "15px",
      },
      "& p": {
        fontSize: "16px",
        color: "#808080",
        padding: "10px 0",
        lineHeight: "1.6",
      },
    },
    buttonBox: {
      textAlign: "right",
    },
    continue: {
      border: "1px solid #a60018",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "#fff",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#a60018",
      boxShadow: "0px 10px 20px #a6001942 !important",
      width: "80%",
      "&:hover": {
        backgroundColor: "#a60018",
      },
    },
    rootMedia: {
      alignItems: "flex-end",
    },
    media: {
      height: "150px",
      padding: "5px 10px 5px 0",
      margin: "15px 0 !important",
      cursor: "pointer",
      "& .MuiImageListItem-root": {
        height: "150px !important",
        padding: "0 5px!important",
        "& .MuiImageListItem-item": {
          borderRadius: "10px",
        },
      },
    },
  })
);

export default TopNavLoginWeb;