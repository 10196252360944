export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img37bfe3ba5b0b09897c1a10d05dcfedf145306428 = require("../../global_assets/37bfe3ba5b0b09897c1a10d05dcfedf145306428.png");
export const img803b9c37aafad0151b06a2a4ed7201852f10a212 = require("../../global_assets/803b9c37aafad0151b06a2a4ed7201852f10a212.png");
export const img47676a188c9c093e814300187bf9a3c0eab5ac2a = require("../../global_assets/47676a188c9c093e814300187bf9a3c0eab5ac2a.png");
export const imgec2518ba77fb9b94cd35a5f402a78b05ebebe1c5 = require("../../global_assets/ec2518ba77fb9b94cd35a5f402a78b05ebebe1c5.png");
export const img1f85f39378b93e6e245b985520bb8ec31f68d616 = require("../../global_assets/1f85f39378b93e6e245b985520bb8ec31f68d616.png");
export const img379a20ffc7e6fa5e7a29faaf1143c07fc8e28264 = require("../../global_assets/379a20ffc7e6fa5e7a29faaf1143c07fc8e28264.png");
export const img9fa969b0cfa058b02d81fde99817e58a2675f093 = require("../../global_assets/9fa969b0cfa058b02d81fde99817e58a2675f093.png");
export const img1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8 = require("../../global_assets/1cf27b9cac2a334c9f259b433f9fd6b33b34ddb8.png");
export const img585f949ce506324109f05cdc5cbf0f4749808e42 = require("../../global_assets/585f949ce506324109f05cdc5cbf0f4749808e42.png");
export const imgdaa200bfa259dd9046a390ea652430fa088080eb = require("../../global_assets/daa200bfa259dd9046a390ea652430fa088080eb.png");
export const imgaa3872f66240290a0317f1499ebd7ee1d4fa724e = require("../../global_assets/aa3872f66240290a0317f1499ebd7ee1d4fa724e.png");
export const imgd2a4eb6716aa44e15cec3a7e89302a14fc8ad462 = require("../../global_assets/d2a4eb6716aa44e15cec3a7e89302a14fc8ad462.png");
export const imgd692c355c1f26f81ceb7672dbb9ec69a833eb93a = require("../../global_assets/d692c355c1f26f81ceb7672dbb9ec69a833eb93a.png");
export const img565fc4fbaaa8696beab2ac5c45947d555a3fcfd2 = require("../../global_assets/565fc4fbaaa8696beab2ac5c45947d555a3fcfd2.png");
export const img28fb54c2204773592e89b6c9889cc0bff9b0c9f7 = require("../../global_assets/28fb54c2204773592e89b6c9889cc0bff9b0c9f7.png");
export const img9fcaeb8d6c801eab0ed1f6dcdae136473d1baf45 = require("../../global_assets/9fcaeb8d6c801eab0ed1f6dcdae136473d1baf45.png");
export const imgd58cb4034702085a44f976d38d1201eafbce1571 = require("../../global_assets/d58cb4034702085a44f976d38d1201eafbce1571.png");
export const img228ba19860654dac423b1de6e666b6ae0c201a4a = require("../../global_assets/228ba19860654dac423b1de6e666b6ae0c201a4a.png");
export const imgf35f68f66053435ea1beb1719b87c874c3c4076b = require("../../global_assets/f35f68f66053435ea1beb1719b87c874c3c4076b.png");
export const img6aebf427fcd082273372c02430551cafdfb7cc20 = require("../assets/6aebf427fcd082273372c02430551cafdfb7cc20.png");
export const imgf8c723ed6d241f1276137e4659038fefa9a1a65f = require("../assets/f8c723ed6d241f1276137e4659038fefa9a1a65f.png");
export const img41c78662a26d2112584e55175de35e189d7fe9f9 = require("../assets/41c78662a26d2112584e55175de35e189d7fe9f9.png");
export const profile = require("../assets/profile.jpg")
export const cowImage = require("../../user-profile-basic/assets/edit.png") 
export const editImage= require("../assets/edit.png")