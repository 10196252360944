import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import MailOutlineSharp from "@material-ui/icons/MailOutlineSharp";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import Modal from "@material-ui/core/Modal";
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

// Customizable Area End

import ForgotPasswordControllerWeb, {
  Props,
} from "./ForgotPasswordControllerWeb";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  imageStyle: {
    width: 100,
  },
  qualificationBlock: {
    marginTop: 40,
  },
  learnMore: {
    color: "#0000FF",
    cursor: "pointer",
  },
  root: {
    margin: 'auto',
    position: 'absolute',
    width: '400px',
    backgroundColor: "#fff",
    borderRadius: '40px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
    textAlign: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    "& h2": {
      color: "#A9C9F7",
      fontWeight: "bold",
      marginTop: "15px",
      marginBOttom: "30px",
      paddingBottom: "10px",
      textTransform: "capitalize",
      // fontSize: "48px"
    }
  },
  rootOuter: {
    width: "30ch",
    margin: "auto",
  },
  textfield: {
    border: "1px solid #ccc",
    boxShadow: "none",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    margin: "8px auto",
    textAlign: "left",
    width: "21ch",
    padding: "8px 12px",
    "& input": {
      fontSize: "12px",
      color: "#231F20",
      fontWeight: "bold",
      paddingLeft: "12px",
      fontFamily:"roboto"
    },
    "& ::after": {
      display: "none",
    },
    "& ::before": {
      display: "none",
    },
  },
  iconInput: {
    // backgroundColor: "#a6001915",
    borderRadius: "5px",
    padding: "8px",
    marginRight: "5px",
  },
  iconInputSize: {
    fontSize: "18px",
    // color: "#a60018",
  },
  linkLogin: {
    width: "100%",
    textAlign: "left",
    fontSize: "12px",
    color: "#700010",
    "& span ": {
      color: "#808080",
      paddingLeft: "5px",
      textDecoration: "none",
    },
  },
  loginBtn: {
    "& button": {
      border: "1px solid #A9C9F7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "white",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#A9C9F7",
      boxShadow: "0px 10px 20px #A9C9F77A !important",
      width: "85%",
      "&:hover": {
        backgroundColor: "#A9C9F7",
      },
    },
  },
  haveAnAccount: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    color: "#808080",
  },
  haveAnAccountLink: {
    color: "#700010",
    paddingLeft: "5px",
    textDecoration: "none",
  },
  iconClose: {
    position: "absolute",
    top: "20px",
    right: "25px",
    backgroundColor: "rgb(224, 236, 253)",
  },
  iconCloseSvg: {
    color: "#231F20",
    fontSize: "14px",
  },
  radioLabel: {
    marginRight: "0px",
    marginLeft: "10px",
    textAlign: "left",
    "& span ": {
      fontSize: "12px",
      color: " #a60018",
      "& svg ": {
        height: "12px",
        width: "12px",
      },
    },
  },
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class ForgotPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.openSnackBar &&
         <Snackbar open={this.state.openSnackBar} autoHideDuration={6000}   anchorOrigin={{ vertical:'top', horizontal:'right' }}>
         <Alert  severity="success">
           Email sent Successfully
         </Alert>
       </Snackbar>
        }
        <Container maxWidth="sm">
          <Modal
            open={this?.props?.isForgotOpen ?? false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={webStyle.root}>
              <Typography variant="h4" component="h2">
                {"Enter Email"}
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  backgroundColor: "rgb(224, 236, 253)",
                }} aria-label="close"
                onClick={this.props && this.props.handleForgotClose && this.props?.handleForgotClose}
              >
                <Close style={webStyle.iconCloseSvg} />
              </IconButton>

              <Box sx={webStyle.rootOuter}>

                <Typography style={{ width: "100%", textAlign: "center", fontSize: "13px", color: "#231F20",marginTop:"-10px" }}>
                  Enter your Email ID to get the Link
                </Typography>

                <Box sx={webStyle.textfield}>
                  <IconButton style={webStyle.iconInput}>
                    <MailOutlineRoundedIcon style={webStyle.iconInputSize} />
                  </IconButton>
                  <Input
                    data-testid="txtInputEmail"
                    placeholder={"Email ID"}
                    fullWidth={true}
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  />
                </Box>
                <span style={{color:'#a13529'}}>{this.state.emailError}</span>

                <Box>
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={this.validateEmail}

                    style={{
                      border: "1px solid #A9C9F7",
                      borderRadius: "30px",
                      padding: " 10px 55px",
                      textDecoration: "none",
                      textTransform: "capitalize",
                      color: "white !important",
                      margin: "20px auto 20px",
                      fontWeight: "bold",
                      backgroundColor: "#A9C9F7",
                      boxShadow: "0px 10px 20px #A9C9F77A !important",
                      width: "81%",
                      fontSize:"14px",
                      fontFamily:"roboto"
                    }}
                  >
                    Get The Link {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>

              </Box>
            </Box>
          </Modal>
        </Container>
      </ThemeProvider>


    );
  }
}