import React from 'react';
import { Elements,ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe("pk_test_51PLJDUIQ0Ku0TrCz7biqLavkd0Kr3vOkcFoqbAEbuHNkmN2LMJWjZcx20O3JPIBy2GSLRZ1JPpE3GNUxQrQnBWhd00qHvMJfFb")
import './style.css';
import MyCart from '../MyCart.web'
const InjectedStripePayment = (props:any) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <>
      <MyCart {...props} stripe={stripe} elements={elements}/>
      {/* <PaymentForm {...props} stripe={stripe} elements={elements} /> */}
      </>
    )}
  </ElementsConsumer>
);
const Stripe: React.FC = (props:any) => {
  return (
    <Elements stripe={stripePromise}>
    <InjectedStripePayment {...props}/>
  </Elements>
  );
};

export default Stripe;