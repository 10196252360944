import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface IFarmdata {
  id: string;
  type: string;
  attributes: FarmAttributes;
}

interface FarmAttributes {
  title: string;
  sub_title: string;
  address: string;
  step_1: string;
  step_2: string;
  created_at: string;
  updated_at: string;
  images: Image[];
  profile_images: Image[];
  step1_images: Image[];
  step2_images: Image[];
  productName: string;
  description: string;
  price: number;
  product_image: string;
  url: string;
  catalogue_id:number;
}

interface Image {
  id: number;
  filename: string;
  url: string;
  type: string;
}
export interface ITermsCondsUsers {
  account_id: string;
  accepted_on: string;
  email: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  termsCondsUserList: ITermsCondsUsers[];
  apiTermsCallId:string
  data:any
  farmData: IFarmdata[] | null
  // apiEmailCallId:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutUsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailCallId: string="";
  apiTermsCallId: any;
  apiFarmDetaisCallId: any;
  // Customizable Area Start
  getFarmDataApiCallId: unknown
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      termsCondsUserList: [],
      apiTermsCallId:"",
      data:null,
      farmData: null
      // apiEmailCallId:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
     
      if (apiRequestCallId === this.apiTermsCallId) {
        console.log("RESP",responseJson)
        this.setState({data:responseJson?.data})
      } else if(apiRequestCallId && this.getFarmDataApiCallId && apiRequestCallId ===this.getFarmDataApiCallId){
        this.setState({farmData: responseJson.data})
      }
    }
    // Customizable Area End
  }
  
  componentDidMount = async () => { 
    this.callAboutUsApi()
    this.getFarmdata()
  };
  
  callAboutUsApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token":localStorage.getItem('authToken')
    };
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTermsCallId = getValidationsMsg.messageId;
    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/contents"
    );
    	
    getValidationsMsg.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headers)
   );
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
     
  getFarmdata() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem('authToken')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFarmDataApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_farm/farms"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
