import React from "react";
import {
    Box,
    Modal,
    Typography, RadioGroup, Radio, FormControlLabel, Button, styled,
    withStyles,
    FormControl, CardActionArea, Card, CardActions, CardMedia, Grid, CardContent,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomSelect } from "../../../components/src/CustomSelect";
// import { cowimg } from "./assets";
import CustomOutlinedButton from "../../../components/src/CustomOutlinedButton.web";
import { cowImage } from './assets';
import { RadioProps } from '@material-ui/core/Radio';
import { pink } from '@material-ui/core/colors';
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";
import ProfileControllerWeb, {
    Props,
} from "./ProfileControllerWeb";
const StyleRadio = withStyles({
    root: {
        color: pink[50],
        '&$checked': {
            color: "#763b47",
        },
    },
    checked: {},
})((props: RadioProps) => <Radio {...props} />);
export default class MyCredits extends ProfileControllerWeb {
    constructor(props: Props) {
        super(props);
    }

    renderSelect = (item?: any) => {
        return <Box>

            <Box style={{ display: 'flex', gap: 20 }}>
                <Box style={{ borderRadius: '15px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <FormControlWeb variant="outlined" style={webStyle.formControl}>
                        <CustomSelect
                            data-test-id="example"
                            label="example"
                            hideLabel
                            options={this.state.categoryData as any}

                            onChange={(e) => { item ? this.handleMultipleProduct(item, e) : this.handleSelectedProduct(e) }}
                            value={item ? item : this.state?.selectedCategory}
                        />
                    </FormControlWeb>
                    {/* <Box><ExpandMoreIcon /></Box> */}
                </Box>
                <Box style={{ borderRadius: '15px', backgroundColor: 'rgb(224, 236, 253)', padding: '10px 20px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                    <Box style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                        <button data-test-id="example1" onClick={() => item ? this.handleMultipleDecreaseQuantity(item) : this.decreaseQuantity()} style={{ width: '25px', height: '25px', borderRadius: '50%', backgroundColor: 'white', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: "none", cursor: "pointer" }} >-</button>
                        <Typography variant="h6">{item ? item.quantity : this.state.quantity}</Typography>
                        <button data-test-id="example2" onClick={() => item ? this.handleMultipleIncreaseQuantity(item) : this.increaseQuantity()} style={{ width: '25px', height: '25px', borderRadius: '50%', backgroundColor: 'white', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: "none", cursor: "pointer" }}>+</button>
                    </Box>
                </Box>
            </Box>
            {/* <Typography variant="body1" style={{ textAlign: 'end', fontSize: 14, color: 'rgb(112, 0, 16)' }} onClick={() => this.increaseCutsQuantity()}>Choose cuts to pick up</Typography>
        {this.renderInputFields()} */}
        </Box>
    }
    modalShow = () =>
        <Modal
            open={this.state.isModalAnalytic}
            onClose={this.handleModalClose}
            className="modal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={webStyle.profileBox}
        // disableBackdropClick
        >
            <Box style={webStyle.modalDiv}>
                <Box style={webStyle.modalSubDiv}>
                    <Box style={{ display: 'flex', gap: '10px', height: 110 }}>
                        <Box style={{ width: '50%' }}>
                            <img style={{ width: '100%', height: '100%', borderRadius: 15 }} src='https://picsum.photos/200/300' alt="cow image" />
                        </Box>
                        <Box style={{ backgroundColor: 'rgb(224, 236, 253)', borderRadius: '10px', width: '50%', padding: '20px' }}>
                            <Typography variant="body1" style={{ color: '#231F20', }}>Total Available Cuts</Typography>
                            <Typography variant="h6" style={{ color: '#231F20', fontWeight: 600 }}>10</Typography>
                        </Box>
                    </Box>
                    <Box style={{ paddingBlock: 30, textAlign: 'center', fontWeight: 700, fontSize: '26px' }} >
                        Pickup / Deliver Remaining Cuts
                    </Box>

                    <Box>
                        <Typography variant="body1" style={{ color: '#231F20', }}>Choose an Option</Typography>
                        <CustomRadioGroup
                            data-testid='modal-test'
                            onChange={(e) => this.setState({ selectedRadioButton: e.target.value })}
                            value={this.state.selectedRadioButton}
                            aria-label="quiz" name="quiz" style={{ borderRadius: '15px', backgroundColor: 'rgb(224, 236, 253)', padding: '7px 20px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <FormControlLabel value="pickup" control={<Radio />} style={{ color: '#231F20', fontWeight: 600 }} label="Pickup" />
                            <FormControlLabel value="deliever" control={<Radio />} style={{ color: '#231F20', fontWeight: 600 }} label="Deliver" />
                            <FormControlLabel value="shipping" control={<Radio />} style={{ color: '#231F20', fontWeight: 600 }} label="Shipping" />
                        </CustomRadioGroup>
                    </Box>
                    <Typography variant="body1" style={{ color: '#231F20', marginTop: '20px' }}>Choose cuts to pick up</Typography>

                    {this.renderSelect()}
                    <Typography data-test-id="cuts-increase-quantity" variant="h1" style={{ textAlign: 'end', fontSize: 14, color: '#231F20', cursor: 'pointer' }} onClick={this.increaseCutsQuantity}>+Add another cut</Typography>
                    {this.renderInputFields()}

                    <Box style={{ marginBottom: '5px' }}>
                        <Typography variant="body1" style={{ color: '#231F20', }}>Enter Nearest Location to Pick Up</Typography>
                        <input type="text" placeholder="e.g. afaew" style={{
                            borderRadius: '15px', backgroundColor: 'rgb(224, 236, 253)',
                            padding: '7px 20px', height: '50px', border: 'none'
                        }} />
                        {this.state.selectedRadioButton === "pickup" &&
                            <>
                                <RadioGroup aria-label="quiz" name="quiz" style={{ marginTop: 10, borderRadius: '15px 15px 0px 0px', backgroundColor: 'rgb(224, 236, 253)', padding: '10px 20px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <FormControlLabel value="radiobtn" control={<Radio />} style={{ color: 'grey' }} label="Pickup (store branch: fhda sdgdg gfgf)" />
                                </RadioGroup>
                                <Box style={{ backgroundColor: 'rgba(255, 43, 73, 0.04)', paddingBottom: 10, borderRadius: '0px 0px 15px 15px' }}>
                                    <Box style={{ color: '#231F20)', fontSize: '20px', fontWeight: 500, padding: '15px 0px 0px 15px' }}>Available Slots</Box>
                                    <Box style={{ marginTop: 10, display: 'flex', gap: '8px', flexWrap: "wrap" }}>
                                        {this.state.availableSlot?.map((i: any) => {
                                            return <>
                                                <Box
                                                    data-test-id="availableSlots"
                                                    onClick={(e) => this.setState({ selectedSlot: i })}
                                                    style={{ color: this.state.selectedSlot === i ? 'white' : 'gray', backgroundColor: this.state.selectedSlot === i ? '#A9C9F7' : 'white', borderRadius: '12px', padding: '12px', fontSize: '15px', width: "80px" }}>{i}</Box>
                                            </>
                                        }

                                        )}
                                    </Box>
                                </Box>
                            </>
                        }
                        {
                            (this.state.selectedRadioButton === "deliever" || this.state.selectedRadioButton === "shipping") &&
                            <div>
                                {this.state.selectAddress.map((address: any) => {
                                    const attr = address.attributes;
                                    // console.log(this.state.checkAddress, "address", attr.id === this.state.checkAddress);
                                    return <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            color: "#231F20",
                                            marginTop: 10
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#231F20",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div style={webStyle.list} />{" "}
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    data-testid='selected-address'
                                                    aria-label="address" name="address" value={+(this.state.checkAddress)} onChange={(e) => this.handleAddressChange(e)}>
                                                    <FormControlLabel
                                                        value={attr.id}
                                                        control={
                                                            <StyleRadio />
                                                        }
                                                        label={`${attr.address_type}`}
                                                    />
                                                </RadioGroup>
                                            </FormControl>{" "}
                                        </div>
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                marginTop: 6,
                                                marginLeft: 30,
                                                marginBottom: 25,
                                            }}
                                        >
                                            {this.humanizeAddress(attr)}
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <CustomOutlinedButton
                            data-test-id="modal-analytic"
                            stylePadding={'12px'} styleMargin={'0px auto 20px'} styleWidth={'220px'} title={"Cancel"}
                            handleChange={this.handleModalClose}
                        />
                        <CustomOutlinedButton
                            data-test-id="modal-analytic"
                            stylePadding={'12px 30px'} styleMargin={'0px auto 20px'} title={"Submit Pickup Request"}
                            handleChange={this.handleSubmitButton}
                        />
                    </Box>
                </Box>
            </Box>
        </Modal>

    renderInputFields = () => {
        const inputFields: any = [];
        console.log(this.state.Cutsquantity, this.state.inputData, "//////logdata ")
        for (let i = 0; i < this.state.Cutsquantity; i++) {
            return this.state.inputData.map((item: any, index: any) => (
                <Box key={index} style={{ width: '100%', position: 'relative', marginTop: 20 }}>
                    <FormControl style={{ width: '100%' }}>


                        {this.renderSelect(item)}

                    </FormControl>
                    <Box data-test-id="cuts-quantity" onClick={() => this.decreaseCutsQuantity(item)} style={{ position: 'absolute', right: 0, top: -20, color: 'red', cursor: "pointer" }}>
                        Delete
                    </Box>
                </Box>
            ));
        }
        return inputFields;
    };

    render() {
        console.log(this.state.inputData, "==============inputData")
        return (
            // Required for all blocks
            <>
                <TopNavLoginWeb />
                {this.modalShow()}                <div style={webStyle.root}>
                    <div style={webStyle.rootContainer}>
                        <Typography variant="h5" style={webStyle.heading}>My Credits</Typography>
                        <Grid container spacing={3} style={webStyle.GridBox}>

                            <Grid item xs={12} sm={4} md={3}>
                                <Card className="creditCard" style={webStyle.creditCard}>
                                    <div>

                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                image={cowImage}
                                                alt="Beef"
                                                style={webStyle.cardImgMedia}
                                            />
                                            <CardContent style={{ padding: 0 }}>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    width={"100%"}
                                                    style={webStyle.cutBorder}>
                                                    <Typography gutterBottom variant="subtitle2" component="div">
                                                        {"Purchased Animal"}
                                                    </Typography>
                                                    <Typography gutterBottom variant="subtitle2" style={webStyle.pickDelivery}>{this.state.creditsProduct?.purchased_animal ?? ""}</Typography>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    width={"100%"}
                                                    style={webStyle.cutBorder}>
                                                    <Typography gutterBottom variant="subtitle2" component="div">
                                                        {"Total Cuts"}
                                                    </Typography>
                                                    <Typography gutterBottom variant="subtitle2" style={webStyle.pickDelivery}>{this.state.creditsProduct?.total_cuts ?? ""}</Typography>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    width={"100%"}
                                                    style={webStyle.cutBorder}
                                                    borderBottom={0}>
                                                    <Typography gutterBottom variant="subtitle2" component="div">
                                                        {"Used Cuts"}
                                                    </Typography>
                                                    <Typography gutterBottom variant="subtitle2" style={webStyle.pickDelivery}>{this.state.creditsProduct?.used_cuts ?? ""}</Typography>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                    width={"100%"}
                                                    style={webStyle.cutBorderEnd}
                                                >
                                                    <Typography gutterBottom variant="subtitle2" component="div">
                                                        {"Remaining Cuts"}
                                                    </Typography>
                                                    <Typography gutterBottom variant="subtitle2" style={webStyle.pickDelivery}>{this.state.creditsProduct?.remaining_cuts ?? ""}</Typography>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Box
                                                display={"block"}
                                                width={"100%"}
                                                px={1}
                                                color="#a60018"
                                                fontWeight={"bold"}
                                            >
                                                <CustomOutlinedButton
                                                    data-test-id="modal-analytic"
                                                    stylePadding={'5px 15px'} styleWidth={'100%'} styleMargin={'0px auto 10px'} title={"Pickup/Deliver"}
                                                    handleChange={this.handleOpen}
                                                />
                                            </Box>
                                        </CardActions>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

const CustomRadioGroup = styled(RadioGroup)({
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#A9C9F7'
    }
})

const FormControlWeb = styled(FormControl)({
    width: '100%',
    "& fieldset": {
        display: "none",
    },
});

const webStyle: any = {
    root: {
        backgroundColor: '#F5F2ED',
    },
    rootContainer: {
        padding: '25px 50px',
        maxWidth: '1320px',
        margin: '0 auto',
    },
    profileBox: {
        overflowY: 'scroll',
    },
    GridBox: {
        paddingInline: "0",
        width: "100%",
        height: "auto !important",
        backgroundColor: "#F5F2ED",
        margin: "0px",
        minHeight: "calc(100vh - 187px)",
    },
    creditCard: {
        boxShadow: "0 5px 6px #f1f1f1",
        borderRadius: "15px",
        padding: "1px",
    },
    cardImgMedia: {
        width: '80%',
        borderRadius: "8px",
        objectFit: "contain",
        margin: "8px auto",
        height: "160px",
    },
    backToWork: {
        backgroundColor: "#fff",
        padding: "25px 50px",
        display: "flex",
        justifyContent: "space-between",
    },
    backTitle: {
        fontWeight: 700,
        color: "#700010",
    },
    heading: {
        color: "#231F20",
        padding: "5px 12px",
        fontWeight: "bold",
    },
    pickDelivery: {
        color: '#a13529',
        fontWeight: 'bold',
        paddingLeft: '5px',
    },
    cutBorder: {
        borderBottom: "1px solid #f1f1f1",
        padding: '12px 15px 4px',
        maxWidth: 'calc(90% - 10px)',
        width: 'calc(90% - 10px)',
    },
    cutBorderEnd: {
        padding: '12px 15px 4px',
        maxWidth: 'calc(90% - 10px)',
        width: 'calc(90% - 10px)',
    },
    modalDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    modalSubDiv: {
        width: '570px',
        maxHeight: '600px',
        background: 'white',
        borderRadius: '15px',
        position: 'relative',
        padding: '50px 50px 20px 50px',
        overflowY: 'auto'
    },
    modalHeading: {
        color: "#700010",
        fontWeight: "bold",
        marginTop: "15px",
        paddingBottom: "10px",
        textTransform: "capitalize",
        textAlign: 'center',
    },
};