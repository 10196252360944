import {
    Box,
    Button,
    createStyles,
    Grid,
    ImageList,
    ImageListItem,
    makeStyles,
    Typography,
    Avatar
  } from "@material-ui/core";
  import React from "react";
  import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
  import {UserProfileBasicBlockWeb} from "../../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
  import { withRouter } from 'react-router-dom';

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        padding: "15px 0",
        backgroundColor: "#F5F2ED",
        height: "100vh",
        width: "100%",
        textAlign: "left",
      },
      backToWork: {
        backgroundColor: "#fff",
        padding: "25px 50px",
        display:"flex",
        justifyContent:"space-between",
      },
      backTitle: {
        fontWeight: 700,
        color: "#231F20"
      },
      mainRoot: {
        backgroundColor: "#fff",
        borderRadius: 20,
  
        margin: "15px auto",
        width: "95%",
      },
      rootSpacing: {
        padding: "24px",
        "& h4": {
          color: "#231F20",
          fontWeight: "600",
          marginBottom: "15px",
        },
        "& p": {
          fontSize: "16px",
          color: "#231F20",
          padding: "10px 0",
          lineHeight: "1.6",
        },
      },
      buttonBox: {
        textAlign: "right",
      },
      continue: {
        border: "1px solid #a9caf7b7",
        borderRadius: "30px",
        padding: " 10px 55px",
        textDecoration: "none",
        textTransform: "capitalize",
        color: "#A9C9F7",
        margin: "20px auto 20px",
        fontWeight: "bold",
        backgroundColor: "rgb(224, 236, 253)",
        boxShadow: "0px 10px 20px #A9C9F77A !important",
        width: "80%",
        "&:hover": {
          backgroundColor: "#A9C9F7",
          color:'white'
        },
      },
      rootMedia: {
        alignItems: "flex-end",
      },
      media: {
        height: "150px",
        padding: "5px 10px 5px 0",
        margin: "15px 0 !important",
        cursor: "pointer",
        "& .MuiImageListItem-root": {
          height: "150px !important",
          padding: "0 5px!important",
          "& .MuiImageListItem-item": {
            borderRadius: "10px",
          },
        },
      },
    })
  );
  const InfoPageBlock = (props:any) => {
    const classes = useStyles();
    const [isProfile,setIsProfile]=React.useState({profile:false});
    
    return (
      <>
      <Box className={classes.backToWork}>
        <div style={{display: "flex", alignItems: "center", gap:"10px"}}>
       <KeyboardBackspaceIcon onClick={()=>props.navigation.navigate('Home')}/>
          <Typography className={classes.backTitle} 
>
            Back to Home
          </Typography>
        </div>
      </Box>
      {isProfile && (<UserProfileBasicBlockWeb open={isProfile.profile} isOpen={isProfile} setOpen={setIsProfile}  navigate={props} getProfile={()=>{}}/>)}

      <div className={classes.root}>
        <div className={classes.mainRoot}>
          <div className={classes.rootSpacing}>
            <Typography variant="h3" component="h4">
              What is the Meat Locker?
            </Typography>
            <Typography component="p">
              A Terms and Conditions is not required and it's not mandatory by
              law.
            </Typography>
            <Typography  component="p">
              Unlike Privacy Policies, which are required by laws such as the
              GDPR, CalOPPA and many others, there's no law or regulation on Terms
              and Conditions.
            </Typography>
            <Typography  component="p">
              However, having a Terms and Conditions gives you the right to
              terminate the access of abusive users or to terminate the access to
              users who do not follow your rules and guidelines, as well as other
              desirable business benefits.
            </Typography>
            <Grid container className={classes.rootMedia}>
              <Grid item xs={9}>
                <ImageList className={classes.media} cols={6}>
                  <ImageListItem>
                    <img
                      src={"https://v4.mui.com/static/images/cards/paella.jpg"}
                      alt="Image"
                    />
                  </ImageListItem>
                  <ImageListItem>
                    <img
                      src={
                        "https://v4.mui.com/static/images/image-list/breakfast.jpg"
                      }
                      alt="Image"
                    />
                  </ImageListItem>
                  <ImageListItem>
                    <img
                      src={
                        "https://v4.mui.com/static/images/image-list/burgers.jpg"
                      }
                      alt="Image"
                    />
                  </ImageListItem>
                  <ImageListItem>
                    <img
                      src={
                        "https://v4.mui.com/static/images/image-list/vegetables.jpg"
                      }
                      alt="Image"
                    />
                  </ImageListItem>
                  <ImageListItem>
                    <img
                      src={
                        "https://v4.mui.com/static/images/image-list/olive.jpg"
                      }
                      alt="Image"
                    />
                  </ImageListItem>
                  <ImageListItem>
                    <img
                      src={
                        "https://v4.mui.com/static/images/image-list/mushroom.jpg"
                      }
                      alt="Image"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.buttonBox}>
                  <Button   variant="contained" className={classes.continue} onClick={()=>setIsProfile({profile:true})}>
                    Continue
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      </>
    );
  };
  
  export default withRouter(InfoPageBlock);
  
  