import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// import MailOutlineSharp from "@material-ui/icons/MailOutlineSharp";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
// import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Modal from "@material-ui/core/Modal";
import Alert from "@material-ui/lab/Alert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
// Customizable Area End

import EmailAccountLoginControllerWeb, {
  Props,
} from "./EmailAccountLoginControllerWeb";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const InfoAlert= styled(Alert)( {
  maxWidth: 300,
  marginLeft: 'auto',
  '& .MuiSvgIcon-root': {
    fill: 'rgb(112, 0, 16)',
  }
});

const webStyle: any = {
  imageStyle: {
    width: 100,
  },
  qualificationBlock: {
    marginTop: 40,
  },
  learnMore: {
    color: "#0000FF",
    cursor: "pointer",
  },
  root: {
    margin: "auto",
    position: "absolute",
    width: "400px",
    backgroundColor: "#fff",
    borderRadius: "40px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
    textAlign: "center",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    "& h2": {
      color: "#A9C9F7",
      fontWeight: "bold",
      marginTop: "15px",
      marginBOttom: "30px",
      paddingBottom: "10px",
      textTransform: "capitalize",
      // fontSize: "48px"
    },
  },
  rootOuter: {
    width: "265px",
    margin: "auto",
  },
  textfield: {
    border: "1px solid #ccc",
    boxShadow: "none",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    margin: "8px auto",
    textAlign: "left",
    width: "26ch",
    padding: "8px 12px",
    "& input": {
      fontSize: "12px",
      color: "#000000",
      fontWeight: "bold",
      paddingLeft: "12px",
      fontFamily:"roboto"
    },
    "& ::after": {
      display: "none",
    },
    "& ::before": {
      display: "none",
    },
  },
  iconInput: {
    backgroundColor: "rgba(166, 0, 25, 0.03)",
    borderRadius: "5px",
    padding: "8px",
    marginRight: "5px",
  },
  iconInputSize: {
    fontSize: "14px",
    color: "#231F20",
  },
  iconInputSize1: {
    fontSize: "18px",
    // color: "#a60018",
  },
  linkLogin: {
    width: "100%",
    textAlign: "left",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#A9C9F7",
    margin: "10px 0 0 10px",
    // fontWeight: 400,

    fontfamily: 'roboto',

    "& .MuiIconButton-root ": {
      borderRadius: "50%",
      backgroundColor: "rgba(166, 0, 25, 0.03)",
      width: "0px",
      height: "0px",
      // fontfamily: 'Roboto',
      "& .MuiSvgIcon-root": {
        width: "16px",
        fill: "#A9C9F7",
      },
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        color: "#A9C9F7 !important",
      }
    },
  },
  loginBtn: {
    "& button": {
      border: "1px solid #A9C9F7",
      borderRadius: "30px",
      padding: " 10px 55px",
      textDecoration: "none",
      textTransform: "capitalize",
      color: "white",
      margin: "20px auto 20px",
      fontWeight: "bold",
      backgroundColor: "#A9C9F7",
      boxShadow: "0px 10px 20px #a6001942 !important",
      width: "85%",
      "&:hover": {
        backgroundColor: "#A9C9F7",
      },
    },
  },
  haveAnAccount: {
    width: "100%",
    textAlign: "center",
    fontSize: "12px",
    color: "#808080",
  },
  haveAnAccountLink: {
    color: "#A9C9F7",
    paddingLeft: "5px",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily:"roboto"
  },
  iconClose: {
    position: "absolute",
    top: "20px",
    right: "25px",
    backgroundColor: "#a6001915",
  },
  iconCloseSvg: {
    color: "#231F20",
    fontSize: "14px",
  },

  radioLabel: {
    marginRight: "0px",
    marginLeft: "10px",
    textAlign: "left",
    "& span ": {
      fontSize: "12px",
      color: " #A9C9F7",
      "& svg ": {
        height: "12px",
        width: "12px",
      },
    },
  },
};
export default class EmailAccountLoginBlockWeb extends EmailAccountLoginControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation, handleClose } = this.props;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Modal
            open={this?.props?.open ?? false}
            onClose={() =>
              this.props?.handleClose && this.props?.handleClose("login")
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
             {this.state.showAlert && (
              <InfoAlert
                severity="error"
                className="alert"
                onClose={this.handleCloseAlert}
              >
              Invalid credentials
              </InfoAlert>
            )}
            <Box sx={webStyle.root}>
              <Typography variant="h4" component="h2">
                {this.labelTitle}
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  backgroundColor: "rgb(224, 236, 253)",
                }}
                aria-label="close"
                onClick={() =>
                  this.props &&
                  this.props.handleClose &&
                  this.props?.handleClose("login")
                }
              >
                <Close style={webStyle.iconCloseSvg} />
              </IconButton>
              <Box sx={webStyle.rootOuter}>
                <Box sx={webStyle.textfield}>
                  <IconButton style={webStyle.iconInput}>
                    <MailOutlineRoundedIcon style={webStyle.iconInputSize1} />
                  </IconButton>
                  <Input
                    data-test-id="txtInputEmail"
                    placeholder={"Email ID"}
                    fullWidth={true}
                    value={this.state.email}
                    onChange={(e) => this.setEmail(e.target.value)}
                  />
                </Box>
                <Box sx={webStyle.textfield}>
                  <IconButton style={webStyle.iconInput}>
                    <LockOutlinedIcon style={webStyle.iconInputSize1} />
                  </IconButton>
                  <Input
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Password"}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordField ? (
                            <Visibility style={webStyle.iconInputSize} />
                          ) : (
                            <VisibilityOff style={webStyle.iconInputSize} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box sx={webStyle.linkLogin}>
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    onClick={() =>
                      this.setRememberMe(!this.state.checkedRememberMe)
                    }
                    checkedIcon={<FiberManualRecordIcon fontSize="small" />}
                    icon={<FiberManualRecordOutlinedIcon />}
                    checked={this.state.checkedRememberMe}
                  />{" "}
                  <p style={{fontFamily:"roboto"}}>Remember Me</p>
                  <span
                    style={{ color: "#231F20", cursor: "pointer",fontSize: "14px",fontFamily:'roboto'}}
                    data-test-id={"btnForgotPassword"}
                    onClick={() =>
                      this?.props &&
                      this?.props.handleForgot &&
                      this?.props?.handleForgot()
                    }
                  >
                  Forgot Password ?
                  </span>
                </Box>
                <Box>
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => this.doEmailLogIn()}
                    style={{
                      border: "1px solid #A9C9F7",
                      borderRadius: "30px",
                      padding: " 10px 55px",
                      textDecoration: "none",
                      textTransform: "capitalize",
                      color: "white",
                      margin: "20px auto 20px",
                      fontWeight: "bold",
                      fontSize:"14px",
                      backgroundColor:  "#A9C9F7",
                      boxShadow: "0px 10px 20px #A9C9F780!important"
                      // width: "295px",
                    }}
                  >
                    Log In {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
                <Typography
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#231F20",
                    // fontWeight: 400
                    fontFamily:"roboto"
                  }}
                >
                  Don't have an account?
                  <span
                    data-test-id={"btnForgotPassword"}
                    onClick={() =>
                      this?.props &&
                      this?.props.handleClose &&
                      this?.props?.handleClose("signUp")
                    }
                    style={webStyle.haveAnAccountLink}
                  >
                    Sign Up
                  </span>
                </Typography>
              </Box>
            </Box>
            </div>
          </Modal>
        </Container>
      </ThemeProvider>
    );
  }
}
