import React from "react";
import { Card, CardActionArea, Box, Typography, CardMedia, CardContent, CardActions, IconButton } from "@material-ui/core"
import { Link } from 'react-router-dom';
import BookmarkBorderOutlined from "@material-ui/icons/BookmarkBorderOutlined";

interface Item {
  itemID: string
  itemName?: string|undefined
  itemDescription?: string
  price?: string
  itemImageURL?: string
  isFavourite?: boolean
  unit?: string
}

interface IProductCardProps extends Item {
  addToFavourite?: () => void
  CardCTA?: React.ReactNode
  testID?: string
}

const ProductCard: React.FC<IProductCardProps> = ({
  addToFavourite,
  itemName,
  itemID,
  itemDescription,
  itemImageURL,
  price,
  CardCTA,
  isFavourite,
  unit
}) => {

  return (
    <Card className={"exploreCard"}>
      <div>
        <CardActionArea>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            margin={"15px 0"}
            position="absolute"
            className={"topHeaderBookmark"}
          >
            <Typography variant="caption" component="div">
              -10% off
            </Typography>
            <IconButton style={{backgroundColor: isFavourite? '#A9C9F7' : ''}} aria-label="bookmark" data-test-ID={'bookmark'} onClick={addToFavourite}>
              <BookmarkBorderOutlined />
            </IconButton>
          </Box>
          <Link to={localStorage.getItem('user_type') !== null ? `CategoriesDescription/${itemID}` : "LandingPage"} style={{ textDecoration: 'inherit', color: 'grey' }}>
            <CardMedia
              component="img"
              height="160"
              image={itemImageURL}
              alt="green iguana" />
            <CardContent>
              <Typography gutterBottom variant="h5" style={{fontSize: "1rem", minHeight: '2.7rem'}} component="div">
                {itemName}
              </Typography>
              <Typography variant="body2" style={webStyles.description}>{itemDescription}</Typography>
            </CardContent>
          </Link>
        </CardActionArea>
        <CardActions>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            px={1}
            color="#231F20"
            fontWeight={"bold"}
          >
            <Typography style={{marginBottom: '20px'}}>
              $<span style={{ fontSize: '1.2rem' }}>{price}</span>
              {unit ? `/${unit}` : ""}
            </Typography>
              {CardCTA && CardCTA}
          </Box>
        </CardActions>
      </div>
    </Card>
  )
}
const webStyles = {
  description: {
    color: "#231F20",
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2', /* number of lines to show */
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    minHeight: '40px'
  },
  name: {

  }
}
export default ProductCard