import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    InputLabel,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import './SendInvoice.css';
// Customizable Area End

import MyCartController, { Props } from "./MyCartController";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";
import CustomInlineButton from "../../../components/src/CustomInlineButton.web";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import EmailIcon from '@material-ui/icons/Email';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class SendInvoice extends MyCartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        const { selectedAdress } = this.state;
        const localDiscount = parseInt(localStorage.getItem('coupon_discount') || "") || 0
        return (
            <>
                <CustomModalWeb handleButton={this.handlePopupClose} handleClose={this.handlePopupClose} isModalOpen={this.state.emailFlag} btnTitle={'Continue'} statusImage={EmailIcon} title={'Check Your E-Mail'} description={"Check emails for order details-thanks!"} />
                <TopNavLoginWeb />
                <div className="parentDiv">
                    <Typography variant="h4" style={{ padding: '20px 50px', color: '#231F20', fontWeight: 600 }}>Invoice</Typography>
                    <div>
                        <Grid container >
                            <Grid item xs={12} sm={8} className='InvoiceContainer' style={{ margin: '0rem 2rem' }}>
                                <div style={{ marginBottom: '10px' }}><span className="inoviceDate">Date :</span><span className="inoviceDateTime">{this.state.formatDate}</span></div>
                                <div ><span className="inoviceDate">Due Date:</span><span className="inoviceDateTime"> {this.state.formatDateDeleiver}</span></div>

                                <Grid container spacing={3} className='invoiceContent'>
                                    <Grid item xs={12} sm={6} style={{ padding: '2rem' }}>
                                        <h3 style={{ color: '#231F20' }}>Bill to</h3>
                                        <div className="invoiceFontContent"><p style={{ fontSize: "14px ", color: "#231F20" }}>
                                            {selectedAdress && selectedAdress.address}&nbsp;
                                            {selectedAdress && selectedAdress.state},&nbsp;
                                            {selectedAdress && selectedAdress.zip_code}
                                        </p></div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{ padding: '2rem' }}>
                                        <h3 style={{ color: '#231F20' }}>Shipping Address</h3>
                                        <div className="invoiceFontContent"><p style={{ fontSize: "14px ", color: "#231F20" }}>
                                            {this.humanizeAddress(selectedAdress)}
                                        </p></div>
                                    </Grid>
                                </Grid>

                                <div style={{ marginTop: '3rem' }}>
                                    <TableContainer component={Paper}>
                                        <Table style={webStyle.table} aria-label="simple table">
                                            <TableHead>
                                                 <TableRow>
                                                    <TableCell style={{ color: '#231F20' }}># </TableCell>
                                                    <TableCell style={{ color: '#231F20' }} align="left">Product&nbsp;</TableCell>
                                                    <TableCell style={{ color: '#231F20' }} align="center">Variant&nbsp;</TableCell>
                                                    <TableCell style={{ color: '#231F20' }} align="center">Quantity&nbsp;</TableCell>
                                                    <TableCell style={{ color: '#231F20' }} align="center">Price&nbsp;</TableCell>
                                                    <TableCell style={{ color: '#231F20', paddingRight: 0 }} align="right">Total&nbsp;</TableCell>
                                                </TableRow> 
                                            </TableHead>
                                             <TableBody>
                                                {this.state.myCartsInvoice.order_items?.data?.map((item: any, index: any) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ color: '#231F20' }} component={"th" as any} scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell style={{ color: '#231F20', fontWeight: 600 }} align="left">{item?.attributes?.catalogue?.data?.attributes?.name}</TableCell>
                                                        <TableCell style={{ color: '#231F20' }} align="center">{item?.attributes?.catalogue_variant?.data.attributes.variantType || item?.attributes?.catalogue?.data?.attributes?.name}</TableCell>
                                                        <TableCell style={{ color: '#231F20' }} align="center">{'x' + item?.attributes?.quantity}</TableCell>
                                                        <TableCell style={{ color: '#231F20' }} align="center">{'$' + item?.attributes?.price}</TableCell>
                                                        <TableCell style={{ color: '#231F20', fontWeight: 600 }} align="right">{'$' + item?.attributes?.quantity*item?.attributes?.price}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody> 
                                        </Table>
                                    </TableContainer>
                                    <div className={'downloadContent'}>
                                        <div className="btn-logout" data-testID="btn-logout" style={{ margin: '22px' }} onClick={() => this.onButtonClick()}>
                                            <GetAppRoundedIcon style={{ margin: '10px' }} />
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '2rem' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ color: '#231F20', marginBottom: "10px" }}>Sub total</span>
                                                <span style={{ color: '#231F20', marginBottom: "10px" }}>Delivery Charges</span>
                                                <span style={{ color: '#231F20', marginBottom: "10px" }}>Discount</span>
                                                <span style={{ color: '#231F20', marginBottom: "10px" }}>Meat Storage</span>
                                                {this.state?.fastDelivery && (
                                                    <span style={{ color: '#231F20', marginBottom: "10px" }}>Delivery in 24hrs fee</span>
                                                )}
                                                <span style={{ color: '#231F20', fontWeight: 600 }}>{'Total'}</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                                <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px" }}>{this.convertPrice(this.state.myCartsInvoice?.total_fees)}</span>
                                                <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px" }}>{this.convertPrice(this.state.myCartsInvoice?.delivery_fees)}</span>
                                                <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px" }}>{this.convertPrice(this.state.myCartsInvoice?.discount_amount)}</span>
                                                <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px" }}>{this.convertPrice(this.state.myCartsInvoice?.meat_storage_amount)}</span>
                                                {this.state?.fastDelivery && (
                                                    <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px" }}>{this.convertPrice(this.state.myCartsInvoice?.delivery_hrs)}</span>
                                                )}
                                                <span style={{ color: '#231F20', marginLeft: '1rem', textAlign: 'right', marginBottom: "10px", fontWeight: 600 }}>
                                                    {this.convertPrice(this.state.myCartsInvoice?.total_fees + this.state.myCartsInvoice?.discount_amount + this.state.myCartsInvoice?.meat_storage_amount + (this.state?.fastDelivery ? this.state.myCartsInvoice?.delivery_hrs : 0) + this.state.myCartsInvoice?.delivery_fees)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </Grid>
                            <Grid item xs={12} style={{ marginLeft: '2rem' }} sm={3} className='InvoiceContainer'>

                                <Typography variant="h4" style={{ fontWeight: 600 }}>Send <br /> Your Invoice</Typography>
                                <Box style={{ marginTop: '1rem' }}
                                    component={"div" as any}
                                >
                                    <InputLabel>From</InputLabel>
                                    <input data-testID='form' value={this.state.invoiceForm.from} name='from' readOnly onChange={(e) => this.handleForm(e)} placeholder="Eg:'example@gmail.com '" className="inputFeild" />
                                </Box>
                                <Box style={{ marginTop: '1rem' }}
                                    component={"div" as any}
                                >
                                    <InputLabel>To</InputLabel>
                                    <input data-testID='to' name='to' value={this.state.invoiceForm.to} readOnly onChange={(e) => this.handleForm(e)} placeholder="Eg:'exampleto@gmail.com '" className="inputFeild" />
                                </Box>
                                <Box style={{ marginTop: '1rem' }}
                                    component={"div" as any}
                                >
                                    <InputLabel>Subject</InputLabel>
                                    <input data-testID='subject' name='subject' value={this.state.invoiceForm.subject}  readOnly onChange={(e) => this.handleForm(e)} placeholder="Eg:'invoice#1234 '" className="inputFeild" />
                                </Box>
                                <Box style={{ marginTop: '1rem' }}
                                    component={"div" as any}
                                >
                                    <InputLabel>Message</InputLabel>
                                    <textarea data-testID='message' value={this.state.invoiceForm.message} readOnly className="inputFeild" style={{resize:"none"}}/>
                                    {/* <textarea data-testID='message' name='message' value={this.state.invoiceForm.body} onChange={(e) => this.handleForm(e)} readOnly style={{ padding: '3rem' }} placeholder="Eg:'invoice#1312 '" className="inputFeild"></textarea> */}
                                </Box>
                                <p className="inoviceDateTime">{this.state.formError.submitMsg}</p>
                                <CustomInlineButton type="submit" handleChange={this.handleSubmit} title={"Send"} styleWidth={'100%'} stylePadding={'10px'} />

                            </Grid>
                        </Grid>
                    </div>
                </div >
            </>
        );
    }
}

// Customizable Area Start
const webStyle = {
    productContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: "white",
        marginTop: "20px",
    },
    productBox: {
        height: 250,
        width: "49%",
        marginRight: "5px",
        flexDirection: "column",
    },
    ImgContainer: {
        //marginBottom: 15,
        height: 150,
    },
    productImg: {
        width: "100%",
        height: "100%",
    },
    detailContent: {
        display: "flex",
        flexDirection: "column",
    },
    card: {
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '30px',
        paddingInline: '15px'
    },
    table: {
        minWidth: 650,
    },
    merchanttextfield: {
        boxShadow: "none",
        margin: "8px auto",
        textAlign: "left",
        width: "100%",
        position: "relative",
        "& label": {
            fontSize: "12px"
        },
        "& input ": {
            border: "1px solid #ccc",
            width: "225px",
            marginTop: "8px",
            padding: "13px 15px",
            borderRadius: "10px",
            fontSize: "12px",
            color: "#700010",
            fontWeight: "bold",
            "& ::placeholder": {
                opacity: "1"
            }
        }
    },
    textfield: {
        border: "1px solid #ccc",
        boxShadow: "none",
        borderRadius: "15px",
        margin: "8px auto",
        textAlign: "left",
        width: "30ch",
        padding: "8px 12px",
        position: "relative",
        "& input ": {
            fontSize: "12px",
            color: "#700010",
            fontWeight: "bold",
            paddingLeft: "12px",
            "& ::placeholder": {
                opacity: "1"
            }
        },
        "& button ": {
            padding: "8px",
            "& svg ": {
                fontSize: "14px",
                color: "#a60018"
            }
        }
    },
};
// Customizable Area End



