import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Avatar,
  CardHeader,
  Grid,
  CardContent,
  Dialog,
  styled
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import CalendarToday from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
//@ts-ignore
import "react-datepicker/dist/react-datepicker.css";
// import dateFnsUtils from "@date-io/date-fns";

// import ArrowBack from "@material-ui/icons/ArrowBack";
const MerchantDateBox = styled(Box)({
  '& .MuiSvgIcon-root': {
    color: '#A9C9F7 !important'
  },
  '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selecting-range-start .react-datepicker__day--selecting-range-end':{
    background: '#A9C9F7 !important'
  },
})
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      color: "#7c4b4e",
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import UserOrderManagementController, {
  Props,
} from "./UserOrderManagementController";
import TopNavLoginWeb from "../../../components/src/TopNavLoginWeb";
import { Card } from "react-native-elements";
import CustomModalWeb from "../../../components/src/CustomModal.web";
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import moment from "moment";

export default class UserOrderManagement extends UserOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderOrder(item: any, index: number) {
    return (
      <Grid
        container
        key={index}
        direction="column"
        style={webStyle.orderCardContainer}
      > 
        <Typography style={webStyle.orderCardDate}>
           {moment.utc(item?.attributes?.created_at).format("Do MMM YYYY")} 
        </Typography>
        <Grid container direction="column" style={webStyle.orderCard}>
          <Grid container direction="row" wrap="nowrap">
            <Grid item xs={8}>
              <Typography style={webStyle.orderCardDate}>
                Added Items (
                {item?.attributes?.order_items?.data?.length
                  .toString()
                  .padStart(2, "0")}
                )
              </Typography>
            </Grid>
            {this.state?.orderControl === false ? (
              ""
            ) : (
              <Grid container direction="column">
                <Grid container direction="row" justifyContent="space-between">
                  <Typography>Estimated Delivery</Typography>
                  <Typography>
                    {this.convertDate(item?.attributes?.delivery_date)}
                  </Typography>
                </Grid>
                <Grid style={webStyle.orderCardDeliveryProgresCon}>
                  <Grid
                    style={{
                      ...webStyle.orderCardDeliveryProgres,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {this.state?.orderControl === false ? (
                item?.attributes?.status === "completed" ? (
                  <Typography style={webStyle.delivered}>
                    {"Delivered"}
                  </Typography>
                ) : (
                  <Typography style={webStyle.cancelled}>
                    {"Cancelled"}
                  </Typography>
                )
              ) : (
                <Button
                  data-testID="cancelOrder"
                  onClick={() => this.handlePopupOpen(item?.id)}
                  style={webStyle.orderCardCancel}
                >
                  Cancel Order
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid>
            {item?.attributes?.order_items?.data &&
              item?.attributes?.order_items?.data.map(
                (key: any, num: number) => {
                  return (
                    <CardContent key={num} style={webStyle.cardContent}>
                      <CardHeader
                        style={{ padding: "0px", minWidth: "20%" }}
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            style={webStyle.orderCardImage}
                          >
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={
                                key.attributes?.catalogue?.data?.attributes?.productImage
                                  ? key.attributes?.catalogue?.data?.attributes?.productImage
                                  : "https://upload.wikimedia.org/wikipedia/commons/6/6d/Good_Food_Display_-_NCI_Visuals_Online.jpg"
                              }
                            />
                          </Avatar>
                        }
                        title={
                          <Typography style={webStyle.cardHeading}>
                            {
                              key.attributes?.catalogue?.data?.attributes?.name
                            }
                          </Typography>
                        }
                        subheader={
                          <div>
                            <Typography>
                              {key.attributes.catalogue_variant?.data?.attributes.variantType ||
                                key.attributes?.catalogue?.data?.attributes?.name}
                            </Typography>
                            <Typography>{"count x" + " " + key?.attributes?.quantity}</Typography>
                          </div>
                        }
                      />
                       <div style={{ minWidth: "150px", textAlign: "right" }}>
                        {this.convertPrice(key?.attributes?.price)} {"X  "}
                        {key?.attributes?.quantity}
                      </div>
                      <div style={webStyle.amount}>
                        <span style={webStyle.orderCardPrice}>
                          {this.convertPrice(
                             key?.attributes?.catalogue_variant.data?.attributes.price * key?.attributes?.quantity
                          )}
                        </span>
                      </div> 
                    </CardContent>
                  );
                }
              )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
        {/* <Dialog onClose={this.handlePopupClose} open={this.state.popupOpen}>
          <div style={webStyle.popupDialog}>
            <Typography variant="h2" style={webStyle.popupDisplay}>
              Are you sure to cancel the order
            </Typography>
            <Grid style={webStyle.cancelGrid}>
              <Button
                data-testID="cancelOrderAccept"
                onClick={this.handlePopupClose}
                style={webStyle.cancelOrderNo}
              >
                Cancel
              </Button>
              <Button
                data-testID="cancelOrderDecline"
                onClick={this.handlePopupCancel}
                style={webStyle.cancelOrderYes}
              >
                Confirm
              </Button>
            </Grid>
          </div>
        </Dialog> */}
        <CustomModalWeb handleButton={this.handlePopupCancel} handleClose={this.handlePopupClose} isModalOpen={this.state.popupOpen} btnTitle={'Proceed'} statusImage={BlockOutlinedIcon} title={'Order Cancellation'} description={"Are you sure you want to send a cancellation request?"}/>
        <TopNavLoginWeb />
        <ThemeProvider theme={theme}>
          <Box style={webStyle.container as any}>
            <Box style={webStyle.mainStyle as any}>
              <Grid container>
                <Typography variant="h1" style={webStyle.pageTitle as any}>
                  My Orders
                </Typography>
              </Grid>

              <Grid style={webStyle.filterStyle as any} container>
                <Grid style={webStyle.categoryButtonContainer as any}>
                  {/* Material Link */}
                  <Button
                    data-testID="ongoing"
                    style={{
                      ...(webStyle.categoryButton as any),
                      backgroundColor: this.state?.orderControl
                        ? "#A9C9F7"
                        : "white",
                      color: this.state?.orderControl ? "#FFF" : "#A9C9F7",
                      fontWeight: this.state?.orderControl ? 600 : 300,
                    }}
                    onClick={() => this.setOrderControl(true)}
                  >
                    Ongoing
                  </Button>
                  <Button
                    data-testID="complete"
                    style={{
                      ...(webStyle.categoryButton as any),
                      backgroundColor: !this.state?.orderControl
                        ? "#A9C9F7"
                        : "white",
                      color: !this.state?.orderControl ? "#FFF" : "#A9C9F7",
                      fontWeight: !this.state?.orderControl ? 600 : 300,
                    }}
                    onClick={() => this.setOrderControl(false)}
                  >
                    Completed
                  </Button>
                </Grid>
                <Grid style={webStyle.filterContainer as any}>
                  <div style={webStyle.searchInputDiv as any}>
                    <span style={webStyle.searchIcon as any}>
                      <Search />
                    </span>
                    <input
                      placeholder="Search Order..."
                      id="input-with-icon-adornment"
                      style={webStyle.searchInput as any}
                      onChange={(e) => this.handleOrderSearch(e.target.value)}
                    />
                  </div>
                  {/* calendar icon button */}
                  <MerchantDateBox className="merchantDate">
                     <CalendarToday
                      data-testID="dataicon"
                      onClick={() => this.handleVisiblePicker()}
                      style={webStyle.calenderIcon}
                    /> 
                    <DatePicker
                      data-testID="datepicker"
                      selected={this.state?.startDate}
                      onChange={this.handleChange}
                      startDate={this.state?.startDate}
                      endDate={this.state?.endDate}
                      selectsRange={true}
                      maxDate={this.state.todayDate}
                    />
                  </MerchantDateBox>
                </Grid>
              </Grid>
              {this.state?.orderControl ? (
                <>
                  <Grid container>
                    <Typography
                      variant="subtitle1"
                      style={webStyle.cardTitle as any}
                      >
                    </Typography>

                    <Grid container style={webStyle.orderCardContainer as any}>
                      {!this.state?.ongoingOrderDetails || this.state.ongoingOrderDetails.length === 0 ? (
                        <p>No record found !!!</p>
                      ) : Array.isArray(this.state?.ongoingOrderDetails) ? (
                        this.state?.ongoingOrderDetails.map(
                          (item: any, index: number) => {
                            return this.renderOrder(item, index);
                          }
                        )
                      ) : (
                        Object.entries(this.state?.ongoingOrderDetails).map(
                          ([key, value], index) => {
                            return this.renderOrder(value, index);
                          }
                        )
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container>
                    <Typography
                      variant="subtitle1"
                      style={webStyle.cardTitle as any}
                    >
                      {}
                    </Typography>
                    <Grid container style={webStyle.orderCardContainer as any}>
                      {!this.state?.orderedComplted || this.state.orderedComplted.length === 0 ? (
                        <p>No record found !!!</p>
                      ) : Array.isArray(this.state?.orderedComplted) ? (
                        this.state?.orderedComplted.map(
                          (item: any, index: number) => {
                            return this.renderOrder(item, index);
                          }
                        )
                      ) : (
                        Object.entries(this.state?.orderedComplted).map(
                          ([key, value], index) => {
                            return this.renderOrder(value, index);
                          }
                        )
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle: any = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#F5F2ED",
  },
  mainStyle: {
    width: "94%",
  },
  popupDialog: {
    margin: "20px 40px",
    padding: "20px 40px",
  },
  popupDisplay: {
    color: "#5e2220",
    fontSize: "18px",
    fontWeight: 600,
    padding: "20px 0px 40px",
  },
  pageTitle: {
    color: "#231F20",
    fontSize: "24px",
    fontWeight: 600,
    padding: "56 0 26 0",
  },
  filterStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  categoryButtonContainer: {
    backgroundColor: "#FFF",
    height: "50px",
    borderRadius: "50px",
    marginBottom: "14px",
  },
  categoryButton: {
    height: "48px",
    borderRadius: "40px",
    textTransform: "capitalize",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  filterContainer: {
    display: "flex",
  },
  searchInputDiv: {
    width: "300px",
    height: "50px",
    marginRight: "20px",
    borderRadius: "40px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "30px",
  },
  searchInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    color: "##7c4b4e",
    fontSize: "18px",
  },
  searchIcon: {
    padding: "0 13px",
  },
  cardTitle: {
    color: "#867f7b",
    fontWeight: 400,
    fontSize: 15,
  },
  orderCardContainer: {
    maxWidth: "calc(100% - 170px)",
  },
  orderCard: {
    background: "white",
    borderRadius: "20px",
    padding: "30px",
    marginBottom: "30px",
    marginTop: "10px",
  },
  orderCardDeliveryProgresCon: {
    borderRadius: "10px",
    height: "10px",
    background: "rgb(224, 236, 253)",
    marginTop: "8px",
  },
  orderCardDeliveryProgres: {
    borderRadius: "10px",
    height: "10px",
    background: "#A9C9F7",
    width: "60%",
  },
  orderCardDate: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#89817c",
    textTransform: "uppercase",
  },
  orderCardCancel: {
    textTransform: "capitalize",
    color: "#a0282a",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  orderCardImage: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
  },

  orderCardPrice: {
    color: "#231F20",
    fontWeight: 600,
    fontSize: "16px",
  },
  button: {
    padding: "12px 47px",
    background: "rgb(160,40,42)",
    border: "1px solid rgb(160,40,42)",
    borderRadius: "30px",
    marginLeft: "20px",
    fontWeight: 600,
    color: "#FFF",
    textTransform: "capitalize",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 0,
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "30px",
  },
  cardHeading: {
    display: "flex",
    justifyContent: "space-between",
    color: "#231F20",
    fontWeight: "bold",
  },

  count: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    minWidth: "80px",
  },
  increment: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#611a2014",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  increment1: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelled: {
    display: "flex",
    justifyContent: "space-between",
    color: "#231F20 !important",
    fontWeight: "bold",
  },
  delivered: {
    display: "flex",
    justifyContent: "space-between",
    color: "#231F20 !important",
    fontWeight: "bold",
  },
  cancelOrderNo: {
    textTransform: "capitalize",
    color: "#a0282a",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    border: "1px solid #a0282a",
  },
  cancelOrderYes: {
    textTransform: "capitalize",
    color: "#fff",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    backgroundColor: "#a0282a",
    borderRadius: "10px",
  },
  cancelGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  calenderIcon: {
    position: "absolute",
    display: "block",
    zIndex: 0,
  }
// Customizable Area End
};