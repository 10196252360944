import React from "react";
import "./CateggoriesCategories.css"
import ViewInventoryController, { Props } from "./ViewInventoryController.web";
import Search from "@material-ui/icons/Search";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';


import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
  Typography,
 
} from "@material-ui/core";
import "./Table.css";
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 





export default class ViewInventory extends ViewInventoryController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
   
  
    return (
      <div>
      
        <Box style={styleWeb.backToWork}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Link to='/'><KeyboardBackspaceIcon/></Link>
          <Typography style={styleWeb.backTitle}
          >
            Back to Home
          </Typography>
        </div>
        </Box>
        <div style={styleWeb.root}>
          <h3 style={styleWeb.heading}>Inventory</h3>
          {/* <div> */}
          <div style={{display :"flex",flexDirection:"row"}}>
          <div style={styleWeb.searchInputDiv as any}>
            <span style={styleWeb.searchIcon as any}>
              <Search />
            </span>
            <input
              data-testID="searchOrder"
              placeholder="Search by Order Number..."
              id="input-with-icon-adornment"
              style={styleWeb.searchInput as any}
              value={this.state.serachTerm}
              onChange={this.handleOrderSearch}
            />
            
          </div>
          <Grid container style={styleWeb.GridBox}>
              <Grid item style={{display:"flex",flexDirection:"row",width: "100%",justifyContent: "flex-end"}}>
                <div style={styleWeb.SelectContainerDate}>

                  <style>
                    {`
            .react-datepicker__day--selected,
            .react-datepicker__day--in-selecting-range,
            .react-datepicker__day--selecting-range-start,
            .react-datepicker__day--selecting-range-end {
              background: #A9C9F7 !important;
            }
          `}
                  </style>
                  <DatePicker
                    selected={this.state.InventoryDate}
                    onChange={this.handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Date"
                    maxDate={new Date()}
                  />
                       {this.state.InventoryDate  && this.state.DateChange ? 
                          (
                            <p style={{fontSize: "0.9em",
                              textAlign: "center", color:"#231F20"}}>{this.state.InventoryDate?.toISOString()?.split('T')[0]}</p>
                          )
                       : 
                       <p className="DateChange">Date</p>
                       }  
                     
                  <span  className="SelectArrowDate">
                    <ExpandMoreOutlinedIcon  className="prl-input-arrow" onClick={this.OnClickData}/>
                  </span>
                </div>
                <div style={styleWeb.SelectContainerCat}>
                  <select  
                    style={styleWeb.CategoryselcectDown}
                    value={this.state.InventoryCategory}
                    onChange={
                        this.handleInventoryCategoryChange
                    }
                  >
                    <option value ="" disabled  hidden className="color">Category</option>
                    {this.state.category.map((item:any)=>{
                      console.log("CategoryItem",item);
                      
                      return(
                        <option key={item.id} value ={item.name} className="color">{item.name}</option>
                      )
                    })}
                  </select>
                  <span  className="SelectArrowCat">
                    <ExpandMoreOutlinedIcon  className="prl-input-arrow" onClick={this.OnClickData}/>
                  </span>
                </div>
                <div style={styleWeb.SelectContainerStaus}>
                  <select  
                  
                  className="SU_S_prl-input-dd-s"
                  value={this.state.InventoryStatus}
                  defaultValue={0}
                  onChange={
                    this.handleInventoryStatusChange
                  }
                  placeholder="status"
                  >
                    <option  value ="" disabled  hidden className="color" >Status</option>
                    <option value ={3} className="color">Completed</option>
                    <option value={2} className="color"> Cancelled</option>
                    <option value={1} className="color">On Going</option>
                    <option value={0} className="color">Scheduled</option>
                  </select>
            
                  <span className="dropdownSpan" >
                      <ExpandMoreOutlinedIcon  className="prl-input-arrow" onClick={this.OnClickData}/>  
                  </span>
                </div>
                
               </Grid>
           </Grid>
          </div>
          <div>
         <TableContainer component={Paper} className='TableContainer' >
          <Table style={{ minWidth: 650,}} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell style={{color:"#231F20"}}>
                  <span className='circle'>
                        <Brightness1OutlinedIcon />
                    </span>
                    Order Number
                    </TableCell>
                <TableCell  style={{color:"#231F20"}}>Date</TableCell>
                <TableCell  style={{color:"#231F20"}}>Customer</TableCell>
                <TableCell  style={{color:"#231F20"}}>Destination</TableCell>
                <TableCell  style={{color:"#231F20"}}>Item</TableCell>
                <TableCell  style={{color:"#231F20"}}>Status</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
                {this.state?.ViewInventoryData.length > 0 && !this.state.noOrders ? (
                  <this.InvetoryDate
                    ViewInventoryData={this.state?.ViewInventoryData}
                    DeleteOrder={this.handlePopupOpen}
                  />
                ) : (
                  <div style={{ padding: '20px', fontSize: '24px' }}>
                    No Data
                  </div>
                )}

          </Table>

            
        </TableContainer>
        {this.state.ViewInventoryData?.length !== 0 && 
        <TablePagination
          component="div"
          rowsPerPageOptions={[10,]}
          count={this.state.totalCount||0}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />}
          
          </div>
          
           
        </div>
      </div>
    );
  }
}



export {ViewInventory}



const styleWeb: any = {
  root: {
    backgroundColor: "#F5F2ED",
    // height: "115vh",
    maxHeight: "100%",
    paddingTop: "2rem",
    paddingLeft:"3rem",
    paddingRight:"3rem"
  },
  backToWork: {
    backgroundColor: "#fff",
    padding: "25px 50px",
    display: "flex",
    justifyContent: "space-between",
    height: "55px",
  },
  backTitle: {
    fontWeight: 700,
    color: "#231F20"
  },
  heading: {
    color: "#231F20",
    marginLeft: "10px",
    fontSize: "1.5rem",
  },
  TableContainer :{
    display:"flex",
    justifyContent: "space-between",
  },
  Table:{
    minWidth: 650,
  },
  SelectContainer:{
    width: "105px",
    background: "white",
    borderRadius: "40px",
    position: "relative",
    height: "45px",
    marginTop: "-10px",
    marginRight:"10px"
  },
  SelectContainerDate:{
    width: "142px",
    background: "white",
    borderRadius: "40px",
    position: "relative",
    height: "45px",
    marginTop: "-10px",
    marginRight:"8px",
    
  },
  SelectContainerCat:{
    width: "157px",
    background: "white",
    borderRadius: "40px",
    position: "relative",
    height: "45px",
    marginTop: "-10px",
    marginRight:"10px",
    fontSize: "0.9em"
  },
  SelectContainerStaus:{
    width: "137px",
    background: "white",
    borderRadius: "40px",
    position: "relative",
    height: "45px",
    marginTop: "-10px",
    marginRight:"10px",
 
  },
  selcectDown:{
    background: "none",
    border: "none",
    position: "absolute",
    top: "15px",
    left: "17px"
  },
  
  CategoryselcectDown:{
    background: "none",
    border: "none",
    position: "absolute",
    top: "12px",
    left: "17px",
    width:"110px",
    color: "#231F20",
    cursor:"pointer",
  },
  GridContainer: {
    backgroundColor: "white",
    width: "80%",
    padding: "1rem",
    borderRadius: "1rem",
    color: "#572328",
    cursor: "pointer",
    marginBottom: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  GridBox: {
    // margin: '3rem',
    padding: "1rem 0rem 0rem 3rem",
    justifyContent: "end",
  },
  modalDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modalSubDiv: {
    width: "80%",
    minHeight: "400px",
    background: "white",
    borderRadius: "15px",
    position: "relative",
  },
  iconCloseSvg: {
    color: "#a60018",
    fontSize: "14px",
  },
  modalHeading: {
    color: "#700010",
    fontWeight: "bold",
    marginTop: "15px",
    marginBOttom: "30px",
    paddingBottom: "10px",
    textTransform: "capitalize",
    textAlign: "center",
  },
  cardStorage: {
    backgroundColor: "#a6001915",
    borderRadius: 20,
    padding: "18px 14px",
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
  },
  cardSpendNumber: {
    fontSize: "14px",
    color: "gray",
    minHeight: "45px",
  },
  cardSpendrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardSpendHeading: {
    color: "rgb(87, 35, 40)",
    fontWeight: "600",
  },
  cardSpendSubHeading: {
    color: "rgb(160, 40, 42)",
  },
  cardStorageSection: {
    width: "94%",
    margin: "20px auto",
  },
  chartList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 0,
    fontSize: "13px",
    color: "rgb(87, 35, 40)",
    gap: "30px",
    fontFamily: 'font-family: "Roboto", "Helvetica", "Arial", sans-serif',
  },
  countinueSettingBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  formControl: {
    width: "100%",
    backgroundColor: "rgba(166, 0, 25, 0.082)",
    borderRadius: "20px",
    marginBottom: 15,
  },
  merchantFormControl: {
    width: "100%",
    backgroundColor: "rgba(166, 0, 25, 0.082)",
    borderRadius: "20px",
    marginBottom: 60,
  },
  totalDate: {
    padding: "7px 15px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    fontSize: "12px",
    color: "#3c3e49",
    fontWeight: 500,
  },
  cardSpend: {
    padding: "20px 10px",
  },
  cardTotal: {
    display: "flex",
    justifyContent: "space-between",
  },
  chart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: "200px",
    padding: "10px",
  },
  barContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bar: {
    width: "30px",
    backgroundColor: "#fff",
    marginRight: "5px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  searchInputDiv: {
    width: "300px",
    height: "50px",
    marginRight: "20px",
    borderRadius: "40px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "30px",
  },
  searchInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    color: "#5d2322",
    fontSize: "16px",
    borderRadius: "40px"
  },
  searchIcon: {
    padding: "0 13px",
  },
  dropdown: {
    width: "80px",
    height: "50px",
    marginRight: "20px",
    borderRadius: "40px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingRight: "30px",
  },
};



// Customizable Area Start
