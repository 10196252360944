import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { duration } from "moment";

// Customizable Area Start
interface CreditsResponse {
  animal: string;
  total_cuts: number;
  used_cuts: number;
  remaining_cuts: number;
  total_amount: number;
  no_of_spend_count: number;
  no_of_spend: number;
  cuts_data: any;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  apiFavoritesCallId: string
  apiRecommendationCallId: string,
  addToCart: any,
  data: any,
  favouriteData: any,
  value: number,
  recommendationData: any
  userData: any,
  isOpen: boolean,
  profile: any,
  apiFavouriteRemoveId: any,
  isModalAnalytic: boolean;
  product: any;
  selectedProduct: any;
  availableSlot: any;
  selectedSlot: any;
  creditsProduct: any;
  selectedRadioButton: any;
  selectAddress: any;
  checkAddress: any;
  categoryData:any;
  selectedCategory:any;
  quantity:any;
  Cutsquantity:any;
  inputData:any;
  multipleArr:any;
  productCredits: CreditsResponse | null;
  userProfilePic:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProfileControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiFavoritesCallId: any;
  apiRecommendationCallId: any;
  apiFavouriteRemoveId: any;
  addToCart: any;
  apiFetchCategoriesCallId: any;
  apiCreditsCallId: any;
  apiSlotCallId: any;
  apiAddressAvailableCallId: any;
  apiSubcategoryCallId:any;
  getProfileDataCallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      apiFavoritesCallId: "",
      apiRecommendationCallId: "",
      apiFavouriteRemoveId: "",
      addToCart: "",
      data: [],
      availableSlot: [],
      value: 0,
      favouriteData: [],
      recommendationData: [],
      userData: {},
      isOpen: false,
      profile: '',
      isModalAnalytic: false,
      product: [],
      selectedProduct: { label: "Potato", value: "Potato", category: 26, image: null, cuts: 0 },
      selectedSlot: "6:00AM",
      creditsProduct: "",
      selectedRadioButton: "pickup",
      selectAddress: "",
      checkAddress: "",
      categoryData:[],
      selectedCategory: { label: "Potato", value: "Potato", category: 26, image: null, cuts: 0 },
      quantity:1,
      Cutsquantity: 1,
      inputData: [],
      multipleArr:[],
      productCredits: null,
      userProfilePic:{},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiFavoritesCallId) {
        console.log(responseJson.data,"FavoritesProduct")
        this.setState({ favouriteData: responseJson?.data })
      }
      if (apiRequestCallId === this.apiRecommendationCallId) {
        this.setState({ recommendationData: responseJson?.data })
      }
      if (apiRequestCallId === this.apiFavouriteRemoveId) {
        if (responseJson && responseJson.message) {
          this.callGetFavoriteApi();
        }
        this.callGetRecommendationApi()
      }
      if (apiRequestCallId === this.addToCart) {
        this.props.navigation.navigate('MyCart')
      }
      if (apiRequestCallId === this.apiFetchCategoriesCallId) {
        console.log(responseJson, "categories Data");
        if (responseJson?.data) {
          let arr = [] as any
          responseJson.data.map((i: any) => {
            let item = {} as any;
            item.label = i.attributes?.name;
            item.value = i.attributes?.id;
            item.category = i.attributes?.id;
            item.image = i.attributes?.categoryImage;
            // item.cuts=responseJson.meta.available_cuts;
            arr.push(item);
          })
          this.setState({ product: arr })
        }
      }
      if (apiRequestCallId &&
        this.apiCreditsCallId &&
        apiRequestCallId === this.apiCreditsCallId) {
        // this.props.navigation.navigate('mycredits', { responseJson })
        if (responseJson && responseJson.animal) {
          this.setState({ productCredits: responseJson })
        }
      }
      if (apiRequestCallId === this.apiSlotCallId) {
        // this.setState({ availableSlot: 
        //   responseJson?.avilable_sloat[0]?.available_slot 
        // })
      }
      if (apiRequestCallId === this.apiAddressAvailableCallId) {
        console.log(responseJson, "address api")
        this.setState({ selectAddress: responseJson?.data, checkAddress: responseJson?.data?.[0]?.id })
      }
      if (apiRequestCallId === this.apiSubcategoryCallId) {
        // console.log(responseJson?.data, "subcategories Data");
        if (responseJson?.data) {
          let arr = [] as any
          responseJson.data.map((i: any) => {
            let item = {} as any;
            item.label = i?.attributes?.name|| i?.attributes?.categoryCode;
            item.value = i?.attributes?.name || i?.attributes?.categoryCode;
            item.category = i?.attributes?.id;
            item.image = i?.attributes?.categoryImage;
            arr.push(item);
          })
          this.setState({categoryData: arr })
        }
      }
      if(apiRequestCallId === this.getProfileDataCallId){
        this.setState({userProfilePic:responseJson?.data})
        localStorage.setItem("userProfileData", responseJson?.data?.attributes?.images?.[0].url)
      }
    }
  }
  // Customizable Area End
handleMultipleProduct=(evt:any,val:any)=>
{
  let cpyItem={...evt};
  let cpyArr=[...this.state.inputData];
  let id = this.state.categoryData.filter((item: any) => {
    if (val === item.label) {
      return item;
    }
    return false;
  });
  let idx=cpyArr.findIndex((i)=>i.id==evt.id);
  cpyArr.splice(idx,1,{...id[0],id:evt.id,quantity:cpyItem.quantity});
  this.setState({inputData:cpyArr})
}

handleMultipleIncreaseQuantity=(evt:any)=>
{
  let cpyItem={...evt};
  let cpyArr=[...this.state.inputData]
  cpyItem.quantity=evt.quantity +1
  let idx=cpyArr.findIndex((i)=>i.id==evt.id);
  cpyArr.splice(idx,1,cpyItem);
  this.setState({inputData:cpyArr})
}
handleMultipleDecreaseQuantity=(evt:any)=>
{
  let cpyItem={...evt};
  let cpyArr=[...this.state.inputData]

  cpyItem.quantity=Math.max(evt.quantity - 1, 1)
  let idx=cpyArr.findIndex((i)=>i.id==evt.id);
  cpyArr.splice(idx,1,cpyItem);
  this.setState({inputData:cpyArr})
}
  // Customizable Area Start
  handleCloseProfile = () => {
    this.setState({ isOpen: false });
  }

  handleOpenProfile = () => {
    this.setState({ isOpen: true })
  }

  handleChange = (event: any, newValue: number) => {
    this.setState({ value: newValue });
  };

  handleSetting = () => {
    this.props.navigation.navigate('settingWeb')
  }

  handleRemove = (id: number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : localStorage.getItem("authToken") || ""
    }
   
    const body = {
      favourites: {
        favouriteable_id: 2,
        favouritebale_type: "AccountBlock::Account",
        catalogue_id: id
      }
    }
 
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFavouriteRemoveId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/favourites"
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  
  }

  componentDidMount = async () => {
    this.getProfileDataApi()
    const retrievedObject = JSON.parse(localStorage.getItem("user_data") || "{}");
    console.log({retrievedObject},"=====================retrievedObjectretrievedObject===============")
    this.setState({ profile: localStorage.getItem('photo') })
    this.setState({ userData: (retrievedObject) }, () =>
    console.log("usersta", (this.state)))
    this.callGetFavoriteApi();
    this.callFetchCategoriesApi();
    this.callSubcategoryApi();
    this.callAvailableSlotApi();
    this.callAvailableAddresstApi();
    if (this.props.navigation?.history?.location?.state?.responseJson) {
      this.setState({ creditsProduct: this.props.navigation?.history?.location?.state?.responseJson })
    }
  };

  callGetFavoriteApi() {
    
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const body={
      favouriteable_type:"AccountBlock::Account"
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFavoritesCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/favourites/index_create"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getProfileDataApi =()=> {
    const headers = {
      "Content-Type": configJSON.apiContentType,

      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDataCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
 return this.getProfileDataCallId;
  }
  callGetRecommendationApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiRecommendationCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/recommendation_products?page=1`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  setAddToCart = (id: number) => {
    this.props.navigation.history.push(`CategoriesDescription/${id}`)
  }

  // fetch categories 
  callFetchCategoriesApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchCategoriesCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_categories/categories"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  //My credits
  callCreditsApi(categoryId: number) {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreditsCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/my_credits?category_id=${categoryId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSelectedProduct = (productId: number) => {
    this.callCreditsApi(productId)
  }
  
  //available slots api
  callAvailableSlotApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSlotCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_shippingchargecalculator/pickups"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  //Deleiver Addreess
  callAvailableAddresstApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddressAvailableCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_order_management/addresses"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  public humanizeAddress(address: any = {}) {
    const {
      flat_no,
      address: street,
      address_line_2,
      zip_code,
      city,
      state,
      country,
    } = address;
    console.log({ street })
    if (street === undefined || street === null) return "";

    let addressString = `${flat_no}, ${street}`;

    addressString += ` ${state} ${zip_code} ${country}`;

    return addressString;
  }

  handleAddressChange = (e: any) => {

    this.setState({ checkAddress: e.target.value })
  }

  handleModalClose = () => {
    this.setState({ isModalAnalytic: false })
  }
  handleOpen = () => {
    this.setState({ isModalAnalytic: true });
  };

  handleSubmitButton = () => {
    this.props.navigation.navigate('MyCart', { personalDetail: 1 })
  }
  //subcategory api
  callSubcategoryApi() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSubcategoryCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_catalogue/catalogues/sub_category"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSelectedSubCategory = (  selectedCategory : any) => {
    let id = this.state.product.filter((item: any) => {
      if (selectedCategory === item.label) {
        return item;
      }
      return false;
    })
    const zbc = id[0] || "";
    console.log(zbc, "abcdata")
    this.setState({ selectedCategory: zbc })
  }

  increaseQuantity = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1,
    }));
  };

  decreaseQuantity = () => {
    this.setState((prevState) => ({
      quantity: Math.max(prevState.quantity - 1, 1),
    }));
  };

  // pickUpApi() {
  //   const headers = {
  //     "Content-Type": configJSON.apiContentType,
  //     "token": localStorage.getItem("authToken")
  //   };

  //   const getValidationsMsg = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.apiSubcategoryCallId = getValidationsMsg.messageId;

  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     "bx_block_shopping_cart/order_items/pickup??"
  //   );

  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );

  //   getValidationsMsg.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     "GET"
  //   );
  //   runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  // }
  increaseCutsQuantity = () => {
    let tempArray = [...this.state.inputData];
    tempArray.push({
      id:String(tempArray.length + 1),
      quantity:1
      
    });
    this.setState({
      inputData: tempArray,
      // multipleArr:[...this.state.multipleArr,{}]
    });
  };

  decreaseCutsQuantity = (index:any) => {
    this.setState({
      inputData: [...this.state.inputData].filter(
        (item) => item.id!=index.id
      ),
    });
  };

  handleInputChange = (index:any, value:any) => {
    // Update the input data array when an input field changes
    this.setState((prevState) => {
      let newData = [...prevState.inputData];
  
      return {
        inputData: newData.map((item) => {
          if (index === Object.keys(item)[0]) {
            return {
              [Object.keys(item)[0]]: value,
            };
          }
          return item;
        }),
      };
    });
  };
  // Customizable Area End
}
